import styled from 'styled-components';
import {Body, buttonTextStyles} from '../Texts/Texts';
import {useRef, useState} from 'react';
import {Transition} from 'react-transition-group';
import {ExpandedTooltip} from '../Tooltip/Tooltip';

const NavButtonWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const StyledNavButton = styled.button`
    border-radius: 0;
    border: none;
    ${buttonTextStyles};
    background-color: transparent;
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: ${({$login}) => $login ? "6px 10px" : "6px"};
    cursor: pointer;
    position: relative;
    max-width: 160px;
    height: 100%;

    svg {
        flex-shrink: 0;
        width: 28px;
        height: 28px;

        path { fill: var(--color-white); }
    }

    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:hover {
        background-color: rgba(255, 255, 255, .1);
    }
`;

const StyledExpandedTooltip = styled(ExpandedTooltip)`
    top: 35px;
    padding: 8px 12px;
    opacity: 0;
    transition: opacity .2s ease, top .2s ease;

    &.entering { opacity: 1; top: 40px; }
    &.entered { opacity: 1; top: 40px; }
    &.exiting { opacity: 0; top: 35px; }
    &.exited { opacity: 0; top: 35px; }
`;

export const NavButton = ({className, children, tooltip, ...props}) => {
    const tooltipNodeRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <NavButtonWrapper className={className}>
            <StyledNavButton onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} {...props}>
                {children}
            </StyledNavButton>
            {Boolean(tooltip) &&
                <Transition nodeRef={tooltipNodeRef} in={showTooltip} timeout={200} unmountOnExit>
                    {state => (
                        <StyledExpandedTooltip ref={tooltipNodeRef} className={state}>
                            <Body $white $small>{tooltip}</Body>
                        </StyledExpandedTooltip>
                    )}
                </Transition>
            }
        </NavButtonWrapper>
    );
}