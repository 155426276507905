export const redirectPermanently = (item, url, params) => {
    let targetPath = item?.newPath;

    if(item.hasSearchParams) {
        // If item has 'type' searchParams, find the correct object in typeSearchParamsMap
        if(url?.searchParams?.get('type')) {
            targetPath = typeSearchParamsMap.find(item => item.type === url?.searchParams?.get('type'))?.target || '/boeken';
        }

        // If item has 'authortitle' searchParams, retreive the slug and navigate
        if(url?.searchParams?.get('authortitle')) {
            const isbnFromPathname = url?.searchParams?.get('authortitle')?.split('--')?.pop();

            targetPath = `/product/${isbnFromPathname}`
        }
    }

    // If item has params, navigate to the correct dynamic path
    if(item.hasParams) {
        if(params?.isbn) {
            targetPath = `/product/${params.isbn}`;
        }
    }

    return window?.location?.replace(targetPath);
};

export const typeSearchParamsMap = [
    // example: ?type=Spel
    {
        type: "Spel",
        target: "/boeken/boektype:games"
    },
    {
        type: "Tweedehands",
        target: "/boeken/boektype:tweedehands"
    },
    {
        type: "Ebook",
        target: "/boeken/boektype:digital-download"
    }
]

export const oldUrls = [
    {
        oldPath: "/home",
        newPath: "/"
    },
    {
        oldPath: "/winkels/webwinkel",
        newPath: "/"
    },
    {
        oldPath: "/in-media",
        newPath: "/laat-je-inspireren/in-de-media"
    },
    {
        oldPath: "/onze-tips",
        newPath: "/laat-je-inspireren/tips"
    },
    {
        oldPath: "/onze-tips/onze-boekverkopers",
        newPath: "/laat-je-inspireren/tips"
    },
    {
        oldPath: "/onze-tips/maarten-t-hart",
        newPath: "/laat-je-inspireren/maarten-t-hart"
    },
    {
        oldPath: "/recensies-eva",
        newPath: "/laat-je-inspireren/tips/eva"
    },
    {
        oldPath: "/recensies-lenie",
        newPath: "/laat-je-inspireren/tips/lenie"
    },
    {
        oldPath: "/recensies-ruben",
        newPath: "/laat-je-inspireren/tips/ruben"
    },
    {
        oldPath: "/recensies-els",
        newPath: "/laat-je-inspireren/tips/els-b"
    },
    {
        oldPath: "/recensies-wally",
        newPath: "/laat-je-inspireren/tips"
    },
    {
        oldPath: "/recensies-els-zoetendaal",
        newPath: "/laat-je-inspireren/tips/els"
    },
    {
        oldPath: "/recensies-anne",
        newPath: "/laat-je-inspireren/tips"
    },
    {
        oldPath: "/recensies-connie",
        newPath: "/laat-je-inspireren/tips"
    },
    {
        oldPath: "/recensies-fee",
        newPath: "/laat-je-inspireren/tips"
    },
    {
        oldPath: "/recensies-helen",
        newPath: "/laat-je-inspireren/tips"
    },
    {
        oldPath: "/zoeken",
        newPath: "/boeken",
        hasSearchParams: true
    },
    {
        oldPath: "/klantenservice/contact",
        newPath: "/klantenservice-en-contact"
    },
    {
        oldPath: "/klantenservice/vacatures",
        newPath: "/over-ons/vacatures"
    },
    {
        oldPath: "/klantenservice/giftcards",
        newPath: "/klantenservice-en-contact"
    },
    {
        oldPath: "/klantenservice/over-ons",
        newPath: "/over-ons"
    },
    {
        oldPath: "/klantenservice/veelgestelde-vragen",
        newPath: "/klantenservice-en-contact"
    },
    {
        oldPath: "/klantenservice/contact",
        newPath: "/klantenservice-en-contact"
    },
    {
        oldPath: "/inloggen",
        newPath: "/login"
    },
    {
        oldPath: "/samenwerken",
        newPath: "/winkels"
    },
    {
        oldPath: "/profiel",
        newPath: "/account/mijn-bestellingen"
    },
    {
        oldPath: "/profiel/adressen",
        newPath: "/account/mijn-adressen"
    },
    {
        oldPath: "/profiel/verlanglijst",
        newPath: "/verlanglijstje"
    },
    {
        oldPath: "/profiel/bestellingen",
        newPath: "/account/mijn-bestellingen"
    },
    {
        oldPath: "/profiel/ebooks",
        newPath: "/account/mijn-bestellingen"
    },
    {
        oldPath: "/llp",
        newPath: "/libris-literatuurprijs"
    },
    {
        oldPath: "/kookboekentips",
        newPath: "/rubrieken/eten-en-koken"
    },
    {
        oldPath: "/winkelwagen",
        newPath: "/winkelmandje"
    },
    {
        oldPath: "/bestseller-60",
        newPath: "/bs-60"
    },
    {
        oldPath: "/cadeau-inspiratie",
        newPath: "/spellen-en-cadeaus"
    },
    {
        oldPath: "/kbw-griffels-en-penselen",
        newPath: "/kinderboekenweek/griffels-en-penselen"
    },
    {
        oldPath: "/kinderboekenweek-2022",
        newPath: "/kinderboekenweek"
    },
    // {
    //     oldPath: "/boek/:isbn",
    //     hasParams: true
    // },
    // {
    //     oldPath: "/boek",
    //     newPath: "/boeken",
    //     hasSearchParams: true
    // },
];