import styled from 'styled-components';
import {Label} from '../Buttons/Buttons';

const StyledTagCloud = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    gap: 10px;
    
    ${Label} {
        display: inline;
        width: auto;
        max-width: unset;
    } 
`;

export const TagCloud = ({data}) => {
    if(!data) { return null; }

    return (
        <StyledTagCloud>
            {data.map((item, index) => {
                if(item.linkType === 'absolute'){
                    return <Label key={index} as="a" href={item.url} target={item.newWindow ? "_blank" : "_self"} $variant="outline">{item.linkText}</Label>
                } else {
                    return <Label key={index} to={item.url} $variant="outline">{item.linkText}</Label>
                }
            })}
        </StyledTagCloud>
    );
}