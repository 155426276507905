import styled from 'styled-components';
import {Container} from '../Container/Container';
import {Link, NavLink} from 'react-router-dom';
import {ReactComponent as LogoDeKler} from '../../assets/logos/logo-dekler.svg';
import {ReactComponent as DeliveryIcon} from '../../assets/icons/delivery.svg';
import {ReactComponent as PersonIcon} from '../../assets/icons/person.svg';
import {ReactComponent as CardIcon} from '../../assets/icons/card.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/check-circle.svg';
import {Body, bodyTextStyles} from '../Texts/Texts';

const StyledOrderMenu = styled.section`
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 97;
    width: 100%;
`;

const Flex = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
`;

const LogoBox = styled.div`
    flex-shrink: 0;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const StyledLogoDeKler = styled(LogoDeKler)`
    width: 84px;
    height: auto;
`;

const NavigationBox = styled.div`
    display: flex;
    align-items: center;
`;

const ProgressLink = styled(NavLink).attrs({
    $bold: true,
})`
    background: transparent;
    border: none;
    padding: 0;
    ${bodyTextStyles};
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-primary-10);
    
    @media screen and (min-width: 769px) {
        &:not(:last-child){
            &:after {
                content: '';
                margin: 0 25px;
                vertical-align: middle;
                display: inline-block;
                height: 1px;
                width: 30px;
                background-color: var(--color-primary-10);
            }
        }
    }

    &.active {
        color: var(--color-primary);

        &:not(:last-child) {
            &:after {
                background-color: var(--color-primary);
            }
        }
    }

    &.done {
        font-weight: 400;
        color: var(--color-primary);
    }
    
    @media screen and (max-width: 768px) {
        display: none;
        
        &.active {
            display: inline-block;
        }
    }
`;

const StyledIcon = styled.div`
    display: inline-block;
    margin-right: 5px;
    
    svg { vertical-align: middle; }
    path { fill: var(--color-primary-10); }
    
    ${ProgressLink}.active & {
        path { fill: var(--color-primary); }
    }
`;

const StyledCheckIcon = styled(CheckIcon)`
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    
    path { fill: var(--color-tertiary); }
`;

const EmptyBox = styled.div`
    width: 84px;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const IndicatorBox = styled.div`
    display: none;
    position: relative;
    width: 47px;
    height: 47px;
    border-radius: 50%;

    svg { transform: rotate(270deg); }

    circle {
        stroke: var(--color-primary-10);
        stroke-width: 14;
        fill: none;
    }

    circle:nth-of-type(2) {
        stroke: var(--color-tertiary);
        stroke-dasharray: ${props => props.$circumference};
        stroke-dashoffset: ${props => props.$percentage}; 
        transition: stroke-dashoffset .2s ease;
    }

    ${Body} {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    @media screen and (max-width: 768px) {
        display: inline-block;
    }
`;

export const OrderMenu = ({currentStep}) => {
    // On mobile there is a circle diagram / counter that uses the circumference (2 * pi * radius)
    // To get the correct stroke-dashoffset inside the percentage prop, multiply circumference by
    // opposite value of percentage for example: 60% = 0.6, so we use 0.4
    let circumference = 2 * (22/7) * 43;

    return (
        <StyledOrderMenu>
            <Container>
                <Flex>
                    <LogoBox>
                        <Link to='/'>
                            <StyledLogoDeKler />
                        </Link>
                    </LogoBox>
                    <NavigationBox>
                        <ProgressLink to="/bestellen/levering" className={currentStep > 1 ? 'done' : ''}>{currentStep > 1 ? <StyledCheckIcon/> : <StyledIcon><DeliveryIcon/></StyledIcon>} Levering</ProgressLink>
                        <ProgressLink as={currentStep >= 2 ? NavLink : 'button'} to="/bestellen/jouw-gegevens" className={currentStep > 2 ? 'done' : ''}>{currentStep > 2 ? <StyledCheckIcon/> : <StyledIcon><PersonIcon /></StyledIcon>} Jouw gegevens</ProgressLink>
                        <ProgressLink as={currentStep >= 3 ? NavLink : 'button'} to="/bestellen/betaling"><StyledIcon><CardIcon /></StyledIcon> Betaling</ProgressLink>
                    </NavigationBox>
                    <EmptyBox />
                    <IndicatorBox $circumference={circumference} $percentage={currentStep === 1 ? ((1 - 0.33) * circumference) : currentStep === 2 ? ((1 - 0.66) * circumference) : 0}>
                        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="43" />
                            <circle cx="50" cy="50" r="43" />
                        </svg>
                        <Body $small>{currentStep}/3</Body>
                    </IndicatorBox>
                </Flex>
            </Container>
        </StyledOrderMenu>
    );
}