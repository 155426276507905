import React from 'react';
import {Await, defer, useLoaderData, useLocation} from 'react-router-dom';
import {Blocks} from '../../components/Blocks/Blocks';
import {Seo} from '../../components/Seo/Seo';

export async function dynamicPageLoader({request, params}) {
    const contentRes = fetch(`${process.env.REACT_APP_API_BASE_URL}/dynamic-page?url=${params["*"]}`, { signal: request.signal})
        .then((res) => {
            // Handle if response is not ok, bad request so probably not found
            if (!res.ok) { throw new Response("Network response was not OK, page is probably Not Found", { status: 404 }); }

            return res.json();
        });

    return defer({content: contentRes}) // Deferred
}

export default function DynamicPage() {
    const data  = useLoaderData();
    const { pathname } = useLocation();

    return (
        <>

            <React.Suspense>
                <Await resolve={data.content}>
                    {(content) => (
                        <Seo key={content.title} metaTitle={content.title} metaDescription={content.metaDescription} url={pathname} />
                    )}
                </Await>
            </React.Suspense>

            <React.Suspense>
                <Await resolve={data.content}>
                    {(content) => (
                        <Blocks data={content.blocks} breadcrumbs={content.breadcrumbs} />
                    )}
                </Await>
            </React.Suspense>
        </>
    );
}