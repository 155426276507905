import {Spacing} from '../../components/Spacing/Spacing';
import styled from 'styled-components';
import {Headline2} from '../../components/Headlines/Headlines';
import {Container} from '../../components/Container/Container';
import {ProductItem} from '../../components/ProductItem/ProductItem';

const StyledProductRows = styled.section`
    ${Headline2} { margin-bottom: 30px; }
`;

const Grid = styled.article`
    display: grid;
    gap: ${({$productsPerRow}) => $productsPerRow === "4" ? '60px' : '30px'};
    grid-template-columns: repeat(${({$productsPerRow}) => $productsPerRow === "4" ?  4 : 5}, 1fr);

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 320px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

export const ProductRows = ({data}) => {
    if(!data?.productRows || !data?.productRows?.products ) { return null; }

    return (
        <Spacing>
            <StyledProductRows>
                <Container>
                    <Headline2>{data?.productRows?.title}</Headline2>
                    <Grid $productsPerRow={data?.productRows?.productsPerRow}>
                        {data?.productRows?.products?.map(item => (
                            <ProductItem
                                key={item.isbn}
                                isbn={item.isbn}
                                eanf={item.eanf}
                                title={item.title}
                                author={item.author}
                                price={item.price}
                                imageUrl={item.image}
                                url={item.url}
                                flag={item.secondhand ? "secondHand" : item.eBook ? "ebook" : null}
                                location="product-rows"
                            />
                        ))}
                    </Grid>
                </Container>
            </StyledProductRows>
        </Spacing>
    );
}