import {Headline3, Headline4} from "../Headlines/Headlines";
import styled from "styled-components";
import {Subtitle2} from "../Subtitles/Subtitles";
import {bodyTextStyles} from "../Texts/Texts";
import {IconButton} from "../Buttons/Buttons";
import {ReactComponent as Bin} from "../../assets/icons/bin.svg";
import {ReactComponent as ShoppingCart} from '../../assets/icons/shopping-cart.svg';
import {useFetcher} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useFetchData} from '../../hooks/useFetchData';
import {Spinner} from '../Spinner/Spinner';
import {useProductsInCartContext} from '../../contexts/ProductsInCartContext';
import {BookShelf, ImageWrapper, MainImageWrapper, PriceBox, ProductBox, ProductForm, ProductImage, ProductSpecs, ProductTitle, TitlePrice} from '../CartProduct/CartProduct';
import {ProductFlag} from '../ProductFlag/ProductFlag';
import {sendEcommerceEvent} from '../../events/dataLayer';
import {WISHLIST_LIST} from '../../events/constants';

const Price = styled(Headline4)`
    ${props => props.$inSideDrawer && bodyTextStyles};
    color: var(--color-secondary);;
`;

const Actions = styled.div`
    display: flex;
    margin-bottom: ${props => props.$inSideDrawer ? 0 : '14px'};
    margin-top: auto;
`;

const IconOnly = styled(IconButton)`
    box-shadow: unset;
    margin-left: auto;
`;

export default function WishlistProduct({data, inSideDrawer = false, close, onDelete = null}) {
    const {isbnsWithGiftwrapInCart} = useProductsInCartContext();
    const [priceData, priceError] = useFetchData(!inSideDrawer ? `product-detail?isbn=${data?.isbn}` : null);
    const fetcher = useFetcher();
    let addingToCart = (fetcher.formMethod === "post" && fetcher.state !== 'idle');
    let deleting = (fetcher.formMethod === "delete" && fetcher.state !== 'idle');
    const [deleteISBN, setDeleteISBN] = useState(null);

    const handleDeleteButton = async (isbn) => {
        const formData = new FormData();
        formData.append("isbn", isbn);
        fetcher.submit(formData, { method: 'DELETE', action: '/verlanglijstje'})
        setDeleteISBN(isbn);
    }

    const handleCartButton = async (isbn) => {
        const formData = new FormData();
        formData.append("isbn", isbn);
        if(isbnsWithGiftwrapInCart?.includes(isbn)) formData.append("giftwrap", 128); // Add giftwrap if it is already in cart with giftwrap
        fetcher.submit(formData, { method: 'POST', action: '/winkelmandje'})

        if(data) sendEcommerceEvent('add_to_cart', [data]);
    }

    useEffect(() => {
        if(fetcher.data && fetcher.state === 'idle' && inSideDrawer) {
            onDelete(deleteISBN);
        }
        // eslint-disable-next-line
    }, [fetcher.state, deleteISBN])

    const handleWishlistProductClick = () => {
        if(close) close();

        if(data) {
            if(priceData) data.price = priceData?.product?.price;

            sendEcommerceEvent('select_item', [data], {
                item: {
                    item_list_name: WISHLIST_LIST.name,
                    item_list_id: WISHLIST_LIST.id,
                }
            });
        }
    }

    return (
        <ProductBox>
            <ImageWrapper to={`/${data?.url}`} onClick={handleWishlistProductClick} $inSideDrawer={inSideDrawer}>
                <MainImageWrapper>
                    <ProductImage src={data?.image} alt={data?.title} $inSideDrawer={inSideDrawer} />
                    {data?.eBook &&
                        <ProductFlag flag="ebook" location="WishlistProduct" />
                    }
                </MainImageWrapper>
                <BookShelf/>
            </ImageWrapper>

            <ProductSpecs>
                <Subtitle2>{data?.author}</Subtitle2>

                <TitlePrice>
                    <ProductTitle to={`/${data?.url}`} onClick={handleWishlistProductClick} $inSideDrawer={inSideDrawer} $bold>
                        <Headline3>{data?.title}</Headline3>
                    </ProductTitle>
                    {!inSideDrawer && (
                        priceData ?
                        <PriceBox $inSideDrawer={inSideDrawer}>
                            <Price $bold $inSideDrawer={inSideDrawer}>{priceData?.product?.price ?? "Fout"}</Price>
                        </PriceBox> : priceError ? null : <Spinner $extraSmall />
                    )}
                </TitlePrice>

                <ProductForm>{data?.productForm}</ProductForm>

                <Actions $inSideDrawer={inSideDrawer}>
                    <IconOnly as="button" onClick={() => handleDeleteButton(data?.isbn)} disabled={deleting || addingToCart} $variant='outline'><Bin/></IconOnly>

                    <IconButton as="button" onClick={() => handleCartButton(data?.isbn)} disabled={deleting || addingToCart} $variant="secondary"><ShoppingCart /></IconButton>
                </Actions>
            </ProductSpecs>
        </ProductBox>
    )
}