import React, {useEffect, useRef} from 'react';
import {Container} from '../../components/Container/Container';
import {Headline2, Headline3} from '../../components/Headlines/Headlines';
import styled from 'styled-components';
import {useForm} from 'react-hook-form';
import FormField from '../../components/Fields/FormField';
import {Body} from '../../components/Texts/Texts';
import {Button} from '../../components/Buttons/Buttons';
import {FormRow, FormRowHidden, FormRowItem} from '../../components/Fields/FormRows';
import {redirect, useActionData, useLocation, useNavigation, useSubmit} from 'react-router-dom';
import {createCustomerFull} from '../../api/customer';
import {Toaster} from '../../components/Toaster/Toaster';
import {useAddressFromPostalCode} from '../../hooks/useAddressFromPostalCode';
import {toast} from "react-toastify";
import {Seo} from '../../components/Seo/Seo';

const StyledRegister = styled.div`
    margin: 56px 0 175px;
`;

const RegisterContainer = styled.section`
    max-width: 1020px;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
    padding: 40px 60px;
    margin-top: 56px;

    @media screen and (max-width: 1024px) {
        padding: 30px 40px;
    }
    
    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 25px;
    }
`;

const FormHeading = styled(Headline3)`
    margin-bottom: 30px;

    &:not(:first-of-type) {
        border-top: 1px solid var(--color-primary-10);
        padding-top: 30px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
`;

const StyledBody = styled(Body)`
    ${({$error}) => $error && `
        color: var(--color-secondary);
        font-weight: 700;
    `};
`;

export async function registerAction({request}) {

    let formData = await request.formData();
    const customerObj = {
        "email": formData.get("email"),
        "password": formData.get("password"),
        "newsletter": JSON.parse(formData.get("newsletter")),
        "salutation":  formData.get("salutation"),
        "firstName":  formData.get("firstName"),
        "prefix":  formData.get("prefix"),
        "lastName":  formData.get("lastName"),
        "phone":  formData.get("phone"),
        "invoiceAddress": {
            "salutation":  formData.get("salutation"),
            "firstName":  formData.get("firstName"),
            "prefix":  formData.get("prefix"),
            "lastName":  formData.get("lastName"),
            "postcode": formData.get("postcode"),
            "number": parseInt(formData.get("number")),
            "postbus": formData.get("postbus"),
            "addressLine1": formData.get("addressLine1"),
            "city": formData.get("city"),
            "country": 2,
            "phone":  formData.get("phone"),
        },
    }

    try {
        await createCustomerFull(request, customerObj);
        toast(<Toaster type="success" title="Gelukt!" message="Je hebt je succesvol geregistreerd!"/>)
        return redirect('/');
    } catch(err) {
        switch (err.status) {
            case 400: {
                return err;
            }
            case 409: {
                return err;
            }
            default: {
                toast(<Toaster type="danger" title="Mislukt!" message="Het is niet gelukt om te registreren."/>)
                return null;
            }
        }
    }
}

export default function Register() {
    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    // Form states and submission function
    const { state } = useLocation();
    const { register, formState: { errors }, setError, handleSubmit, getValues, setFocus, clearErrors, setValue, watch } = useForm({
        defaultValues: {
            salutation: "De heer",
            email: state?.registerEmail,
            newsletter: false
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    // Use action data for setting errors with the response
    const actionData = useActionData();
    const navigation = useNavigation();
    let prevState = useRef('idle');

    useEffect(() => {
        if(prevState.current === 'loading' && navigation.state === 'idle') {
            if(actionData) {
                let err = JSON.parse(actionData);

                // Errorcode 11 = already exists
                if(err?.errorcode === 11) {
                    setError('email', { type: 'required', message: 'Dit e-mailadres wordt al gebruikt'});
                    setFocus('email')
                }
                // If other validation err from api
                if(err?.error) {
                    if(err?.error.startsWith('invoiceAddress') || err?.error.startsWith('deliveryAddress')) {
                        // Postcode API was not ready yet, so set error to postcode field
                        setError('postcode', { type: 'required', message: 'Vul een geldige postcode in'});
                    } else {
                        setError(err?.error, { type: 'required', message: 'Dit veld is verplicht'});
                    }
                }
            }
        }

        prevState.current = navigation.state;
    }, [errors, navigation.state, setFocus, actionData, setError])

    const submit = useSubmit();
    const onSubmit = (data) => {
        clearErrors();

        submit(data, { method: 'post', action: '/registreren' })
    };

    const {displayAddressFromPostalCode, handleAddress} = useAddressFromPostalCode(getValues, setValue);

    return (
        <StyledRegister>
            <Seo metaTitle="Registreren" />

            <Container>
                <Headline2>Nieuw bij De Kler</Headline2>
                <RegisterContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormHeading>Persoonlijke gegevens</FormHeading>
                        
                        <FormRow $spacing="large" $ignore>
                            <FormField
                                type="radio"
                                title="Aanhef"
                                name="salutation"
                                breakPoint={768}
                                options={[
                                    {
                                        "id": "salutationChoice1",
                                        "value": "De heer",
                                        "title": "De heer"
                                    },
                                    {
                                        "id": "salutationChoice2",
                                        "value": "Mevrouw",
                                        "title": "Mevrouw"
                                    },
                                    {
                                        "id": "salutationChoice3",
                                        "value": "-",
                                        "title": "Liever geen van beide"
                                    }
                                ]}
                            />
                        </FormRow>
                        
                        <FormRow $spacing="small" $breakpoint="768px" $fullRow>
                            <FormRowItem>
                                <FormField type="text" title="Voornaam" name="firstName" required={true}/>
                            </FormRowItem>
                            <FormRowItem $small>
                                <FormField type="text" title="Tussenv." name="prefix" maxLength={10} />
                            </FormRowItem>
                            <FormRowItem>
                                <FormField type="text" title="Achternaam" name="lastName" required={true}/>
                            </FormRowItem>
                        </FormRow>

                        <FormRow $spacing="small" $breakpoint="768px">
                            <FormRowItem>
                                <FormField type="text" title="Postcode" name="postcode" onBlur={handleAddress ?? null} required={true} />
                            </FormRowItem>
                            <FormRowItem $small>
                                <FormField type="text" title="Huisnr." name="number" onBlur={handleAddress ?? null} required={true} />
                            </FormRowItem>
                            <FormRowItem $small>
                                <FormField type="text" title="Toev." name="postbus" onBlur={handleAddress ?? null} />
                            </FormRowItem>
                        </FormRow>
                        
                        <FormRow $spacing="large" $ignore>
                            <Body $bold $small>Adres</Body>
                            <StyledBody
                                $error={displayAddressFromPostalCode === "Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen."}
                            >
                                {displayAddressFromPostalCode ?? "Geef een postcode en huisnummer op"}
                            </StyledBody>
                        </FormRow>
                        
                        <FormRow $spacing="small" $breakpoint="768px">
                            <FormRowItem>
                                <FormField type="tel" title="Telefoonnummer" name="phone" required={false}/>
                            </FormRowItem>
                        </FormRow>

                        <FormHeading>Inloggegevens</FormHeading>

                        <FormRow $spacing="small" $breakpoint="768px">
                            <FormRowItem>
                                <FormField type="email" title="E-mailadres" placeholder="E-mailadres" name="email" autoComplete="username" required={true}/>
                            </FormRowItem>
                        </FormRow>

                        <FormRow $spacing="small" $breakpoint="768px">
                            <FormRowItem>
                                <FormField type="password" name="password" autoComplete="new-password" title="Wachtwoord" placeholder="Wachtwoord" watch={watch} required={true} />
                            </FormRowItem>
                            <FormRowItem>
                                <FormField type="password" name="password-repeat" autoComplete="new-password" title="Herhaal wachtwoord" placeholder="Herhaal wachtwoord" watch={watch} required={true} />
                            </FormRowItem>
                        </FormRow>

                        <FormRow $spacing="extraLarge" $ignore>
                            <FormField type="checkbox" title="Schrijf je in voor de nieuwsbrief." name="newsletter" />
                        </FormRow>

                        {/* Hidden fields so it's included in FormData */}
                        <FormRowHidden>
                            <FormField type="text" title="Adres" name="addressLine1" required={true} />
                            <FormField type="text" title="Plaats" name="city" required={true} />
                            <FormField type="text" name="country"  />
                        </FormRowHidden>

                        <Button as="button" $variant="secondary">Aanmaken</Button>

                    </form>
                </RegisterContainer>
            </Container>
        </StyledRegister>
    );
}