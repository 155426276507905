import styled from 'styled-components';
import {ReactComponent as Info} from '../../assets/icons/info.svg';
import {Body} from '../Texts/Texts';
import { Transition } from 'react-transition-group';
import {useEffect, useRef, useState} from 'react';
import useWindowDimensions from "../../hooks/useWindowDimensions";

const StyledTooltip = styled.span`
    position: relative;
    display: inline-block;
`;

const StyledInfo = styled(Info)`
    path { fill: var(--color-primary); }
    vertical-align: top;
    cursor: pointer;

    &:hover {
        path { fill: var(--color-primary-60); }
    }
`;

export const ExpandedTooltip = styled.div`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    max-width: 300px;
    z-index: 1;
    pointer-events: none;
    user-select: none;
    background-color: var(--color-primary);
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    padding: 12px;
    opacity: 0;
    transition: opacity .2s ease, top .2s ease;
    
    &:before {
        content: '';
        width: 10px;
        height: 10px;
        background-color: var(--color-primary);
        position: absolute;
        top: -4px;
        left: 50%;
        border-radius: 2px;
        transform: translateX(-50%) rotate(45deg);
    }

    &.entering { opacity: 1; top: 30px; }
    &.entered { opacity: 1; top: 30px; }
    &.exiting { opacity: 0; top: 20px; }
    &.exited { opacity: 0; top: 20px; }
    
    ${Body} { margin: 0; ) }

    @media screen and (max-width: 768px) {
        max-width: 200px;
    }
`;

export const Tooltip = ({text}) => {
    const tooltipNodeRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        let node = tooltipNodeRef.current;
        if (node) {
            let rect = node.getBoundingClientRect();
            if (rect.right > width) {
                node.style.right = 0;
            }
        }
        // eslint-disable-next-line
    }, [showTooltip]);

    return (
        <StyledTooltip>
            <StyledInfo onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
            <Transition nodeRef={tooltipNodeRef} in={showTooltip} timeout={200} unmountOnExit>
                {state => (
                    <ExpandedTooltip ref={tooltipNodeRef} className={state}>
                        <Body $white>{text}</Body>
                    </ExpandedTooltip>
                )}
            </Transition>
        </StyledTooltip>
    );
}