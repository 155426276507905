import Cookies from 'js-cookie';

export const checkToken = async (request) => {
    const checkTokenRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/token/check`, {
        ...(request && {signal: request.signal}),
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!checkTokenRes.ok) {
        throw new Response("Response is not OK", {status: checkTokenRes.status});
    }

    const checkTokenData = await checkTokenRes.json();

    return checkTokenData;
}

export const refreshToken = async (request) => {
    const refreshTokenRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/token`, {
        ...(request && {signal: request.signal}),
        method: 'PUT',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'token': Cookies.get('titelive_webshop'),
            'refresh': Cookies.get('titelive_webshoprefresh'),
        },
    });

    if (!refreshTokenRes.ok) {
        throw new Response("Response is not OK", {status: refreshTokenRes.status});
    }

    const refreshTokenData = await refreshTokenRes.json();

    let expDate = new Date(Date.parse(refreshTokenData.expirationDate))
    Cookies.set('titelive_webshop', refreshTokenData.value, { expires: expDate, secure: true });
    Cookies.set('titelive_webshoprefresh', refreshTokenData.refresh, { expires: expDate, secure: true });

    return refreshTokenData;
}

export const getTokens = async (request) => {
    const getTokensRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/token`, {
        ...(request && {signal: request.signal}),
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!getTokensRes.ok) {
        throw new Response("Not Found", {status: 400});
    }

    const getTokensData = await getTokensRes.json();

    let expDate = new Date(Date.parse(getTokensData.expirationDate))
    Cookies.set('titelive_webshop', getTokensData.value, {expires: expDate, secure: true});
    Cookies.set('titelive_webshoprefresh', getTokensData.refresh, {expires: expDate, secure: true});

    return getTokensData;
}