import styled from "styled-components";
import {useForm} from "react-hook-form";
import FormField from "../../../components/Fields/FormField";
import {Button} from "../../../components/Buttons/Buttons";
import {FormRow, FormRowItem} from '../../../components/Fields/FormRows';
import {Await, defer, useLoaderData, useSubmit} from 'react-router-dom';
import React from 'react';
import {Toaster} from '../../../components/Toaster/Toaster';
import {getCustomer, updateCustomer} from '../../../api/customer';
import {toast} from "react-toastify";
import {Seo} from '../../../components/Seo/Seo';
import {rootLoaderPromise} from '../../../layouts/Root';

const StyledForm = styled.form`
    max-width: 825px;
`;

const SubmitButton = styled(Button)`
    margin-top: 15px;

    @media screen and (max-width: 768px) {
        max-width: unset;
    }
`;

export async function myDataAction({request}) {

    let formData = await request.formData();
    const customerObj = {
        "salutation": formData.get("salutation"),
        "firstName": formData.get("firstName"),
        "prefix": formData.get("prefix"),
        "lastName": formData.get("lastName"),
        "email": formData.get("email"),
        "phone": formData.get("phone"),
        "birthdate": formData.get("birthdate"),
    }

    try {
        const updateCustomerData = await updateCustomer(request, customerObj);
        toast(<Toaster type='success' title='Gelukt!' message='Je gegevens zijn succesvol gewijzigd.' $onLight/>);
        return updateCustomerData;
    } catch(err) {
        toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        return { "error": err };
    }
}

export async function myDataLoader({request}) {
    await rootLoaderPromise;

    let getCustomerData;
    try {
        getCustomerData = await getCustomer(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({customerData: getCustomerData});
}

export default function MyData() {
    const {customerData} = useLoaderData();

    return (
        <>
            <Seo metaTitle="Mijn gegevens" />

            <React.Suspense>
                <Await resolve={customerData}>
                    {(myData) => (
                        <MyDataComponent data={myData} />
                    )}
                </Await>
            </React.Suspense>
        </>
    )
}

function MyDataComponent({data}) {
    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            salutation: data?.salutation,
            firstName: data?.firstName,
            prefix: data?.prefix ?? "",
            lastName: data?.lastName,
            email: data?.email,
            phone: data?.phone ?? "",
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    const submit = useSubmit();
    const onSubmit = (data, e) => {
        submit(data, { method: 'put', action: '/account/mijn-gegevens' })
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <FormRow $spacing="large" $ignore>
                <FormField
                    type="radio"
                    title="Aanhef"
                    name="salutation"
                    options={[
                        {
                            "id": "salutationChoice1",
                            "value": "De heer",
                            "title": "De heer"
                        },
                        {
                            "id": "salutationChoice2",
                            "value": "Mevrouw",
                            "title": "Mevrouw"
                        },
                        {
                            "id": "salutationChoice3",
                            "value": "-",
                            "title": "Liever geen van beide"
                        }
                    ]}
                />
            </FormRow>

            <FormRow $spacing="small" $breakpoint="768px" $fullRow>
                <FormRowItem>
                    <FormField type="text" title="Voornaam" name="firstName" required={true}/>
                </FormRowItem>
                <FormRowItem $small>
                    <FormField type="text" title="Tussenv." name="prefix" placeholder="Tussenvoegsel" maxLength={10} />
                </FormRowItem>
                <FormRowItem>
                    <FormField type="text" title="Achternaam" name="lastName" required={true}/>
                </FormRowItem>
            </FormRow>

            <FormRow $spacing="small" $breakpoint="768px">
                <FormRowItem>
                    <FormField type="email" title="E-mailadres" name="email" required={true}/>
                </FormRowItem>
            </FormRow>

            <FormRow $spacing="small" $breakpoint="768px">
                <FormRowItem>
                    <FormField type="tel" title="Telefoonnummer" name="phone" placeholder="Telefoonnummer" required={false}/>
                </FormRowItem>
            </FormRow>

            <SubmitButton as="input" type="submit" value="Opslaan" $variant="secondary" />
        </StyledForm>
    )
}