import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Spacing} from '../../components/Spacing/Spacing';
import React, {useState} from 'react';
import {ReactComponent as PlayButton} from '../../assets/icons/play-button.svg';

const StyledContainer = styled(Container)`
    ${({$widthType}) => $widthType === "columnWidth" && 'max-width: 768px;'}
`;

const ImageBox = styled.div``;

const Image = styled.img`
    display: block;

    ${({$widthType}) => $widthType === "centerWidth" ? `
        max-height: 500px;
        width: auto;
        object-fit: contain;
        margin: 0 auto;
        max-width: 100%;
    ` : `
        width: 100%;
        height: auto;
        object-fit: cover; 
    `}
`;

const HorizontalBox = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--color-light);

    ${({$widthType}) => $widthType === "centerWidth" && `
        max-width: 400px;
        margin: 0 auto;
    `}

    @supports not (aspect-ratio: 16 / 9) {
        overflow: hidden;
        padding-top: 56.25%;
    }
`;

const IframeWrapper = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
    
    iframe {
        width: 100%;
        height: 100%;
    }
`;

const PreviewWrapper = styled.div`
    position: absolute;
    inset: 0; 
    cursor: pointer;
`;

const PreviewImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledPlayButton = styled(PlayButton)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;


export const Media = ({data}) => {
    const [showCustomPreviewImage, setShowCustomPreviewImage] = useState(data?.media?.type === "youtube" && Boolean(data?.media?.image));

    if(!data?.media) { return null; }

    return (
        <Spacing>
            <StyledContainer $widthType={data?.media?.widthType}>
                {data?.media?.type === "youtube" ? (
                    <HorizontalBox $widthType={data?.media?.widthType}>
                        <IframeWrapper dangerouslySetInnerHTML={{__html: data?.media?.youTubeCode}} />
                        {showCustomPreviewImage &&
                            <PreviewWrapper onClick={() => setShowCustomPreviewImage(false)}>
                                <PreviewImage src={data?.media?.image} alt={data?.media?.title} />
                                <StyledPlayButton />
                            </PreviewWrapper>
                        }
                    </HorizontalBox>
                ) : (
                    <ImageBox>
                        <Image src={data?.media?.image} alt={data?.media?.title} $widthType={data?.media?.widthType} />
                    </ImageBox>
                )}
            </StyledContainer>
        </Spacing>
    );
}