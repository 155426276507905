import styled from "styled-components";
import RadioUnchecked from "../../assets/icons/radio-unchecked.svg";
import RadioChecked from "../../assets/icons/radio-checked.svg";
import {bodyTextStyles} from "../Texts/Texts";
import {Tooltip} from '../Tooltip/Tooltip';

const StyledRadioField = styled.div`
    display: flex;
    gap: ${props => props.$column ? '18px' : '30px'};
    flex-direction: ${props => props.$column ? 'column' : 'row'};
    margin-top: ${props => props.$column ? 'unset' : '15px'};
    
    @media screen and (max-width: ${props => props.$breakpoint ? `${props.$breakpoint}px` : '768px'}) {
        flex-direction: column;
        gap: 18px;
    }
    
    @media screen and (max-width: 768px) {
        gap: 5px;
    }
`;

const Option = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const Radio = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    content: url(${RadioUnchecked});
    
    &:checked{
        content: url(${RadioChecked});
    }
`;

const StyledLabel = styled.label`
    ${bodyTextStyles};
`;

export default function RadioField({...props}) {
    return (
        <StyledRadioField $column={props.column} $breakpoint={props.breakPoint}>
            {props.options.map((option) => (
                <Option key={option.id}>
                    <Radio
                        id={option.id}
                        type='radio'
                        value={option.value}
                        {...props?.register(props.name)}
                    />
                    <StyledLabel htmlFor={option.id}>
                        {option.title}
                        {option.tooltip && <Tooltip text={option.tooltip} /> }
                    </StyledLabel>
                </Option>
            ))}
        </StyledRadioField>
    )
}