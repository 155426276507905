import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Subtitle1, Subtitle2, Subtitle3} from '../../components/Subtitles/Subtitles';
import {Headline2, Headline5} from '../../components/Headlines/Headlines';
import {ProductItem} from '../../components/ProductItem/ProductItem';
import {Spacing} from '../../components/Spacing/Spacing';

const StyledTips = styled.section`
    margin-top: 13px;
`;

const Flex = styled.div`
    display: flex;
    gap: 30px;
    
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 100px;
    }

    @media screen and (max-width: 768px) {
        gap: 60px;
    }
`;

const ContentBox = styled.div`
    flex: 1;
    ${Headline2} { margin-bottom: 40px; }
    
    @media screen and (max-width: 768px) {
        ${Headline2} { margin-bottom: 8px; }
    }
`;

const Box = styled.div`
    flex-shrink: 0;
`;

const Author = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
`;

const Avatar = styled.img`
    width: 100px;
    max-width: 100%;
    height: 100px;
    max-height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 26px;
`;

const Info = styled.div`
    ${Subtitle2} { color: var(--color-secondary); }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const Item = styled.div`
    border-bottom: 2px solid var(--color-primary);
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    max-width: 285px;
    
    @media screen and (max-width: 1024px) {
        max-width: unset;
    }
`;

export const Tips = ({data}) => {
    if(!data?.tip) { return null; }

    return (
        <Spacing>
            <StyledTips>
                <Container>
                    <Flex>
                        <ContentBox>
                            {data?.tip?.supertitle && <Subtitle1>{data?.tip?.supertitle}</Subtitle1>}
                            <Headline2>{data?.tip?.title}</Headline2>
                            <Subtitle3>{data?.tip?.description}</Subtitle3>

                            {data?.tip?.imageURL &&
                                <Author>
                                    <Avatar src={data?.tip?.imageURL} alt={data?.tip?.imageTitle}/>
                                    <Info>
                                        <Subtitle2>{data?.tip?.imageTitle}</Subtitle2>
                                        <Headline5>{data?.tip?.imageSubtitle}</Headline5>
                                    </Info>
                                </Author>
                            }
                        </ContentBox>
                        <Box>
                            <Grid>
                                {data?.tip?.products.map((product, index) => (
                                    <Item key={index}>
                                        <ProductItem
                                            data={product}
                                            isbn={product.isbn}
                                            eanf={product.eanf}
                                            title={product.title}
                                            author={product.author}
                                            price={product.price}
                                            imageUrl={product.image}
                                            url={product.url}
                                            ranking={index + 1}
                                        />
                                    </Item>
                                ))}
                            </Grid>
                        </Box>
                    </Flex>
                </Container>
            </StyledTips>
        </Spacing>
    );
}