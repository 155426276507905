import styled from 'styled-components';
import {alternativeTextStyles} from '../Texts/Texts';
import {NavLink} from 'react-router-dom';
import {ReactComponent as ChevronDown} from '../../assets/icons/chevron-down.svg';
import {useRef} from 'react';
import {useFlyOutMenu} from '../../hooks/useFlyOutMenu';
import {FlyOutMenu} from '../FlyOutMenu/FlyOutMenu';

const StyledMenuItem = styled.li`
    display: inline-block;
    position: relative;
    padding: 0 0 12px 0;

    &:not(:last-of-type) {
        margin-right: 16px;
    }
`;

const MenuLink = styled(NavLink)`
    ${alternativeTextStyles};
    line-height: 1;
    
    &:hover, &.active {
        text-decoration: underline;
    }
`;

const StyledChevronDown = styled(ChevronDown)`
    width: 12px;
    height: 12px;
    margin-left: 5px;
    
    path { fill: var(--color-primary); }
`;

export const MenuItem = ({data, to, children}) => {

    const ref = useRef(null);
    const { open, setOpen } = useFlyOutMenu(ref);

    return (
        <StyledMenuItem ref={ref}>
            <MenuLink to={to} onClick={() => setOpen(false)}>{children}{data.length > 0 && <StyledChevronDown />}</MenuLink>
            {(data.length > 0 && open) &&
                <FlyOutMenu data={data} clickAction={setOpen} />
            }
        </StyledMenuItem>
    );
}