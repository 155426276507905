import {createContext, useContext, useState} from "react";

// This context holds the isbns that are in the cart WITH giftwrap
export const ProductsInCartContext = createContext(null);

export default function ProductsInCartProvider({children}) {
    const [isbnsWithGiftwrapInCart, setIsbnsWithGiftwrapInCart] = useState([]);

    const addInitialCart = (cartData) => {
        // cartData is a (deferred) promise initially
        cartData?.then((cart) => {
            const isbnsWithGiftwrap = cart.products?.filter(item => item.giftwrap).map(item => item.isbn);
            setIsbnsWithGiftwrapInCart(isbnsWithGiftwrap);
        });
    };

    const addFetchedCart = (cart) => {
        const isbnsWithGiftwrap = cart.products?.filter(item => item.giftwrap).map(item => item.isbn);
        setIsbnsWithGiftwrapInCart(isbnsWithGiftwrap);
    };

    const addToArray = (isbn) => {
       setIsbnsWithGiftwrapInCart([...isbnsWithGiftwrapInCart, ...isbn])
    }

    const removeFromArray = (isbn) => {
        setIsbnsWithGiftwrapInCart(isbnsWithGiftwrapInCart.filter(item => item !== isbn));
    }

    return (
        <ProductsInCartContext.Provider value={{
            isbnsWithGiftwrapInCart,
            addInitialCart,
            addFetchedCart,
            addToArray,
            removeFromArray
        }}>
            {children}
        </ProductsInCartContext.Provider>
    )
}

export const useProductsInCartContext = () => useContext(ProductsInCartContext);