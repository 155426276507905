import {useState} from 'react';

const useThrottle = (func, delay) => {
    const [timeout, saveTimeout] = useState(null);
    const [controller, setController] = useState(null);

    return function (value) {
        if (timeout) {
            clearTimeout(timeout);
        }

        if (controller) {
            controller.abort();
            setController(null);
        }

        const newTimeout = setTimeout(() => {
            const controller = new AbortController();
            setController(controller);
            func(...arguments, controller.signal);
            if (newTimeout === timeout) {
                saveTimeout(null);
                setController(null);
            }
        }, delay);
        saveTimeout(newTimeout);
    };
}

export default useThrottle;