import Cookies from "js-cookie";

export const getWishlist = async (request) => {
    const getWishlistRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wishlist`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getWishlistRes.ok) {
        throw new Response("Response is not OK", {status: getWishlistRes.status});
    }

    const getWishlistData = await getWishlistRes.json();

    return getWishlistData;
}

export const postWishlist = async (request, isbn) => {
    const postWishlistRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wishlist/${isbn}`, {
        // signal: request.signal,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!postWishlistRes.ok) {
        throw new Response("Response is not OK", {status: postWishlistRes.status});
    }

    const postWishlistData = await postWishlistRes.json();

    return postWishlistData;
}

export const destroyWishlist = async (request, isbn) => {
    const destroyWishlistRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wishlist/${isbn}`, {
        // signal: request.signal,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!destroyWishlistRes.ok) {
        throw new Response("Response is not OK", {status: destroyWishlistRes.status});
    }

    const destroyWishlistData = await destroyWishlistRes.json();

    return destroyWishlistData;
}