import {Input} from "../Input/Input";
import styled from "styled-components";
import Calendar from '../../assets/icons/calendar-primary.svg';
import {Tooltip} from '../Tooltip/Tooltip';
import {ConditionalWrap} from '../ConditionalWrap/ConditionalWrap';

const RelativeField = styled.div`
    position: relative;
`;

const StyledInput = styled(Input)`
    &[type="date"] {
        appearance: none;
        height: 50px;
        background-color: var(--color-white);
        position: relative;

        &::-webkit-calendar-picker-indicator { opacity: 0; }
        &::-webkit-date-and-time-value { text-align: left; }

        background-image: url(${Calendar});
        background-repeat: no-repeat, repeat;
        background-position: right 14px center, 0 0;
        background-size: 16px;
    }
`;

const TooltipWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -38px;
    
    @media screen and (max-width: 768px) {
        right: -26px;
    }
`;

export default function BasicField({...props}) {
    return (
        <ConditionalWrap condition={props?.tooltip} wrap={children => <RelativeField>{children}</RelativeField>}>
            <StyledInput
                id={props.name}
                type={props?.type}
                placeholder={props?.placeholder} $lightPlaceholder
                autoComplete={props?.autoComplete}
                errors={props?.errors[props?.name]}
                {...props?.register(props.name, {
                    onBlur: props?.onBlur,
                    onChange: props?.onChange,
                    required: props?.required && `${props?.title} is verplicht`,
                    maxLength: {
                        value: props?.maxLength,
                        message: `Max. ${props?.maxLength} karakters`
                    },
                    pattern: {
                        value: props?.pattern,
                        message: props?.message
                    }
                })}
            />
            {props?.tooltip &&
                <TooltipWrapper>
                    <Tooltip text={props.tooltip} />
                </TooltipWrapper>
            }
        </ConditionalWrap>
    )
}