import {HeaderBanner} from '../../../sections/HeaderBanner/HeaderBanner';
import {DirectToLinks} from '../../../sections/DirectToLinks/DirectToLinks';
import deKlerImage from '../../../assets/images/de-kler-leiden.jpeg'
import {Seo} from '../../../components/Seo/Seo';
import React from 'react';

const headerBannerData = {
    "type": "banner-header",
    "title": null,
    "bannerHeader": {
        "id": "1",
        "supertitle": "De Kler",
        "title": "Pagina niet gevonden",
        "subtitle": "",
        "description": "Helaas, deze pagina kan niet worden gevonden. De pagina die u zoekt bestaat niet, of is verplaatst of verwijderd.",
        "imageURL": deKlerImage,
        "linkType": "relative",
        "linkText": "Homepagina",
        "url": "/",
        "shapeType": "label",
        "color": "primary",
        "breadcrumbs": [
            {
                "linkText": "Pagina niet gevonden",
                "url": "404"
            }
        ]
    }
}

const directToLinksData = {
    "type": "direct-to-link",
    "title": null,
    "directToLinkContent": {
        "title": "Direct naar",
        "columns": 3,
        "links": [
            {
                "id": "1",
                "imageType": null,
                "imageURL": null,
                "linkType": "relative",
                "linkText": "Boeken",
                "url": "boeken/",
                "color": "primary"
            },
            {
                "id": "2",
                "imageType": null,
                "imageURL": null,
                "linkType": "relative",
                "linkText": "Registreren",
                "url": "registreren/",
                "color": "secondary"
            },
            {
                "id": "3",
                "imageType": null,
                "imageURL": null,
                "linkType": "relative",
                "linkText": "Inloggen",
                "url": "login/",
                "color": "tertiary"
            }
        ]
    }
}

export default function NoMatch() {

    return (
        <>
            <Seo metaTitle="Er is iets fout gegaan" />

            <HeaderBanner data={headerBannerData} />

            <DirectToLinks data={directToLinksData} />
        </>
    )
}