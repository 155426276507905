import styled from "styled-components";
import {Link, useLoaderData} from "react-router-dom";
import {Headline4, Headline5} from "../../components/Headlines/Headlines";
import {Body} from "../../components/Texts/Texts";
import {IconButton} from "../../components/Buttons/Buttons";
import {ReactComponent as Facebook} from "../../assets/icons/facebook.svg";
import {ReactComponent as Twitter} from "../../assets/icons/twitter.svg";
import {ReactComponent as YouTube} from "../../assets/icons/youtube.svg";
import {Container} from '../../components/Container/Container';

const StyledFooter = styled.footer`
    margin-top: auto;
    padding: 0 20px 20px;
    
    @media screen and (max-width: 1024px) {
        padding: 0;
    }
`;

const MainFooter = styled.section`
    background-color: var(--color-primary);
`;

const SubFooter = styled.section`
    background-color: var(--color-primary-10);
`;

const Columns = styled.div`
    padding: 50px 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    
    @media screen and (max-width: 858px) {
        grid-template-columns: repeat(2, 1fr);
        
        article:not(:last-of-type) { padding-bottom: 40px; }
    }
    
    @media screen and (max-width: 525px) {
        grid-template-columns: 1fr;
    }
`;

const StyledArticle = styled.article`
    ${Headline4}{
        margin-bottom: 10px;
    }
`;

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const ListItem = styled.li`
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const StyledLink = styled(Body).attrs({as: Link})`
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
`;

const BottomBox = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: space-between;

    @media screen and (max-width: 858px) {
        flex-direction: column-reverse;
        gap: 30px;
        padding: 30px 0;
        margin-bottom: 0;
    }
`;

const LeftBox = styled.div`
    display: flex;
    align-items: center;
    gap: 50px;

    @media screen and (max-width: 858px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
`;

const RightBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    ${Headline5}{
        @media screen and (min-width: 858px) {
            display: none;
        }
    }
`;

const Socials = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Notice = styled(Body)`
    color: var(--color-primary);
    text-align: center;
    margin: 0;
    
    a { color: inherit; }
`;

export const Footer = () => {
    const { footerNav, subFooterNav }  = useLoaderData();

    return (
        <StyledFooter>
            <MainFooter>
                <Container>
                    <Columns>
                        {footerNav?.map((column, index) => (
                            <StyledArticle key={index}>
                                <Headline4 $variant='primary'>{column.title}</Headline4>
                                <List>
                                    {column.items.map((item, index) => (
                                        <ListItem key={index}>
                                            <StyledLink $variant='primary' to={item.url}>{item.title}</StyledLink>
                                        </ListItem>
                                    ))}
                                </List>
                            </StyledArticle>
                        ))}
                    </Columns>
                </Container>
            </MainFooter>
            <SubFooter>
                <Container>
                    <BottomBox>
                        <LeftBox>
                            <Notice>&copy; {new Date().getFullYear()} De Kler</Notice>
                            {subFooterNav?.map(item => (
                                <StyledLink key={item?.id} to={`/${item?.url}`}>{item?.title}</StyledLink>
                            ))}
                        </LeftBox>

                        <RightBox>
                            <Headline5>Volg Ons</Headline5>

                            <Socials>
                                <IconButton as="a" href="https://www.facebook.com/boekhandeldekler" target="_blank" $small>
                                    <Facebook/>
                                </IconButton>
                                <IconButton as="a" href="https://twitter.com/De_Kler" target="_blank" $small>
                                    <Twitter/>
                                </IconButton>
                                <IconButton as="a" href="https://www.youtube.com/channel/UChKBoaySh9FUS2IEmek6PyQ" target="_blank" $small>
                                    <YouTube/>
                                </IconButton>
                            </Socials>
                        </RightBox>
                    </BottomBox>
                </Container>
            </SubFooter>
        </StyledFooter>
    )
}