import styled from 'styled-components';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import { Autoplay, EffectFade, Navigation } from "swiper";
import {useRef} from 'react';
import {ReactComponent as ChevronLeft} from '../../assets/icons/chevron-left.svg';
import {ReactComponent as ChevronRight} from '../../assets/icons/chevron-right.svg';
import {IconButton} from '../../components/Buttons/Buttons';
import {Spacing} from '../../components/Spacing/Spacing';
import storeImage from '../../assets/images/store-image-1.jpg';
import {EmptySlide} from './EmptySlide';
import {AgendaBookSlide} from '../../components/BookSlide/AgendaBookSlide';
import {AgendaImageSlide} from '../../components/BookSlide/AgendaImageSlide';

const StyledAgendaHeaderSlider = styled.div`
    padding: 20px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const ControlButtons = styled.div`
    position: absolute;
    bottom: 50px;
    right: 80px;
    z-index: 1;
    display: flex;
    gap: 10px;
    
    @media screen and (max-width: 768px) {
        bottom: 40px;
        right: 20px;
    }
`;

export const AgendaHeaderSlider = ({data}) => {
    const swiperRef = useRef(null);

    return (
        <Spacing $spacingLg={'80px'} $spacingMd={'60px'} $spacingSm={'40px'}>
            <StyledAgendaHeaderSlider>
                {data.length >= 1 ? (
                    <Swiper
                        ref={swiperRef}
                        effect={"fade"}
                        threshold={15}
                        loop={data?.length !== 1}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        autoHeight={true}
                        slidesPerView={1}
                        centeredSlides={true}
                        modules={[Autoplay, EffectFade, Navigation]}
                    >
                        {data?.map(slide => {
                            const composedSubTitle = (slide.day ? slide.day + " " : "") + (slide.fullMonth ? slide.fullMonth + " " : "") + (slide.startTime ? slide.startTime + " " : "") + ((slide.startTime && slide.endTime) ? "- " : "") + (slide.endTime ? slide.endTime + " " : "") + (slide.shop ? "bij " + slide.shop : "");
                            return (
                                <SwiperSlide key={slide.id}>
                                    {slide.type === 'image' ? (
                                        <AgendaImageSlide
                                            title={slide.title}
                                            subTitle={composedSubTitle}
                                            buttonText="Lees meer"
                                            url={`/${slide.url}`}
                                            imageUrl={slide.image}
                                            linkType="relative"
                                            onAgenda={true}
                                        />
                                    ) : slide.type === 'product' ? (
                                        <AgendaBookSlide
                                            title={slide.title}
                                            subTitle={composedSubTitle}
                                            description={composedSubTitle}
                                            buttonText="Lees meer"
                                            url={`/${slide.url}`}
                                            imageUrl={slide.image}
                                            variant="primary"
                                            onAgenda={true}
                                        />
                                    ) : null}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                ) : (
                    <EmptySlide
                        title="Agenda"
                        subTitle="Bekijk hier onze aankomende evenementen"
                        imageUrl={storeImage}
                    />
                )}

                {data?.length >= 2 &&
                    <ControlButtons>
                        <IconButton $variant="accent" as="button" onClick={() => swiperRef?.current?.swiper.slidePrev()}><ChevronLeft /></IconButton>
                        <IconButton $variant="accent" as="button" onClick={() => swiperRef?.current?.swiper.slideNext()}><ChevronRight /></IconButton>
                    </ControlButtons>
                }
            </StyledAgendaHeaderSlider>
        </Spacing>
    );
}