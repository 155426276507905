import {Navigate, Outlet, useLocation, useRouteLoaderData} from 'react-router-dom';

export function RequireGuest() {
    let {state} = useLocation();
    const { checkTokenData } = useRouteLoaderData("root");

    // User is authenticated if customer block exists in checkTokenData
    if (checkTokenData?.customer) {
        return <Navigate to={state?.from?.pathname || "/account/mijn-gegevens"} replace />;
    }

    return <Outlet />;
}