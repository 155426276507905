import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Headline2, Headline5} from '../../components/Headlines/Headlines';
import {Spacing} from '../../components/Spacing/Spacing';
import React from 'react';
import {HTMLText} from '../../components/HTMLText/HTMLText';

const StyledGiftcard = styled.section`
    border-bottom: 2px solid var(--color-primary);
`;

const Flex = styled.div`
    display: flex;
    flex-direction: row;
    gap: 80px;
    
    @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 20px;
    }
`;

const ImageBox = styled.div`
    flex: 1 1 0;
    max-width: 500px;
`;

const ContentBox = styled.div`
    flex: 1 1 0;
`;

const InnerImageBox = styled.div`
    padding: 30px 0;
    max-width: 500px;

    ${Headline2} { margin-bottom: 16px; }

    @media screen and (max-width: 1024px) {
        max-width: unset;
        padding: 30px 0 0 0;
    }
`;

const Image = styled.img`
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
`;

const StyledHeadline5 = styled(Headline5)`
    margin-top: 30px;
    margin-bottom: 10px;

    @media screen and (max-width: 1024px) {
        margin-top: 10px;
    }
`;

const IframeWrapper = styled.div`
    display: block;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
`;

export const Giftcard = ({data}) => {
    if(!data.giftcard) { return null; }

    return (
        <Spacing>
            <StyledGiftcard>
                <Container>
                    <Flex>
                        <ImageBox>
                            <Image src={data?.giftcard?.imageURL} alt={data?.giftcard?.title} />
                            <InnerImageBox>
                                <HTMLText dangerouslySetInnerHTML={{__html: data?.giftcard?.text}} />
                            </InnerImageBox>
                        </ImageBox>

                        <ContentBox>
                            {data?.giftcard?.title && <StyledHeadline5>{data?.giftcard?.title}</StyledHeadline5>}
                            {data?.giftcard?.iFrame && <IframeWrapper dangerouslySetInnerHTML={{__html: data?.giftcard?.iFrame}} />}
                        </ContentBox>
                    </Flex>
                </Container>
            </StyledGiftcard>
        </Spacing>
    );
}