import styled from 'styled-components';
import {Headline1} from '../../components/Headlines/Headlines';
import {Subtitle3} from '../../components/Subtitles/Subtitles';

const StyledEmptySlide = styled.article`
    background-color: ${props => props.$variant === 'secondary' ? 'var(--color-secondary)' : props.$variant === 'tertiary' ? 'var(--color-tertiary)' : 'var(--color-primary)'};
    display: flex;
    height: 548px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 540px;
        max-height: 100%;
    }
`;

const Box = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const ImageBox = styled.div`
    flex: 1;
    
    @media screen and (max-width: 768px) {
        max-height: 180px;
    }
`;

const Content = styled.div`
    margin: auto 0;
    padding: 0 70px;
    width: 100%;
    
    ${Subtitle3} { margin: 16px 0; }

    ${Headline1} {
        min-height: 126px;
        display: flex;
        align-items: center;
    }
    
    @media screen and (max-width: 768px) {
        padding: 0 30px;
        margin: auto 0;

        ${Headline1} { min-height: 0; }
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;

export const EmptySlide = ({title, subTitle, imageUrl, variant}) => {
    return (
        <StyledEmptySlide $variant={variant}>
            <ImageBox>
                <Image src={imageUrl} alt={title} />
            </ImageBox>
            <Box>
                <Content>
                    <Headline1 $white>{title}</Headline1>
                    <Subtitle3 $white>{subTitle}</Subtitle3>
                </Content>
            </Box>
        </StyledEmptySlide>
    );
}