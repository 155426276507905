import {GuestLogInForm} from "../../components/SideDrawers/AccountSideDrawer/GuestLogInForm";
import {GuestRegisterForm} from "../../components/SideDrawers/AccountSideDrawer/GuestRegisterForm";
import {Container} from "../../components/Container/Container";
import styled from "styled-components";
import {Headline2} from "../../components/Headlines/Headlines";
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {logIn} from '../../api/authentication';
import {Seo} from '../../components/Seo/Seo';

const StyledLogin = styled.div`
    margin: 56px 0 175px;
`;

const LoginContainer = styled.section`
    max-width: 1020px;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
    padding: 40px 60px;
    margin-top: 56px;

    @media screen and (max-width: 1024px) {
        padding: 30px 40px;
    }

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 25px;
    }
`;

export async function loginAction({request}) {

    let formData = await request.formData();
    const loginObj = {
        "email": formData.get("email"),
        "password":  formData.get("password"),
    }

    try {
        const logInData = await logIn(request, loginObj);
        return ({logInData: logInData});
    } catch(err) {
        return err;
    }
}

export default function Login() {
    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    let location = useLocation();
    let newLocation = location.state?.from?.pathname || '/';

    return (
        <StyledLogin>
            <Seo metaTitle="Login" />

            <Container>
                <Headline2>Login</Headline2>

                <LoginContainer>
                    <GuestLogInForm close={()=>{}} newLocation={newLocation}/>

                    <GuestRegisterForm close={()=>{}} />
                </LoginContainer>
            </Container>
        </StyledLogin>
    )
}