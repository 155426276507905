import styled from "styled-components";
import {useForm} from "react-hook-form";
import FormField from "../../../components/Fields/FormField";
import {Button} from "../../../components/Buttons/Buttons";
import {Headline4} from '../../../components/Headlines/Headlines';
import {FormRow, FormRowHidden, FormRowItem} from '../../../components/Fields/FormRows';
import {Seo} from '../../../components/Seo/Seo';
import React from 'react';
import {Await, defer, useAsyncValue, useLoaderData, useSubmit} from 'react-router-dom';
import {getCustomer, updateCustomer} from '../../../api/customer';
import {toast} from 'react-toastify';
import {Toaster} from '../../../components/Toaster/Toaster';
import {rootLoaderPromise} from '../../../layouts/Root';

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 825px;
`;

const SubmitButton = styled(Button)``

export async function mySettingsAction({request}) {
    let formData = await request.formData();

    const customerObj = {
        "salutation": formData.get("salutation"),
        "firstName": formData.get("firstName"),
        "prefix": formData.get("prefix"),
        "lastName": formData.get("lastName"),
        "email": formData.get("email"),
        "phone": formData.get("phone"),
        "birthdate": formData.get("birthdate"),
        "password": formData.get("password"),
    }

    try {
        const updateCustomerData = await updateCustomer(request, customerObj);
        toast(<Toaster type='success' title='Gelukt!' message='Je wachtwoord is succesvol gewijzigd.' $onLight/>);
        return updateCustomerData;
    } catch(err) {
        toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        return { "error": err };
    }
}

export async function mySettingsLoader({request}) {
    await rootLoaderPromise;

    let getCustomerData;
    try {
        getCustomerData = await getCustomer(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({customerData: getCustomerData});
}

export default function MySettings() {
    const {customerData} = useLoaderData();

    return (
        <>
            <Seo metaTitle="Mijn instellingen" />
            <React.Suspense>
                <Await resolve={customerData}>
                    <MySettingsComponent/>
                </Await>
            </React.Suspense>
        </>
    )
}

function MySettingsComponent() {
    const customerData = useAsyncValue();

    const { register, formState: { errors }, handleSubmit, watch } = useForm({
        defaultValues: {
            salutation: customerData?.salutation,
            firstName: customerData?.firstName,
            prefix: customerData?.prefix ?? "",
            lastName: customerData?.lastName,
            email: customerData?.email,
            phone: customerData?.phone ?? "",
        }
    });
    FormField.defaultProps = {errors: errors, register: register, watch: watch};

    const submit = useSubmit();
    const onSubmit = (data, e) => {
        submit(data, { method: 'put', action: '/account/instellingen' })
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Headline4>Wachtwoord wijzigen</Headline4>
            <FormRow $breakpoint="768px">
                <FormRowItem>
                    <FormField type="password" name="password" autoComplete="new-password" title="Nieuw wachtwoord" placeholder="Nieuw wachtwoord" required={true} />
                </FormRowItem>
                <FormRowItem>
                    <FormField type="password" name="password-repeat" autoComplete="new-password" title="Herhaal nieuw wachtwoord" placeholder="Herhaal nieuw wachtwoord" required={true} />
                </FormRowItem>
            </FormRow>

            {/* Hidden fields so it's included in FormData */}
            <FormRowHidden>
                <FormField type="text" name="salutation" />
                <FormField type="text" name="firstName" />
                <FormField type="text" name="prefix" />
                <FormField type="text" name="lastName" />
                <FormField type="text" name="email" />
                <FormField type="text" name="phone" />
            </FormRowHidden>

            <SubmitButton as="input" type="submit" value="Stel dit wachtwoord in" $variant="primary"/>
        </StyledForm>
    )
}