import Cookies from "js-cookie";

// Get link (payment)
export const getPaymentLink = async (request, orderID) => {
    const getPaymentLinkRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/pay/${orderID}`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getPaymentLinkRes.ok) {
        throw new Response("Response is not OK", {status: getPaymentLinkRes.status});
    }

    const getPaymentLinkData = await getPaymentLinkRes.json();

    return getPaymentLinkData;
}

// Save payment
export const savePayment = async (request, orderID) => {
    const savePaymentRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/pay/${orderID}`, {
        signal: request.signal,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!savePaymentRes.ok) {
        throw new Response("Response is not OK", {status: savePaymentRes.status});
    }

    const savePaymentData = await savePaymentRes.json();

    return savePaymentData;
}