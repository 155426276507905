import {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {Headline2} from "../../components/Headlines/Headlines";
import {Body} from "../../components/Texts/Texts";
import {IconButton} from "../../components/Buttons/Buttons";
import {ReactComponent as ChevronLeft} from "../../assets/icons/chevron-left.svg";
import {ReactComponent as ChevronRight} from "../../assets/icons/chevron-right.svg";
import {ProductItem} from '../../components/ProductItem/ProductItem';
import {Spacing} from '../../components/Spacing/Spacing';

const StyledProductSlider = styled.section`
    overflow: hidden;

    @media screen and (max-width: 768px) {
        ${Container} {
            padding: 0;
        }
    }
`;

const Grid = styled.div`
    position: relative;
    display: grid;
    column-gap: 30px;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr 114px; // NOTE: temporarily changed to 114px while buttons are hidden
    //grid-template-rows: 1fr 180px; // 180px is the height of the info under a ProductItem, so the bookshelf will always align with the bottom of image
    grid-template-areas: 
        "context slider"
        ". slider";

    @media screen and (max-width: 1024px) {
        grid-template-columns: 240px 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: 
            "context"
            "slider";
    }
`;

// Context
const ContextItem = styled.div`
    grid-area: context;
    margin-top: ${props => props.$hasTabs ? '87px' : '0'}; // Height of the tabs

    @media screen and (max-width: 768px) {
        margin-top: 0;
        padding: 0 20px;
    }
`;

const ContextInner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap: 30px;
    padding-bottom: 30px;

    @media screen and (max-width: 768px) {
        flex-direction: row;
        justify-content: space-between;

        ${Headline2} { ${props => !props.$hasTabs && 'height: auto'}; }
    }
`

const ControlButtons = styled.div`
    display: flex;
    gap: 10px;
`;

const StyledIconButton = styled(IconButton)`
    &:disabled {
        opacity: 0.2;

        &:hover {
            background-color: transparent;
            color: var(--color-primary);
            path { fill: var(--color-primary); }
        }
    }
`

// Slider
const SliderItem = styled.div`
    grid-area: slider;
    align-self: end;
    max-width: 100%;
    overflow: hidden;
`;

const SwiperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`

const TabContainer = styled.div`
    display: flex;
    margin-bottom: 37px;
    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }

    @media screen and (max-width: 768px) {
        padding: 0 0 0 20px;
    }
`;

const Tab = styled.button`
    flex: 1;
    min-width: 190px;
    margin: 0;
    cursor: pointer;
    background: none;
    outline: 0;
    border: 0;
    box-shadow: ${props => !props.$active ? 'inset 0 -2px 0 var(--color-primary-10)' : 'inset 0 -5px 0 var(--color-primary)'};

    @media screen and (max-width: 768px) {
        flex-basis: 160px;
    }
`;

const SwiperContainer = styled.div`
    width: 100%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const StyledSwiper = styled(Swiper)`
    .swiper-wrapper {
        align-items: flex-end;
    }
    
    @media screen and (max-width: 768px) {
        padding: 0 20px;

        .swiper-slide {
            width: 153px;
        }
    }
`;

const StyledSlide = styled.div``;

// Bookshelf
const BookShelf = styled.hr`
    border: 1px solid var(--color-primary);
    position: absolute;
    bottom: 114px; // NOTE: temporarily 114: btn height + margin = 70, - margin from price = 66, 180-66=114px 
    //bottom: 180px;
    margin: 0;
    width: 100vw;
    right: 0;
    z-index: 1;
    
    @media screen and (max-width: 768px) {
        width: calc(100vw + 20px);
        bottom: 107px; // NOTE: temporarily while buttons are hidden
        //bottom: 173px;
    }
`;

export const ProductSlider = ({data}) => {
    const [active, setActive] = useState(0);
    const [hasTabs, setHasTabs] = useState(false);

    const swiperRef = useRef(null);
    const prevBtnRef = useRef(null);
    const nextBtnRef = useRef(null);

    useEffect(() => {
        swiperRef?.current?.swiper.slideTo(0, 0);
    }, [active]);

    useEffect(() => {
        if (data?.tabs?.length >= 2 ) {
            setHasTabs(true)
        } else {
            setHasTabs(false);
        }
    }, [data]);

    if(data.tabs.length === 0) { return null; }

    return (
        <Spacing>
            <StyledProductSlider>
                <Container>
                    <Grid>
                        <ContextItem $hasTabs={hasTabs}>
                            <ContextInner $hasTabs={hasTabs}>
                                <Headline2>{data?.title}</Headline2>
                                <ControlButtons>
                                    <StyledIconButton $variant="outline" as="button" ref={prevBtnRef}><ChevronLeft/></StyledIconButton>
                                    <StyledIconButton $variant="outline" as="button" ref={nextBtnRef}><ChevronRight/></StyledIconButton>
                                </ControlButtons>
                            </ContextInner>
                        </ContextItem>

                        <SliderItem>
                            <SwiperWrapper>
                                {hasTabs &&
                                    <TabContainer>
                                        {data?.tabs?.map((tab, index) => {
                                            if(tab.products.length === 0) { return null; }

                                            return (
                                                <Tab key={tab.id} $active={active === index} onClick={() => setActive(index)}>
                                                    <Body $small $bold={active === index}>{tab.title}</Body>
                                                </Tab>
                                            )
                                        })}
                                    </TabContainer>
                                }

                                <SwiperContainer>
                                    <StyledSwiper
                                        ref={swiperRef}
                                        slidesPerView={"auto"}
                                        spaceBetween={10}
                                        breakpoints={{
                                            769: {
                                                slidesPerView: 3,
                                                spaceBetween: 30
                                            },
                                            1025: {
                                                slidesPerView: 4,
                                                spaceBetween: 30
                                            },
                                        }}
                                        onInit={(swiper) => {
                                            swiper.params.navigation.prevEl = prevBtnRef.current;
                                            swiper.params.navigation.nextEl = nextBtnRef.current;
                                            swiper.navigation.init();
                                            swiper.navigation.update();
                                        }}
                                        modules={[Navigation]}
                                    >
                                        {data?.tabs[active]?.products?.map((product, index) => (
                                            <SwiperSlide key={index}>
                                                <StyledSlide>
                                                    <ProductItem
                                                        data={product}
                                                        isbn={product.isbn}
                                                        eanf={product.eanf}
                                                        title={product.title}
                                                        author={product.author}
                                                        price={product.price}
                                                        imageUrl={product.image}
                                                        url={product.url}
                                                        flag={product.secondhand ? "secondHand" : product.eBook ? "ebook" : null}
                                                    />
                                                </StyledSlide>
                                            </SwiperSlide>
                                        ))}
                                    </StyledSwiper>
                                </SwiperContainer>
                            </SwiperWrapper>
                        </SliderItem>

                        <BookShelf $hasTabs={hasTabs}/>
                    </Grid>
                </Container>
            </StyledProductSlider>
        </Spacing>
    );
}