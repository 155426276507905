import Cookies from "js-cookie";

// Password Start
export const startForgotPassword = async (request, emailObj) => {
    const startForgotPasswordRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/password/start`, {
        signal: request.signal,
        method: 'POST',
        body: JSON.stringify(emailObj),
        headers: {
            'Content-Type': 'application/json',
            // 'token': Cookies.get('titelive_webshop'),
        }
    })

    if (!startForgotPasswordRes.ok) {
        throw new Response("Response is not OK", {status: startForgotPasswordRes.status})
    }

    const startForgotPasswordData = await startForgotPasswordRes.json();

    return startForgotPasswordData;
}

// Password Check
export const checkForgotPassword = async (request, user, validationKey) => {
    const checkForgotPasswordRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/password/check`, {
        signal: request.signal,
        method: 'POST',
        body: JSON.stringify({
            "user": user,
            "validationKey": validationKey
        }),
        headers: {
            'Content-Type': 'application/json',
            // 'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!checkForgotPasswordRes.ok) {
        throw new Response("Response is not OK", {status: checkForgotPasswordRes.status});
    }

    const checkForgotPasswordData = await checkForgotPasswordRes.json();

    return checkForgotPasswordData;
}

// Password Change
export const changeForgotPassword = async (request, changeObj) => {
    const changeForgotPasswordRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/password/change`, {
        signal: request.signal,
        method: 'PATCH',
        body: JSON.stringify(changeObj),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!changeForgotPasswordRes.ok) {
        throw new Response("Response is not OK", {status: changeForgotPasswordRes.status});
    }

    const changeForgotPasswordData = await changeForgotPasswordRes.json();

    return changeForgotPasswordData;
}