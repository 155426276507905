import GuestUser from './GuestUser';
import RegisteredUser from './RegisteredUser';
import React, {useEffect, useState} from 'react';
import {useRouteLoaderData} from 'react-router-dom';

export default function Details() {
    const { checkTokenData } = useRouteLoaderData("root");

    const [authenticated, setAuthenticated] = useState(!!checkTokenData?.customer?.firstName)

    useEffect(() => {
        setAuthenticated(!!checkTokenData?.customer?.firstName)
    }, [checkTokenData])

    if (authenticated) return <RegisteredUser />

    return <GuestUser />
}