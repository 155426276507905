import {NavLink, useFetcher, useRouteLoaderData} from 'react-router-dom';
import React, {useEffect} from 'react';
import {SideDrawer} from '../SideDrawer/SideDrawer';
import {DirectToLink} from '../../DirectToLink/DirectToLink';
import bagIcon from '../../../assets/icons/bag.svg';
import {Headline5} from '../../Headlines/Headlines';
import styled from 'styled-components';
import {Body} from '../../Texts/Texts';
import {ReactComponent as ChevronRight} from '../../../assets/icons/breadcrumb.svg';
import {Button} from '../../Buttons/Buttons';
import {toast} from 'react-toastify';
import {Toaster} from '../../Toaster/Toaster';

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const Item = styled.li`
    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;

const StyledLink = styled(Body).attrs({
    as: NavLink
})`
    text-decoration: none;
    display: inline-flex;
    
    &:hover, &.active {
        text-decoration: underline;
    }
`;

const StyledChevronRight = styled(ChevronRight)`
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    flex-shrink: 0;
    margin-top: 4px;
    
    path { fill: var(--color-primary); }
`;

const Links = styled.div`
    margin-top: 50px;
    
    ${Headline5} { margin-bottom: 20px; }
`;

const LogoutButton = styled(Button)`
    max-width: 148px;
    
    @media screen and (max-width: 768px) {
        max-width: 130px;
        min-height: unset;
        padding: 7px 0 8px;
    }
`;

export const AccountSideDrawer = ({isOpen, close}) => {
    const { checkTokenData } = useRouteLoaderData("root");

    const fetcher = useFetcher();

    const handleSignOut = async () => {
        fetcher.submit({}, { method: 'POST', action: '/uitloggen' });
    }

    useEffect(() => {
        if(fetcher.state === "idle" && fetcher.data?.logOutData) {
            close();
            toast(<Toaster type='success' title='Gelukt!' message='Je bent succesvol uitgelogd.' $onLight/>);
        }
        // eslint-disable-next-line
    }, [fetcher.state, fetcher.data])

    return (
        <SideDrawer title={`Hoi, ${checkTokenData?.customer?.firstName ?? ""}!`} isOpen={isOpen} close={close} header={<LogoutButton as="button" onClick={handleSignOut} disabled={fetcher.state !== 'idle'} $variant="accent">Uitloggen</LogoutButton>}>
            <DirectToLink to="/account/mijn-bestellingen" onClick={close} imageType="svg" icon={bagIcon} variant="primary">Mijn bestellingen</DirectToLink>

            <Links>
                <Headline5>Direct naar</Headline5>
                <List>
                    <Item><StyledLink to="/account/mijn-gegevens" onClick={close}><StyledChevronRight />Mijn gegevens</StyledLink></Item>
                    <Item><StyledLink to="/account/mijn-adressen" onClick={close}><StyledChevronRight />Mijn adressen</StyledLink></Item>
                    <Item><StyledLink to="/account/instellingen" onClick={close}><StyledChevronRight />Instellingen</StyledLink></Item>
                </List>
            </Links>
        </SideDrawer>
    );
}
