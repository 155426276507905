import styled from 'styled-components';
import Bookmark from '../../../assets/masks/bookmark.svg';
import BookmarkInverted from '../../../assets/masks/bookmark-inverted.svg';
import {ReactComponent as BookmarkOuter} from '../../../assets/masks/bookmark-outer.svg';

const StyledBookmarkMask = styled.div`
    position: relative;
    display: inline-block;
    align-self: flex-end;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        ${props => props.$position === 'left' && `left: 100%;`}
        ${props => props.$position === 'right' && `right: 100%;`}
        height: 2px;
        width: 100vw;
        background-color: ${props => props.$variant === 'primary' ? 'var(--color-primary-10)' : 'var(--color-primary)'};
    }
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 13px 13px 0 13px;
`;

const Image = styled.img`
    display: inline-block;
    width: 514px;
    max-width: 100%;
    height: 514px;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    
    @supports(-webkit-mask-image: url(${Bookmark})) or (mask-image: url(${Bookmark})) {
        mask-image: url(${props => props.$position === 'right' ? BookmarkInverted : Bookmark});
        -webkit-mask-image: url(${props => props.$position === 'right' ? BookmarkInverted : Bookmark});
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
    }
`;

const StyledBookmarkOuter = styled(BookmarkOuter)`
    width: 100%;
    max-width: 543px;
    height: auto;
    max-height: 546px;
    vertical-align: bottom;
    ${props => props.$position === 'right' && `
        transform: scaleX(-1);
    `}
    
    path { 
        stroke: ${props => props.$variant === 'primary' ? 'var(--color-primary-10)' : 'var(--color-primary)'};
        vector-effect: non-scaling-stroke;
    }
`;

export const BookmarkMask = ({variant, position, imageURL}) => {
    return (
        <StyledBookmarkMask $variant={variant} $position={position}>
            {imageURL &&
                <ImageWrapper $position={position}>
                    <Image src={imageURL} alt="" $position={position} />
                </ImageWrapper>
            }

            <StyledBookmarkOuter $variant={variant} $position={position} />
        </StyledBookmarkMask>
    );
}