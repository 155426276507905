import {checkHasAccount} from '../api/customer';
import {useState} from 'react';

export const useAccount = () => {
    const [hasAccount, setHasAccount] = useState(false);

    const handleHasAccount = async (email) => {
        let validEmail = /\S+@\S+\.\S+/.test(email);

        if (validEmail) {
            try {
                const checkHasAccountData = await checkHasAccount(email);

                if (checkHasAccountData?.hasAccount) {
                    return setHasAccount(true)
                }

                return setHasAccount(false)
            } catch (err) {
                // Ignore error, just continue in order flow with the email address
            }
        }

        return null;
    }

    return {hasAccount, handleHasAccount}
}