import React from "react";
import styled from "styled-components";
import {Headline3, Headline5} from "../Headlines/Headlines";
import {Button} from "../Buttons/Buttons";
import {Body} from "../Texts/Texts";
import {ReactComponent as LogoIdeal} from '../../assets/paymentOptions/ideal.svg';
import {ReactComponent as LogoMastercard} from '../../assets/paymentOptions/mastercard.svg';
import {ReactComponent as LogoVisa} from '../../assets/paymentOptions/visa.svg';
import {ReactComponent as LogoDeKler} from '../../assets/paymentOptions/dekler.svg';
import {useNavigation} from 'react-router-dom';
import {sendEcommerceEvent} from '../../events/dataLayer';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    max-width: 389px;
    min-width: 235px;
    width: 100%;
    flex-shrink: 3;
    
    @media screen and (max-width: 768px) {
        max-width: unset;
    }
`;

const Checkout = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--color-tertiary);
    max-height: 403px;
    width: 100%;
    padding: 34px;
    
    ${Button} { max-width: unset; }

    @media screen and (max-width: 768px) {
        background-color: transparent;
        padding: 0;
    }

    @media screen and (max-width: 1024px) {
        ${Headline3}{
            font-size: 24px;
        }
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CostTable = styled.div`
    border-bottom: 1px solid var(--color-primary-60);
`;

const Total = styled.div`
    margin: 18px 0 18px;
`;

const CheckoutButton = styled(Button)`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const PaymentMethods = styled.div`
    background-color: var(--color-light);
    padding: 6px 34px 34px;

    @media screen and (max-width: 768px) {
        background-color: transparent;
        padding: 0;
    }
`;

const LogoBox = styled.div`
    display: flex;
    gap: 10px
`;

const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    width: 50px;
    height: 50px;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
        background-color: var(--color-light);
    }
`;

export default function OrderSummary({data}) {
    const {state} = useNavigation();

    const mightCollectInShop = Boolean(data?.products?.filter(item => !item.eBook)?.length);

    const handleBeginCheckout = () => {
        if(data?.products && state === "idle") sendEcommerceEvent('begin_checkout', data.products)
    }

    return (
        <Wrapper>
            <Checkout>
                <Headline3>Besteloverzicht</Headline3>

                <CostTable>
                    <Row>
                        <Body>Subtotaal</Body>
                        <Body>{data.price ?? "Fout"}</Body>
                    </Row>

                    {mightCollectInShop &&
                        <Row>
                            <Body>Afhalen in winkel</Body>
                            <Body>0,00</Body>
                        </Row>
                    }
                </CostTable>

                <Total>
                    <Row>
                        <Headline5>Totaal</Headline5>
                        <Headline5 $bold>{data.totalPrice ?? "Fout"}</Headline5>
                    </Row>
                </Total>

                <CheckoutButton $variant='secondary' to='/bestellen/levering' disabled={state !== 'idle'} onClick={handleBeginCheckout}>Bestellen</CheckoutButton>
            </Checkout>

            <PaymentMethods>
                <Body $bold>Veilig betalen met:</Body>

                <LogoBox>
                    <Circle><LogoIdeal/></Circle>
                    <Circle><LogoMastercard/></Circle>
                    <Circle><LogoVisa/></Circle>
                    <Circle><LogoDeKler/></Circle>
                </LogoBox>
            </PaymentMethods>
        </Wrapper>
    )
}