import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Headline4} from "../../../components/Headlines/Headlines";
import {Button} from "../../../components/Buttons/Buttons";
import {ReactComponent as Add} from '../../../assets/icons/add.svg';
import {Body} from "../../../components/Texts/Texts";
import {Await, defer, NavLink, useAsyncError, useLoaderData, useNavigation, useRevalidator} from "react-router-dom";
import {toast} from 'react-toastify';
import {Toaster} from '../../../components/Toaster/Toaster';
import {EmptyState} from '../../../components/EmptyState/EmptyState';
import {Seo} from '../../../components/Seo/Seo';
import {rootLoaderPromise} from '../../../layouts/Root';
import {getAddresses} from '../../../api/addresses';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 64px;
`;

const AddressBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
`;

const StyledLink = styled(Body).attrs({
    as: NavLink
})`
    color: var(--color-secondary);
    ${props => props.$disabled && 'pointer-events: none;'}
`;

export async function myAddressesLoader({request}) {
    await rootLoaderPromise;

    let getAddressesData;
    try {
        getAddressesData = await getAddresses(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({addressesData: getAddressesData});
}

export default function MyAddresses() {
    const {addressesData} = useLoaderData();

    const {state} = useNavigation();
    return (
        <>
            <Seo metaTitle="Mijn adressen" />
            <React.Suspense>
                <Await resolve={addressesData} errorElement={<MyAddressesErrorBoundary />}>
                    {(myAddresses) => (
                        <Wrapper>
                            <AddressBox>
                                <Headline4>Bezorgadressen</Headline4>

                                {myAddresses?.deliveryAddresses.length > 0
                                    ? myAddresses?.deliveryAddresses.map((address, index) => (
                                        <StyledLink key={index} to={`/account/mijn-adressen/${address.id}`} $disabled={state !== 'idle'}>
                                            {address.addressLine1} {address.number}{address.postbus}, {address.postcode} {address.city}, {address.country}<br/>
                                        </StyledLink>
                                    ))
                                    : <Body>Je hebt nog geen bezorgadres ingesteld.</Body>
                                }

                                <Button to={`/account/mijn-adressen/toevoegen`} state={{ type: 1 }} disabled={state !== 'idle'} $icon><Add/>Adres toevoegen</Button>
                            </AddressBox>

                            <AddressBox>
                                <Headline4>Factuuradressen</Headline4>

                                {myAddresses?.invoiceAddresses.length > 0
                                    ? myAddresses?.invoiceAddresses.map((address, index) => (
                                        <StyledLink key={index} to={`/account/mijn-adressen/${address.id}`}>
                                            {address.addressLine1} {address.number}{address.postbus}, {address.postcode} {address.city}, {address.country}<br/>
                                        </StyledLink>
                                    ))
                                    : <Body>Je hebt nog geen factuuradres ingesteld.</Body>
                                }

                                <Button to={`/account/mijn-adressen/toevoegen`} state={{ type: 2 }} disabled={state !== 'idle'} $icon><Add/>Adres toevoegen</Button>
                            </AddressBox>
                        </Wrapper>
                    )}
                </Await>
            </React.Suspense>
        </>
    )
}

const MyAddressesErrorBoundary = () => {
    const err = useAsyncError()
    const [errorType, setErrorType] = useState(null);
    let revalidator = useRevalidator();

    useEffect( () => {
        if (err.status === 401) {
            // If Promise rejects as 401, something is wrong with the token so revalidate
            revalidator.revalidate();
        }
        else if (err.status === 409) {
            // If Promise rejects as 409, the cart is empty
            setErrorType(409);
        } else {
            // On other errors, return a toast
            toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        }

        return () => {setErrorType(null)};
        // eslint-disable-next-line
    }, [err])

    if(errorType === 409) {
        return (
            <>
                <EmptyState heading="Je hebt nog geen adressen" description="Maak je eerste adres aan" />
                <Button to={`/account/mijn-adressen/toevoegen`} state={{ type: 1 }} $icon><Add/>Adres toevoegen</Button>
            </>
        );
    }

    return <></>;
}