import styled, {keyframes} from 'styled-components';

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
    display: ${({$block}) => $block ? 'block' : 'inline-block'};
    position: relative;
    width: ${props => props.$extraSmall ? '20px' : props.$small ? '30px' : props.$medium ? '40px' : '50px'};
    height: ${props => props.$extraSmall ? '20px' : props.$small ? '30px' : props.$medium ? '40px' : '50px'};
`;

const LoadPart = styled.div`
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => props.$extraSmall ? '20px' : props.$small ? '20px' : props.$medium ? '30px' : '40px'};
    height: ${props => props.$extraSmall ? '20px' : props.$small ? '20px' : props.$medium ? '30px' : '40px'};
    margin: 4px;
    border: ${props => props.$extraSmall ? '3px solid var(--color-primary)' : props.$small ? '3px solid var(--color-primary)' : props.$medium ? '4px solid var(--color-primary)' : '6px solid var(--color-primary)'};
    border-radius: 50%;
    animation: ${spinner} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color-primary) transparent transparent transparent;
    
    &:nth-child(1) {
        animation-delay: -0.45s;
    }

    &:nth-child(2) {
        animation-delay: -0.3s;
    }

    &:nth-child(3) {
        animation-delay: -0.15s;
    }
`;

export const Spinner = ({...props}) => {

    return (
        <StyledLoader {...props}>
            <LoadPart {...props} />
            <LoadPart {...props} />
            <LoadPart {...props} />
            <LoadPart {...props} />
        </StyledLoader>
    );
}