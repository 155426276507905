import React, {Fragment, useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {Headline2, Headline3} from "../../components/Headlines/Headlines";
import {Body} from "../../components/Texts/Texts";
import {IconButton, Label, OptionalButton} from "../../components/Buttons/Buttons";
import {ReactComponent as ChevronLeft} from "../../assets/icons/chevron-left.svg";
import {ReactComponent as ChevronRight} from "../../assets/icons/chevron-right.svg";
import {Spacing} from '../../components/Spacing/Spacing';
import {ConditionalWrap} from '../../components/ConditionalWrap/ConditionalWrap';
import {LayeredCustomLink} from '../../components/CustomLink/CustomLink';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledContentSlider = styled(Container)``;

const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const ControlButtons = styled.div`
    display: flex;
    gap: 10px;

    @media screen and (max-width: 768px) {
        padding-right: 20px;
    }

    ${({$amount}) => $amount === 3 && `
        display: none;
        
        @media screen and (max-width: 1024px) {
            display: flex;
        }
    `};

    ${({$amount}) => $amount === 2 && `
        display: none;
        
        @media screen and (max-width: 768px) {
            display: flex;
        }
    `};

    ${({$amount}) => $amount === 1 && ` display: none; `};
`;

const StyledIconButton = styled(IconButton)`
  &:disabled {
      opacity: 0.2;

      &:hover {
          background-color: transparent;
          color: var(--color-primary);
          path { fill: var(--color-primary); }
      }
  }
`;

const ContentSliderItem = styled.div`
    // Used for making the whole ContentSliderItem clickable while still maintaining nested links
    position: relative;
    a {
        position: relative;
        z-index: 1;
    }
`;

const StyledLabel = styled(Label)`
    position: absolute;
    left: 15px;
    top: 15px;
    pointer-events: none;
`;

const StyledImage = styled.img`
    display: block;
    object-fit: cover;
    width: 100%;
    height: 230px; 
    max-height: 100%;
`;

const SlideContent = styled.div`
    padding: 28px 28px 0;
    border-left: 2px solid  var(--color-primary-10);
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    ${Body} { 
        height: 173px; 
        
        a {
            color: inherit;
            
            &:hover {
                text-decoration: none;
            }
        }
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    row-gap: 100px;
    
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 60px;
    }
    
    @media screen and (max-width: 475px) {
        grid-template-columns: 1fr;
        row-gap: 40px;
    }
`;

export const ContentSlider = ({data}) => {
    const swiperRef = useRef(null);
    const prevBtnRef = useRef(null);
    const nextBtnRef = useRef(null);
    const {width} = useWindowDimensions();

    if(data.slides.length === 0) { return null; }

    const findEmailInText = (description) => {
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
        const emailAddresses = description.match(emailRegex);

        if(Boolean(emailAddresses)) {
            const splittedDescription = description.split(emailRegex);

            return splittedDescription.map((part, index) => {
                if (index < emailAddresses.length) {
                    const email = emailAddresses[index].trim();
                    return (
                        <Fragment key={index}>
                            {part}
                            <a href={`mailto:${email}`}>{email}</a>
                        </Fragment>
                    );
                } else {
                    return part;
                }
            })
        }

        return description;
    }

    const isSlider = data?.slider === true;

    return (
        <Spacing>
            <StyledContentSlider>
                <TopBox>
                    {data?.title && <Headline2>{data?.title}</Headline2>}
                    {isSlider &&
                        <ControlButtons $amount={data?.slides?.length}>
                            <StyledIconButton $variant="outline" as="button" ref={prevBtnRef}><ChevronLeft/></StyledIconButton>
                            <StyledIconButton $variant="outline" as="button" ref={nextBtnRef}><ChevronRight/></StyledIconButton>
                        </ControlButtons>
                    }
                </TopBox>

                <ConditionalWrap
                    condition={isSlider}
                    wrap={children => (
                        <Swiper
                            ref={swiperRef}
                            threshold={40}
                            allowTouchMove={width < 768}
                            breakpoints={{
                                481: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                769: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                1025: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                            }}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = prevBtnRef.current;
                                swiper.params.navigation.nextEl = nextBtnRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                            modules={[Navigation]}
                        >
                            {children}
                        </Swiper>
                    )}
                    fallback={children => (<Grid>{children}</Grid>)}
                >
                    {data?.slides?.map((slide, index) => (
                        <SwiperSlide key={index} as={isSlider ? "div" : SwiperSlide}>
                            <ContentSliderItem>
                                {slide.labelText && <StyledLabel as="span" $variant={slide.labelColor}>{slide.labelText}</StyledLabel>}
                                <StyledImage src={slide.imageURL} />
                                <SlideContent>
                                    <LayeredCustomLink to={slide.url} linkType={slide.linkType}><Headline3 $truncated $clamp={2}>{slide.title}</Headline3></LayeredCustomLink>
                                    <Body $truncated $clamp={7}>{findEmailInText(slide.description)}</Body>
                                    <OptionalButton to={slide.url} linkType={slide.linkType} truncate={true} $variant="outline" $small>{slide.linkText}</OptionalButton>
                                </SlideContent>
                            </ContentSliderItem>
                        </SwiperSlide>
                    ))}
                </ConditionalWrap>
            </StyledContentSlider>
        </Spacing>
    );
}