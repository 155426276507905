import FormField from '../Fields/FormField';
import styled from 'styled-components';
import {Button} from '../Buttons/Buttons';
import {Headline3} from '../Headlines/Headlines';
import {Body} from '../Texts/Texts';
import {FormRow, FormRowHidden, FormRowItem} from '../Fields/FormRows';
import {useNavigation} from 'react-router-dom';

const StyledAddressForm = styled.div`
    margin: 40px 0 0;

    @media screen and (max-width: 768px) {
        margin: 20px 0 0;
    }
`;

const Title = styled(Headline3)`
    margin-bottom: 40px;
    
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const StyledForm = styled.form``;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 45px;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

const SubmitButton = styled(Button)`
    @media screen and (max-width: 768px) {
        margin-top: 5px;
    }
`;

const StyledBody = styled(Body)`
    ${({$error}) => $error && `
        color: var(--color-secondary);
        font-weight: 700;
    `};
`;

export const AddressForm = ({title, onSubmit, errors, register, handleAddress, displayAddressFromPostalCode, breakpoint, intent}) => {
    const { state } = useNavigation();

    FormField.defaultProps = {errors: errors, register: register};

    return (
        <StyledAddressForm>
            <Title>{title || "Adres toevoegen"}</Title>

            <StyledForm onSubmit={onSubmit}>

                <FormRow $spacing="large" $ignore>
                    <FormField
                        type="radio"
                        title="Aanhef"
                        name="salutation"
                        breakPoint={768}
                        options={[
                            {
                                "id": "salutationChoice1",
                                "value": "De heer",
                                "title": "De heer"
                            },
                            {
                                "id": "salutationChoice2",
                                "value": "Mevrouw",
                                "title": "Mevrouw"
                            },
                            {
                                "id": "salutationChoice3",
                                "value": "-",
                                "title": "Liever geen van beide"
                            }
                        ]}
                    />
                </FormRow>

                <FormRow $spacing="small" $breakpoint={breakpoint ? `${breakpoint}px` : '768px'} $fullRow>
                    <FormRowItem>
                        <FormField type="text" title="Voornaam" name="firstName" placeholder="Voornaam" required={true}/>
                    </FormRowItem>
                    <FormRowItem $small>
                        <FormField type="text" title="Tussenv." name="prefix" placeholder="Tussenvoegsel" maxLength={10} />
                    </FormRowItem>
                    <FormRowItem>
                        <FormField type="text" title="Achternaam" name="lastName" placeholder="Achternaam" required={true}/>
                    </FormRowItem>
                </FormRow>

                <FormRow $spacing="small" $breakpoint={breakpoint ? `${breakpoint}px` : '768px'}>
                    <FormRowItem>
                        <FormField type="text" title="Postcode" name="postcode" placeholder="Postcode" onBlur={handleAddress ?? null} required={true} />
                    </FormRowItem>
                    <FormRowItem $small>
                        <FormField type="int" title="Huisnr." name="number" placeholder="Nr." onBlur={handleAddress ?? null} required={true} small={true} />
                    </FormRowItem>
                    <FormRowItem $small>
                        <FormField type="text" title="Toevoeging" name="postbus" placeholder="Toevoeging" onBlur={handleAddress ?? null} required={false} />
                    </FormRowItem>
                </FormRow>

                <FormRow $spacing="large" $ignore>
                    <Body $bold $small>Adres</Body>
                    <StyledBody
                        $error={displayAddressFromPostalCode === "Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen."}
                    >
                        {displayAddressFromPostalCode ?? "Geef een postcode en huisnummer op"}
                    </StyledBody>
                </FormRow>

                <FormRow $breakpoint={breakpoint ? `${breakpoint}px` : '768px'}>
                    <FormRowItem>
                        <FormField type="tel" title="Telefoonnummer" name="phone" placeholder="Telefoonnummer" required={false}/>
                    </FormRowItem>
                </FormRow>

                {/* Hidden fields so it's included in FormData */}
                <FormRowHidden>
                    <FormField type="int" name="type" />
                    <FormField type="text" title="Adres" name="addressLine1" required={true} />
                    <FormField type="text" title="Plaats" name="city" required={true} />
                    <FormField type="text" name="country"  />
                    {intent && <FormField type="text" name="intent"  />}
                    {intent === "editAddress" && <FormField type="int" name="id"  />}
                </FormRowHidden>

                <ButtonWrapper>
                    <SubmitButton as="button" type="submit" disabled={state !== 'idle'} $variant="secondary">Opslaan</SubmitButton>
                </ButtonWrapper>
            </StyledForm>
        </StyledAddressForm>
    );
}