import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-right.svg';
import {headline5Styles} from '../Headlines/Headlines';
import {buttonVariants} from '../Buttons/Buttons';
import {truncatedStyles} from '../Texts/Texts';

const StyledDirectToLink = styled(Link)`
    ${headline5Styles};
    text-decoration: none;

    ${({$variant})  => buttonVariants($variant)}

    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding: ${props => props.$icon ? '0 30px 0 0' : '18px 30px'};
    
    @media screen and (max-width: 1024px) {
        padding: ${props => props.$icon ? '0 10px 0 0' : '18px 10px'};
    }
`;

const Box = styled.div`
    display: flex;
    align-items: center;
`;

const IconWrapper = styled.div`
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.3);
    margin-right: 27px;
    flex-shrink: 0;
    
    @media screen and (max-width: 1024px) {
        margin-right: 18px;
    }
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

    // If primary and secondary: icon is always white, if icon is tertiary: icon is blue and white on hover
    filter: ${props => props.$icon === 'primary' || props.$icon === 'secondary'
        ? 'brightness(0) saturate(100%) invert(90%) sepia(0%) saturate(7500%) hue-rotate(102deg) brightness(113%) contrast(113%)'
        : props.$icon === 'tertiary' ? 'filter: brightness(0) saturate(100%) invert(21%) sepia(74%) saturate(525%) hue-rotate(155deg) brightness(103%) contrast(101%);'
        : 'none'
    };
    
    ${StyledDirectToLink}:hover & {
        ${props => props.$icon === 'tertiary' && 'filter: brightness(0) saturate(100%) invert(100%) sepia(85%) saturate(2%) hue-rotate(89deg) brightness(108%) contrast(100%);'};
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledChevron = styled(Chevron)`
    flex-shrink: 0;
`;

const Content = styled.span`
    ${truncatedStyles}
`;

export const DirectToLink = ({to, variant, icon, imageType, children, ...props}) => {

    return (
        <StyledDirectToLink to={to} $icon={!!icon} $variant={variant} {...props}>
            <Box>
                {icon &&
                    <IconWrapper>
                        {imageType === 'svg'
                            ? <Icon src={icon} alt="Icon" $icon={variant} />
                            : <Image src={icon} alt="Icon" />
                        }
                    </IconWrapper>
                }
                <Content $truncated $clamp={2}>
                    {children}
                </Content>
            </Box>
            <StyledChevron />
        </StyledDirectToLink>
    );
}