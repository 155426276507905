import styled from 'styled-components';
import {Body} from '../Texts/Texts';

export const FormRowItem = styled.div`
    width: ${props => props.$small ? '20%' : '40%'};
    
    // Media query in FormRow
`;

// A FormRow contains one/multiple FormRowItem's to display a row
// of form fields OR contains a single FormField to use full width
export const FormRow = styled.div`
    margin-bottom: ${props => props.$spacing === 'small' ? '10px'
        : props.$spacing === 'medium' ? '20px'
        : props.$spacing === 'large' ? '25px'
        : props.$spacing === 'extraLarge' ? '60px'
        : props.$spacing === 'custom' ? props.$space
        : '0'
    };

    // If FormRow is not ignored and not a full row
    ${props => (!props.$ignore && !props.$fullRow) && `
        padding-right: 24px;
        
        @media screen and (max-width: ${props.$breakpoint || '1024px'}) {
            padding-right: 0;
        }
    `}
    
    // If FormRow is not ignored
    ${props => !props.$ignore && `
        max-width: 100%;
        display: flex;
        gap: 12px;
        
        @media screen and (max-width: ${props.$breakpoint || '1024px'}) {
            flex-direction: column;
        }
    `}
    
    ${Body} { margin: 0; }
    
    ${FormRowItem} {
        @media screen and (max-width: ${props => props.$breakpoint || '1024px'}) {
            width: 100%;
        }
    }
`;

export const FormRowHidden = styled.div`
    display: none;
`;