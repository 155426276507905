import {HeaderFailed} from "../../sections/HeaderFailed/HeaderFailed";
import {Seo} from '../../components/Seo/Seo';
import React from 'react';

export default function OrderCanceled() {
    return (
        <>
            <Seo metaTitle="Bestelling geannuleerd" />

            <HeaderFailed message={"Je bestelling is geannuleerd."} />
        </>
    );
}