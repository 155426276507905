import styled from 'styled-components';
import {Headline1} from '../Headlines/Headlines';
import {Button, OptionalButton} from '../Buttons/Buttons';
import {Subtitle2, Subtitle3} from '../Subtitles/Subtitles';
import {Body} from '../Texts/Texts';
import {Link} from 'react-router-dom';

const StyledBookSlide = styled.article`
    background-color: ${props => props.$variant === 'secondary' ? 'var(--color-secondary)' : props.$variant === 'tertiary' ? 'var(--color-tertiary)' : 'var(--color-primary)'};
    height: 548px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        height: 540px;
        max-height: 100%;
    }
`;

const Inner = styled.div`
    margin: 0 auto;
    height: 100%;
    display: flex;
    position: relative;
    padding: 0 20px;
    width: 100%;
    
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin: 0;
    }
`;

const BookImageWrapper = styled.div`
    flex: 1 1 40%;
    margin: auto 0 30px;
    padding-right: 60px;
    
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
        margin: auto 0 0;
        padding-bottom: 120px;
    }
`;

const BookImage = styled.img`
    margin-left: auto;
    max-width: 257px;
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    
    @media screen and (max-width: 768px) {
        width: auto;
        max-height: 180px;
        margin-left: unset;
    }
`;

const BookShelf = styled.hr`
    border: 1px solid rgba(255, 255, 255, .3);
    position: absolute;
    bottom: 30px;
    left: -50%;
    margin: 0;
    width: 100%;
    
    @media screen and (max-width: 768px) {
        bottom: 120px;
        left: 30px;
    }
`;

const BookContent = styled.div`
    flex: 1 1 60%;
    padding-right: 40px;
    align-self: center;
    
    ${Subtitle2}, ${Headline1}, ${Subtitle3} { margin-bottom: 16px; }
    ${Subtitle3} { display: none; }

    @media screen and (max-width: 768px) {
        flex-basis: 100%;
        align-self: flex-start;
        padding: 118px 0 0;
        height: 100%;
        
        ${Subtitle3} { display: -webkit-box; }
        ${Body} { display: none; }
        
        ${Button} {
            position: absolute;
            bottom: 40px;
            left: 20px;
        }
    }
`;

export const AgendaBookSlide = ({superTitle, title, subTitle, description, buttonText, url, imageUrl, variant, onAgenda = false}) => {
    return (
        <StyledBookSlide $variant={variant}>
            <Inner>
                <BookImageWrapper>
                    <Link to={url}>
                        <BookImage src={imageUrl} alt={title} />
                    </Link>
                </BookImageWrapper>
                <BookContent>
                    {superTitle && <Subtitle2 $truncated $clamp={1} $variant={variant}>{superTitle}</Subtitle2>}
                    <Headline1 as='h2' $truncated $clamp={onAgenda ? 3 : 2} $variant={variant}>{title}</Headline1>
                    <Subtitle3 $truncated $clamp={onAgenda ? 3 : 1} $variant={variant}>{subTitle}</Subtitle3>
                    <Body $truncated $clamp={3} $variant={variant}>{description}</Body>
                    <OptionalButton to={url} truncate={true} $variant={variant === 'secondary' ? 'tertiary' : 'secondary'}>{buttonText}</OptionalButton>
                </BookContent>
                <BookShelf />
            </Inner>
        </StyledBookSlide>
    );
}