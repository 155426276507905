import {Button} from "../../../components/Buttons/Buttons";
import styled from "styled-components";
import {Container} from "../../../components/Container/Container";
import {ReactComponent as ChevronLeft} from "../../../assets/icons/chevron-left.svg";
import React, {useEffect} from "react";
import {AddressForm} from '../../../components/AddressForm/AddressForm';
import {createAddress} from '../../../api/addresses';
import {redirect, useLocation, useSubmit} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import FormField from '../../../components/Fields/FormField';
import {useAddressFromPostalCode} from '../../../hooks/useAddressFromPostalCode';
import {Toaster} from '../../../components/Toaster/Toaster';
import {toast} from "react-toastify";
import {Seo} from '../../../components/Seo/Seo';

const Wrapper = styled.div`
    max-width: 825px;
    margin: 40px 0 40px;

    @media screen and (max-width: 768px) {
        margin: 20px 0 20px;
    }
`;

const BackButton = styled(Button)`
    max-width: 116px;
`;

export async function createAddressAction({request}) {
    let formData = await request.formData();
    const addressObj = {
        "type": parseInt(formData.get("type")),
        "salutation": formData.get("salutation"),
        "firstName": formData.get("firstName"),
        "prefix": formData.get("prefix"),
        "lastName": formData.get("lastName"),
        "postcode": formData.get("postcode"),
        "number": parseInt(formData.get("number")),
        "postbus": formData.get("postbus"),
        "addressLine1": formData.get("addressLine1"),
        "city": formData.get("city"),
        "country": 2,
        "phone": formData.get("phone"),
    }

    try {
        await createAddress(request, addressObj);

        toast(<Toaster type='success' title='Gelukt!' message='Het adres is succesvol toegevoegd.' $onLight/>);

        return redirect('/account/mijn-adressen');
    } catch(err) {
        return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
    }
}

export default function CreateAddress(){
    const { state } = useLocation();

    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
        defaultValues: {
            type: state?.type ?? 1,
            salutation: "De heer",
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    const submit = useSubmit();

    const onSubmit = (data) => {
        submit(data, { method: 'post', action: '/account/mijn-adressen/toevoegen' })
    };

    const {displayAddressFromPostalCode, handleAddress} = useAddressFromPostalCode(getValues, setValue);

    return (
        <Container>
            <Seo metaTitle="Adres toevoegen" />
            <Wrapper>
                <BackButton to=".." relative="path" $variant="outline" $icon><ChevronLeft/>Terug</BackButton>
                <AddressForm onSubmit={handleSubmit(onSubmit)} handleAddress={handleAddress} displayAddressFromPostalCode={displayAddressFromPostalCode} register={register} errors={errors} />
            </Wrapper>
        </Container>
    )
}