import {Await, defer, useLoaderData} from 'react-router-dom';
import {Seo} from '../../components/Seo/Seo';
import {Blocks} from '../../components/Blocks/Blocks';
import React from 'react';

export async function homeLoader({request}) {
    const contentRes = fetch(`${process.env.REACT_APP_API_BASE_URL}/dynamic-page?url=home`, { signal: request.signal})
        .then((res) => {
            // Handle if response is not ok, bad request so probably not found
            if (!res.ok) { throw new Response("Network response was not OK, page is probably Not Found", { status: 404 }); }

            return res.json();
        });

    return defer({content: contentRes}) // Deferred
}

export default function Home() {
    const data  = useLoaderData();

    return (
        <>
            <React.Suspense>
                <Await resolve={data.content}>
                    {(content) => (
                        <Seo metaTitle={content.title} metaDescription={content.metaDescription} url="/" />
                    )}
                </Await>
            </React.Suspense>

            <React.Suspense>
                <Await resolve={data.content}>
                    {(content) => (
                        <Blocks data={content.blocks} breadcrumbs={content.breadcrumbs} />
                    )}
                </Await>
            </React.Suspense>
        </>
    )
}