import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home, {homeLoader} from "./routes/Home/Home";
import {HelmetProvider} from "react-helmet-async";
import Books, {booksLoader, Grid, gridLoader, shouldRevalidateBooks, shouldRevalidateGrid} from "./routes/Books/Books";
import Product, {productLoader, shouldRevalidateProducts} from "./routes/Product/Product";
import {useEffect} from "react";
import DynamicPage, {dynamicPageLoader} from "./routes/DynamicPage/DynamicPage";
import WebFont from 'webfontloader';
import BebasNeue from './assets/fonts/BebasNeue-Regular-typeface.css';
import CartOverview, {cartLoader, cartOverviewAction} from "./routes/CartOverview/CartOverview";
import Layout, {navigationLoader, shouldRevalidateNavigation} from "./layouts/Layout";
import OrderLayout, {orderLayoutLoader, shouldRevalidateOrderLayout} from "./layouts/OrderLayout";
import AccountLayout from "./layouts/AccountLayout";
import Delivery, {deliveryAction, deliveryLoader} from "./routes/OrderFlow/Delivery/Delivery";
import Details from "./routes/OrderFlow/Details/Details";
import Payment, {paymentAction, paymentLoader} from "./routes/OrderFlow/Payment/Payment";
import SideDrawerProvider from "./contexts/SideDrawerContext";
import MyData, {myDataAction, myDataLoader} from "./routes/Account/MyData/MyData";
import MyOrders, {myOrdersLoader} from "./routes/Account/MyOrders/MyOrders";
import OrderDetail, {orderDetailLoader} from "./routes/Account/MyOrders/OrderDetail";
import MyAddresses, {myAddressesLoader} from "./routes/Account/MyAddresses/MyAddresses";
import MySettings, {mySettingsAction, mySettingsLoader} from "./routes/Account/MySettings/MySettings";
import Address, {addressAction, addressLoader} from "./routes/Account/MyAddresses/Address";
import ApiFailureFallback from "./routes/Error/ApiFailureFallback/ApiFailureFallback";
import NoMatch from "./routes/Error/NoMatch/NoMatch";
import ErrorBoundary from "./routes/Error/ErrorBoundary/ErrorBoundary";
import WishlistOverview, {wishlistLoader, wishlistOverviewAction} from "./routes/WishlistOverview/WishlistOverview";
import Register, {registerAction} from "./routes/Register/Register";
import ForgotPassword, {forgotPasswordAction} from "./routes/ForgotPassword/ForgotPassword";
import OrderSuccess, {orderSuccessAction, orderSuccessLoader} from "./routes/OrderSuccess/OrderSuccess";
import OrderCanceled from "./routes/OrderCanceled/OrderCanceled";
import ResetPassword, {resetPasswordAction, resetPasswordLoader} from "./routes/ResetPassword/ResetPassword";
import CreateAddress, {createAddressAction} from "./routes/Account/MyAddresses/CreateAddress";
import SideDrawerLayout, {shouldRevalidateSideDrawer, sideDrawerLoader} from "./layouts/SideDrawerLayout";
import Root, {rootLoader} from "./layouts/Root";
import Login, {loginAction} from "./routes/Login/Login";
import Logout, {logoutAction} from "./routes/Logout/Logout";
import NewAddress, {newAddressAction} from "./routes/OrderFlow/Details/NewAddress";
import EditAddress, {editAddressLoader} from "./routes/OrderFlow/Details/EditAddress";
import {RequireAuth} from "./routes/RouteProtectors/RequireAuth";
import {RequireGuest} from "./routes/RouteProtectors/RequireGuest";
import DetailsLayout, {detailsLayoutAction, detailsLayoutLoader} from "./layouts/DetailsLayout";
import {OrderFlowProtector} from "./routes/RouteProtectors/OrderFlowProtector";
import Agenda, {agendaLoader} from "./routes/Agenda/Agenda";
import AgendaDetail, {agendaDetailLoader} from "./routes/Agenda/AgendaDetail";
import ProductsInCartProvider from "./contexts/ProductsInCartContext";
import {oldUrls, redirectPermanently} from "./helpers/redirectPermanently";

function App() {
    useEffect(() => {
        WebFont.load({
            custom: {
                families: 'Bebas Neue',
                urls: BebasNeue,
            }
        });
    })

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            loader: rootLoader,
            errorElement: <ApiFailureFallback />,
            id: "root",
            children: [
                {
                    element: <SideDrawerLayout />,
                    loader: sideDrawerLoader,
                    shouldRevalidate: shouldRevalidateSideDrawer,
                    id: "sideDrawer",
                    children: [
                        {
                            element: <Layout />,
                            loader: navigationLoader,
                            errorElement: <ApiFailureFallback />,
                            shouldRevalidate: shouldRevalidateNavigation,
                            children: [
                                {
                                    index: true,
                                    element: <Home />,
                                    loader: homeLoader,
                                    errorElement: <NoMatch />,
                                },
                                {
                                    path: '/boeken',
                                    element: <Books />,
                                    loader: booksLoader,
                                    errorElement: <NoMatch />,
                                    shouldRevalidate: shouldRevalidateBooks,
                                    children: [
                                        {
                                            path: '*?',
                                            element: <Grid />,
                                            loader: gridLoader,
                                            errorElement: <ErrorBoundary />,
                                            shouldRevalidate: shouldRevalidateGrid,
                                        },
                                    ],
                                },
                                {
                                    path: '/product/:isbn/:title?/:eanf?',
                                    element: <Product />,
                                    loader: productLoader,
                                    errorElement: <NoMatch />,
                                    shouldRevalidate: shouldRevalidateProducts,
                                },
                                {
                                    path: '/agenda',
                                    element: <Agenda />,
                                    loader: agendaLoader,
                                    errorElement: <NoMatch />,
                                },
                                {
                                    path: '/agenda/:slug',
                                    element: <AgendaDetail />,
                                    loader: agendaDetailLoader,
                                    errorElement: <NoMatch />,
                                },
                                {
                                    element: <RequireAuth/>,
                                    children: [
                                        {
                                            element: <AccountLayout/>,
                                            children: [
                                                {
                                                    path: '/account/mijn-bestellingen',
                                                    element: <MyOrders/>,
                                                    loader: myOrdersLoader,
                                                    errorElement: <ErrorBoundary />,
                                                },
                                                {
                                                    path: '/account/mijn-gegevens',
                                                    element: <MyData/>,
                                                    loader: myDataLoader,
                                                    action: myDataAction,
                                                    errorElement: <ErrorBoundary />,
                                                },
                                                {
                                                    path: '/account/mijn-adressen',
                                                    element: <MyAddresses/>,
                                                    loader: myAddressesLoader,
                                                    errorElement: <ErrorBoundary />,
                                                },
                                                {
                                                    path: '/account/instellingen',
                                                    element: <MySettings/>,
                                                    loader: mySettingsLoader,
                                                    action: mySettingsAction,
                                                    errorElement: <ErrorBoundary />,
                                                }
                                            ]
                                        },
                                        {
                                            path: '/account/mijn-bestellingen/:id',
                                            element: <OrderDetail/>,
                                            loader: orderDetailLoader,
                                            errorElement: <NoMatch />,
                                        },
                                        {
                                            path: '/account/mijn-adressen/:id',
                                            element: <Address/>,
                                            loader: addressLoader,
                                            action: addressAction,
                                            errorElement: <NoMatch />,
                                        },
                                        {
                                            path: '/account/mijn-adressen/toevoegen',
                                            element: <CreateAddress/>,
                                            action: createAddressAction,
                                            errorElement: <ErrorBoundary />,
                                        },
                                    ],
                                },
                                {
                                    path: '/winkelmandje',
                                    element: <CartOverview />,
                                    loader: cartLoader,
                                    action: cartOverviewAction,
                                    errorElement: <ErrorBoundary />,
                                },
                                {
                                    path: '/verlanglijstje',
                                    element: <WishlistOverview />,
                                    loader: wishlistLoader,
                                    action: wishlistOverviewAction,
                                    errorElement: <ErrorBoundary />,
                                },
                                {
                                    element: <RequireGuest/>,
                                    children: [
                                        {
                                            path: '/login',
                                            element: <Login/>,
                                            action: loginAction,
                                            errorElement: <ErrorBoundary />
                                        },
                                        {
                                            path: '/registreren',
                                            element: <Register/>,
                                            action: registerAction,
                                            errorElement: <ErrorBoundary />,
                                        },
                                    ],
                                },
                                {
                                    path: '/uitloggen',
                                    element: <Logout/>,
                                    action: logoutAction,
                                    errorElement: <ErrorBoundary />
                                },
                                {
                                    path: '/wachtwoord-vergeten',
                                    element: <ForgotPassword/>,
                                    action: forgotPasswordAction,
                                    errorElement: <ErrorBoundary />
                                },
                                {
                                    path: '/wachtwoord-resetten',
                                    element: <ResetPassword/>,
                                    loader: resetPasswordLoader,
                                    action: resetPasswordAction,
                                    errorElement: <ErrorBoundary />,
                                },
                                {
                                    path: '/bestelling-gelukt',
                                    element: <OrderSuccess/>,
                                    loader: orderSuccessLoader,
                                    action: orderSuccessAction,
                                    errorElement: <NoMatch />,
                                },
                                {
                                    path: '/bestelling-geannuleerd',
                                    element: <OrderCanceled/>
                                },
                                {
                                    path: '/*',
                                    element: <DynamicPage />,
                                    loader: dynamicPageLoader,
                                    errorElement: <NoMatch />,
                                },
                                {
                                    path: "*",
                                    element: <NoMatch />,
                                },
                            ],
                        },
                    ],
                },
                {
                    element: <OrderFlowProtector/>,
                    children: [
                        {
                            element: <OrderLayout/>,
                            loader: orderLayoutLoader,
                            errorElement: <ApiFailureFallback/>,
                            shouldRevalidate: shouldRevalidateOrderLayout,
                            id: "orderLayout",
                            children: [
                                {
                                    path: '/bestellen/levering',
                                    element: <Delivery/>,
                                    loader: deliveryLoader,
                                    action: deliveryAction,
                                    errorElement: <ErrorBoundary/>,
                                },
                                {
                                    path: '/bestellen/jouw-gegevens',
                                    element: <DetailsLayout/>,
                                    loader: detailsLayoutLoader,
                                    action: detailsLayoutAction,
                                    errorElement: <ErrorBoundary/>,
                                    children: [
                                        {
                                            index: true,
                                            element: <Details/>,
                                            errorElement: <ErrorBoundary/>,
                                        },
                                        {
                                            path: '/bestellen/jouw-gegevens/adres-toevoegen',
                                            element: <NewAddress/>,
                                            action: newAddressAction,
                                            errorElement: <ErrorBoundary/>,
                                        },
                                        {
                                            path: '/bestellen/jouw-gegevens/adres-bewerken/:id',
                                            element: <EditAddress/>,
                                            loader: editAddressLoader,
                                            errorElement: <ErrorBoundary/>,
                                        },
                                    ],
                                },
                                {
                                    path: '/bestellen/betaling',
                                    element: <Payment/>,
                                    loader: paymentLoader,
                                    action: paymentAction,
                                    errorElement: <ErrorBoundary/>,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        ...oldUrls.map(item => (
            {
                path: item.oldPath,
                loader: ({request, params}) => {
                    const url = new URL(request.url);

                    return redirectPermanently(item, url, params)
                },
                errorElement: <ApiFailureFallback />,
            }
        )),
    ]);

    return (
        <HelmetProvider>
            <SideDrawerProvider>
                <ProductsInCartProvider>
                    <RouterProvider router={router} />
                </ProductsInCartProvider>
            </SideDrawerProvider>
        </HelmetProvider>
    );
}

export default App;