import {Headline3} from '../../../components/Headlines/Headlines';
import styled from 'styled-components';
import {useForm} from 'react-hook-form';
import FormField from '../../../components/Fields/FormField';
import {getDeliveryTypes, setCartInformations} from '../../../api/cart';
import {toast} from 'react-toastify';
import {Toaster} from '../../../components/Toaster/Toaster';
import {Await, defer, redirect, useLoaderData, useNavigate, useRouteLoaderData, useSubmit} from 'react-router-dom';
import React from 'react';
import {useFetchData} from '../../../hooks/useFetchData';
import {Spinner} from '../../../components/Spinner/Spinner';
import {Seo} from '../../../components/Seo/Seo';
import {Body} from '../../../components/Texts/Texts';
import {rootLoaderPromise} from '../../../layouts/Root';
import {sendEcommerceEvent} from '../../../events/dataLayer';

const StyledDelivery = styled.section``;

const StyledForm = styled.form`
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    padding: 30px 25px;
    margin-top: 20px;
`;

const TakeAway = styled.div`
    padding-top: 20px;
    padding-left: 27px;
`;

const SpinnerWrapper = styled.div`
    padding: 20px 0 0 27px;
`;

export async function deliveryAction({request}) {
    let formData = await request.formData();

    let informationsObj = {}
    switch (parseInt(formData.get("deliveryType"))) {
        case 4:
            informationsObj = {
                "deliveryType": parseInt(formData.get("deliveryType")),
            }
            break;
        case 1:
            informationsObj = {
                "deliveryType": parseInt(formData.get("deliveryType")),
                "shop": parseInt(formData.get("shop")),
            }
            break;
        default:
            return toast(<Toaster type='danger' title='Mislukt!' message='Het is verplicht om een leveringsoptie te selecteren.' $onLight/>);
    }

    try {
        await setCartInformations(request, informationsObj);
        return redirect('/bestellen/jouw-gegevens');
    } catch(err) {
        return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
    }
}

export async function deliveryLoader({request}) {
    await rootLoaderPromise;

    let getDeliveryTypesData;
    try {
        getDeliveryTypesData = await getDeliveryTypes(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({deliveryTypesData: getDeliveryTypesData});
}

export default function Delivery() {
    const [shopData, shopError] = useFetchData('shops');
    const {cartData} = useRouteLoaderData("orderLayout");
    const {deliveryTypesData} = useLoaderData();

    const { register, watch, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            deliveryType: cartData?.deliveryType?.toString() ?? "4",
            shop: cartData?.shop ?? "",
        }
    });

    const watchDeliveryType = watch("deliveryType");

    const submit = useSubmit();
    const navigate = useNavigate();

    const onSubmit = (data) => {
        submit(data, { method: 'put', action: '/bestellen/levering' });
        sendEcommerceEvent('add_shipping_info', cartData?.products, {
            ecommerce: {
                shipping_tier: data?.deliveryType?.toString() === "4" ? "Thuisbezorgd" : data?.deliveryType?.toString() === "1" ? "Afhalen in de winkel" : "",
            }
        });
    };

    const onSubmitOnlyEbooks = () => {
        navigate('/bestellen/jouw-gegevens');
        sendEcommerceEvent('add_shipping_info', cartData?.products, {
            ecommerce: {
                shipping_tier: "Email (Uitsluitend ebooks)",
            }
        });
    }

    FormField.defaultProps = {errors: errors, register: register}

    return (
        <StyledDelivery>
            <Seo metaTitle="Levering" />

            <Headline3>Levering</Headline3>

            <React.Suspense fallback={<SpinnerWrapper><Spinner/></SpinnerWrapper>}>
                <Await resolve={deliveryTypesData} errorElement={<Body>Er is iets fout gegaan, probeer het opnieuw.</Body>}>
                    {(deliveryTypes) => (
                        <StyledForm id="deliveryForm" onSubmit={deliveryTypes.find(item => item.id === 2) ? handleSubmit(onSubmitOnlyEbooks) : handleSubmit(onSubmit)}>
                            {deliveryTypes.find(item => item.id === 2) ? (
                                <Body>Je bestelling bestaat uit {cartData?.products?.length === 1 ? 'het ebook ' : 'ebooks'}{cartData?.products?.length === 1 && <strong>{cartData?.products[0]?.title}</strong>}. Dat betekent dat je een email ontvangt met daarin een link om {cartData?.products?.length === 1 ? 'het ebook' : 'de ebooks'} te downloaden.</Body>
                            ) : (
                                <>
                                    <FormField
                                        type="radio"
                                        name="deliveryType"
                                        options={[
                                            ...deliveryTypes?.slice(0).reverse().map(item => {
                                                if(item.id !== 4 && item.id !== 1) return null;

                                                return ({
                                                    id: item.id,
                                                    value: item.id,
                                                    title: item.id === 4 // 4 = Home delivery, 1 = Delivery in shop, 2 = only ebooks so no delivery
                                                        ? "Ik wil graag dat mijn bestelling wordt thuisbezorgd"
                                                        : item.id === 1 ? "Afhalen bij een van onze winkels (gratis)"
                                                            : ""
                                                    ,
                                                    tooltip: item.id === 4
                                                        ? "Selecteer deze optie om je bestelling thuis te laten bezorgen. Geef je bezorgadres op in de volgende stap."
                                                        : item.id === 1 ? "Selecteer deze optie om je bestelling op te halen in een van onze winkels."
                                                            : ""
                                                    ,
                                                }
                                            )}),
                                        ]}
                                        column={true}
                                    />
                                    {watchDeliveryType === "1" &&
                                        <>
                                            {shopData ?
                                                <TakeAway>
                                                    <FormField
                                                        type="select"
                                                        title="Winkel"
                                                        name="shop"
                                                        options={[
                                                            {
                                                                id: "shopChoice",
                                                                value: "",
                                                                title: "Selecteer een winkel",
                                                                disabled: true
                                                            },
                                                            ...shopData.map(shop => ({
                                                                id: shop.id,
                                                                value: shop.id,
                                                                title: shop.name,
                                                            })),
                                                        ]}
                                                        hugged={true}
                                                        required={true}
                                                    />
                                                </TakeAway> : shopError ? null : <SpinnerWrapper><Spinner $small/></SpinnerWrapper>
                                            }
                                        </>
                                    }
                                </>
                            )}
                        </StyledForm>
                    )}
                </Await>
            </React.Suspense>
        </StyledDelivery>
    );
}