import styled from 'styled-components';

export const Container = styled.section`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
    
    @media screen and (max-width: 1024px) {
        padding: 0 20px;
    }
`;