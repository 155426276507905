import {Breadcrumb} from '../../components/Texts/Texts';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as HomeIcon} from '../../assets/icons/home.svg';
import BreadcrumbIcon from '../../assets/icons/breadcrumb.svg';
import BreadcrumbIconInverted from '../../assets/icons/breadcrumb-white.svg';

const StyledBreadcrumbs = styled.div`

    ${Breadcrumb} {
        
        &:not(:last-child) {
            margin: 0 6px 0 0;
            
            &:after {
                content: '';
                margin-left: 6px;
                display: inline-flex;
                background-image: ${props => props.$variant === 'primary' || props.$variant === 'secondary' ? `url(${BreadcrumbIconInverted})` : `url(${BreadcrumbIcon})`};
                background-size: 14px 14px;
                height: 14px;
                width: 14px;
                position: relative;
                top: 1px;
            }
        }
        
        &:first-child {
            margin-right: 10px;
        }
    }
`;

const StyledHomeIcon = styled(HomeIcon)`
    width: 20px;
    height: 20px;
    vertical-align: text-bottom;
    
    path { fill: ${props => props.$variant === 'primary' || props.$variant === 'secondary' ? 'var(--color-white)' : 'var(--color-primary)'} 
`;

export const Breadcrumbs = ({data, ...props}) => {
    if(!data) { return null; }
    return (
        <StyledBreadcrumbs {...props}>
            <Breadcrumb as={Link} to="/" {...props}><StyledHomeIcon {...props}/></Breadcrumb>

            {data.map((item, index) => (
                <Breadcrumb
                    key={index}
                    as={index === data.length - 1 ? "p" : Link}
                    to={index === data.length - 1 ? "" : `/${item.url}`}
                    $bold={index === data.length - 1}
                    {...props}
                >{item.linkText}</Breadcrumb>
            ))}

        </StyledBreadcrumbs>
    );
}