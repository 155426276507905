import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Headline1} from "../../components/Headlines/Headlines";
import {Subtitle3} from "../../components/Subtitles/Subtitles";
import {Body} from "../../components/Texts/Texts";
import {IconButton, OptionalButton} from "../../components/Buttons/Buttons";
import {Link} from 'react-router-dom';
import {ReactComponent as ChevronLeft} from '../../assets/icons/chevron-left.svg';
import {ReactComponent as ChevronRight} from '../../assets/icons/chevron-right.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {ReactComponent as Calendar} from '../../assets/icons/calendar.svg';
import {ReactComponent as Clock} from '../../assets/icons/clock.svg';
import {ReactComponent as Marker} from '../../assets/icons/marker.svg';
import {Breadcrumbs} from '../Breadcrumbs/Breadcrumbs';
import {HTMLText} from '../../components/HTMLText/HTMLText';

const StyledAgendaDetailHeader = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (max-width: 820px) {
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
`;

const NavigateEventContainer = styled(Container)`
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

const NavigateEventButton = styled(Link)`
    display: flex;
    flex-direction: ${({$next}) => $next ? 'row-reverse' : 'row'};
    gap: 10px;
    align-items: center;
    text-decoration: none;
    
    &:hover ${Body} { color: var(--color-primary-120); }
    
    ${({$next}) => $next && `${Body} { text-align: right; }`};
`;

const StyledIconButton = styled(IconButton).attrs({
    as: 'div',
    $variant: 'secondary',
    $small: true
})`
    ${NavigateEventButton}:hover & { background-color: var(--color-secondary-120); }
`;

const Outer = styled.div`
    width: 100%;
    padding: 20px 20px 0 20px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    @media screen and (max-width: 820px) {
        padding: 0;
    }
`;

const Inner = styled.div`
    background: var(--color-primary);
    padding: 40px 0;
    
    @media screen and (max-width: 820px) {
        ${Container} { padding: 0 20px; }
    }
`;

const Flex = styled.div`
    margin-top: 40px;
    display: flex;
    gap: 60px;
    align-items: flex-end;

    @media screen and (max-width: 1024px) {
        gap: 30px;
    }
    
    @media screen and (max-width: 820px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ImageBox = styled.div`
    position: relative;
    flex-shrink: 0;

    @media screen and (max-width: 820px) {
        width: 100%;
    }
`;

const ImageOuter = styled.div`
    ${({$type}) => $type === "product" && `
        padding-right: 60px;
        
        @media screen and (max-width: 1024px) {
            padding-right: 30px;
        }
        
        @media screen and (max-width: 820px) {
            padding-right: 0;
        }
        
        @media screen and (max-width: 480px) {
            padding: 6px 0;
            background-color: var(--color-primary-10);
        }
    `}
`;

const Image = styled.img`
    display: block;
    max-width: 100%;

    ${({$type}) => $type === "product" ? `
        width: 255px;
        max-height: 396px;
        object-fit: contain;
        object-position: bottom;
    ` : `
        width: 355px;
        max-height: 100%;
        height: 355px;
        object-fit: cover;
    `};

    @media screen and (max-width: 1024px) {
        ${({$type}) => $type === "product" ? `
            width: 215px;
            max-height: 350px;
        ` : `
            width: 255px;
            height: 255px;
        `};
    }

    @media screen and (max-width: 820px) {
        ${({$type}) => $type !== "product" && `
            width: 100%;
            max-height: 180px;
        `};
    }

    @media screen and (max-width: 480px) {
        ${({$type}) => $type === "product" && `
            max-width: 130px;
            max-height: 200px;
            box-shadow: var(--box-shadow-60);
            margin: 0 auto;
        `};
    }
`;

const BookShelf = styled.hr`
    border: 1px solid var(--color-primary-60);
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    width: 100vw;
    
    @media screen and (max-width: 480px) {
        display: none;
    }
`;

const ContentBox = styled.div``;

const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

const StyledSubtitle3 = styled(Subtitle3)`
    white-space: pre-wrap;
`;

const Info = styled.div`
    padding: 50px 0 10px;
    display: flex;
    align-items: center;
    gap: 30px;
    
    @media screen and (max-width: 1024px) {
        gap: 10px;
    }

    @media screen and (max-width: 1024px) {
        padding-bottom: 0;
    }

    @media screen and (max-width: 768px) {
        padding-top: 20px;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const InfoItem = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    
    ${Body} { margin: 0; line-height: 1.2; }
`;

const Icon = styled.svg`
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    path { fill: var(--color-white); }
`;

const StyledCalendar = styled(Icon).attrs({ as: Calendar })``;
const StyledClock = styled(Icon).attrs({ as: Clock })``;
const StyledMarker = styled(Icon).attrs({ as: Marker })``;

export const AgendaDetailHeader = ({data}) => {
    const {width} = useWindowDimensions();

    return (
        <StyledAgendaDetailHeader>
            <NavigateEventContainer>
                <div>
                    {data.previousUrl &&
                        <NavigateEventButton to={`/${data.previousUrl}`}>
                            <StyledIconButton><ChevronLeft /></StyledIconButton>
                            <Body $bold>{width > 480 ? 'Vorig evenement' : 'Vorige'}</Body>
                        </NavigateEventButton>
                    }
                </div>

                <div>
                    {data.nextUrl &&
                        <NavigateEventButton to={`/${data.nextUrl}`} $next>
                            <StyledIconButton><ChevronRight /></StyledIconButton>
                            <Body $bold>{width > 480 ? 'Volgend evenement' : 'Volgende'}</Body>
                        </NavigateEventButton>
                    }
                </div>
            </NavigateEventContainer>
            <Outer>
                <Inner>
                    <Container>
                        <Breadcrumbs $variant='primary' data={data?.breadcrumbs} />

                        <Flex>
                            {data.agenda?.image &&
                                <ImageBox>
                                    <ImageOuter $type={data.agenda?.type}>
                                        <Image src={data.agenda?.image} alt={data.agenda?.title} $type={data.agenda?.type} />
                                    </ImageOuter>
                                    {data.agenda?.type === "product" && <BookShelf/>}
                                </ImageBox>
                            }

                            <ContentBox>
                                <InnerContent>
                                    <Headline1 $white>{data.agenda?.title}</Headline1>
                                    {data.agenda?.subtitle && <StyledSubtitle3 $white>{data.agenda.subtitle}</StyledSubtitle3>}
                                    {data.agenda?.description &&
                                        <HTMLText
                                            dangerouslySetInnerHTML={{__html: data.agenda?.description}}
                                            $white
                                        />
                                    }
                                </InnerContent>
                                {(data.agenda?.type === "product" && data.agenda?.productUrl) && <OptionalButton to={`/${data.agenda?.productUrl}`} $variant="secondary">{data.agenda?.detailLinkText ? data.agenda?.detailLinkText : "Bestel het boek"}</OptionalButton>}

                                <Info>
                                    {(data.agenda?.weekday || data.agenda?.day || data.agenda?.fullMonth || data.agenda?.year) &&
                                        <InfoItem>
                                            <StyledCalendar />
                                            <Body $white>{data.agenda.weekday} {data.agenda.day} {data.agenda.fullMonth} {data.agenda.year}</Body>
                                        </InfoItem>
                                    }
                                    {(data.agenda?.startTime || data.agenda?.endTime) &&
                                        <InfoItem>
                                            <StyledClock />
                                            <Body $white>{data.agenda.startTime}{(data.agenda.startTime && data.agenda.endTime) && ' - ' }{data.agenda.endTime} uur</Body>
                                        </InfoItem>
                                    }
                                    {data.agenda?.shop &&
                                        <InfoItem>
                                            <StyledMarker />
                                            <Body as={data?.agenda?.shopUrl ? Link : undefined} to={data?.agenda?.shopUrl ? `/${data?.agenda?.shopUrl}` : undefined} $white>{data.agenda.shop}</Body>
                                        </InfoItem>
                                    }
                                </Info>
                            </ContentBox>
                        </Flex>
                    </Container>
                </Inner>
            </Outer>
        </StyledAgendaDetailHeader>
    );
}
