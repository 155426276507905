import {Link} from 'react-router-dom';
import styled from 'styled-components';

const StyledCustomLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

export const CustomLink = ({className, children, to, linkType, ...props}) => {
    const isExternalLink = linkType === "absolute";

    if(!to) { return children }

    return (
        <StyledCustomLink
            className={className}
            to={to}
            target={isExternalLink ? "_blank" : "_self"}
            rel={isExternalLink ? "noreferrer" : undefined}
            {...props}
        >
            {children}
        </StyledCustomLink>
    );
}

export const LayeredCustomLink = styled(CustomLink)`
    position: static !important;
    
    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;