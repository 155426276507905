import styled from 'styled-components';
import {SideDrawer} from '../SideDrawer/SideDrawer';
import CartProduct from '../../CartProduct/CartProduct';
import {Button} from '../../Buttons/Buttons';
import {useFetchers} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Spinner} from '../../Spinner/Spinner';
import {EmptyState} from '../../EmptyState/EmptyState';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {Toaster} from '../../Toaster/Toaster';
import {useImmer} from 'use-immer';

const CartProducts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const SideDrawerButtonsWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-top: auto;
    padding-top: 30px;
    
    ${Button} {
        max-width: unset;
    }
    
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

const SpinnerWrapper = styled.div`
    padding: 16px 0;
    text-align: center;
`;

export const CartSideDrawer = ({isOpen, close}) => {
    const [cartData, setCartData] = useImmer(null);
    const [loading, setLoading] = useState(true)

    const fetchers = useFetchers();
    const relevantFetchers = fetchers.filter((fetcher) => (fetcher.formAction?.startsWith('/winkelmandje') && fetcher.formMethod === 'post'));
    // const deleteFetchers = fetchers.filter((fetcher) => (fetcher.formAction?.startsWith('/winkelmandje') && fetcher.formMethod === 'delete'));

    useEffect(() => {
        const controller = new AbortController();

        async function getCartData() {
            setLoading(true);

            const getCartRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/management`, {
                signal: controller.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'token': Cookies.get('titelive_webshop'),
                },
            })

            if (!getCartRes.ok) {
                toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }

            return getCartRes.json();
        }

        if(isOpen) {
            getCartData().then((data) => {
                setCartData(data);
                setLoading(false);
            })
        }

        return () => { setCartData(null); controller.abort(); }
        // eslint-disable-next-line
    }, [isOpen])

    const deleteFromCartObject = (data) => {
        setCartData(data);
    }

    return (
        <SideDrawer title="Winkelmandje" isOpen={isOpen} close={close}>
            <CartProducts>
                {cartData &&
                    <>
                        {cartData?.products?.length > 0
                            ? cartData?.products?.map((item, index) => (
                                <CartProduct key={`${index}-${item?.id}`} data={item} close={close} inSideDrawer={true} onDelete={deleteFromCartObject} />
                            ))
                            : relevantFetchers.length === 0 ? <EmptyState heading="Je winkelmandje is leeg" description="Voeg een artikel toe aan je winkelmandje." /> : null}
                    </>
                }

                {loading && <SpinnerWrapper><Spinner /></SpinnerWrapper>}
            </CartProducts>

            <SideDrawerButtonsWrapper>
                <Button onClick={close} as="button" $variant="outline">Verder winkelen</Button>
                <Button to="/winkelmandje" onClick={close} $variant="secondary">Ga door naar bestellen</Button>
            </SideDrawerButtonsWrapper>
        </SideDrawer>
    );
}