import styled, {css} from 'styled-components';
import {overflowWrapStyles, textColorVariants, truncatedStyles} from '../Texts/Texts';

export const Headline1 = styled.h1`
    font-family: var(--font-primary);
    font-weight: 700;
    ${({$variant})  => textColorVariants($variant)};
    ${props => props.$white && `color: var(--color-white)`};
    font-size: 55px;
    margin: 0;
    ${truncatedStyles};
    ${overflowWrapStyles};
    
    @media screen and (max-width: 768px) {
        font-size: 40px;
    }
`;

export const Headline2 = styled(Headline1).attrs({
    as: 'h2'
})`
    font-size: 40px;
    
    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
`;

export const headline3Styles = css`
    font-family: var(--font-primary);
    font-weight: 700;
    color: ${({$white}) => $white ? 'var(--color-white)' : 'var(--color-primary)'};
    font-size: 30px;
    ${overflowWrapStyles};

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

export const Headline3 = styled(Headline1).attrs({
    as: 'h3'
})`
    font-size: 30px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

export const Headline4 = styled(Headline1).attrs({
    as: 'h4'
})`
    font-size: 24px;

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
`;

export const headline5Styles = css`
    font-family: var(--font-primary);
    font-weight: 700;
    color: var(--color-primary);
    font-size: 20px;
    ${overflowWrapStyles};

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
`;

export const Headline5 = styled(Headline1).attrs({
    as: 'h5'
})`
    font-size: 20px;
    

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
`;