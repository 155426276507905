import {useEffect, useState} from 'react';

export const useFlyOutMenu = (ref) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const node = ref.current;
        let timeout = null;

        const handleEnter = () => timeout = setTimeout(() => setOpen(true), 200);

        const handleLeave = () => {
            clearTimeout(timeout);
            setOpen(false);
        }

        node.addEventListener("mouseenter", handleEnter);
        node.addEventListener("mouseleave", handleLeave);

        return () => {
            node.removeEventListener("mouseenter", handleEnter);
            node.removeEventListener("mouseleave", handleLeave);
        };
    }, [ref])

    return { open, setOpen };
}