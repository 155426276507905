import React from "react";
import {Await, defer, useLoaderData} from "react-router-dom";
import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {Headline1} from "../../components/Headlines/Headlines";
import {Body} from "../../components/Texts/Texts";
import {Button} from "../../components/Buttons/Buttons";
import {destroyWishlist, getWishlist, postWishlist} from '../../api/wishlist';
import WishlistProduct from '../../components/WishlistProduct/WishlistProduct';
import {toast} from 'react-toastify';
import {Toaster} from '../../components/Toaster/Toaster';
import {ReactComponent as EmptyShelf} from '../../assets/images/empty-shelf.svg';
import {Spinner} from '../../components/Spinner/Spinner';
import {Seo} from '../../components/Seo/Seo';
import {rootLoaderPromise} from '../../layouts/Root';

const Wrapper = styled.div`
    margin: 40px 0 80px 0;
`;

const Header = styled.div`
    margin-bottom: 70px;
    
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
    }
`;

const NoProducts = styled.div`
    margin-top: 20px;
`;

const WishlistContainer = styled.div`
    max-width: 810px;
    margin-bottom: 30px;
`;

const WishlistItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const SpinnerWrapper = styled.div`
    padding: 0 0 64px;
`;

export async function wishlistOverviewAction({request}) {
    let formData = await request.formData();
    const isbn = formData.get("isbn");

    switch(request.method) {
        case "POST": {
            try {
                await postWishlist(request, isbn);
                return toast(<Toaster type='success' title='Gelukt!' message='Het product is toegevoegd aan je verlanglijstje.' $onLight/>);
            } catch(err) {
                switch (err.status) {
                    case 409:
                        return toast(<Toaster type='warning' title='Let op' message='Het product zit al in je verlanglijstje.' $onLight/>);
                    default:
                        return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                }
            }
        }
        case "DELETE": {
            try {
                const destroyWishlistData = await destroyWishlist(request, isbn);
                toast(<Toaster type='success' title='Gelukt!' message='Het product is verwijderd uit je verlanglijstje.' $onLight/>);
                return destroyWishlistData;
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }
        default: {
            throw new Response("Method not allowed", { status: 405 });
        }
    }
}

export async function wishlistLoader({request}) {
    await rootLoaderPromise;

    let getWishlistData;
    try {
        getWishlistData = await getWishlist(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({wishlistData: getWishlistData});
}

export default function WishlistOverview() {
    const {wishlistData} = useLoaderData();

    return (
        <Container>
            <Seo metaTitle="Verlanglijstje" />

            <Wrapper>
                <Header>
                    <Headline1>Verlanglijstje</Headline1>
                </Header>

                <React.Suspense fallback={<SpinnerWrapper><Spinner /></SpinnerWrapper>}>
                    <Await resolve={wishlistData} errorElement={<p>Er is iets fout gegaan</p>}>
                        {(wishlist) => (
                            <>
                                {wishlist?.products?.length > 0
                                    ?
                                    <WishlistContainer>
                                        <WishlistItems>
                                            {wishlist?.products?.map((item, index) => (
                                               <WishlistProduct key={`${index}-${item?.isbn}`} data={item} />
                                            ))}
                                        </WishlistItems>
                                    </WishlistContainer>
                                    :
                                    <NoProducts>
                                        <EmptyShelf/>
                                        <Body>Je hebt op dit moment geen artikelen in je verlanglijstje.</Body>
                                    </NoProducts>
                                }

                                <Button to="/boeken" $variant={wishlist?.products?.length > 0 ? 'outline' : 'secondary'}>Verder winkelen</Button>
                            </>
                        )}
                    </Await>
                </React.Suspense>

            </Wrapper>
        </Container>
    )
}

// export const WishlistOverviewErrorBoundary = () => {
//     const err = useAsyncError()
//     let revalidator = useRevalidator();
//
//     useEffect( () => {
//         if (err.status === 401) {
//             // If Promise rejects as 401, something is wrong with the token so revalidate
//             revalidator.revalidate();
//         } else {
//             // On other errors, return a toast
//             toast(<Toaster type='danger' title='Foutmelding' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
//         }
//
//         return () => {};
//         // eslint-disable-next-line
//     }, [err])
//
//     return <SpinnerWrapper><Spinner /></SpinnerWrapper>
// }