import {useState} from 'react';
import {getAddressFromPostalCode} from '../api/addresses';

export const useAddressFromPostalCode = (getValues, setValue, defaultAddress, defaultDeliveryAddress = null) => {
    const [displayAddressFromPostalCode, setDisplayAddressFromPostalCode] = useState(defaultAddress ?? "Geef een postcode en huisnummer op");
    const [displayDeliveryAddressFromPostalCode, setDisplayDeliveryAddressFromPostalCode] = useState(defaultDeliveryAddress ?? "Geef een postcode en huisnummer op");
    const [request, setRequest] = useState(null);


    const handleAddress = async () => {
        if (request) {
            request.abort();
        }

        if (getValues('postcode')?.length >= 6 && getValues('number')) {
            setDisplayAddressFromPostalCode("Adres aan het zoeken");

            const addressFromPostalCodeObj = {
                "postcode": getValues('postcode'),
                "housenumber": parseInt(getValues('number')),
            }

            const newRequest = new AbortController();
            setRequest(newRequest);

            try {
                const addressFromPostalCodeData = await getAddressFromPostalCode(newRequest, addressFromPostalCodeObj);
                setDisplayAddressFromPostalCode(`${addressFromPostalCodeData.street} ${addressFromPostalCodeData.housenumber}${getValues('postbus')}, ${addressFromPostalCodeData.city}`);
                setValue('addressLine1', addressFromPostalCodeData.street, { shouldValidate: true })
                setValue('city', addressFromPostalCodeData.city, { shouldValidate: true })
                setValue('country', addressFromPostalCodeData.country, { shouldValidate: true })
            } catch (err) {
                if(err.name !== 'AbortError') {
                    setDisplayAddressFromPostalCode("Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen.");
                    setValue('addressLine1', "")
                    setValue('city', "")
                    setValue('country', "")
                }
            } finally {
                setRequest(null);
            }
        } else if (getValues('postcode')?.length <= 5 && getValues('number')) {
            setDisplayAddressFromPostalCode("Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen.");
            setValue('addressLine1', "")
            setValue('city', "")
            setValue('country', "")
        }
    };

    const handleDeliveryAddress = async () => {
        if (request) {
            request.abort();
        }

        if (getValues('deliveryPostcode')?.length >= 6 && getValues('deliveryNumber')) {
            setDisplayDeliveryAddressFromPostalCode("Adres aan het zoeken");

            const deliveryAddressFromPostalCodeObj = {
                "postcode": getValues('deliveryPostcode'),
                "housenumber": parseInt(getValues('deliveryNumber')),
            }

            const newRequest = new AbortController();
            setRequest(newRequest);

            try {
                const deliveryAddressFromPostalCodeData = await getAddressFromPostalCode(newRequest, deliveryAddressFromPostalCodeObj);
                setDisplayDeliveryAddressFromPostalCode(`${deliveryAddressFromPostalCodeData.street} ${deliveryAddressFromPostalCodeData.housenumber}${getValues('deliveryPostbus')}, ${deliveryAddressFromPostalCodeData.city}`);
                setValue('deliveryAddressLine1', deliveryAddressFromPostalCodeData.street, { shouldValidate: true })
                setValue('deliveryCity', deliveryAddressFromPostalCodeData.city, { shouldValidate: true })
                setValue('deliveryCountry', deliveryAddressFromPostalCodeData.country, { shouldValidate: true })
            } catch (err) {
                if (err.name !== 'AbortError') {
                    setDisplayDeliveryAddressFromPostalCode("Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen.");
                    setValue('deliveryAddressLine1', "")
                    setValue('deliveryCity', "")
                    setValue('deliveryCountry', "")
                }
            } finally {
                setRequest(null);
            }
        } else if (getValues('deliveryPostcode')?.length <= 5 && getValues('deliveryNumber')) {
            setDisplayDeliveryAddressFromPostalCode("Het adres kon niet gevonden worden. Controleer of je postcode en huisnummer kloppen.");
            setValue('deliveryAddressLine1', "")
            setValue('deliveryCity', "")
            setValue('deliveryCountry', "")
        }
    }
    return {displayAddressFromPostalCode, displayDeliveryAddressFromPostalCode, handleAddress, handleDeliveryAddress};
};