import {useEffect, useState} from 'react';
import Cookies from 'js-cookie';

export const useFetchData = (endpoint, shouldAddToken = false) => {
    const [fetchedData, setFetchedData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(endpoint) {
            const controller = new AbortController();

            fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
                signal: controller.signal,
                ...(shouldAddToken && {
                    headers: {
                        'token': Cookies.get('titelive_webshop'),
                    }
                })
            }).then(response => {
                if(!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`)
                } else {
                    return response.json();
                }
            })
            .then(json => {
                setFetchedData(json);
            })
            .catch(error => {
                setError(error.message);
            });

            return () => {
                controller.abort();
            }
        }
    }, [endpoint, shouldAddToken])

    return [fetchedData, error];
}