import {createPortal} from 'react-dom';
import {defer, Outlet, redirect, useActionData, useLoaderData, useRouteLoaderData} from 'react-router-dom';
import {DeliveryAddresses} from '../components/SideDrawers/AddressSideDrawer/DeliveryAddresses';
import {InvoiceAddresses} from '../components/SideDrawers/AddressSideDrawer/InvoiceAddresses';
import React, {useEffect, useState} from 'react';
import {createCustomerFull} from '../api/customer';
import {toast} from 'react-toastify';
import {Toaster} from '../components/Toaster/Toaster';
import {setCartInformations} from '../api/cart';
import {createAddress, getAddresses, updateAddress} from '../api/addresses';
import {Seo} from '../components/Seo/Seo';
import {rootLoaderPromise} from './Root';

export async function detailsLayoutAction({request}) {
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        // 1. GuestUser flow
        case "guestUser": {
            // Check if PostcodeAPI has filled in the addressLine1 and city, otherwise prevent to continue
            if(!formData.get("invoiceAddress") && (!formData.get("addressLine1") || !formData.get("city"))) {
                toast(<Toaster type='danger' title='Mislukt!' message='Het adres kon niet gevonden worden, probeer het opnieuw.' $onLight/>);
                return null;
            }

            if((JSON.parse(formData.get("addressCheck")) === false && !formData.get("deliveryAddress")) && (!formData.get("deliveryAddressLine1") || !formData.get("deliveryCity")) && parseInt(formData.get("deliveryType")) !== 2) {
                toast(<Toaster type='danger' title='Mislukt!' message='Het adres kon niet gevonden worden, probeer het opnieuw.' $onLight/>);
                return null;
            }

            // 1.1 GuestUser flow - Creating a customer (optional with addresses via API/CMS)
            if(formData.get("accountCheck") === 'true') {
                const customerObj = {
                    "email": formData.get("email"),
                    "password": formData.get("password"),
                    "newsletter": JSON.parse(formData.get("newsletter")),
                    "salutation":  formData.get("salutation"),
                    "firstName":  formData.get("firstName"),
                    "prefix":  formData.get("prefix"),
                    "lastName":  formData.get("lastName"),
                    "phone":  formData.get("phone"),
                    ...(!formData.get("invoiceAddress") && {
                        "invoiceAddress": {
                            "salutation":  formData.get("salutation"),
                            "firstName":  formData.get("firstName"),
                            "prefix":  formData.get("prefix"),
                            "lastName":  formData.get("lastName"),
                            "postcode": formData.get("postcode"),
                            "number": parseInt(formData.get("number")),
                            "postbus": formData.get("postbus"),
                            "addressLine1": formData.get("addressLine1"),
                            "city": formData.get("city"),
                            "country": 2,
                            "phone":  formData.get("phone"),
                        },
                    }),
                    ...((JSON.parse(formData.get("addressCheck")) === false && !formData.get("deliveryAddress") && parseInt(formData.get("deliveryType")) !== 2) &&
                        {
                            "deliveryAddress": {
                                "salutation": formData.get("deliverySalutation"),
                                "firstName": formData.get("deliveryFirstName"),
                                "prefix": formData.get("deliveryPrefix"),
                                "lastName": formData.get("deliveryLastName"),
                                "postcode": formData.get("deliveryPostcode"),
                                "number": parseInt(formData.get("deliveryNumber")),
                                "postbus": formData.get("deliveryPostbus"),
                                "addressLine1": formData.get("deliveryAddressLine1"),
                                "city": formData.get("deliveryCity"),
                                "country": 2,
                                "phone": formData.get("deliveryPhone"),
                            },
                        }
                    )
                }

                try {
                    const createCustomerFullData = await createCustomerFull(request, customerObj);

                    try {
                        const informationsObj = {
                            ...(parseInt(formData.get("deliveryType")) !== 2 && {
                                "deliveryAddress": createCustomerFullData?.deliveryAddress?.id || parseInt(formData.get("deliveryAddress")),
                            }),
                            "invoiceAddress": createCustomerFullData?.invoiceAddress?.id || parseInt(formData.get("invoiceAddress")),
                            ...(formData.get("shop") && {
                                "shop": parseInt(formData.get("shop")),
                            }),
                        }

                        await setCartInformations(request, informationsObj);

                        return redirect('/bestellen/betaling');
                    } catch(err) {
                        toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                        return err;
                    }
                } catch(err) {
                    switch (err.status) {
                        case 400: {
                            return err;
                        }
                        case 409: {
                            return err;
                        }
                        default: {
                            toast(<Toaster type="danger" title="Mislukt!" message="Er is iets fout gegaan, probeer het opnieuw."/>)
                            return null;
                        }
                    }
                }
            }

            // 1.2 GuestUser flow - Creating addresses without registering as a customer
            try {
                const invoiceAddressObj = {
                    "type": 2,
                    "salutation": formData.get("salutation"),
                    "firstName": formData.get("firstName"),
                    "prefix": formData.get("prefix"),
                    "lastName": formData.get("lastName"),
                    "postcode": formData.get("postcode"),
                    "number": parseInt(formData.get("number")),
                    "postbus": formData.get("postbus"),
                    "addressLine1": formData.get("addressLine1"),
                    "city": formData.get("city"),
                    "country": 2,
                    "phone": formData.get("phone"),
                    ...(parseInt(formData.get("invoiceAddress")) && {
                        "id": parseInt(formData.get("invoiceAddress")),
                    })
                }

                const deliveryAddressObj = {
                    "type": 1,
                    "salutation": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliverySalutation") : formData.get("salutation"),
                    "firstName": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryFirstName") : formData.get("firstName"),
                    "prefix": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryPrefix") : formData.get("prefix"),
                    "lastName": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryLastName") : formData.get("lastName"),
                    "postcode": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryPostcode") : formData.get("postcode"),
                    "number": JSON.parse(formData.get("addressCheck")) === false ? parseInt(formData.get("deliveryNumber")) : parseInt(formData.get("number")),
                    "postbus": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryPostbus") : formData.get("postbus"),
                    "addressLine1": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryAddressLine1") : formData.get("addressLine1"),
                    "city": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryCity") : formData.get("city"),
                    "country": 2,
                    "phone": JSON.parse(formData.get("addressCheck")) === false ? formData.get("deliveryPhone") : formData.get("phone"),
                    ...(parseInt(formData.get("deliveryAddress")) && {
                        "id": parseInt(formData.get("deliveryAddress")),
                    })
                }

                // If request method is POST, we should create the addresses
                let createInvoiceAddressData;
                let createDeliveryAddressData;
                if (request.method === "POST") {
                    [createInvoiceAddressData, createDeliveryAddressData] = await Promise.all([createAddress(request, invoiceAddressObj), createAddress(request, deliveryAddressObj)])
                }

                // If request method is PUT and there are invoice- and delivery addresses, we should update the addresses
                let updateInvoiceAddressData;
                let updateDeliveryAddressData;
                if (request.method === "PUT" && formData.get("invoiceAddress") && formData.get("deliveryAddress")) {
                    [updateInvoiceAddressData, updateDeliveryAddressData] = await Promise.all([updateAddress(request, invoiceAddressObj), updateAddress(request, deliveryAddressObj)])
                }

                try {
                    const informationsObj = {
                        "email": formData.get("email"),
                        "invoiceAddress": updateInvoiceAddressData?.id || createInvoiceAddressData?.id,
                        ...(parseInt(formData.get("deliveryType")) !== 2 && {
                            "deliveryAddress": updateDeliveryAddressData?.id || createDeliveryAddressData?.id,
                        }),
                        ...(formData.get("shop") && {
                            "shop": parseInt(formData.get("shop")),
                        })
                    }

                    await setCartInformations(request, informationsObj);

                    return redirect('/bestellen/betaling');
                } catch(err) {
                    toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                    return err;
                }
            } catch(err) {
                toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                return err;
            }
        }

        // 2. RegisteredUser flow
        case "registeredUser": {
            // Validate if deliveryAddress and invoiceAddress are available
            if (parseInt(formData.get("deliveryType")) === 4 && !parseInt(formData.get("deliveryAddress"))) {
                return JSON.stringify({ "error": "deliveryAddress" })
            }

            if (!parseInt(formData.get("invoiceAddress"))) {
                return JSON.stringify({ "error": "invoiceAddress" })
            }

            const informationsObj = {
                "invoiceAddress": parseInt(formData.get("invoiceAddress")),
                ...(parseInt(formData.get("deliveryType")) === 4 && {
                    "deliveryAddress": parseInt(formData.get("deliveryAddress")),
                }),
                ...(formData.get("shop") && {
                    "shop": parseInt(formData.get("shop")),
                })
            }

            try {
                await setCartInformations(request, informationsObj);
                return redirect('/bestellen/betaling');
            } catch(err) {
                toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                return err;
            }
        }

        // 3. Create Address during RegisteredUser flow
        case "createAddress": {
            // Check if PostcodeAPI has filled in the addressLine1 and city, otherwise prevent to continue
            if(!formData.get("addressLine1") || !formData.get("city")) {
                toast(<Toaster type='danger' title='Mislukt!' message='Het adres kon niet gevonden worden, probeer het opnieuw.' $onLight/>);
                return null;
            }

            const addressObj = {
                "type": parseInt(formData.get("type")),
                "salutation": formData.get("salutation"),
                "firstName": formData.get("firstName"),
                "prefix": formData.get("prefix"),
                "lastName": formData.get("lastName"),
                "postcode": formData.get("postcode"),
                "number": parseInt(formData.get("number")),
                "postbus": formData.get("postbus"),
                "addressLine1": formData.get("addressLine1"),
                "city": formData.get("city"),
                "country": 2,
                "phone": formData.get("phone"),
            }

            try {
                const createAddressData = await createAddress(request, addressObj);
                toast(<Toaster type='success' title='Gelukt!' message='Het adres is succesvol toegevoegd.' $onLight/>);
                return ({addressData: createAddressData});
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }

        // 4. Edit Address during RegisteredUser flow
        case "editAddress": {
            // Check if PostcodeAPI has filled in the addressLine1 and city, otherwise prevent to continue
            if(!formData.get("addressLine1") || !formData.get("city")) {
                toast(<Toaster type='danger' title='Mislukt!' message='Het adres kon niet gevonden worden, probeer het opnieuw.' $onLight/>);
                return null;
            }

            const addressObj = {
                "id": parseInt(formData.get("id")),
                "salutation": formData.get("salutation"),
                "firstName": formData.get("firstName"),
                "prefix": formData.get("prefix"),
                "lastName": formData.get("lastName"),
                "postcode": formData.get("postcode"),
                "number": parseInt(formData.get("number")),
                "postbus": formData.get("postbus"),
                "addressLine1": formData.get("addressLine1"),
                "city": formData.get("city"),
                "country": 2,
                "phone":  formData.get("phone"),
            }

            try {
                const editAddressData = await updateAddress(request, addressObj);
                toast(<Toaster type='success' title='Gelukt!' message='Het adres is succesvol gewijzigd.' $onLight/>);
                return ({addressData: editAddressData});
            } catch(err) {
                toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
                return err;
            }
        }

        default:
            toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            return null;
    }
}

export async function detailsLayoutLoader({request}) {
    await rootLoaderPromise;

    let getAddressesData;
    try {
        getAddressesData = await getAddresses(request);
    } catch(err) {
        // Catch DOMException for AbortController (this bug is fixed in newer React Router v6.8.0)
    }

    return defer({"status": "success", ...(getAddressesData && {addressesData: getAddressesData})});
}

export default function DetailsLayout() {
    const { checkTokenData } = useRouteLoaderData("root");

    const [authenticated, setAuthenticated] = useState(!!checkTokenData?.customer?.firstName)
    useEffect(() => {
        setAuthenticated(!!checkTokenData?.customer?.firstName)
    }, [checkTokenData])

    if (authenticated) return <RegisteredUserLayout />

    return <GuestUserLayout />
}

function RegisteredUserLayout() {
    const {cartData} = useRouteLoaderData("orderLayout");
    const {addressesData} = useLoaderData();
    const actionData = useActionData();

    const [deliveryAddress, setDeliveryAddress] = useState(cartData?.deliveryAddress?.id
        ? addressesData.deliveryAddresses.find(deliveryAddres => deliveryAddres.id === cartData?.deliveryAddress?.id)
        : addressesData.deliveryAddresses.find(deliveryAddres => deliveryAddres.default === true)
    );

    const [invoiceAddress, setInvoiceAddress] = useState(cartData?.invoiceAddress?.id
        ? addressesData.invoiceAddresses.find(invoiceAddress => invoiceAddress.id === cartData?.invoiceAddress?.id)
        : addressesData.invoiceAddresses.find(invoiceAddress => invoiceAddress.default === true)
    );

    const [isDeliveryDrawerOpen, setIsDeliveryDrawerOpen] = useState(false);
    const closeDeliveryDrawer = () => { setIsDeliveryDrawerOpen(false); }

    const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
    const closeInvoiceDrawer = () => { setIsInvoiceDrawerOpen(false); }

    useEffect(() => {
        if(actionData?.addressData?.type === 1) { setDeliveryAddress(actionData.addressData) }
        if(actionData?.addressData?.type === 2) { setInvoiceAddress(actionData.addressData) }
    }, [actionData])

    return (
        <>
            <Seo metaTitle="Jouw gegevens" />

            {createPortal(
                <>
                    <DeliveryAddresses isOpen={isDeliveryDrawerOpen} close={closeDeliveryDrawer} data={addressesData.deliveryAddresses} selectedAddress={deliveryAddress?.id} setDeliveryAddress={setDeliveryAddress} />
                    <InvoiceAddresses isOpen={isInvoiceDrawerOpen} close={closeInvoiceDrawer} data={addressesData.invoiceAddresses} selectedAddress={invoiceAddress?.id} setInvoiceAddress={setInvoiceAddress} />
                </>
                , document.body
            )}

            <Outlet context={[deliveryAddress, invoiceAddress, setIsDeliveryDrawerOpen, setIsInvoiceDrawerOpen]} />
        </>
    );
}

function GuestUserLayout() {
    return (
        <Outlet />
    );
}