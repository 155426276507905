import Cookies from "js-cookie";

export const getAddresses = async (request) => {
    const getAddressesRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/address`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getAddressesRes.ok) {
        throw new Response("Response is not OK", {status: getAddressesRes.status});
    }

    const getAddressesData = await getAddressesRes.json();

    return getAddressesData;
}

export const getAddress = async (request, id) => {
    const getAddressRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/address/${id}`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getAddressRes.ok) {
        throw new Response("Response is not OK", {status: getAddressRes.status});
    }

    const getAddressData = await getAddressRes.json();

    return getAddressData;
}

export const createAddress = async (request, addressObj) => {
    const createAddressRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/address`, {
        signal: request.signal,
        method: 'POST',
        body: JSON.stringify(addressObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    })

    if (!createAddressRes.ok) {
        throw new Response("Response is not OK", {status: createAddressRes.status})
    }

    const createAddressData = await createAddressRes.json();

    return createAddressData;
}

export const updateAddress = async (request, addressObj) => {
    const updateAddressRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/address`, {
        signal: request.signal,
        method: 'PUT',
        body: JSON.stringify(addressObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!updateAddressRes.ok) {
        throw new Response("Response is not OK", {status: updateAddressRes.status});
    }

    const updateAddressData = await updateAddressRes.json();

    return updateAddressData;
}

export const destroyAddress = async (request, addressObj) => {
    const destroyAddressRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/address`, {
        signal: request.signal,
        method: 'DELETE',
        body: JSON.stringify(addressObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!destroyAddressRes.ok) {
        throw new Response("Response is not OK", {status: destroyAddressRes.status});
    }

    const destroyAddressData = await destroyAddressRes.json();

    return destroyAddressData;
}

export const getAddressFromPostalCode = async (request, addressFromPostalCodeObj) => {
    const getAddressFromPostalCodeRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/postcode`, {
        signal: request.signal,
        method: 'POST',
        body: JSON.stringify(addressFromPostalCodeObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getAddressFromPostalCodeRes.ok) {
        throw new Response("Response is not OK", {status: getAddressFromPostalCodeRes.status});
    }

    const getAddressFromPostalCodeData = await getAddressFromPostalCodeRes.json();

    return getAddressFromPostalCodeData;
}