import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Headline1, Headline3, Headline4} from '../../components/Headlines/Headlines';
import {Body} from '../../components/Texts/Texts';
import {ReactComponent as ShoppingCartIcon} from "../../assets/icons/shopping-cart.svg";
import {ReactComponent as CardIcon} from '../../assets/icons/card.svg';

const StyledHeaderFailed = styled.section`
    overflow: hidden;
    position: relative;
    margin-bottom: 120px;
`;

const Outer = styled.div`
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
`;

const Inner = styled.div`
    background-color: var(--color-primary);
    padding: 60px 0;
    position: relative;
    
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: -10px;
        width: 100vw;
        height: 2px;
        background-color: var(--color-primary-10);
    }
`;

const Subtitle = styled(Body)`
    font-size: 40px;
    max-width: 740px;
    margin: 0;

    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
    
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
`;

const Info = styled.div`
    margin-top: 30px;
    
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const ExpectationTitle = styled(Headline3)`
    margin-bottom: 60px;
    
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const InfoItems = styled.div`
    display: flex;
    gap: 30px;
    max-width: 740px;

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }

    @media screen and (max-width: 475px) {
        flex-direction: column;
    }
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Icon = styled.div`
    background-color: var(--color-primary-10);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    
    svg {
        width: 35px;
        height: 35px;
        
        path { fill: var(--color-primary); }
    }
    
    @media screen and (max-width: 768px) {
        width: 78px;
        height: 78px;
        
        svg {
            width: 55px;
            height: 55px;
        }
    }
`;

export const HeaderFailed = ({message}) => {

    return (
        <StyledHeaderFailed>
            <Outer>
                <Inner>
                    <Container>
                        <Headline1 $variant={'secondary'} >Bestelling afgebroken</Headline1>
                        <Subtitle $variant={'secondary'}>{message}</Subtitle>
                    </Container>
                </Inner>
            </Outer>

            <Info>
                <Container>
                    <ExpectationTitle>Wil je het pakket alsnog ontvangen?</ExpectationTitle>
                    <InfoItems>
                        <InfoItem>
                            <Icon>
                                <ShoppingCartIcon />
                            </Icon>
                            <Headline4>Winkelmandje</Headline4>
                            <Body>Ga naar je winkelmandje en plaats de bestelling opnieuw.</Body>
                        </InfoItem>
                        <InfoItem>
                            <Icon>
                                <CardIcon />
                            </Icon>
                            <Headline4>Bestellen</Headline4>
                            <Body>Rond je bestelling af. We gaan vervolgens aan de slag om de bestelling alsnog te versturen.</Body>
                        </InfoItem>
                    </InfoItems>
                </Container>
            </Info>
        </StyledHeaderFailed>
    );
}