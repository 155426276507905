import {useState, useRef, useCallback, useEffect} from "react";
import {SearchSuggestions} from "../SearchSuggestions/SearchSuggestions";
import useThrottle from "../../hooks/useThrottle";
import {useClickInside} from "../../hooks/useClickInside";
import styled from 'styled-components';
import {IconButton} from '../Buttons/Buttons';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import {Input} from '../Input/Input';
import {createSearchParams, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {searchEvent} from '../../events/books';

const StyledSearchBar = styled.form`
    position: relative;
    display: flex;
    max-width: ${props => props.$menu ? '600px' : '100%'};
    width: 100%;
    
    ${IconButton} {
        flex-shrink: 0;
    }
`;

const StyledIconButton = styled(IconButton)`
    padding: 0;
    
    ${({$menu}) => $menu && `
        svg {
            width: 30px;
            height: 30px;
        }
    `}
`;

export const SearchBar = ({placeholder, ...props}) => {
    let [searchParams,] = useSearchParams();
    let searchKey = searchParams.get('zoeken');

    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const { pathname, search } = useLocation();

    useEffect(() => {
        setSearchTerm(searchKey ? decodeURIComponent(searchKey) : "")
        // eslint-disable-next-line
    }, [pathname, search]);

    const searchBarRef = useRef(null);
    const inside = useClickInside(searchBarRef);
    const inputRef = useRef(null);

    const throttle = useThrottle((searchTerm, signal) => {
        if(searchTerm.length >= 3) { // TiteLive supports queries >= 3
            fetch(`${process.env.REACT_APP_API_BASE_URL}/autosuggest-search?q=${encodeURIComponent(searchTerm)}`, { signal })
                .then(response =>  response.json())
                .then(resData => {
                    setLoading(false);
                    setData(resData);
                })
                .catch(error => {
                    if(error.name === 'DOMException' && error.message === 'The user aborted a request') {
                        return null;
                    }
                })
        }
    }, 250);

    const changeHandler = useCallback((event) => {
        setSubmitted(false);
        setSearchTerm(event.target.value);
        if(inputRef.current.value.length >= 3) {
            setLoading(true);
        }
        throttle(event.target.value);
    }, [throttle])

    const navigate = useNavigate();

    const onSubmit = (e) => {
        setSubmitted(true);
        e.preventDefault();

        if (throttle.controller) {
            throttle.controller.abort();
        }

        setData(null);

        let newPathname = "boeken";
        if(pathname.startsWith('/boeken')) {
            newPathname = pathname;
        }

        searchEvent(searchTerm);

        navigate({
            pathname: newPathname,
            search: createSearchParams({
                zoeken: encodeURIComponent(searchTerm)
            }).toString()
        });
    }

    return (
        <StyledSearchBar ref={searchBarRef} onSubmit={onSubmit} {...props}>
            <Input
                ref={inputRef}
                type="text"
                placeholder={placeholder || "Zoek op titel, auteur of isbn"}
                value={searchTerm}
                onChange={changeHandler}
                {...props}
            />
            <StyledIconButton as="button" {...props}><SearchIcon /></StyledIconButton>
            {(inside && searchTerm?.length >= 1) && !submitted &&
                <SearchSuggestions
                    data={data}
                    empty={data?.length === 0}
                    tooFewCharacters={searchTerm?.length <= 2}
                    loading={loading}
                    {...props}
                />
            }
        </StyledSearchBar>
    );
}