import styled from 'styled-components';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import {useRef} from 'react';
import {ReactComponent as ChevronLeft} from '../../assets/icons/chevron-left.svg';
import {ReactComponent as ChevronRight} from '../../assets/icons/chevron-right.svg';
import {ImageSlide} from '../../components/ImageSlide/ImageSlide';
import {BookSlide} from '../../components/BookSlide/BookSlide';
import {IconButton} from '../../components/Buttons/Buttons';
import {Spacing} from '../../components/Spacing/Spacing';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledPageHeaderSlider = styled.div`
    padding: 20px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    .swiper-pagination-bullet {
        bottom: 5px;
        background-color: rgba(255, 255, 255, 1);    
    }
    
    @media screen and (min-width: 821px) {
        .swiper-pagination { display: none; }
    }
    
    @media screen and (max-width: 820px) {
        padding: 0;
    }
`;

const ControlButtons = styled.div`
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    display: flex;
    gap: 10px;
    
    @media screen and (max-width: 820px) {
        display: none;
    }
`;

export const PageHeaderSlider = ({data}) => {
    const swiperRef = useRef(null);
    const {width} = useWindowDimensions();

    if(data.slides.length === 0) { return null; }

    return (
        <Spacing $spacingLg={'80px'} $spacingMd={'60px'} $spacingSm={'40px'}>
            <StyledPageHeaderSlider>
                <Swiper
                    ref={swiperRef}
                    effect={"fade"}
                    threshold={40}
                    loop={data?.slides.length !== 1}
                    pagination={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    allowTouchMove={width < 820}
                    autoHeight={true}
                    slidesPerView={1}
                    centeredSlides={true}
                    modules={[Autoplay, EffectFade, Navigation, Pagination]}
                >
                    {data?.slides?.map(slide => {
                        if(slide.type === 'image') {
                            return (
                                <SwiperSlide key={slide.id}>
                                    <ImageSlide
                                        superTitle={slide.superTitle}
                                        title={slide.title}
                                        subTitle={slide.subtitle}
                                        description={slide.description}
                                        buttonText={slide.linkText}
                                        variant={slide.color}
                                        url={slide.url}
                                        imageUrl={slide.imageURL}
                                        linkType={slide.linkType}
                                    />

                                    { data?.slides?.length >= 2 &&
                                        <ControlButtons>
                                            <IconButton $variant={slide.color} as="button" onClick={() => swiperRef?.current?.swiper.slidePrev()}><ChevronLeft /></IconButton>
                                            <IconButton $variant={slide.color} as="button" onClick={() => swiperRef?.current?.swiper.slideNext()}><ChevronRight /></IconButton>
                                        </ControlButtons>
                                    }
                                </SwiperSlide>
                            )
                        }

                        if(slide.type === 'product') {
                            return (
                                <SwiperSlide key={slide.id}>
                                    <BookSlide
                                        superTitle={slide.superTitle}
                                        title={slide.title}
                                        subTitle={slide.subtitle}
                                        description={slide.description}
                                        buttonText={slide.linkText}
                                        variant={slide.color}
                                        linkType={slide.linkType}
                                        url={slide.url}
                                        imageUrl={slide.imageURL}
                                    />

                                    { data?.slides?.length >= 2 &&
                                        <ControlButtons>
                                            <IconButton $variant={slide.color} as="button" onClick={() => swiperRef?.current?.swiper.slidePrev()}><ChevronLeft /></IconButton>
                                            <IconButton $variant={slide.color} as="button" onClick={() => swiperRef?.current?.swiper.slideNext()}><ChevronRight /></IconButton>
                                        </ControlButtons>
                                    }
                                </SwiperSlide>
                            )
                        }

                        return null;
                    })}
                </Swiper>
            </StyledPageHeaderSlider>
        </Spacing>
    );
}