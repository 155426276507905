import Cookies from 'js-cookie';
import {Outlet, ScrollRestoration, useLocation, useNavigation} from 'react-router-dom';
import React, {useEffect} from 'react';
import nProgress from 'nprogress';
import GlobalStyle from '../globalStyles';
import {checkToken, getTokens, refreshToken} from '../api/token';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createPortal} from 'react-dom';

const getAndCheckToken = async () => {
    Cookies.remove('titelive_webshop');
    Cookies.remove('titelive_webshoprefresh');

    try {
        const getTokensData = await getTokens();
        shouldRefreshToken = false;

        try {
            const checkTokenData = await checkToken();
            return {getTokensData, checkTokenData};
        } catch(err) {
            Cookies.remove('titelive_webshop');
            Cookies.remove('titelive_webshoprefresh');
        }

        return {getTokensData};
    } catch (err) {
        return {"status": "error"};
    }
}

let shouldRefreshToken = true; // It should only refresh the token once

// Closure to await for routes that are dependent for the checkToken call
export const rootLoaderPromise = (async () => {
    // If the user has tokens, try to refresh them. If a refresh is not possible, remove old tokens and get new tokens.
    if(Cookies.get('titelive_webshop') && Cookies.get('titelive_webshoprefresh')) {
        if(shouldRefreshToken) {
            try {
                const refreshTokenData = await refreshToken();
                shouldRefreshToken = false;

                try {
                    const checkTokenData = await checkToken();
                    return {refreshTokenData, checkTokenData};
                } catch(err) {
                    return await getAndCheckToken();
                }
            } catch (err) {
                return await getAndCheckToken();
            }
        }
    }

    // If the user doesn't have tokens, get them and store in cookies
    return await getAndCheckToken();
})();

let isFirstLoad = true; // It should only check token after the first load (when rootLoaderPromise is fulfilled)

const checkTokenAfterFirstLoad = async (request) => {
    if(isFirstLoad) {
        isFirstLoad = false;
        return {"status": "success"};
    }

    if(Cookies.get('titelive_webshop') && Cookies.get('titelive_webshoprefresh')) {
        let checkTokenData;
        try {
            checkTokenData = await checkToken(request);
            isFirstLoad = false;
            return {checkTokenData};
        } catch(err) {
            return await getAndCheckToken();
        }
    }

    // If the user still doesn't have tokens, get them and store in cookies
    return await getAndCheckToken();
}

export async function rootLoader({request}) {
    const {checkTokenData} = await rootLoaderPromise; // This checkTokenData becomes stale after first load

    if(isFirstLoad) {
        isFirstLoad = false;
        return {checkTokenData};
    }

    return await checkTokenAfterFirstLoad(request);
}

export default function Root() {
    const navigation = useNavigation();
    const location = useLocation();

    // NProgress bar (loadingbar on page change)
    nProgress.configure({
        showSpinner: false,
        template: '<div class="bar" role="bar"></div>',
        minimum: 0.3,
        speed: 400,
    });

    // Normal navigation in data router is idle -> loading -> idle, so start nProgress if state changed to "loading"
    if(navigation.state === "loading" || navigation.state === "submitting") {
        nProgress.start();
    }

    if(navigation.state === "idle") {
        nProgress.done();
    }

    // When the location changed, complete the nProgress
    useEffect(() => {
        nProgress.done();
    }, [location])

    return (
        <>
            <GlobalStyle />
            <Outlet />
            <ScrollRestoration />

            {createPortal(
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover
                    theme="light"
                />,
                document.body
            )}
        </>
    );
};