import styled from 'styled-components';
import {Headline1} from '../Headlines/Headlines';
import {OptionalButton} from '../Buttons/Buttons';
import {Subtitle2, Subtitle3} from '../Subtitles/Subtitles';
import {Body} from '../Texts/Texts';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {LayeredCustomLink} from '../CustomLink/CustomLink';

const StyledImageSlide = styled.article`
    background-color: ${props => props.$variant === 'secondary' ? 'var(--color-secondary)' : props.$variant === 'tertiary' ? 'var(--color-tertiary)' : 'var(--color-primary)'};
    display: flex;
    height: 450px;
    
    @media screen and (max-width: 820px) {
        flex-direction: column;
        height: 640px;
        max-height: 100%;
    }
`;

const ImageBox = styled.div`
    flex: 1;
    max-width: 580px;
    
    // Used for making the whole ImageBox clickable while still maintaining nested links
    position: relative;
    a {
        position: relative;
        z-index: 1;
    }
    
    @media screen and (max-width: 820px) {
        max-height: 310px;
        max-width: unset;
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;

const Box = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    position: relative;
    overflow: hidden;
    padding: ${({$extraPaddingBottom}) => $extraPaddingBottom ? '30px 50px 60px' : '30px 50px'};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // Used for making the whole Content clickable while still maintaining nested links
    position: relative;
    a {
        position: relative;
        z-index: 1;
    }
    
    @media screen and (max-width: 1024px) {
        padding: 20px 20px 30px;    
    }
    
    @media screen and (min-width: 821px) {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 850px;
            height: 850px;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, .1);
        }
    }
`;

const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    z-index: 1;
    
    ${Body} { margin: 12px 0 0; }

    @media screen and (max-width: 1024px) {
        ${Headline1} { font-size: 40px; }
    }

    @media screen and (max-width: 820px) {
        ${Body} { margin: 6px 0 0; }
        margin-bottom: 0;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 30px;
    
    @media screen and (max-width: 820px) {
        margin-top: 20px;
    }
`;

export const ImageSlide = ({superTitle, title, subTitle, description, buttonText, url, imageUrl, variant, linkType, onAgenda = false}) => {
    const {width} = useWindowDimensions();

    return (
        <StyledImageSlide $variant={variant}>
            <ImageBox>
                <LayeredCustomLink to={url} linkType={linkType}>
                    <Image src={imageUrl} alt={title} />
                </LayeredCustomLink>
            </ImageBox>
            <Box>
                <Content $extraPaddingBottom={!Boolean(buttonText)}>
                    <InnerContent>
                        {superTitle && <Subtitle2 $truncated $clamp={1} $variant={variant}>{superTitle}</Subtitle2>}
                        <LayeredCustomLink to={url} linkType={linkType}><Headline1 $truncated={!onAgenda} $clamp={2} $variant={variant}>{title}</Headline1></LayeredCustomLink>
                        {subTitle && <Subtitle3 $truncated={!onAgenda} $clamp={width < 820 ? 1 : 2} $variant={variant}>{subTitle}</Subtitle3>}
                        {description && <Body $noMargin $truncated $clamp={width < 820 ? 2 : 3} $variant={variant}>{description}</Body>}
                    </InnerContent>
                    {buttonText &&
                        <ButtonWrapper>
                            <OptionalButton to={url} linkType={linkType} truncate={true} $variant={variant === 'secondary' ? 'tertiary' : 'secondary'}>{buttonText}</OptionalButton>
                        </ButtonWrapper>
                    }
                </Content>
            </Box>
        </StyledImageSlide>
    );
}