import styled from 'styled-components';
import {Body} from '../Texts/Texts';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-right.svg';
import {useState} from 'react';
import {Headline5} from '../Headlines/Headlines';

const StyledAccordion = styled.section`
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
`;

const Header = styled.header`
    display: flex;
    gap: 20px;
    align-items: center;
    background-color: var(--color-light);
    padding: 18px;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    min-height: 60px;

    @media screen and (max-width: 768px) {
        gap: 15px;
        padding: 9px;
    }
`;

const StyledChevron = styled(Chevron)`
    flex-shrink: 0;
    ${props => props.$open && `transform: rotate(90deg)`};
    path { fill: var(--color-primary); }
`;

const Content = styled.article`
    max-width: 1024px;
    padding: 37px 0 37px 65px;
    box-sizing: content-box;
    
    &.closed {
        display: none;
    }

    ${Body} { margin: 0; }
    
    @media screen and (max-width: 768px) {
        padding: 20px 0 20px 50px;
    }
`;

export const FAQAccordion = ({data}) => {
    const [open, setOpen] = useState(false);

    const handleAccordion = () => {
        setOpen(!open);
    }

    return (
        <StyledAccordion>
            <Header onClick={handleAccordion}>
                <StyledChevron $open={open} />
                <Headline5 $open={open}>{data.question}</Headline5>
            </Header>
            <Content className={open ? 'open' : 'closed'}>
                <Body>{data.answer}</Body>
            </Content>
        </StyledAccordion>
    );
}