import styled from 'styled-components';
import {ReactComponent as LogoDeKler} from '../../assets/logos/logo-dekler.svg';
import {Link, useLocation, useRouteLoaderData} from 'react-router-dom';
import {Container} from '../Container/Container';
import {ReactComponent as Person} from '../../assets/icons/person.svg';
import {ReactComponent as Favorite} from '../../assets/icons/favorite-outline.svg';
import {ReactComponent as ShoppingCart} from '../../assets/icons/shopping-cart.svg';
import {SearchBar} from '../SearchBar/SearchBar';
import {bodyTextStyles} from '../Texts/Texts';
import {useSideDrawerContext} from '../../contexts/SideDrawerContext';
import {useEffect, useState} from 'react';
import {NavButton} from '../NavButton/NavButton';

const StyledHeader = styled.section`
    background-color: var(--color-secondary);
`;

const Flex = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
`;

const LogoBox = styled.div`
    flex: 0;
`;

const SearchBarBox = styled.div`
    flex: 1 1 0;
    display: flex;
    justify-content: center;
`;

const ButtonsBox = styled.div`
    flex: 0;
    display: flex;
`;

const Amount = styled.span`
    position: absolute;
    ${bodyTextStyles};
    top: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
        font-size: 12px;
    }
`;

const StyledLogoDeKler = styled(LogoDeKler)`
    width: 84px;
    height: auto;
    
    path { fill: var(--color-white); }
`;

export const Header = () => {
    const { checkTokenData } = useRouteLoaderData("root");
    const {pathname} = useLocation();

    const [firstName, setFirstName] = useState(checkTokenData?.customer?.firstName ?? null);
    const [productsQuantity, setProductsQuantity] = useState(checkTokenData?.cart?.productsQuantity ?? null);

    useEffect(() => {
        setFirstName(checkTokenData?.customer?.firstName ?? null);
        setProductsQuantity(checkTokenData?.cart?.productsQuantity ?? null);
    }, [checkTokenData]);

    const {state, openDrawer, closeDrawer} = useSideDrawerContext();

    return (
        <StyledHeader>
            <Container>
                <Flex>
                    <LogoBox>
                        <Link to='/'>
                            <StyledLogoDeKler />
                        </Link>
                    </LogoBox>
                    <SearchBarBox>
                        <SearchBar $menu />
                    </SearchBarBox>
                    <ButtonsBox>
                        {firstName
                            ? <NavButton onClick={state.isAccountOpen ? () => closeDrawer('Account') : () => openDrawer('Account')}><Person /> <div>{firstName}</div></NavButton>
                            : <NavButton onClick={state.isGuestOpen ? () => closeDrawer('Guest') : () => openDrawer('Guest')} $login>Inloggen</NavButton>
                        }
                        <NavButton onClick={state.isWishlistOpen ? () => closeDrawer('Wishlist') : () => openDrawer('Wishlist')} tooltip="Verlanglijstje">
                            <Favorite />
                        </NavButton>
                        <NavButton onClick={state.isCartOpen ? () => closeDrawer('Cart') : () => openDrawer('Cart')} tooltip="Winkelmandje">
                            <ShoppingCart />
                            {(productsQuantity && !pathname.startsWith('/bestelling-gelukt')) && <Amount $white>{productsQuantity > 9 ? <>9<span>+</span></> : productsQuantity}</Amount>}
                        </NavButton>
                    </ButtonsBox>
                </Flex>
            </Container>
        </StyledHeader>
    );
}