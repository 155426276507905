import styled from "styled-components";
import CheckboxUnchecked from "../../assets/icons/checkbox-unchecked.svg";
import CheckboxChecked from "../../assets/icons/checkbox-checked.svg";
import {bodyTextStyles} from "../Texts/Texts";
import {Tooltip} from '../Tooltip/Tooltip';

const StyledCheckboxField = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`

const Checkbox = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    content: url(${CheckboxUnchecked});
    border-radius: 0;
    
    &:checked{
        content: url(${CheckboxChecked});
    }
`
const StyledLabel = styled.label`
    ${bodyTextStyles};
    line-height: 1.5;
    
    svg { width: 21px; height: 21px; }
`

export default function CheckboxField({...props}) {
    return (
        <StyledCheckboxField>
            <Checkbox
                id={props.name}
                type="checkbox"
                {...props?.register(props.name)}
            />
            <StyledLabel htmlFor={props.name} $small>
                {props.title}
                {props.tooltip && <Tooltip text={props.tooltip}/> }
            </StyledLabel>
        </StyledCheckboxField>
    )
}