import Cookies from 'js-cookie';

export const logIn = async (request, loginObj) => {
    const logInRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/authentication/login`, {
        signal: request.signal,
        method: 'POST',
        body: JSON.stringify(loginObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!logInRes.ok) {
        const error = await logInRes.json();
        throw new Response(JSON.stringify(error), {status: logInRes.status});
    }

    const logInData = await logInRes.json();

    return logInData;
}

export const logOut = async (request) => {
    const logOutRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/authentication/logout`, {
        signal: request.signal,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    })

    if (!logOutRes.ok) {
        throw new Response("Response is not OK", {status: logOutRes.status})
    }

    const logOutData = await logOutRes.json();

    return logOutData;
}