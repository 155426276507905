import {Container} from "../../components/Container/Container";
import {Headline3} from "../../components/Headlines/Headlines";
import {Body} from "../../components/Texts/Texts";
import {Button, ButtonTruncate} from "../../components/Buttons/Buttons";
import styled from "styled-components";
import {Input} from '../../components/Input/Input';
import {Spacing} from '../../components/Spacing/Spacing';
import {useState} from 'react';

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-${props => props.$variant});
`

const StyledNewsletter = styled.form`
    max-width: 790px;
    width: 100%;
    margin: 50px 33px 50px;

    @media screen and (max-width: 820px) {
        margin: 30px 20px 30px;
    }
`

const StyledInputBar = styled.div`
    display: flex;
    max-width: 100%;
    width: 100%;
    
    ${Input}:disabled {
        background-color: var(--color-white);
        opacity: 0.8;
    }
    
    ${Button}:disabled {
        opacity: 0.8;
        pointer-events: none;
    }
    
    @media screen and (max-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        
        ${Button} {
            max-width: unset;
        }
    }
`;

const ErrorText = styled(Body)`
    margin-bottom: 0;
`;

export const Newsletter = ({data}) => {

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('idle');

    async function handleSubmit(e) {
        e.preventDefault();

        setStatus('submitting');
        try {
            setError(null);
            const response = await submitForm(email);
            if (!response.ok) {
                throw new Error('Het lijkt erop dat je al bent ingeschreven voor onze nieuwsbrief');
            }
            setEmail('');
            setStatus('success');
        } catch (err) {
            setStatus('idle');
            setError(err);
        }
    }

    const submitForm = async (email) => {
        const body = JSON.stringify({
            email: email
        });

        return fetch(`${process.env.REACT_APP_API_BASE_URL}/subscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });
    }

    function handleTextareaChange(e) {
        setEmail(e.target.value);
    }

    if(!data?.newsletter) { return null; }

    return (
        <Spacing>
            <Container>
                <Flex $variant={data?.newsletter?.color}>
                    <StyledNewsletter onSubmit={handleSubmit}>
                        <Headline3 $variant={data?.newsletter?.color}>{status === 'success' ? data?.newsletter?.thanksTitle : data?.newsletter?.title}</Headline3>
                        <Body $variant={data?.newsletter?.color}>{status === 'success' ? data?.newsletter?.thanksText : data?.newsletter?.description}</Body>
                        <StyledInputBar>
                            <Input type="email" placeholder={data?.newsletter?.placeholderText} value={email} onChange={handleTextareaChange} disabled={status === 'submitting'}/>
                            <Button as="button" disabled={email.length === 0 || status === 'submitting'} $variant={data?.newsletter?.color === 'secondary' ? 'tertiary' : 'secondary'}><ButtonTruncate>{data?.newsletter?.linkText}</ButtonTruncate></Button>
                        </StyledInputBar>

                        {error !== null &&
                            <ErrorText $italic $variant={data?.newsletter?.color}>{error.message}</ErrorText>
                        }
                    </StyledNewsletter>
                </Flex>
            </Container>
        </Spacing>
    )
}