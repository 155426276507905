import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Breadcrumbs} from '../Breadcrumbs/Breadcrumbs';
import {Headline1} from '../../components/Headlines/Headlines';
import {Body} from '../../components/Texts/Texts';
import {Spacing} from '../../components/Spacing/Spacing';
import {Input} from '../../components/Input/Input';
import {IconButton} from '../../components/Buttons/Buttons';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import {useState} from 'react';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {searchEvent} from '../../events/books';

const StyledHeaderSearch = styled.section`
    margin-top: 60px;
    
    @media screen and (max-width: 1024px) {
        margin-top: 30px;
    }
`;

const StyledSearchBar = styled.div`
    position: relative;
    display: flex;
    max-width: 100%;
    width: 100%;
    
    ${IconButton} {
        flex-shrink: 0;
    }
`;

const Flex = styled.div`
    display: flex;
    gap: 100px;
    
    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        gap: 30px;
    }
`;

const Box = styled.div`
    flex: 1;
    ${Headline1} { margin: 30px 0; }
`;

const ImageBox = styled.div`
    margin-top: auto;
    max-width: 500px;

    @media screen and (max-width: 1024px) {
        max-width: unset;
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;

    @media screen and (max-width: 1024px) {
        max-height: 182px;
    }
`;

export const HeaderSearch = ({data, breadcrumbs}) => {
    const [status, setStatus] = useState(null)
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setStatus('submitting');
        searchEvent(searchTerm);
        navigate({
            pathname: `boeken/${data?.headerSearch?.filterUrl}`,
            search: createSearchParams({
                zoeken: encodeURIComponent(searchTerm)
            }).toString()
        });
    }

    if(!data?.headerSearch) { return null; }

    return (
        <Spacing>
            <StyledHeaderSearch>
                <Container>
                    <Flex>
                        <Box>
                            <Breadcrumbs data={breadcrumbs} />
                            <Headline1>{data?.headerSearch?.title}</Headline1>
                            {data?.headerSearch?.description && <Body>{data?.headerSearch?.description}</Body> }

                            <form onSubmit={onSubmit}>
                                <StyledSearchBar>
                                    <Input type="text" placeholder={data?.headerSearch?.placeholderText} value={searchTerm} onChange={handleSearchTerm} />
                                    <IconButton as="button" $variant="tertiary" disabled={searchTerm.length < 3 || status === 'submitting'}><SearchIcon /></IconButton>
                                </StyledSearchBar>
                            </form>

                        </Box>
                        {data?.headerSearch?.imageURL &&
                            <ImageBox>
                                <Image src={data?.headerSearch?.imageURL} alt={data?.headerSearch?.title} />
                            </ImageBox>
                        }
                    </Flex>
                </Container>
            </StyledHeaderSearch>
        </Spacing>
    );
}