import styled from 'styled-components';
import {overflowWrapStyles, textColorVariants, truncatedStyles} from '../Texts/Texts';

export const Subtitle1 = styled.p`
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    ${({$variant})  => textColorVariants($variant)};
    ${props => props.$white && `color: var(--color-white)`};
    margin: 0;
    ${overflowWrapStyles};
`;

export const Subtitle2 = styled.p`
    font-family: var(--font-primary-caps);
    font-weight: 400;
    font-size: 16px;
    ${({$variant})  => textColorVariants($variant)};
    ${props => props.$white && `color: var(--color-white)`};
    margin: 0;
    ${truncatedStyles};
    ${overflowWrapStyles};
`;

export const Subtitle3 = styled.p`
    font-family: var(--font-primary);
    font-weight: 400;
    font-style: italic;
    font-size: 25px;
    ${({$variant})  => textColorVariants($variant)};
    ${props => props.$white && `color: var(--color-white)`};
    ${truncatedStyles};
    ${overflowWrapStyles};
    margin: 0;
    
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
`;