import {Helmet} from 'react-helmet-async';

export const Seo = ({ metaTitle, metaDescription, metaImage, url}) => {
    return (
        <Helmet>
            <title>{metaTitle ? `De Kler | ${metaTitle}` : 'De Kler'}</title>
            {url && <link rel="canonical" href={`https://www.dekler.nl${url.endsWith("/") ? url.slice(0, -1) : url}`} />}

            {metaTitle &&
                <>
                    <meta name="title" content={metaTitle} />
                    <meta property="og:title" content={metaTitle} />
                    <meta name="twitter:title" content={metaTitle} />
                </>
            }

            {metaDescription &&
                <>
                    <meta name="description" content={metaDescription} />
                    <meta property="og:description" content={metaDescription} />
                    <meta name="twitter:description" content={metaDescription} />
                </>
            }

            {metaImage &&
                <>
                    <meta name="image" content={metaImage} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={url ? `https://www.dekler.nl/${url}` : 'https://www.dekler.nl/'} />
                    <meta property="og:image" content={metaImage} />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={url ? `https://www.dekler.nl/${url}` : 'https://www.dekler.nl/'} />
                    <meta name="twitter:image" content={metaImage} />
                </>
            }
        </Helmet>
    );
}