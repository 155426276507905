import {Link} from 'react-router-dom';
import styled, { css } from 'styled-components';
import {bodyTextStyles, buttonTextStyles, truncatedStyles} from '../Texts/Texts';
import {ConditionalWrap} from '../ConditionalWrap/ConditionalWrap';

export const buttonVariants = (variant = 'primary') => ({
    primary: css`
        background-color: var(--color-primary);
        color: var(--color-white);
        path { fill: var(--color-white); }

        &:hover { background-color: var(--color-primary-120); }
    `,
    secondary: css`
        background-color: var(--color-secondary);
        color: var(--color-white);
        path { fill: var(--color-white); }

        &:hover { background-color: var(--color-secondary-120); }
    `,
    tertiary: css`
        background-color: var(--color-tertiary);
        color: var(--color-primary);
        path { fill: var(--color-primary); }

        &:hover {
            background-color: var(--color-tertiary-120);
            color: var(--color-white);
            path { fill: var(--color-white); }
        }
    `,
    accent: css`
        background-color: var(--color-accent);
        color: var(--color-primary);
        path { fill: var(--color-primary); }

        &:hover {
            background-color: var(--color-accent-120);
            color: var(--color-white);
            path { fill: var(--color-white); }
        }
    `,
    outline: css`
        background-color: transparent;
        color: var(--color-primary);
        box-shadow: inset 0 0 0 1px var(--color-primary);
        path { fill: var(--color-primary); }

        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
            path { fill: var(--color-white); }
        }
    `,
    inactive: css`
        background-color: var(--color-primary-10);
        color: var(--color-primary);
        path { fill: var(--color-primary); }

        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
            path { fill: var(--color-white); }
        }
    `,
}[variant]);

export const Button = styled(Link)`
    border-radius: 0;
    border: none;
    ${buttonTextStyles};
    ${({$variant})  => buttonVariants($variant)};
    display: ${props => props.$icon ? 'inline-flex' : 'inline-block'};
    text-align: ${props => props.$icon ? 'left' : 'center'};
    cursor: pointer;
    width: 100%;
    max-width: ${props => props.$small ? '160px' : '190px'};
    min-height: ${props => props.$small ? 'unset' : '50px'};
    padding: ${props => props.$small ? '10px 0 8px' : '14px 11px 11px'};
    ${props => props.$icon && ` align-items: center; `}
    
    svg { 
        vertical-align: middle; 
        margin-right: 11px 
    }
    
    &:disabled {
        opacity: .8;
    }
`;

export const ButtonTruncate = styled.span.attrs({
    $truncated: true,
    $clamp: 1
})`
    ${truncatedStyles};
`;

export const OptionalButton = ({to, linkType = "relative", truncate = false, children, ...props}) => {
    if(!to || !children) { return null; }

    return (
        <Button
            to={to}
            target={(linkType === 'absolute' || to.startsWith("http")) ? '_blank' : '_self'}
            rel={linkType === 'absolute' ? 'noreferrer' : undefined}
            {...props}
        >
            <ConditionalWrap condition={truncate} wrap={children => <ButtonTruncate>{children}</ButtonTruncate>}>
                {children}
            </ConditionalWrap>
        </Button>
    );
}

export const Label = styled(Link).attrs({
    $small: true
})`
    border: none;
    ${bodyTextStyles};
    ${({$variant})  => buttonVariants($variant)};
    display: inline-block;
    text-align: center;
    cursor: pointer;
    width: 100%;
    max-width: ${props => props.$hugged ? '150px' : '134px'};
    padding: ${props => props.$hugged ? '4px 16px' : '8px 13px'};
    border-radius: ${props => props.$hugged ? '5px' : '10px'};
    text-transform: none;
    text-decoration: none;
    pointer-events: ${props => props.$disabled ? 'none' : 'unset'};
`;

export const IconButton = styled(Button)`
    width: ${props => props.$small ? '35px' : '50px'};
    height: ${props => props.$small ? '35px' : '50px'};
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    ${props => props.$relative && 'position: relative;'}
    
    svg {
        margin-right: 0;
        vertical-align: unset;
        ${props => props.$small && `
            width: 17px;
            height: 17px;  
        `}
    }
`;