import styled, {css} from 'styled-components';

// Mixin to truncate texts. Usage: add $truncated prop and $clamp=<number_of_lines> (default 3)
export const truncatedStyles = css`
    ${props => props.$truncated && `
        display: -webkit-box;
        -webkit-line-clamp: ${props.$clamp ? props.$clamp : '3'};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    
        // Hide all child elements except the first on truncated text (Safari/iOS bug)
        // Also except anchor tags, because they are allowed for email addresses
        & > *:not(:first-child):not(a) {
            display: none;
        }
    `}
`;

// Mixin to apply overflow-wrap styles to texts
export const overflowWrapStyles = css`
    overflow-wrap: anywhere;
`;

export const textColorVariants = (variant = 'default') => ({
    default: css`
        color: var(--color-primary);
    `,
    primary: css`
        color: var(--color-white);
    `,
    secondary: css`
        color: var(--color-white);
    `,
    tertiary: css`
        color: var(--color-primary);
    `,
    accent: css`
        color: var(--color-primary);
    `,
    outline: css`
        color: var(--color-primary);
    `,
}[variant]);

export const bodyTextStyles = css`
    font-family: var(--font-primary);
    font-weight: ${props => props.$bold ? '700' : '400'};
    font-style: ${props => props.$italic ? 'italic' : 'normal'};
    font-size: ${props => props.$small ? '14px' : '16px'};
    line-height: 1.55;
    white-space: pre-wrap;
    ${({$variant})  => textColorVariants($variant)};
    ${props => props.$white && 'color: var(--color-white);'};
    ${({$noMargin}) => $noMargin && 'margin: 0;'};
`;

export const Body = styled.p`
    ${bodyTextStyles};
    ${truncatedStyles};
    ${overflowWrapStyles};
`;

export const Breadcrumb = styled.p`
    font-family: var(--font-primary);
    font-weight: ${props => props.$bold ? '700' : '400'};
    font-style: normal;
    font-size: 15px;
    ${({$variant})  => textColorVariants($variant)};
    display: inline-block;
    text-decoration: none;
    margin: 0;
`;

export const buttonTextStyles = css`
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    text-decoration: none;
    color: var(--color-primary);
`;

export const alternativeTextStyles = css`
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--color-primary);

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
`;