import styled from "styled-components";
import {Input} from "../Input/Input";
import {useState} from "react";
import {ReactComponent as Visibility} from '../../assets/icons/visibility.svg';
import {ReactComponent as VisibilityOff} from '../../assets/icons/visibility-off.svg';
import {IconButton} from "../Buttons/Buttons";

const StyledPasswordField = styled.div`
    position: relative;
`;

const StyledInput = styled(Input)`
    padding-right: 50px;
`;

const VisibilityButton = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--color-white);
    border: solid ${props => props?.errors ? 'var(--color-secondary)' : 'var(--color-primary-10)'};
    border-width: 1px 1px 1px 0;
    path { fill: var(--color-primary)}

    ${StyledInput}:focus + & {
        border-color: var(--color-primary);
    }
    
    &:hover { 
        background-color: var(--color-white); 
    }
`;




export default function PasswordField({...props}) {
    const [type, setType] = useState("password");

    const handleVisibility = () => {
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    }

    return (
        <StyledPasswordField>
            <StyledInput
                id={props?.name}
                type={type}
                placeholder={props?.placeholder} $lightPlaceholder
                autoComplete={props?.autoComplete}
                errors={props?.errors[props?.name]}
                {...props?.register(props.name, {
                    required: "Wachtwoord is verplicht",
                    ...(props.name === 'password' && {
                        minLength: {
                            value: 6,
                            message: "Minimaal 6 karakters"
                        },
                    }),
                    ...(props.name === 'password-repeat' && {
                        validate: (val) => {
                            if (props.watch('password') !== val) {
                                return "Wachtwoorden komen niet overeen";
                            }
                        }
                    }),
                })}
            />

            <VisibilityButton as="div" onClick={handleVisibility} errors={props?.errors[props?.name]}>
                {type === "password" ? <Visibility/> : <VisibilityOff/>}
            </VisibilityButton>
        </StyledPasswordField>
    )
}