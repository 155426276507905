import styled from "styled-components";
import {Headline3, Headline4} from "../Headlines/Headlines";
import {Body} from "../Texts/Texts";
import {Button, OptionalButton} from "../Buttons/Buttons";
import {LayeredCustomLink} from '../CustomLink/CustomLink';

const StyledAgendaItem = styled.div`
    display: flex;
    flex-direction: ${({$direction}) => $direction === "horizontal" ? 'row' : 'column'};
    ${({$direction}) => $direction === "horizontal" && 'padding-bottom: 30px;'};
    ${({$direction}) => $direction === "vertical" && 'min-height: 560px;'};
    
    // Used for making the whole AgendaItem clickable while still maintaining nested links
    position: relative;
    a {
        position: relative;
        z-index: 1;
    }
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding-bottom: unset;
        min-height: 500px;
    }
`;

const ImageBox = styled.div`
    background-color: var(--color-primary-10);
    position: relative;
    margin-top: auto;
    ${({$direction}) => $direction === "horizontal" ? `
        flex-basis: 390px;
        min-height: 230px;
    ` : `
        flex: 0 0 230px;
    `};

    @media screen and (max-width: 768px) {
        flex: 0 0 230px;
    }

    ${({$type}) => $type === "product" && `
        display: flex;
        align-items: flex-end;
        justify-content: center;
    `}
`;

const StyledImage = styled.img`
    display: block;
    width: 100%;
    max-height: 100%;
    
    ${({$type}) => $type === "product" ? `
        object-fit: contain;
        max-width: 130px;
        max-height: 200px;
        box-shadow: var(--box-shadow-60);
    ` : `
        object-fit: cover;
        height: 230px; 
    `}
`;

const Date = styled.div`
    background-color: var(--color-white);
    position: absolute;
    bottom: -30px;
    left: 28px;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 4px 4px 4px;
    box-shadow: var(--box-shadow);
    
    @media screen and (max-width: 768px) {
        width: 53px;
        height: 53px;
    }
`;

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    
    ${({$direction, $hasButton}) => $direction === "horizontal" ? `
        padding: 30px 0 30px 30px;
        border-bottom: 2px solid var(--color-primary-10);
        flex-grow: 1;
        ${$hasButton && 'margin-right: 30px;'};
    ` : `
        padding: 58px 28px 0;
        border-left: 2px solid var(--color-primary-10);
        justify-content: space-between;
    `};

    @media screen and (max-width: 768px) {
        padding: 58px 28px 0;
        justify-content: space-between;
        border-bottom: unset;
        flex-grow: unset;
        margin-right: 0;
        border-left: 2px solid var(--color-primary-10);
    }
`;

const Inner = styled.div`
    ${Body} { 
        margin-bottom: 0;
        
        &:not(:first-of-type) { margin: 0; }
    }
`;

const ButtonBox = styled.div`
    ${({$direction}) => $direction === "horizontal" ? `
        flex: 0 0 160px;
        margin-top: auto;
        margin-left: auto;
    ` : `
        flex: 1;
        padding: 0 28px;
        border-left: 2px solid var(--color-primary-10);
        display: flex;
        
        ${Button} { margin-top: auto; } 
    `};

    ${({$hasButton, $direction}) => (!$hasButton && $direction === "horizontal") && `
        flex: 0;
    `};
    
    @media screen and (max-width: 768px) {
        display: flex;
        flex: 1;
        padding: 0 28px;
        border-left: 2px solid var(--color-primary-10);
        margin-top: unset;
        margin-left: unset;

        ${Button} { margin-top: auto; }
    }
`;

export const AgendaItem = ({type, image, date, title, shop, startTime, endTime, linkType, url, linkText, direction = "vertical"}) => {
    return (
        <StyledAgendaItem $direction={direction}>
            <ImageBox $direction={direction} $type={type}>
                <StyledImage src={image} alt="" $type={type} />
                <Date><Headline4>{date}</Headline4></Date>
            </ImageBox>
            <ContentBox $direction={direction} $hasButton={Boolean(linkText)}>
                <LayeredCustomLink to={`/${url}`} linkType={linkType}><Headline3 $truncated $clamp={2}>{title}</Headline3></LayeredCustomLink>
                <Inner>
                    {shop && <Body>{shop}</Body>}
                    {(startTime || endTime) && <Body>{startTime ? startTime : ''}{endTime ? ` - ${endTime}` : ''}</Body>}
                </Inner>
            </ContentBox>
            <ButtonBox $direction={direction} $hasButton={Boolean(linkText)}>
                {Boolean(linkText) && <OptionalButton to={`/${url}`} linkType={linkType} truncate={true} $variant="outline" $small>{linkText}</OptionalButton>}
            </ButtonBox>
        </StyledAgendaItem>
    );
}