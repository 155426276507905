import {Button} from "../../../components/Buttons/Buttons";
import styled from "styled-components";
import {Container} from "../../../components/Container/Container";
import {ReactComponent as ChevronLeft} from "../../../assets/icons/chevron-left.svg";
import React, {useEffect} from "react";
import {AddressForm} from '../../../components/AddressForm/AddressForm';
import {destroyAddress, getAddress, updateAddress} from '../../../api/addresses';
import {defer, redirect, useFetcher, useLoaderData, useParams, useSubmit} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Toaster} from '../../../components/Toaster/Toaster';
import {useForm} from 'react-hook-form';
import FormField from '../../../components/Fields/FormField';
import {useAddressFromPostalCode} from '../../../hooks/useAddressFromPostalCode';
import {bodyTextStyles} from '../../../components/Texts/Texts';
import {Seo} from '../../../components/Seo/Seo';
import {rootLoaderPromise} from '../../../layouts/Root';

const Wrapper = styled.div`
    max-width: 825px;
    margin: 40px 0 40px;

    @media screen and (max-width: 768px) {
        margin: 20px 0 20px;
    }
`;

const BackButton = styled(Button)`
    max-width: 116px;
`;

const RemoveButton = styled.button`
    all: unset;
    cursor: pointer;
    ${bodyTextStyles};
    text-decoration: underline;
    margin-top: 20px;
    width: fit-content;
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

export async function addressAction({request, params}) {
    let formData = await request.formData();

    switch(request.method) {
        case "PUT": {
            const addressObj = {
                "id": parseInt(params.id),
                "salutation": formData.get("salutation"),
                "firstName": formData.get("firstName"),
                "prefix": formData.get("prefix"),
                "lastName": formData.get("lastName"),
                "postcode": formData.get("postcode"),
                "number": parseInt(formData.get("number")),
                "postbus": formData.get("postbus"),
                "addressLine1": formData.get("addressLine1"),
                "city": formData.get("city"),
                "country": 2,
                "phone":  formData.get("phone"),
            }

            try {
                await updateAddress(request, addressObj);
                toast(<Toaster type='success' title='Gelukt!' message='Het adres is succesvol gewijzigd.' $onLight/>);
                return redirect('/account/mijn-adressen');
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }
        case "DELETE": {
            const addressObj = {
                "id": parseInt(params.id),
            }

            try {
                await destroyAddress(request, addressObj);
                toast(<Toaster type='success' title='Gelukt!' message='Het adres is succesvol verwijderd.' $onLight/>);
                return redirect('/account/mijn-adressen');
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }
        default: {
            throw new Response("Method not allowed", { status: 405 })
        }
    }
}

export async function addressLoader({request, params}) {
    await rootLoaderPromise;

    let getAddressData;
    try {
        getAddressData = await getAddress(request, params?.id);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({addressData: getAddressData});
}

export default function Address(){
    const {addressData} = useLoaderData();

    const params = useParams();

    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
        defaultValues: {
            salutation: addressData?.salutation,
            firstName: addressData?.firstName,
            prefix: addressData?.prefix ?? "",
            lastName: addressData?.lastName,
            postcode: addressData?.postcode,
            number: addressData?.number,
            postbus: addressData?.postbus ?? "",
            addressLine1: addressData?.addressLine1,
            type: addressData?.type,
            city: addressData?.city,
            country: addressData?.country,
            phone: addressData?.phone ?? "",
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    const submit = useSubmit();

    const onSubmit = (data) => {
        submit(data, { method: 'put', action: `/account/mijn-adressen/${params?.id}` })
    };

    let defaultAddress = `${addressData?.addressLine1} ${addressData?.number}${addressData?.postbus ?? ""}, ${addressData?.city}`;
    const {displayAddressFromPostalCode, handleAddress} = useAddressFromPostalCode(getValues, setValue, defaultAddress);

    const fetcher = useFetcher();
    const handleDeleteAddress = async () => {
        fetcher.submit({}, { method: 'delete', action: `/account/mijn-adressen/${params?.id}` })
    }

    return (
        <Container>
            <Seo metaTitle="Mijn adressen" />
            <Wrapper>
                <BackButton to=".." relative="path" $variant="outline" $icon><ChevronLeft/>Terug</BackButton>
                <AddressForm title="Adres bewerken" onSubmit={handleSubmit(onSubmit)} handleAddress={handleAddress} displayAddressFromPostalCode={displayAddressFromPostalCode} register={register} errors={errors}  />
                <RemoveButton onClick={handleDeleteAddress}>Adres verwijderen</RemoveButton>
            </Wrapper>
        </Container>
    )
}