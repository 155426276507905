import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Subtitle1} from '../../components/Subtitles/Subtitles';
import {Headline1, Headline5} from '../../components/Headlines/Headlines';
import {Body} from '../../components/Texts/Texts';
import {Button, OptionalButton} from '../../components/Buttons/Buttons';
import {Breadcrumbs} from '../Breadcrumbs/Breadcrumbs';
import {Spacing} from '../../components/Spacing/Spacing';
import React from 'react';
import {LampMask} from '../../components/Masks/LampMask/LampMask';
import {LabelMask} from '../../components/Masks/LabelMask/LabelMask';
import {BookmarkMask} from '../../components/Masks/BookmarkMask/BookmarkMask';

const StyledHeaderBanner = styled.section`
    overflow: hidden;
`;

const Outer = styled.div`
    padding: 20px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const Inner = styled.div`
    background: var(--color-${props => props.$variant});
    padding: 40px 0;
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;
`;

const ContentBox = styled.div`
    flex-basis: 500px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    @media screen and (max-width: 768px) {
        flex-basis: unset;
        margin-bottom: 0;
    }
`;

const ContentInner = styled.div`
    margin: auto 0;
    
    ${Subtitle1} {  
        margin-bottom: 5px;
        ${props => props.$variant === 'tertiary' && `color: var(--color-secondary);`};
    }
    ${Headline1} { margin-bottom: 5px; }
    ${Headline5} { margin-bottom: 16px; }
    ${Body} { margin: 0; }
    ${Button} { margin-top: 50px; }
`;

export const HeaderBanner = ({data, breadcrumbs}) => {
    if(!data.bannerHeader) { return null }

    return (
        <Spacing>
            <StyledHeaderBanner>
                <Outer>
                    <Inner $variant={data?.bannerHeader?.color}>
                        <Container>
                            <Flex>
                                <ContentBox>
                                    <Breadcrumbs $variant={data?.bannerHeader?.color} data={breadcrumbs} />
                                    <ContentInner $variant={data?.bannerHeader?.color}>
                                        <Subtitle1 $variant={data?.bannerHeader?.color}>{data?.bannerHeader?.supertitle}</Subtitle1>
                                        <Headline1 $variant={data?.bannerHeader?.color}>{data?.bannerHeader?.title}</Headline1>
                                        <Headline5 $variant={data?.bannerHeader?.color}>{data?.bannerHeader?.subtitle}</Headline5>
                                        <Body $variant={data?.bannerHeader?.color}>{data?.bannerHeader?.description}</Body>
                                        <OptionalButton to={data?.bannerHeader?.url} linkType={data?.bannerHeader?.linkType } truncate={true} $variant={data?.bannerHeader?.color === 'primary' ? 'accent' : data?.bannerHeader?.color === 'secondary' ? 'tertiary' : 'secondary'}>{data?.bannerHeader?.linkText}</OptionalButton>
                                    </ContentInner>
                                </ContentBox>
                                {data?.bannerHeader?.shapeType === 'lamp' ?
                                    <LampMask variant={data?.bannerHeader?.color} imageURL={data?.bannerHeader?.imageURL} position="right" />
                                : data?.bannerHeader?.shapeType === 'label' ?
                                    <LabelMask variant={data?.bannerHeader?.color} imageURL={data?.bannerHeader?.imageURL} position="right" />
                                :
                                    <BookmarkMask variant={data?.bannerHeader?.color} imageURL={data?.bannerHeader?.imageURL} position="right" />
                                }
                            </Flex>
                        </Container>
                    </Inner>
                </Outer>
            </StyledHeaderBanner>
        </Spacing>
    );
}