import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Headline2} from '../../components/Headlines/Headlines';
import {Spacing} from '../../components/Spacing/Spacing';
import {ConditionalWrap} from '../../components/ConditionalWrap/ConditionalWrap';
import {HTMLText} from '../../components/HTMLText/HTMLText';

const StyledContainer = styled(Container)`
    ${({$isTwoColumns}) => !$isTwoColumns && 'max-width: 768px;'};

    ${Headline2} { margin-bottom: 30px; }
`;

const FlexOrColumn = styled.div`
    ${({$flex}) => $flex && (`
        display: flex;
        gap: 60px;
        
        @media screen and (max-width: 1024px) {
            gap: 30px;
        }
    
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    `)}
`;

const TextBox = styled.article`
    flex: 1 1 0;
`;

export const TextBlock = ({data}) => {
    if(!data?.text) { return null; }

    let isTwoColumns = data.text.numberOfColumns === "2";

    return (
        <Spacing>
            <StyledContainer $isTwoColumns={isTwoColumns}>
                <FlexOrColumn $flex={isTwoColumns}>
                    <ConditionalWrap condition={isTwoColumns} wrap={children => <TextBox>{children}</TextBox>}>
                        <Headline2>{data.text.title}</Headline2>
                        <HTMLText dangerouslySetInnerHTML={{__html: data.text.text}} />
                    </ConditionalWrap>

                    {isTwoColumns &&
                        <TextBox>
                            <Headline2>{data.text.titleTwo}</Headline2>
                            <HTMLText dangerouslySetInnerHTML={{__html: data.text.textTwo}} />
                        </TextBox>
                    }
                </FlexOrColumn>
            </StyledContainer>
        </Spacing>
    );
}