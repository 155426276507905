import React from "react";
import {Await, defer, Link, useLoaderData} from "react-router-dom";
import styled from "styled-components";
import {Body} from "../../../components/Texts/Texts";
import {ReactComponent as ChevronRight} from '../../../assets/icons/chevron-right.svg';
import {EmptyState} from '../../../components/EmptyState/EmptyState';
import {Seo} from '../../../components/Seo/Seo';
import {rootLoaderPromise} from '../../../layouts/Root';
import {getOrders} from '../../../api/orders';

const Wrapper = styled.div``

const Orders = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 1230px;
    width: 100%;
`

const Row = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 52px;
    position: relative;
    background-color: var(--color-white);
    box-shadow: 0 4px 4px 0 #9696960D;
    padding: 17px 52px 17px;
    text-decoration: none;

    @media screen and (max-width: 768px) {
        padding: 20px 24px 20px;
    }
`

const Order = styled.div`
    display: flex;
    align-items: center;
    gap: 52px;
    width: 100%;
    min-width: 0;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
`

const Info = styled.div`
    flex-basis: 150px;
    ${Body}{
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        flex: none;
    }
`

const Products = styled.div`
    display: flex;
    gap: 13px;
    width: 100%;
    overflow-x: auto;
    
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }
`

const Image = styled.img`
    height: 86px;
    object-fit: cover;
`

const StyledChevronRight = styled(ChevronRight)`
    flex-shrink: 0;
    path { fill: var(--color-primary); }
    @media screen and (max-width: 768px) {
        position: absolute;
        top: 20px;
        right: 24px;
    }
`

export async function myOrdersLoader({request}) {
    await rootLoaderPromise;

    let getOrdersData;
    try {
        getOrdersData = await getOrders(request);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({ordersData: getOrdersData});
}

export default function MyOrders() {
    const {ordersData} = useLoaderData();

    return (
        <>
            <Seo metaTitle="Mijn bestellingen" />
            <React.Suspense>
                <Await resolve={ordersData}>
                    {(myOrders) => (
                        <Wrapper>
                            <Orders>
                                {myOrders.length > 0 ? myOrders.map((order) => (
                                    <Row key={order.id} to={`/account/mijn-bestellingen/${order.id}`}>
                                        <Order>
                                            <Info>
                                                <Body $bold>{order.date}</Body>
                                                <Body>{order.price ?? "Fout"}</Body>
                                            </Info>

                                            <Products>
                                                {order.products.map((product, index) => (
                                                    <Image key={`${product.isbn}-${index}`} src={product.image} alt={product.title}/>
                                                ))}
                                            </Products>
                                        </Order>

                                        <StyledChevronRight/>
                                    </Row>
                                )) : <EmptyState heading="Je hebt nog geen bestellingen" description="Bekijk onze boeken en plaats je eerste bestelling" />}
                            </Orders>
                        </Wrapper>
                    )}
                </Await>
            </React.Suspense>
        </>
    )
}