import {Headline5} from "../Headlines/Headlines";
import {Body} from "../Texts/Texts";
import styled from "styled-components";
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';

const Notification = styled.aside`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    max-width: 622px;
    padding: 14px 14px 14px 20px;
    background-color: ${props => props.$onLight ? 'var(--color-white)' : 'var(--color-light)'};
    box-shadow: var(--box-shadow);

    border-left: 5px solid ${props => 
        props.$type === 'success' ? 'var(--color-tertiary)' : 
        props.$type === 'warning' ? 'var(--color-accent)' :
        props.$type === 'danger' ? 'var(--color-secondary)' :
        'var(--color-light)'
    };
    
    ${Body} { 
        margin: 0;
        color: var(--color-primary-60);
    }
`;

const Close = styled(CloseIcon)`
    flex-shrink: 0;
    cursor: pointer;
`

export const Toaster = ({type, title, message, closeToast, toastProps, ...props}) => {

    return (
        <Notification $type={type} {...props}>
            <div>
                <Headline5>{title}</Headline5>
                <Body>{message}</Body>
            </div>
            <Close onClick={()=> closeToast}/>
        </Notification>
    )
}