import {SideDrawer} from '../SideDrawer/SideDrawer';
import {Body} from '../../Texts/Texts';
import {Button} from '../../Buttons/Buttons';
import {EditPopup} from '../../EditPopup/EditPopup';
import * as S from './AddressesSideDrawer.elements';
import {EmptyState} from '../../EmptyState/EmptyState';
import React from 'react';

export const InvoiceAddresses = ({isOpen, close, data, selectedAddress = null, setInvoiceAddress}) => {

    const selectInvoiceAddress = (item) => { setInvoiceAddress(item); }

    return (
        <SideDrawer title="Factuuradres" isOpen={isOpen} close={close} $noMargin>
            <S.Addresses>
                {data?.length > 0 ?
                    <>
                        {data.map(item => (
                            <S.Address key={item?.id} onClick={() => { selectInvoiceAddress(item); close(); }} className={item?.id === selectedAddress ? 'active' : ''}>
                                <S.StyledMarker />
                                <S.AddressBox>
                                    <Body>{item?.firstName} {item?.prefix} {item?.lastName}<br/>{item?.addressLine1} {item?.number}{item?.postbus}<br/>{item?.postcode}, {item?.city}</Body>
                                    {item?.default && <S.DefaultAddress $bold $small>Standaard bezorgadres</S.DefaultAddress>}
                                </S.AddressBox>
                                <EditPopup data={item} close={close} selectAddress={selectInvoiceAddress} allowDelete={data.length > 1} />
                            </S.Address>
                        ))}
                    </>
                    :  <EmptyState heading="Je hebt nog geen factuuradres ingesteld" description="Maak een nieuw factuuradres aan." />
                }
            </S.Addresses>

            <S.SideDrawerButtonsWrapper>
                <Button to="/bestellen/jouw-gegevens/adres-toevoegen" state={{ type: 2 }} onClick={close} $variant="outline">Nieuw factuuradres</Button>
            </S.SideDrawerButtonsWrapper>
        </SideDrawer>
    );
}