import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Headline2} from '../../components/Headlines/Headlines';
import {TagCloud} from '../../components/TagCloud/TagCloud';
import {DirectToLink} from '../../components/DirectToLink/DirectToLink';
import {Spacing} from '../../components/Spacing/Spacing';

const StyledDirectToTagCloud = styled.section``;

const Flex = styled.div`
    display: flex;
    gap: 120px;
    
    ${Headline2} { margin-bottom: 30px; }

    @media screen and (max-width: 1024px) {
        gap: 60px;
        flex-direction: column;
    }
`;

const DirectToBox = styled.div`
    width: 45%;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

const Wrapper = styled.div`
    display: flex;
    &:not(:last-of-type) { margin-bottom: 10px; }
`;

const TagCloudBox = styled.div``;

export const DirectToTagCloud = ({data}) => {
    if(!data.directToLinkContent || !data.tagCloudContent) { return null; }
    if(data.directToLinkContent.links.length === 0 || data.tagCloudContent.links.length === 0) { return null; }

    return (
        <Spacing>
            <StyledDirectToTagCloud>
                <Container>
                    <Flex>
                        <DirectToBox>
                            <Headline2>{data?.directToLinkContent?.title}</Headline2>
                            {data?.directToLinkContent?.links.map(item => {
                                if(!item.url) return null;

                                if(item.linkType === 'absolute'){
                                    return <Wrapper key={item.id}><DirectToLink as="a" href={item.url} target={item.newWindow ? "_blank" : "_self"} icon={item.imageURL} imageType={item.imageType} variant={item.color}>{item.linkText}</DirectToLink></Wrapper>
                                } else {
                                    return <Wrapper key={item.id}><DirectToLink to={item.url} icon={item.imageURL} imageType={item.imageType} variant={item.color}>{item.linkText}</DirectToLink></Wrapper>
                                }
                            })}
                        </DirectToBox>
                        <TagCloudBox>
                            <Headline2>{data?.tagCloudContent?.title}</Headline2>
                            <TagCloud data={data?.tagCloudContent?.links} />
                        </TagCloudBox>
                    </Flex>
                </Container>
            </StyledDirectToTagCloud>
        </Spacing>
    );
}