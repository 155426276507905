import Cookies from "js-cookie";

export const createCustomerFull = async (request, customerObj) => {
    const createCustomerFullRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customer/full`, {
        signal: request.signal,
        method: 'POST',
        body: JSON.stringify(customerObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    })

    if (!createCustomerFullRes.ok) {
        const error = await createCustomerFullRes.json();
        throw new Response(JSON.stringify(error), {status: createCustomerFullRes.status})
    }

    const createCustomerFullData = await createCustomerFullRes.json();

    return createCustomerFullData;
}

export const createCustomer = async (request, customerObj) => {
    const createCustomerRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customer`, {
        signal: request.signal,
        method: 'POST',
        body: JSON.stringify(customerObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    })

    if (!createCustomerRes.ok) {
        const error = await createCustomerRes.json();
        throw new Response(JSON.stringify(error), {status: createCustomerRes.status})
    }

    const createCustomerData = await createCustomerRes.json();

    return createCustomerData;
}

export const getCustomer = async (request) => {
    const getCustomerRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customer`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getCustomerRes.ok) {
        throw new Response("Response is not OK", {status: getCustomerRes.status});
    }

    const getCustomerData = await getCustomerRes.json();

    return getCustomerData;
}

export const updateCustomer = async (request, customerObj) => {
    const updateCustomerRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/customer`, {
        signal: request.signal,
        method: 'PUT',
        body: JSON.stringify(customerObj),
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!updateCustomerRes.ok) {
        throw new Response("Response is not OK", {status: updateCustomerRes.status});
    }

    const updateCustomerData = await updateCustomerRes.json();

    return updateCustomerData;
}

export const checkHasAccount = async (email) => {
    const checkHasAccountRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/has-account?email=${encodeURIComponent(email)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!checkHasAccountRes.ok) {
        throw new Response("Response is not OK", {status: checkHasAccountRes.status});
    }

    const checkHasAccountData = await checkHasAccountRes.json();

    return checkHasAccountData;
}