import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Subtitle1} from '../../components/Subtitles/Subtitles';
import {Headline2, Headline5} from '../../components/Headlines/Headlines';
import {Body} from '../../components/Texts/Texts';
import {Spacing} from '../../components/Spacing/Spacing';
import {OptionalButton} from '../../components/Buttons/Buttons';
import React, {useState} from 'react';
import {ReactComponent as PlayButton} from '../../assets/icons/play-button.svg';

const StyledContentItem = styled.section`
    border-bottom: 2px solid var(--color-primary);
`;

const Flex = styled.div`
    display: flex;
    flex-direction: ${props => props.$reversed ? 'row-reverse' : 'row'};
    gap: 80px;
    
    @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
`;

const ImageBox = styled.div`
    margin-top: auto;
    max-width: 500px;
`;

const ContentBox = styled.div`
    flex: 1 0 100%;
    padding-top: 30px;
    max-width: 500px;
    
    ${Subtitle1} { margin-bottom: 8px; }
    ${Headline2} { margin-bottom: 16px; }
    ${OptionalButton} { margin-bottom: 30px; }
    
    @media screen and (max-width: 1024px) {
        padding-top: 0;
        max-width: unset;
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
`;

const HorizontalBox = styled.div`
    margin-top: auto;
    max-width: 500px;

    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: var(--color-light);

    @supports not (aspect-ratio: 16 / 9) {
        overflow: hidden;
        padding-top: 56.25%;
    }
`;

const IframeWrapper = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;

    @supports not (aspect-ratio: 16 / 9) {
        position: absolute;
        inset: 0;
    }
    
    iframe {
        width: 100%;
        height: 100%;
    }
`;

const PreviewWrapper = styled.div`
    position: absolute;
    inset: 0; 
    cursor: pointer;
`;

const PreviewImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledPlayButton = styled(PlayButton)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ContentItem = ({data}) => {
    const [showCustomPreviewImage, setShowCustomPreviewImage] = useState(Boolean(data?.contentItem?.youTubeCode) && Boolean(data?.contentItem?.imageURL));

    if(!data.contentItem) { return null; }

    return (
        <Spacing>
            <StyledContentItem>
                <Container>
                    <Flex $reversed={data?.contentItem?.alignment === "left" ? false : true}>
                        {Boolean(data?.contentItem?.youTubeCode) ? (
                            <HorizontalBox>
                                <IframeWrapper dangerouslySetInnerHTML={{__html: data?.contentItem?.youTubeCode}} />
                                {showCustomPreviewImage &&
                                    <PreviewWrapper onClick={() => setShowCustomPreviewImage(false)}>
                                        <PreviewImage src={data?.contentItem?.imageURL} alt={data?.contentItem?.title} />
                                        <StyledPlayButton />
                                    </PreviewWrapper>
                                }
                            </HorizontalBox>
                        ) : (
                            <ImageBox>
                                <Image src={data?.contentItem?.imageURL} alt={data?.contentItem?.title} />
                            </ImageBox>
                        )}
                        <ContentBox>
                            <Subtitle1>{data?.contentItem?.supertitle}</Subtitle1>
                            <Headline2>{data?.contentItem?.title}</Headline2>
                            <Headline5>{data?.contentItem?.subtitle}</Headline5>
                            <Body>{data?.contentItem?.description}</Body>
                            <OptionalButton to={data?.contentItem?.url} linkType={data?.contentItem?.linkType} truncate={true}>{data?.contentItem?.linkText}</OptionalButton>
                        </ContentBox>
                    </Flex>
                </Container>
            </StyledContentItem>
        </Spacing>
    );
}