import {Headline3} from '../Headlines/Headlines';
import styled from 'styled-components';
import {Button} from '../Buttons/Buttons';
import {Subtitle2} from '../Subtitles/Subtitles';
import {Body} from '../Texts/Texts';
import {Await, Link, useLocation, useNavigation, useRouteLoaderData} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Spinner} from '../Spinner/Spinner';

const StyledOrderOverview = styled.section`
    flex-basis: 390px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    padding: 30px 25px;

    ${Button} { margin-top: 20px; max-width: unset; }
    
    @media screen and (max-width: 768px) {
        flex-basis: unset;
        
        ${Button} {
            display: none;
        }
    }
`;

const Heading = styled(Headline3)`
    border-bottom: 1px solid var(--color-primary-10);
    padding-bottom: 20px;
`;

const Product = styled.article`
    ${Subtitle2} { line-height: 1.55; }
    ${Body} { margin: 0; }

    &:not(:last-child) {
        border-bottom: 1px solid var(--color-primary-10);
        padding: 16px 12px;
    }
`;

const AuthorPrice = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3px;
`;

const Title = styled(Body)`
    max-width: 260px;
`;

const Details = styled.div`
    padding: 25px 12px;
    ${Body} { margin: 0; }
`;

const DetailsLine = styled.div` 
    display: flex;
    justify-content: space-between;
`;

const Total = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    background-color: var(--color-light);
    border-top: 2px solid var(--color-primary-10);
    ${Body} { margin: 0; }
`;

const Notice = styled(Body)`
    margin: 20px 0 0;
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NoticeSmall = styled(Body)`
    display: none;
    margin: 0;

    @media screen and (max-width: 768px) {
        display: block;
    }
`;

const StyledLink = styled(Link)`
    color: var(--color-primary);
    
    &:hover {
        text-decoration: none;
    }
`;

const Price = styled(Body)`
    flex-shrink: 0;
`;

export const OrderOverview = ({data, currentStep, paymentMethod}) => {
    const {state} = useNavigation();
    const { checkTokenData } = useRouteLoaderData("root");
    const { shippingCostsData } = useRouteLoaderData("orderLayout");

    const [authenticated, setAuthenticated] = useState(!!checkTokenData?.customer?.firstName)

    useEffect(() => {
        setAuthenticated(!!checkTokenData?.customer?.firstName)
    }, [checkTokenData])

    const {pathname} = useLocation();

    // Shipping call only works for deliveryType 4. When a new user get in the flow for the first time, there is no data?.price i.e. so the shippingCostsData call is still needed.
    const price = data?.deliveryType !== 1 ? (shippingCostsData?.price || (data?.price ?? "Fout")) : (data?.price ?? "Fout");
    const shipping = data?.deliveryType !== 1 ? (shippingCostsData?.shipping || (data?.shipping ?? 'Fout')) : (data?.shipping ?? 'Fout');
    const totalPrice = data?.deliveryType !== 1 ? (shippingCostsData?.totalPrice || (data?.totalPrice ?? 'Fout')) : (data?.totalPrice ?? 'Fout');

    return (
        <>
            <StyledOrderOverview>
                <Heading>Besteloverzicht</Heading>

                {data?.products.map((item, index) => (
                    <Product key={index}>
                        <AuthorPrice>
                            <Subtitle2>{item.author}</Subtitle2>
                            <Price $bold>{item.totalPrice ?? "Fout"}</Price>
                        </AuthorPrice>
                        <Title $bold>{item.quantity > 1 && `${item.quantity}x `}{item.title}</Title>
                        <Body>{item.secondhand ? "Tweedehands" : item.productForm}</Body>
                    </Product>
                ))}

                <Details>
                    <DetailsLine>
                        <Body>Subtotaal</Body>
                        {!pathname?.startsWith('/bestellen/betaling') ?
                            <React.Suspense fallback={<Spinner $extraSmall />}>
                                <Await resolve={shippingCostsData} errorElement={<Body>{data?.price ?? 'Fout'}</Body>}>
                                    {(shippingCosts) => (
                                        <Body>{price}</Body>
                                    )}
                                </Await>
                            </React.Suspense> :
                            <Body>{data?.price ?? 'Fout'}</Body>
                        }
                    </DetailsLine>
                    {data?.deliveryType !== 1 &&
                        <DetailsLine>
                            <Body>Verzendkosten</Body>
                            {!pathname?.startsWith('/bestellen/betaling') ?
                                <React.Suspense fallback={<Spinner $extraSmall/>}>
                                    <Await resolve={shippingCostsData} errorElement={<Body>{data?.shipping ?? 'Fout'}</Body>}>
                                        {(shippingCosts) => (
                                            <Body>{shipping}</Body>
                                        )}
                                    </Await>
                                </React.Suspense> :
                                <Body>{data?.shipping ?? 'Fout'}</Body>
                            }
                        </DetailsLine>
                    }
                </Details>

                <Total>
                    <Body $bold>Totaal</Body>
                    {!pathname?.startsWith('/bestellen/betaling') ?
                        <React.Suspense fallback={<Spinner $extraSmall/>}>
                            <Await resolve={shippingCostsData} errorElement={<Body $bold>{data?.totalPrice ?? 'Fout'}</Body>}>
                                {(shippingCosts) => (
                                    <Body $bold>{totalPrice}</Body>
                                )}
                            </Await>
                        </React.Suspense> :
                        <Body $bold>{data?.totalPrice ?? 'Fout'}</Body>
                    }
                </Total>

                {currentStep === 1 && <Button as="button" form="deliveryForm" type="submit" $variant="secondary" disabled={state !== 'idle'}>Volgende</Button>}
                {(currentStep === 2 && (pathname === '/bestellen/jouw-gegevens' || pathname === '/bestellen/jouw-gegevens/')) && <Button as="button" form="detailsForm" type="submit" name="intent" value={authenticated ? 'registeredUser' : 'guestUser'} $variant="secondary" disabled={state !== 'idle'}>Volgende</Button>}
                {currentStep === 3 && <Button as="button" form="paymentForm" type="submit" $variant="secondary" disabled={state !== 'idle'}>{paymentMethod === "1" ? 'Bestelling afronden' : 'Betalen'}</Button>}

                {currentStep === 3 && <Notice>Als je op '{paymentMethod === "1" ? 'Bestelling afronden' : 'Betalen'}' klikt ga je akkoord met de op jouw bestelling van toepassing zijnde <StyledLink to="/algemene-voorwaarden" target="_blank" rel="noreferrer">(algemene) voorwaarden</StyledLink> van De Kler.</Notice> }
            </StyledOrderOverview>
            {currentStep === 3 && <NoticeSmall $small>Als je op '{paymentMethod === "1" ? 'Bestelling afronden' : 'Betalen'}' klikt ga je akkoord met de op jouw bestelling van toepassing zijnde <StyledLink to="/algemene-voorwaarden" target="_blank" rel="noreferrer">(algemene) voorwaarden</StyledLink> van De Kler.</NoticeSmall> }
        </>
    );
}