import styled from 'styled-components';
import {ReactComponent as Dots} from '../../assets/icons/dots.svg';
import {Body, bodyTextStyles} from '../Texts/Texts';
import { Transition } from 'react-transition-group';
import {useEffect, useRef, useState} from 'react';
import {useClickInside} from '../../hooks/useClickInside';
import {Link, useFetcher} from 'react-router-dom';

const StyledEditPopup = styled.span`
    flex-shrink: 0;
    position: relative;
    display: inline-block;
`;

const StyledDots = styled(Dots)`
    path { fill: var(--color-primary); }
    cursor: pointer;
`;

const ExpandedEditPopup = styled.div`
    position: absolute;
    top: 20px;
    right: 0;
    width: max-content;
    max-width: 240px;
    z-index: 1;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
    box-shadow: var(--box-shadow);
    opacity: 0;
    transition: opacity .2s ease, top .2s ease;

    &.entering { opacity: 1; top: 30px; }
    &.entered { opacity: 1; top: 30px; }
    &.exiting { opacity: 0; top: 20px; }
    &.exited { opacity: 0; top: 20px; }
    
    ${Body} { margin: 0; }
`;

const Action = styled(Link)`
    ${bodyTextStyles};
    background: none;
    border: none;
    outline: none;
    padding: 8px 16px;
    width: 100%;
    text-align: left;
    cursor: pointer; 
    display: inline-block;
    text-decoration: none;
    
    &:hover {
        background-color: var(--color-light);
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid var(--color-primary-10);    
    }
`;

export const EditPopup = ({data, close, allowDelete}) => {
    const insideRef = useRef(null);
    const editPopupNodeRef = useRef(null);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const inside = useClickInside(insideRef);
    const fetcher = useFetcher();

    useEffect(() => {
        if(!inside) { setShowEditPopup(false); }
    }, [inside]);

    const handleDefaultButton = async (id) => {
        const formData = new FormData();
        formData.append("id", id);
        fetcher.submit(formData, { method: 'PUT', action: '/bestellen/jouw-gegevens/adres-toevoegen' })
    }

    const handleDeleteButton = async (id) => {
        const formData = new FormData();
        formData.append("id", id);
        fetcher.submit(formData, { method: 'DELETE', action: '/bestellen/jouw-gegevens/adres-toevoegen' })
    }

    useEffect(() => {
        if(fetcher.data) {
            setShowEditPopup(!showEditPopup);
        }
        // eslint-disable-next-line
    }, [fetcher.data])

    return (
        <StyledEditPopup ref={insideRef}>
            <StyledDots onClick={(e) => { e.stopPropagation(); setShowEditPopup(!showEditPopup) }} />
            <Transition nodeRef={editPopupNodeRef} in={showEditPopup} timeout={200} unmountOnExit>
                {state => (
                    <ExpandedEditPopup ref={editPopupNodeRef} className={state} onClick={(e) => { e.stopPropagation(); }}>
                        <Action to={`/bestellen/jouw-gegevens/adres-bewerken/${data?.id}`} onClick={close}>Bewerken</Action>
                        {!data?.default && <Action as="button" onClick={() => handleDefaultButton(data?.id)}>Maak standaard bezorgadres</Action> }
                        {allowDelete && <Action as="button" onClick={() => handleDeleteButton(data?.id)}>Verwijderen</Action>}
                    </ExpandedEditPopup>
                )}
            </Transition>
        </StyledEditPopup>
    );
}