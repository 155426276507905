import styled, {css} from 'styled-components';
import {Link, useLoaderData} from 'react-router-dom';
import {ReactComponent as LogoHaasbeek} from '../../assets/logos/logo-haasbeek.svg';
import {ReactComponent as LogoKniphorst} from '../../assets/logos/logo-kniphorst.svg';
import {ReactComponent as LogoNovita} from '../../assets/logos/logo-novita.svg';
import {ReactComponent as LogoRomijn} from '../../assets/logos/logo-romijn.svg';
import {Container} from '../Container/Container';
import {MenuItem} from '../MenuItem/MenuItem';

const StyledNavBar = styled.nav`
    background-color: var(--color-navbar);
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MenuItems = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 14px 0 0 0;
`;

const BookStores = styled.div`
    display: flex;
    gap: 30px;
    
    @media screen and (max-width: 1280px) {
        gap: 10px;
    }
`;

const bookStoreLogoStyles = css`
    width: auto;
    height: 25px;
    path { fill: var(--color-primary); }
`;

const StyledLogoHaasbeek = styled(LogoHaasbeek)` ${bookStoreLogoStyles} `;
const StyledLogoKniphorst = styled(LogoKniphorst)` ${bookStoreLogoStyles} `;
const StyledLogoNovita = styled(LogoNovita)` ${bookStoreLogoStyles} `;
const StyledLogoRomijn = styled(LogoRomijn)` ${bookStoreLogoStyles} `;

export const NavBar = () => {
    const { mainNav, shopNav } = useLoaderData();

    return (
        <StyledNavBar>
            <Container>
                <Flex>
                    <MenuItems>
                        {mainNav.map(item => (
                            <MenuItem key={item.id} to={item.url} data={item.columns}>{item.title}</MenuItem>
                        ))}
                    </MenuItems>
                    <BookStores>
                        {shopNav.map(item => (
                            <Link key={item?.id} to={item?.url}>
                                {item?.title === "Haasbeek" && <StyledLogoHaasbeek />}
                                {item?.title === "Kniphorst" && <StyledLogoKniphorst />}
                                {item?.title === "Novita" && <StyledLogoNovita />}
                                {item?.title === "Romijn" && <StyledLogoRomijn />}
                            </Link>
                        ))}
                    </BookStores>
                </Flex>
            </Container>
        </StyledNavBar>
    );
}