import styled, {css} from 'styled-components';
import {ReactComponent as LogoDeKler} from '../../assets/logos/logo-dekler.svg';
import {ReactComponent as FavoriteIcon} from '../../assets/icons/favorite-outline.svg';
import {ReactComponent as ShoppingCartIcon} from '../../assets/icons/shopping-cart.svg';
import {ReactComponent as PersonIcon} from '../../assets/icons/person.svg';
import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {Link, useLoaderData, useLocation, useRouteLoaderData} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Accordion} from '../../components/Accordion/Accordion';
import {MobileMenuSubLink} from '../../components/MobileMenuSubLink/MobileMenuSubLink';
import {IconButton} from '../../components/Buttons/Buttons';
import {SearchBar} from '../../components/SearchBar/SearchBar';
import {useToggleMenu} from '../../hooks/useToggleMenu';
import {bodyTextStyles} from '../../components/Texts/Texts';
import {useSideDrawerContext} from '../../contexts/SideDrawerContext';

const StyledMobileMenu = styled.header`
    @media screen and (min-width: 1025px) {
        display: none;
    }
`;

const TopBar = styled.nav`
    background-color: var(--color-secondary);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 98;
`;

const TopBarInner = styled.section`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 18px 16px 17px;
`;

const TopBarSearch = styled.section`
    //padding: 7px 20px 8px;
    padding: 0 20px 20px;
`;

const LogoWrapper = styled(Link)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

const Icons = styled.div`
    margin-left: auto;
`;

const iconStyles = css`
    width: 30px;
    height: 30px;
    vertical-align: middle;
    path { fill: var(--color-white); }
`;

const HamburgerButton = styled.button`
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
    
    svg {
        ${iconStyles}
    }
`;

const ButtonIcon = styled(Link)`
    display: inline-block;
    background: none;
    outline: none;
    border: none;
    margin: 0;
    font-size: initial;
    padding: 0;
    ${props => props.$relative && 'position: relative;'}
    svg { ${iconStyles} }
    
    &:not(:last-child) {
        margin-right: 5px;
    }
`;

const Amount = styled.span`
    position: absolute;
    ${bodyTextStyles};
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 12px;
    }
`;

const HamburgerMenu = styled.section`
    position: fixed;
    top: 0;
    right: ${props => props.$isOpen ? '0' : '100%'};
    background-color: var(--color-white);
    height: 100vh;
    width: 100%;
    z-index: 99;
    transition: right .2s ease;
`;

const HamburgerMenuInner = styled.div`
    overflow-y: auto;
    padding: 15px 20px 190px;
    height: 100%;
`;

const HamburgerTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledLogoDeKler = styled(LogoDeKler)`
    width: 65px;
    height: auto;
    
    ${props => props.$white && `path { fill: var(--color-white) }`}
`;

const SubLinks = styled.section`
    margin-top: 30px;
`;

export const MobileMenu = () => {
    const { mainNav, topNav }  = useLoaderData();
    const { checkTokenData } = useRouteLoaderData("root");
    const {pathname} = useLocation();

    const [firstName, setFirstName] = useState(checkTokenData?.customer?.firstName ?? null);
    const [productsQuantity, setProductsQuantity] = useState(checkTokenData?.cart?.productsQuantity ?? null);

    useEffect(() => {
        setFirstName(checkTokenData?.customer?.firstName ?? null);
        setProductsQuantity(checkTokenData?.cart?.productsQuantity ?? null);
    }, [checkTokenData]);

    const {state, openDrawer, closeDrawer} = useSideDrawerContext();

    // Hamburger menu
    const { isOpen, openMenu, closeMenu } = useToggleMenu();
    const [activeId, setActiveId] = useState(null);

    const closeMobileMenu = () => {
        closeMenu();
        setActiveId(null);
    }

    const onShow = (item) => {
        setActiveId(activeId === item.id ? null : item.id)
    }

    return (
        <StyledMobileMenu>
            <TopBar>
                <TopBarInner>
                    <HamburgerButton onClick={openMenu}>
                        <MenuIcon />
                    </HamburgerButton>
                    <LogoWrapper to='/'>
                        <StyledLogoDeKler $white />
                    </LogoWrapper>
                    <Icons>
                        { firstName
                            ? <ButtonIcon as="button" onClick={state.isAccountOpen ? () => closeDrawer('Account') : () => openDrawer('Account')}><PersonIcon /></ButtonIcon>
                            : <ButtonIcon as="button" onClick={state.isGuestOpen ? () => closeDrawer('Guest') : () => openDrawer('Guest')}><PersonIcon /></ButtonIcon>
                        }
                        <ButtonIcon as="button" onClick={state.isWishlistOpen ? () => closeDrawer('Wishlist') : () => openDrawer('Wishlist')}><FavoriteIcon /></ButtonIcon>
                        <ButtonIcon as="button" onClick={state.isCartOpen ? () => closeDrawer('Cart') : () => openDrawer('Cart')} $relative>
                            <ShoppingCartIcon />
                            {(productsQuantity && !pathname.startsWith('/bestelling-gelukt')) && <Amount $white>{productsQuantity > 9 ? <>9<span>+</span></> : productsQuantity}</Amount>}
                        </ButtonIcon>
                    </Icons>
                </TopBarInner>
                <TopBarSearch>
                    <SearchBar />
                </TopBarSearch>
            </TopBar>
            <HamburgerMenu $isOpen={isOpen}>
                <HamburgerMenuInner>
                    <HamburgerTop>
                        <Link to='/' onClick={closeMobileMenu}>
                            <StyledLogoDeKler />
                        </Link>
                        <IconButton as="button" onClick={closeMobileMenu} $small><CloseIcon /></IconButton>
                    </HamburgerTop>

                    {mainNav.map(item => (
                        <Accordion key={item.id} data={item} toggleMenu={closeMobileMenu} isActive={activeId === item.id} setActiveId={setActiveId} onShow={() => onShow(item)}/>
                    ))}

                    <SubLinks>
                        {topNav.map(item => (
                            <MobileMenuSubLink key={item.id} data={item} toggleMenu={closeMobileMenu} isActive={activeId === item.id} setActiveId={setActiveId} onShow={() => onShow(item)} />
                        ))}
                    </SubLinks>
                </HamburgerMenuInner>
            </HamburgerMenu>
        </StyledMobileMenu>
    );
}