import {sendCustomEvent} from "./dataLayer";

const BOOKS_OVERVIEW_EVENT = "books_overview";

export const enableFilterEvent = (category, label) => {
    sendCustomEvent(BOOKS_OVERVIEW_EVENT, category, "Enable filter", label);
}

export const disableFilterEvent = (category, label) => {
    sendCustomEvent(BOOKS_OVERVIEW_EVENT, category, "Disable filter", label);
}

export const searchEvent = (label) => {
    sendCustomEvent(BOOKS_OVERVIEW_EVENT, "Search", "Search in books overview", label);
}