import {Body} from "../Texts/Texts";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Headline5} from '../Headlines/Headlines';
import React from 'react';
import {ReactComponent as EmptyShelf} from '../../assets/images/empty-shelf.svg';
import {Spinner} from '../Spinner/Spinner';

const MobileSuggestionsBox = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 58px;
    width: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border: var(--color-primary-10) solid 1px;
    overflow-y:scroll;
    ${props => props.$loading && 'min-height: 79px;'};
    max-height: calc(100dvh - 135px); // new viewport units (lvh, svh, dvh)

    @media screen and (min-width: 1025px) {
        display: none;
    }
`

const MobileStyledLink = styled(Link)`
    text-decoration: none;
    
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-primary-10);
    }
`

const DesktopSuggestionsBox = styled.div`
    position: absolute;
    z-index: 1;
    top: 60px;
    width: 100%;
    padding: ${props => props.$menu ? '10px' : '20px'};
    background-color: var(--color-white);
    border: var(--color-primary-10) solid 1px;
    ${props => props.$loading && 'min-height: 79px;'};
    
    @media screen and (max-width: 1024px) {
        display: none;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
    display: block;
    
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-primary-10);
    }

    &:hover {
        text-decoration: underline;
        text-decoration-color: var(--color-primary);
    }
`

const GridItem = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 10px;
`

const EmptyBooks = styled.article`
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    ${Body} { margin: 8px 0; }
`;

const TooFewCharacters = styled.div`
    text-align: center;
`;

const StyledEmptyShelf = styled(EmptyShelf)`
    margin-bottom: 20px;
    width: 100%;
`;

const LoadingOverlay = styled.div`
    background-color: rgba(255, 255, 255, .8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const SpinnerWrapper = styled.div`
    padding: 16px 0;
    text-align: center;
`;

export const SearchSuggestions = ({data, empty, loading, tooFewCharacters, ...props}) => {
    if(data === null && !empty && !loading && !tooFewCharacters) { return null; }

    return (
        <>
            <MobileSuggestionsBox $loading={loading}>
                {tooFewCharacters &&
                    <TooFewCharacters>
                        <Body $italic>Zoek op minimaal 3 karakters</Body>
                    </TooFewCharacters>
                }

                {(!tooFewCharacters && data?.length > 0) && data?.map((product) => (
                    <MobileStyledLink key={product?.isbn} to={product?.url}>
                        <Body $truncated $clamp={2} $small><strong>{product?.title + " - "}</strong>{product.author + " - " + product?.productForm}</Body>
                    </MobileStyledLink>
                ))}

                {(!tooFewCharacters && empty) &&
                    <EmptyBooks>
                        <StyledEmptyShelf />
                        <Headline5>Er zijn geen boeken gevonden</Headline5>
                        <Body>Probeer een andere zoekopdracht</Body>
                    </EmptyBooks>
                }

                {(!tooFewCharacters && loading) &&
                    <LoadingOverlay>
                        <SpinnerWrapper>
                            <Spinner $medium />
                        </SpinnerWrapper>
                    </LoadingOverlay>
                }
            </MobileSuggestionsBox>

            <DesktopSuggestionsBox $loading={loading} {...props}>
                {tooFewCharacters &&
                    <TooFewCharacters>
                        <Body $italic>Zoek op minimaal 3 karakters</Body>
                    </TooFewCharacters>
                }

                {(!tooFewCharacters && data?.length > 0) && data?.map((product) => (
                    <StyledLink key={product?.isbn} to={product?.url}>
                        <GridItem>
                            <Body $truncated $clamp={2} $bold $small>{product?.title}</Body>
                            <Body $truncated $clamp={2} $small>{product?.author}</Body>
                            <Body $truncated $clamp={2} $small>{product?.productForm}</Body>
                        </GridItem>
                    </StyledLink>
                ))}

                {(!tooFewCharacters && empty) &&
                    <EmptyBooks>
                        <StyledEmptyShelf />
                        <Headline5>Er zijn geen boeken gevonden</Headline5>
                        <Body>Probeer een andere zoekopdracht</Body>
                    </EmptyBooks>
                }

                {(!tooFewCharacters && loading) &&
                    <LoadingOverlay>
                        <SpinnerWrapper>
                            <Spinner $medium />
                        </SpinnerWrapper>
                    </LoadingOverlay>
                }
            </DesktopSuggestionsBox>
        </>
    )
}