const pushToDataLayer = (event, eventData) => {
    if(!window.dataLayer) return;

    const dataLayer = window.dataLayer || [];

    dataLayer.push({
        event,
        ...eventData,
    });
};

export default pushToDataLayer;

export const sendCustomEvent = (name, category, action, label) => {
    pushToDataLayer(name, {
        category: category,
        action: action,
        label: label,
    });
};

const eventsWithIndexParam = ['view_item_list', 'select_item'];
const eventsThatUseOwnQuantity = ['view_cart', 'begin_checkout', 'add_shipping_info', 'add_payment_info'];

export const sendEcommerceEvent = (event, products, additionalData = {}, quantity = 1) => {
    const obj = {
        ...additionalData.ecommerce,
        items: products.map((product, index) => ({
            item_name: product.title,
            item_id: product.secondhand ? product.eanf.toString() : product.isbn.toString(),
            price: product.originalPrice ?? "0",
            item_brand: product.author,
            item_category: product.secondhand ? "Tweedehands" : "Nieuw",
            item_variant: product.productForm,
            quantity: eventsThatUseOwnQuantity.includes(event) ? product.quantity : quantity.toString(), // Quantity is default 1 for each item. The quantity can be changed using the optional argument for the events add_to_cart and remove_from_cart, where the quantity depends on user action. For the eventsThatUseOwnQuantity it uses the product's quantity.
            ...(eventsWithIndexParam.includes(event) && { index: index + 1 }),
            ...additionalData.item,
        })),
    };

    pushToDataLayer(event, { ecommerce: obj });
};