import React from "react";
import {Await, defer, useLoaderData} from 'react-router-dom';
import {Seo} from '../../components/Seo/Seo';
import {AgendaDetailHeader} from '../../sections/AgendaDetailHeader/AgendaDetailHeader';
import {Blocks} from '../../components/Blocks/Blocks';

export async function agendaDetailLoader({request, params}) {
    const dataPromise = fetch(`${process.env.REACT_APP_API_BASE_URL}/agenda/${params.slug}`, {
        signal: request.signal
    }).then((res) => {
        // Handle if response is not ok, bad request so probably not found
        if (!res.ok) { throw new Response("Network response was not OK, page is probably Not Found", { status: 404 }); }

        return res.json();
    });

    return defer({content: dataPromise})
}

export default function AgendaDetail() {
    const data = useLoaderData();

    return (
        <React.Suspense>
            <Await resolve={data.content}>
                {(content) => (
                    <div key={content.agenda?.id}>
                        <Seo metaTitle={content.agenda?.title} url={`/${content.agenda?.url}`} />

                        <AgendaDetailHeader data={content} />

                        <Blocks data={content.blocks} />
                    </div>
                )}
            </Await>
        </React.Suspense>
    );
}