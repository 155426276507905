import {ProductItem} from '../../components/ProductItem/ProductItem';
import {Container} from '../../components/Container/Container';
import {Breadcrumbs} from '../../sections/Breadcrumbs/Breadcrumbs';
import styled from 'styled-components';
import {Headline5} from '../../components/Headlines/Headlines';
import {Seo} from '../../components/Seo/Seo';
import React, {useEffect, useState} from 'react';
import {FiltersAside} from '../../components/FiltersAside/FiltersAside';
import {Await, defer, Outlet, redirect, useLoaderData, useLocation, useNavigation, useSearchParams} from 'react-router-dom';
import { useImmer } from 'use-immer';
import qs from 'qs';
import {Spinner} from '../../components/Spinner/Spinner';
import {IconButton} from "../../components/Buttons/Buttons";
import {ReactComponent as ChevronLeft} from "../../assets/icons/chevron-left.svg";
import {ReactComponent as ChevronRight} from "../../assets/icons/chevron-right.svg";
import {ReactComponent as EmptyShelf} from '../../assets/images/empty-shelf.svg';
import {Body} from '../../components/Texts/Texts';
import {useFetchData} from '../../hooks/useFetchData';
import {sendEcommerceEvent} from '../../events/dataLayer';
import {PRODUCT_OVERVIEW_LIST} from '../../events/constants';

const StyledBreadcrumbs = styled.section`
    padding-top: 30px;
    padding-bottom: 60px;
    
    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
    }
`;

const Flex = styled.section`
    display: flex;
    gap: 130px;
    
    @media screen and (max-width: 1024px) {
        gap: 60px;
    }
    
    @media screen and (max-width: 768px) {
        gap: 30px;
        flex-direction: column;
    }
`;

const SideBarBox = styled.aside`
    flex: 1 1 285px;
    padding-bottom: 90px;
    
    @media screen and (max-width: 768px) {
        flex-basis: 0;
        padding-bottom: 0;
    }
`;

const ProductGridBox = styled.section`
    flex: 1 2 100%;
`;

const PaginationInfo = styled(Headline5)`
    margin-bottom: 20px;
`;

const ProductGrid = styled.article`
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 475px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 320px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const PaginationBox = styled.div`
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 80px 0 240px;
    
    @media screen and (max-width: 1024px) {
        margin: 80px 0 160px;
    }

    @media screen and (max-width: 1024px) {
        margin: 80px 0 100px;
    }
    
    @media screen and (max-width: 525px) {
        display: none;
    }
`;

const MobilePaginationBox = styled(PaginationBox)`
    display: none;

    @media screen and (max-width: 525px) {
        display: flex;
    }
`;

const PaginationControl = styled(IconButton)`
    box-shadow: inset 0 0 0 1px var(--color-primary-10);
    
    &:disabled {
        opacity: 0.2;

        &:hover {
            background-color: transparent;
            color: var(--color-primary);
            path { fill: var(--color-primary); }
        }
    }
`;

const EmptyBooks = styled.article`
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    ${Body} { margin: 8px 0; }
`;

const StyledEmptyShelf = styled(EmptyShelf)`
    margin-bottom: 20px;
    width: 100%;
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

// Parse the parameters (In App.js defined as '*') to a QueryString using the 'qs' library
// to get the correct API endpoint when navigating to specific (filtered) routes.
function parseParametersFromUrl(params) {
    const parametersFromUrl = params;

    if(parametersFromUrl) {
        const keyValuePairs = parametersFromUrl.split("/");

        const queryObject = keyValuePairs.reduce((acc, keyValuePair) => {
            const [key, valueString] = keyValuePair.split(":");
            if(!valueString) { return redirect('/boeken') } // Check if there is a valueString, otherwise redirect
            acc[key] = valueString.split(",");

            return acc;
        }, {});

        return queryObject;
    }
}

// Initial loader for the '/boeken' page. It fetches the '/filters' endpoint to know which
// filters are active. This loader gets called when you first load the '/boeken' page.
export async function booksLoader({request, params}) {
    const parametersFromUrl = params["*"];
    const parsedParameters = parseParametersFromUrl(parametersFromUrl);

    const queryString = qs.stringify({filters: parsedParameters}, { encodeValuesOnly: true });

    let endpoint;
    if(queryString) {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/filters?${queryString}`
    } else {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/filters`
    }

    const filtersRes = fetch(endpoint, { signal: request.signal}).then((res) => res.json());

    return defer({filters: filtersRes}) // Deferred
}

// It should not revalidate when the currentUrl startsWith /boeken to prevent extra fetching
export function shouldRevalidateBooks({currentUrl}) {
    return !currentUrl.pathname.startsWith('/boeken');
}

export default function Books() {
    const data = useLoaderData();

    return (
        <>
            <Seo metaTitle="Boeken" />

            <Container>
                <StyledBreadcrumbs>
                    <React.Suspense>
                        <Await resolve={data.filters}>
                            {(filters) => (
                                <Breadcrumbs data={filters.breadcrumbs} />
                            )}
                        </Await>
                    </React.Suspense>
                </StyledBreadcrumbs>

                <Flex>
                    <SideBarBox>
                        <React.Suspense>
                            <Await resolve={data.filters}>
                                {(filters) => (
                                    <FiltersAside data={filters.filters} />
                                )}
                            </Await>
                        </React.Suspense>
                    </SideBarBox>
                    <ProductGridBox>
                        <Outlet />
                    </ProductGridBox>
                </Flex>
            </Container>
        </>
    );
}

// Grid that will be placed inside Books' Outlet
let abortController;
export async function gridLoader({request, params}) {
    // If there is a previous fetch pending, cancel it by calling abort() on the AbortController.
    if (abortController) {
        abortController.abort();
    }

    // Create a new instance of URLSearchParams with a decoded URL
    const queryParameters = new URLSearchParams(new URL(decodeURIComponent(request.url)).search)

    // Use the get() method to retrieve the value of the query string parameters
    const paginationPageValue = queryParameters.get('pagina');
    const searchQueryValue = queryParameters.get('zoeken');

    // Create a new AbortController instance every time the gridLoader gets called (when navigating
    // to 'boeken/*' (filters)).
    abortController = new AbortController();

    const parametersFromUrl = params["*"];
    const parsedParameters = parseParametersFromUrl(parametersFromUrl);

    const queryString = qs.stringify({
        filters: parsedParameters,
        pagination: {
            page: paginationPageValue,
        },
        search: {
            query: searchQueryValue,
        }
    }, { encodeValuesOnly: true, skipNulls: true });

    let endpoint;
    if(queryString) {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/product-overview?${queryString}`
    } else {
        endpoint = `${process.env.REACT_APP_API_BASE_URL}/product-overview`
    }

    // Temporarily disabled AbortController to prevent error when canceling call
    // const productsRes = fetch(endpoint, { signal: abortController.signal}).then((res) => res.json());
    const productsRes = fetch(endpoint).then((res) => res.json());

    return defer({products: productsRes}) // Deferred
}

// It should not revalidate when there is a formMethod (when triggering an action, it has a formMethod).
export function shouldRevalidateGrid({currentUrl, nextUrl, formMethod, defaultShouldRevalidate}) {
    let shouldRevalidate = defaultShouldRevalidate;

    if (Boolean(formMethod)) { shouldRevalidate = false; } // has form method (i.e. when adding to cart or wishlist)
    if ((currentUrl?.pathname === nextUrl?.pathname) && (currentUrl?.search === nextUrl?.search)) { shouldRevalidate = false; } // searching with the same params

    return shouldRevalidate;
}

export function Grid() {
    const data = useLoaderData();

    const [fetchedProducts, updateFetchedProducts] = useImmer(null);
    const [totalPages, setTotalPages] = useState(10);
    const [listToCheckSecondHand, setListToCheckSecondHand] = useState(null);

    // Data is a promise, so chain to set the fetchedProducts and totalPages
    useEffect(() => {
        setListToCheckSecondHand(null); // Setting to null, to prevent listToCheckSecondHand to stay the same

        data.products.then((products) => {
            updateFetchedProducts(products.products);
            setListToCheckSecondHand(products?.products?.filter(product => product.secondhand === false && product.eBook === false).map(product => product.isbn).join(","));
            setTotalPages(products.numberOfPages <= 10 ? products.numberOfPages : 10);

            // If there are products, send a view_item_list event
            if(products.products.length >= 1) {
                sendEcommerceEvent('view_item_list', products.products, {
                    item: {
                        item_list_name: PRODUCT_OVERVIEW_LIST.name,
                        item_list_id: PRODUCT_OVERVIEW_LIST.id,
                    },
                });
            }

        });
    }, [data, updateFetchedProducts])

    // Each time secondHandData changes, update the fetchedProducts to add secondHand boolean and price
    const [secondHandData] = useFetchData((fetchedProducts && listToCheckSecondHand) && `product-overview-secondhand?isbn=${listToCheckSecondHand}`, true);
    useEffect(() => {
        if(secondHandData?.length) {
            updateFetchedProducts(draft => {
                secondHandData.forEach(secondHandItem => {
                    const fetchedProductIndex = draft.findIndex(product => product.isbn === secondHandItem.isbn);

                    if (fetchedProductIndex !== -1) {
                        draft[fetchedProductIndex].price = `vanaf ${secondHandItem.price}`;
                        draft[fetchedProductIndex].secondhand = true;
                        draft[fetchedProductIndex].alsoSecondHand = true;
                    }
                });
            });
        }
    }, [secondHandData, updateFetchedProducts]);

    // Pagination
    const navigation = useNavigation();
    let [searchParams, setSearchParams] = useSearchParams();
    const [currPage, setCurrPage] = useState(parseInt(searchParams.get('pagina')) || 1);

    // Each time the search/query params change, set current page to 1
    const { search } = useLocation();
    useEffect(() => {
        setCurrPage( parseInt(searchParams.get('pagina')) || 1);
        // eslint-disable-next-line
    }, [search])

    const handlePagination = (action, index) => {
        switch (action) {
            case 'previous':
                if (currPage > 1) {
                    let newPage = currPage - 1;
                    setCurrPage(newPage);
                    let searchTermFromQuery = searchParams.get('zoeken');
                    let newParams = searchTermFromQuery ? { zoeken: searchTermFromQuery, pagina: newPage } : { pagina: newPage };
                    setSearchParams(newParams)
                }
                break;
            case 'next':
                if(currPage < totalPages) {
                    let newPage = currPage + 1;
                    setCurrPage(newPage);
                    let searchTermFromQuery = searchParams.get('zoeken');
                    let newParams = searchTermFromQuery ? { zoeken: searchTermFromQuery, pagina: newPage } : { pagina: newPage };
                    setSearchParams(newParams)
                }
                break;
            case 'direct':
            default:
                if(index <= totalPages && index !== currPage) {
                    let newPage = index;
                    setCurrPage(newPage);
                    let searchTermFromQuery = searchParams.get('zoeken');
                    let newParams = searchTermFromQuery ? { zoeken: searchTermFromQuery, pagina: newPage } : { pagina: newPage };
                    setSearchParams(newParams)
                }
        }
    }

    return (
        <>
            <React.Suspense fallback={<Center><Spinner /></Center>}>
                <Await resolve={data.products}>
                    {(productsData) => (
                        <>
                            {productsData.products.length > 0
                                ?
                                <>
                                    <PaginationInfo>{productsData?.productTotalTitle}</PaginationInfo>
                                    <ProductGrid>
                                        {fetchedProducts?.map((item) => (
                                            <ProductItem
                                                key={item.isbn}
                                                data={item}
                                                isbn={item.isbn}
                                                eanf={item.eanf}
                                                title={item.title}
                                                author={item.author}
                                                price={item.price}
                                                imageUrl={item.image}
                                                url={item.url}
                                                flag={item.secondhand ? "secondHand" : item.eBook ? "ebook" : null}
                                                alsoSecondHand={item.alsoSecondHand}
                                                location="product-overview"
                                                targetList={PRODUCT_OVERVIEW_LIST}
                                            />
                                        ))}
                                    </ProductGrid>
                                    <PaginationBox>
                                        {totalPages > 1 && (
                                            <>
                                                <PaginationControl onClick={() => handlePagination('previous')} $small $variant='outline' as="button" disabled={navigation.state === "loading" || currPage <= 1}><ChevronLeft/></PaginationControl>
                                                    {[...Array(totalPages)].map((item, index) => (
                                                        <IconButton key={index} as="button" onClick={() => handlePagination('direct', index + 1)} $small $variant={index + 1 === currPage ? 'primary' : 'inactive'} disabled={navigation.state === "loading"}>{index + 1}</IconButton>
                                                    ))}
                                                <PaginationControl onClick={() => handlePagination('next')} $small $variant='outline' as="button" disabled={navigation.state === "loading" || currPage >= totalPages}><ChevronRight/></PaginationControl>
                                            </>
                                        )}
                                    </PaginationBox>
                                    <MobilePaginationBox>
                                        {totalPages > 1 && (
                                            <>
                                                <PaginationControl onClick={() => handlePagination('previous')} $small $variant='outline' as="button" disabled={navigation.state === "loading" || currPage <= 1}><ChevronLeft/></PaginationControl>
                                                {[...Array(totalPages >= 5 ? 5 : totalPages)].map((item, index) => (
                                                    <IconButton key={index} as="button" onClick={() => handlePagination('direct', index + 1)} $small $variant={index + 1 === currPage ? 'primary' : 'inactive'} disabled={navigation.state === "loading"}>{index + 1}</IconButton>
                                                ))}
                                                <PaginationControl onClick={() => handlePagination('next')} $small $variant='outline' as="button" disabled={navigation.state === "loading" || currPage >= (totalPages >= 5 ? 5 : totalPages)}><ChevronRight/></PaginationControl>
                                            </>
                                        )}
                                    </MobilePaginationBox>
                                </>
                                :
                                <EmptyBooks>
                                    <StyledEmptyShelf />
                                    <Headline5>Er zijn geen boeken gevonden</Headline5>
                                    <Body>Probeer een andere zoekopdracht of wijzig de actieve filters</Body>
                                </EmptyBooks>
                            }
                        </>
                    )}
                </Await>
            </React.Suspense>
        </>
    );
}