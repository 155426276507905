import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {DirectToLink} from '../../components/DirectToLink/DirectToLink';
import {Headline2} from '../../components/Headlines/Headlines';
import {Spacing} from '../../components/Spacing/Spacing';

const StyledDirectToLinks = styled.section`
    ${Headline2} { margin-bottom: 30px; }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${props => props.$columns ? `repeat(${props.$columns}, 1fr)` : '1fr'};
    column-gap: 30px;
    row-gap: 10px;
    
    @media screen and (max-width: 1024px) {
        grid-template-columns: ${props => props.$columns > 1 ? `repeat(2, 1fr)` : '1fr'};
    }
    
    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const DirectToLinks = ({data}) => {
    if(data?.directToLinkContent === null) { return null; }

    return (
        <Spacing>
            <StyledDirectToLinks>
                <Container>
                    <Headline2>{data?.directToLinkContent?.title}</Headline2>
                    <Grid $columns={data?.directToLinkContent?.columns}>

                        {data?.directToLinkContent?.links.map((item) => {
                            if(!item.url) return null;

                            if(item.linkType === 'absolute'){
                                return <DirectToLink key={item.id} as="a" href={item.url} target={item.newWindow ? "_blank" : "_self"} icon={item.imageURL} imageType={item.imageType} variant={item.color}>{item.linkText}</DirectToLink>
                            } else {
                                return <DirectToLink key={item.id} to={item.url} icon={item.imageURL} imageType={item.imageType} variant={item.color}>{item.linkText}</DirectToLink>
                            }
                        })}
                    </Grid>
                </Container>
            </StyledDirectToLinks>
        </Spacing>
    );
}