import styled from 'styled-components';
import {Body, buttonTextStyles} from '../Texts/Texts';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {useRef, useState} from 'react';
import check from '../../assets/icons/check.svg';
import {disableFilterEvent, enableFilterEvent} from '../../events/books';

const StyledAccordion = styled.section`
    user-select: none;
    width: 100%;
    
    &:first-of-type {
        margin-top: 30px;
    }
    
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-primary);
    padding: 14px 15px;
    cursor: pointer;
    
    @media screen and (max-width: 768px) {
        background-color: var(--color-primary-10);
    }
`;

const Title = styled.p`
    ${buttonTextStyles};
    margin: 0;
    color: var(--color-white);

    @media screen and (max-width: 768px) {
        color: var(--color-primary);
    }
`;

const StyledChevron = styled(Chevron)`
    ${props => props.$open && `transform: rotate(180deg)`};
    path { fill: var(--color-white); }

    @media screen and (max-width: 768px) {
        path { fill: var(--color-primary); }
    }
`;

const Content = styled.article`
    &.closed {
        display: none;
    }
    
    margin-top: 10px;
    padding: 20px;
    background-color: var(--color-primary-5);

    @media screen and (max-width: 768px) {
        background-color: var(--color-white);
    }
`;

const Label = styled(Body).attrs({
    as: "label",
    $small: true,
})`
    cursor: pointer;
    line-height: 1.35;
`;

const Checkbox = styled.label`
    display: flex;
    cursor: pointer;
    
    &:not(:last-of-type) {
        margin-bottom: 15px;
    }
`;

const CheckboxInput = styled.input`
    flex-shrink: 0;
    width: 17px;
    height: 17px;
    background-color: var(--color-primary-10);
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 12px 0 0;

    &:checked {
        border: 0;
        background-image: url(${check});
        background-color: var(--color-tertiary);
        background-size: 75% 65%;
    }
    
    &:checked + ${Label} {
        font-weight: 700;
    }
`;

export const FiltersAccordion = ({data, toggleFn}) => {
    const ref = useRef();
    const [open, setOpen] = useState(true);

    const handleAccordion = () => {
        setOpen(!open);
    }

    const handleInputChange = (dataId, itemId, checkedBool) => {
        toggleFn(dataId, itemId, checkedBool);
    }

    return (
         <StyledAccordion>
            <Header onClick={handleAccordion}>
                <Title ref={ref} $open={open}>{data.title}</Title>
                <StyledChevron $open={open} />
            </Header>
            <Content className={open ? 'open' : 'closed'}>
                <div>
                    {data.filterItems.map(item => (
                        <Checkbox key={item.id} htmlFor={item.title}>
                            <CheckboxInput className={item.active} type='checkbox' id={item.title} name={item.title} value={item.titleKey} checked={item.active ?? false} onChange={(e) => { handleInputChange(data.id, item.id, e.target.checked); e.target.checked ? enableFilterEvent(data.title, item.title) : disableFilterEvent(data.title, item.title)}}/>
                            <Label htmlFor={item.title}>{item.title}</Label>
                        </Checkbox>
                    ))}
                </div>
            </Content>
        </StyledAccordion>
    );
}