import React from 'react';
import {Container} from "../../components/Container/Container";
import {Headline2, Headline3} from "../../components/Headlines/Headlines";
import {FormRow, FormRowItem} from "../../components/Fields/FormRows";
import FormField from "../../components/Fields/FormField";
import {Button} from "../../components/Buttons/Buttons";
import styled from "styled-components";
import {useEffect} from "react";
import {redirect, useSearchParams, useSubmit} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Seo} from '../../components/Seo/Seo';
import {changeForgotPassword, checkForgotPassword} from '../../api/forgot-password';
import {toast} from 'react-toastify';
import {Toaster} from '../../components/Toaster/Toaster';
import {Body} from "../../components/Texts/Texts";

const StyledReset = styled.div`
    margin: 56px 0 175px;
`;

const ResetContainer = styled.section`
    max-width: 1020px;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
    padding: 40px 60px;
    margin-top: 56px;

    @media screen and (max-width: 1024px) {
        padding: 30px 40px;
    }
    
    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 25px;
    }
`;

const StyledForm = styled.form`
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-primary-10);
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
`;

const FormHeading = styled(Headline3)`
    margin-bottom: 30px;
`;

const StyledFormRow = styled(FormRow)`
    margin-bottom: 30px;
`;

const Announcement = styled(Body)`
    padding: 0 0 0 15px;
    border-left: 5px solid var(--color-accent);
`;

export async function resetPasswordLoader({request}) {
    const url = new URL(request.url);

    if (url?.searchParams?.get('user') && url?.searchParams?.get('validationKey')) {
        try {
            const checkForgotPasswordData = await checkForgotPassword(request, url?.searchParams?.get('user'), url?.searchParams?.get('validationKey'))
            return {forgotPasswordData: checkForgotPasswordData};
        } catch(err) {
            toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            return redirect('/wachtwoord-vergeten');
        }
    }

    return redirect('/wachtwoord-vergeten');
}

export async function resetPasswordAction({request}) {
    let formData = await request.formData();

    const changeObj = {
        "user": parseInt(formData.get("user")),
        "validationKey": formData.get("validationKey"),
        "password": formData.get("password"),
    }

    try {
        await changeForgotPassword(request, changeObj);
        toast(<Toaster type='success' title='Gelukt!' message='Het wachtwoord is succesvol gewijzigd.' $onLight/>);
        return redirect('/login');
    } catch(err) {
        return toast(<Toaster type='danger' title='Mislukt!' message='Het is niet mogelijk om dit wachtwoord te gebruiken. Probeer het opnieuw.' $onLight/>);;
    }
}

export default function ResetPassword() {
    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    let [searchParams] = useSearchParams();

    const { register, formState: { errors }, handleSubmit, watch } = useForm({
        defaultValues: {
            user: searchParams.get('user'),
            validationKey: searchParams.get('validationKey'),
        }
    });

    FormField.defaultProps = {errors: errors, register: register, watch: watch}

    const submit = useSubmit();

    const onSubmit = (data) => {
        submit(data, { method: "patch", action: `/wachtwoord-resetten?user=${searchParams.get('user')}&validationKey=${searchParams.get('validationKey')}` });
    }

    return (
        <>
            <Seo metaTitle="Wachtwoord resetten" />

            <StyledReset>
                <Container>
                    <Headline2>Wachtwoord resetten</Headline2>
                    <ResetContainer>
                        <StyledForm onSubmit={handleSubmit(onSubmit)}>
                            <FormHeading>Reset je wachtwoord.</FormHeading>

                            <Announcement>Nadat je wachtwoord is gereset, kun je inloggen met je e-mailadres en nieuw aangemaakte wachtwoord.</Announcement>

                            <StyledFormRow $spacing="extraLarge" $breakpoint="768px">
                                <FormRowItem>
                                    <FormField type="password" name="password" autoComplete="new-password" title="Nieuw wachtwoord" placeholder="Nieuw wachtwoord" required={true} />
                                </FormRowItem>
                                <FormRowItem>
                                    <FormField type="password" name="password-repeat" autoComplete="new-password" title="Herhaal nieuw wachtwoord" placeholder="Herhaal nieuw wachtwoord" required={true} />
                                </FormRowItem>
                            </StyledFormRow>

                            <Button as="button" $variant="secondary">Versturen</Button>
                        </StyledForm>
                    </ResetContainer>
                </Container>
            </StyledReset>
        </>
    )
}