import styled from 'styled-components';
import {SideDrawer} from '../SideDrawer/SideDrawer';
import {Button} from '../../Buttons/Buttons';
import {useFetchers} from 'react-router-dom';
import {Spinner} from '../../Spinner/Spinner';
import React, {useEffect, useState} from 'react';
import WishlistProduct from '../../WishlistProduct/WishlistProduct';
import {EmptyState} from '../../EmptyState/EmptyState';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {Toaster} from '../../Toaster/Toaster';
import {useImmer} from 'use-immer';

const WishlistProducts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const SideDrawerButtonsWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-top: auto;
    padding-top: 30px;
    
    ${Button} {
        max-width: unset;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

const SpinnerWrapper = styled.div`
    padding: 16px 0;
    text-align: center;
`;

export const WishlistSideDrawer = ({isOpen, close}) => {
    const [wishlistData, setWishlistData] = useImmer(null);
    const [loading, setLoading] = useState(true)

    const fetchers = useFetchers();
    const relevantFetchers = fetchers.filter((fetcher) => (fetcher.formAction?.startsWith('/verlanglijstje') && fetcher.formMethod === 'post'));

    useEffect(() => {
        const controller = new AbortController();

        async function getWishlistData() {
            setLoading(true);

            const getWishlistRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wishlist`, {
                signal: controller.signal,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'token': Cookies.get('titelive_webshop'),
                },
            })

            if (!getWishlistRes.ok) {
                toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }

            return getWishlistRes.json();
        }

        if(isOpen) {
            getWishlistData().then((data) => {
                setWishlistData(data);
                setLoading(false);
            })
        }

        return () => { setWishlistData(null); controller.abort(); }
        // eslint-disable-next-line
    }, [isOpen])

    const deleteFromWishlistObject = (isbn) => {
        setWishlistData(draft => {
            draft.products = draft.products.filter(item => item.isbn !== isbn)
        });
    }

    return (
        <SideDrawer title="Verlanglijstje" isOpen={isOpen} close={close}>
            <WishlistProducts>

                {wishlistData &&
                    <>
                        {wishlistData?.products?.length > 0
                            ? wishlistData?.products?.map((item, index) => (
                                <WishlistProduct key={`${index}-${item?.isbn}`} data={item} close={close} inSideDrawer={true} wishlist={true} onDelete={deleteFromWishlistObject} />
                            ))
                            : relevantFetchers.length === 0 ? <EmptyState heading="Je verlanglijstje is leeg" description="Voeg een artikel toe aan je verlanglijstje." /> : null}
                    </>
                }

                {loading && <SpinnerWrapper><Spinner /></SpinnerWrapper>}
            </WishlistProducts>

            <SideDrawerButtonsWrapper>
                <Button onClick={close} as="button" $variant="outline">Verder winkelen</Button>
                <Button to="/verlanglijstje" onClick={close} $variant="secondary">Verlanglijstje bewerken</Button>
            </SideDrawerButtonsWrapper>
        </SideDrawer>
    );
}