import React, {useEffect} from "react";
import {ProductHeader} from '../../sections/ProductHeader/ProductHeader';
import {ProductInfo} from "../../sections/ProductInfo/ProductInfo";
import {Await, defer, redirect, useLoaderData, useParams} from 'react-router-dom';
import {Seo} from '../../components/Seo/Seo';
import {rootLoaderPromise} from '../../layouts/Root';
import Cookies from 'js-cookie';
import {sendEcommerceEvent} from '../../events/dataLayer';

export async function productLoader({request, params}) {
    let productDetailQueryParams;
    let productDetailShopsQueryParams;

    // If it has eanf params, it is a secondhand book
    if(params?.eanf) {
        productDetailQueryParams = `isbn=${params?.isbn}&eanf=${params.eanf}`
        productDetailShopsQueryParams = `eanf=${params.eanf}`
    } else {
        productDetailQueryParams = `isbn=${params?.isbn}`
        productDetailShopsQueryParams = `isbn=${params?.isbn}`
    }

    const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/product-detail?${productDetailQueryParams}`, { signal: request.signal}).then((res) => res.json())

    // Redirect to product detail page of secondHand if there is no new book available
    if(Boolean(data?.redirectUrl)) {
        return redirect(`/${data?.redirectUrl}`);
    }

    // Fetch the shops with availability for the (secondhand) product and await the rootLoaderPromise because a token is required for the calls
    let shopsPromise;
    let typesPromise;

    await rootLoaderPromise;
    shopsPromise = fetch(`${process.env.REACT_APP_API_BASE_URL}/product-detail-shops?${productDetailShopsQueryParams}`, {
        signal: request.signal,
        headers: {
            'token': Cookies.get('titelive_webshop'),
        }
    }).then((res) => res.json());

    // If might not have a nstc if it's a private or personalized book
    let productDetailTypesQueryParams;

    if(data?.product?.isbn) {
        if(data?.product?.nstc) {
            productDetailTypesQueryParams = `isbn=${data?.product?.isbn}&nstc=${data?.product?.nstc}`;
        } else {
            productDetailTypesQueryParams = `isbn=${data?.product?.isbn}`;
        }

        // Fetch the different productforms and secondhand conditions
        typesPromise = fetch(`${process.env.REACT_APP_API_BASE_URL}/product-detail-types?${productDetailTypesQueryParams}`, {
            signal: request.signal,
            headers: {
                'token': Cookies.get('titelive_webshop'),
            }
        }).then((res) => res.json());
    }

    return defer({content: data, shops: shopsPromise, ...({types: typesPromise})});
}

// It should not revalidate when there is a formMethod (when triggering an action, it has a formMethod).
export function shouldRevalidateProducts({formMethod, defaultShouldRevalidate}) {
    let shouldRevalidate = defaultShouldRevalidate;

    if (Boolean(formMethod)) { shouldRevalidate = false; } // has form method (i.e. when adding to cart or wishlist)

    return shouldRevalidate;
}

export default function Product() {
    const data = useLoaderData();
    const params = useParams();

    useEffect(() => {
        const eventData = data?.content?.product;
        eventData.secondhand = Boolean(params.eanf);

        if(eventData) {
            sendEcommerceEvent('view_item', [eventData])
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <React.Suspense>
                <Await resolve={data.content}>
                    {(content) => (
                        <Seo metaTitle={content['product'].title} url={`/${data?.content?.product?.url}`} />
                    )}
                </Await>
            </React.Suspense>

            <React.Suspense>
                <Await resolve={data.content}>
                    {(content) => (
                        <div key={content['product'].isbn}>
                            <ProductHeader data={content} shops={data?.shops} types={data?.types} />
                            <ProductInfo data={content}/>
                        </div>
                    )}
                </Await>
            </React.Suspense>
        </>
    );
}