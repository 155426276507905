import { createGlobalStyle } from 'styled-components';

import normalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`
    ${normalize}
    
    * {
        box-sizing: border-box;
    }
    
    html, body {
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;    // macOS chrome
        -moz-osx-font-smoothing: grayscale;     // macOS firefox
    }

    .grecaptcha-badge {
        visibility: hidden;
    }
    
    .prevent-scrolling {
        overflow: hidden;
        touch-action: none;
    }
    
    :root {
        /* Brand colors */
        --color-primary: #0B4769;     // Vandar Poel's Blue
        --color-secondary: #C24450;   // Euginia Red
        --color-tertiary: #A3D1AB;    // Cobalt Green
        --color-accent: #CEA96F;      // Maple

        /* Brand colors variations */
        --color-primary-5: #EDEEF2;
        --color-primary-10: #DCDDE5;
        --color-primary-60: #74819B;
        --color-primary-120: #08324A;
        --color-secondary-60: #EE9999;
        --color-secondary-120: #911C27;
        --color-tertiary-120: #719B78;
        --color-accent-120: #A58759;
        
        /* Other colors */
        --color-light: #FAFAFA;
        --color-white: #fff;
        --color-black: #000;
        --color-success: #44B958;
        --color-danger: #C24450;
        --color-navbar: #F4F4F4; 
        
        /* Box shadows */
        --box-shadow: 0 4px 4px rgba(50, 50, 50, 0.05);
        --box-shadow-60: 0 4px 4px rgba(150, 150, 150, 0.2);
        --box-shadow-up: 0 -4px 4px rgba(50, 50, 50, 0.05);
        
        /* Font families */
        --font-primary: "dolly-new", sans-serif;
        --font-primary-caps: "dolly-small-caps-new", sans-serif;
        --font-secondary: "Bebas Neue", sans-serif;
    }

    /*
     * The Typekit service used to deliver this font or fonts for use on websites
     * is provided by Adobe and is subject to these Terms of Use
     * http://www.adobe.com/products/eulas/tou_typekit. For font license
     * information, see the list below.
     *
     * dolly-new:
     *   - http://typekit.com/eulas/000000000000000077359b1b
     *   - http://typekit.com/eulas/000000000000000077359b2a
     *   - http://typekit.com/eulas/000000000000000077359b35
     * dolly-small-caps-new:
     *   - http://typekit.com/eulas/000000000000000077359b1a
     *
     * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
     */
    /*{"last_published":"2022-11-21 09:56:07 UTC"}*/
    
    @font-face {
        font-family: "dolly-new";
        src: url("https://use.typekit.net/af/76df78/000000000000000077359b1b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/76df78/000000000000000077359b1b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/76df78/000000000000000077359b1b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
        font-display: auto;
        font-style: italic;
        font-weight: 400;
        font-stretch: normal;
    }

    @font-face {
        font-family: "dolly-new";
        src: url("https://use.typekit.net/af/2bb3d0/000000000000000077359b2a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/2bb3d0/000000000000000077359b2a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/2bb3d0/000000000000000077359b2a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 700;
        font-stretch: normal;
    }

    @font-face {
        font-family: "dolly-new";
        src: url("https://use.typekit.net/af/93fd42/000000000000000077359b35/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/93fd42/000000000000000077359b35/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/93fd42/000000000000000077359b35/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 400;
        font-stretch: normal;
    }

    @font-face {
        font-family: "dolly-small-caps-new";
        src: url("https://use.typekit.net/af/2687b2/000000000000000077359b1a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/2687b2/000000000000000077359b1a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/2687b2/000000000000000077359b1a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
        font-display: auto;
        font-style: normal;
        font-weight: 400;
        font-stretch: normal;
    }
    
    /* NProgress navigation loader bar */
    #nprogress {
        pointer-events: none;
    }

    #nprogress .bar {
        background: var(--color-tertiary);
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
    }
    
    /* React Toastify */
    .Toastify__toast-container {
        max-width: 622px;
        width: 100%;
    }
    .Toastify__toast {
        border-radius: unset;
        padding: 0;
    }
    .Toastify__toast-body {
        padding: 0;
    }
    .Toastify__close-button {
        display: none;
    }
`;

export default GlobalStyle;