import {SideDrawer} from '../SideDrawer/SideDrawer';
import {GuestLogInForm} from './GuestLogInForm';
import {GuestRegisterForm} from './GuestRegisterForm';

export const GuestSideDrawer = ({isOpen, close}) => {
    return (
        <SideDrawer title="Inloggen" isOpen={isOpen} close={close}>
            <GuestLogInForm close={close} />

            <GuestRegisterForm close={close} />
        </SideDrawer>
    );
}
