import {HeaderBanner} from '../../../sections/HeaderBanner/HeaderBanner';
import deKlerImage from '../../../assets/images/de-kler-leiden.jpeg'
import GlobalStyle from '../../../globalStyles';

const headerBannerData = {
    "type": "banner-header",
    "title": null,
    "bannerHeader": {
        "id": "1",
        "supertitle": "De Kler",
        "title": "Onze website is in onderhoud",
        "subtitle": "",
        "description": "Onze website is momenteel niet beschikbaar. We zijn hard aan het werk om onze website weer zo snel mogelijk online te krijgen.",
        "imageURL": deKlerImage,
        "linkType": "relative",
        "linkText": "",
        "url": "",
        "shapeType": "label",
        "color": "primary",
        "breadcrumbs": [
            {
                "linkText": "Onze website is in onderhoud",
                "url": "404"
            }
        ]
    }
}

export default function ApiFailureFallback() {

    return (
        <>
            <GlobalStyle />

            <HeaderBanner data={headerBannerData} />
        </>
    )
}