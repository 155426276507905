import styled from 'styled-components';
import {Body, buttonTextStyles} from '../Texts/Texts';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as ChevronRight} from '../../assets/icons/breadcrumb.svg';
import {NavLink} from 'react-router-dom';
import {useRef} from 'react';
import {Headline5} from '../Headlines/Headlines';

const StyledAccordion = styled.section`
    user-select: none;
    
    &:first-of-type {
        margin-top: 30px;
    }
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-primary-10);
    padding: 18px 0 18px 6px;
    cursor: pointer;
`;

const Title = styled(NavLink)`
    ${buttonTextStyles};
    text-transform: uppercase;
    margin: 0;
    color: ${props => props.$open ? 'var(--color-secondary)' : 'var(--color-primary)'};
`;

const StyledChevron = styled(Chevron)`
    ${props => props.$open && `transform: rotate(180deg)`};
    
    path { fill: ${props => props.$open ? 'var(--color-secondary)' : 'var(--color-primary)'}; }
`;

const Content = styled.article`
    padding: 10px 0 30px 8px;
    
    ${Headline5} {
        margin: 16px 0;
    }
`;

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    &:not(:last-of-type) {
        margin-bottom: 30px;
    }
`;

const Item = styled.li``;

const StyledChevronRight = styled(ChevronRight)`
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: 4px;
    flex-shrink: 0;
    
    path { fill: var(--color-primary); }
`;

const StyledLink = styled(Body).attrs({
    as: NavLink
})`
    text-decoration: none;
    display: flex;
    
    &:not(:last-of-type) {
        margin-right: 22px;
    }
    
    &:hover {
        text-decoration: underline;
    }
`;

export const Accordion = ({data, toggleMenu, isActive, setActiveId, onShow}) => {

    const ref = useRef();

    const toggleExpand = (e) => {
        // If clicked on the link itself, it shouldn't open the expandable section
        if(e.target === ref.current) {
            setActiveId(null);
        }
    }

    return (
         <StyledAccordion>
            <Header onClick={(e) => { onShow(); toggleExpand(e) }}>
                <Title to={data.url} ref={ref} onClick={toggleMenu} $open={isActive}>{data.title}</Title>
                {data.columns.length > 0 && <StyledChevron $open={isActive} />}
            </Header>
            {(data.columns.length > 0 && isActive) &&
                <Content>
                    {data.columns.map((item, index) => (
                        <div key={index}>
                            <Headline5>{item.title}</Headline5>
                            <List>
                                {item.items.map(subItem => (
                                    <Item key={subItem.id}><StyledLink to={subItem.url} onClick={toggleMenu}><StyledChevronRight />{subItem.title}</StyledLink></Item>
                                ))}
                            </List>
                        </div>
                    ))}
                </Content>
            }
        </StyledAccordion>
    );
}