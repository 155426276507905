import styled from 'styled-components';
import {Headline4} from '../Headlines/Headlines';
import {Button, IconButton} from '../Buttons/Buttons';
import {ReactComponent as Filter} from '../../assets/icons/filter.svg';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {FiltersAccordion} from '../FiltersAccordion/FiltersAccordion';
import {useToggleMenu} from '../../hooks/useToggleMenu';
import {createSearchParams, useMatch, useNavigate, useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useImmer} from 'use-immer';

const StyledFiltersAside = styled.section``;

const FilterButton = styled(Button).attrs({
    as: "button",
    $icon: true,
})`
    display: none;
    
    @media screen and (max-width: 768px) {
        display: inline-flex;    
    }
`;

const Content = styled.section`
    
    @media screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        right: ${props => props.$isOpen ? '0' : '100%'};
        background-color: var(--color-white);
        height: 100vh;
        width: 100%;
        z-index: 99;
        transition: right .2s ease;
    }
`;

const Inner = styled.div`
    
    @media screen and (max-width: 768px) {
        overflow-y: auto;
        padding: 15px 20px 190px;
        height: 100%;   
    }
`;

const CloseButtonWrapper = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

export const FiltersAside = ({data}) => {
    const { isOpen, openMenu, closeMenu } = useToggleMenu();

    // Handle controlled filters
    let [searchParams,] = useSearchParams();
    const [selectedFilters, updateSelectedFilters] = useImmer(data);

    const [navigateLink, setNavigateLink] = useState("initial");
    const navigate = useNavigate();

    function handleToggle(categoryId, filterId, active) {
        updateSelectedFilters(draft => {
            // Find the filter object with the specified ID
            const filter = draft.find(a =>
                a.id === categoryId
            );

            // Find the value object with the specified ID and update its "active" property
            // eslint-disable-next-line
            filter.filterItems.find(a => {
                if(a.id === filterId) {
                    a.active = active;
                }
            });

            // Reduce the selectedFilters object
            const reducedObject = draft.reduce((acc, cur) => {
                acc[cur.titleKey] = cur.filterItems
                    .filter(value => value.active)
                    .map(value => value.titleKey);
                return acc;
            }, {});

            // Create a nice URL from the reduced Object and parse it to the following format: filterCategory:filter,filter/filterCategory:filter,filter
            const reducedString = Object.entries(reducedObject)
                .filter(([, values]) => values.length > 0)
                .map(([key, values]) => `${key}:${values.join(",")}`)
                .join("/");

            setNavigateLink(reducedString);
        });
    }

    useEffect(() => {
        if(navigateLink !== "initial") {
            // Use the `navigate` function from React Router to navigate to the new URL
            if(searchParams.get('zoeken')) {
                navigate({
                    pathname: navigateLink,
                    search: createSearchParams({
                        zoeken: searchParams.get('zoeken')
                    }).toString()
                }, {preventScrollReset: true});
            } else {
                navigate(navigateLink, {preventScrollReset: true});
            }
        }
        // eslint-disable-next-line
    }, [navigateLink]);

    // If pathname matches, set all filters to active=false (visually clearing filters)
    const match = useMatch("/boeken");
    useEffect(() => {
        if(!!match) {
            updateSelectedFilters(draft => {
                draft.forEach(filter => {
                    filter.filterItems.forEach(item => {
                        if (item.active) {
                            item.active = false;
                        }
                    });
                })
            });

            setNavigateLink("initial")
        }
    }, [match, updateSelectedFilters])

    return (
        <StyledFiltersAside>
            <FilterButton onClick={openMenu}><Filter /> Toon filters</FilterButton>

            <Content $isOpen={isOpen}>
                <Inner>
                    <CloseButtonWrapper>
                        <IconButton as="button" onClick={closeMenu} $small><CloseIcon /></IconButton>
                    </CloseButtonWrapper>

                    <Headline4>Filters</Headline4>
                    {selectedFilters.map(filter => (
                        <FiltersAccordion key={filter.id} data={filter} toggleFn={handleToggle} />
                    ))}
                </Inner>
            </Content>
        </StyledFiltersAside>
    );
}