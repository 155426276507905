import styled from 'styled-components';
import {Headline1} from '../Headlines/Headlines';
import {OptionalButton} from '../Buttons/Buttons';
import {Subtitle2, Subtitle3} from '../Subtitles/Subtitles';
import {Body} from '../Texts/Texts';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {LayeredCustomLink} from '../CustomLink/CustomLink';

const StyledBookSlide = styled.article`
    background-color: ${props => props.$variant === 'secondary' ? 'var(--color-secondary)' : props.$variant === 'tertiary' ? 'var(--color-tertiary)' : 'var(--color-primary)'};
    display: flex;
    height: 450px;

    @media screen and (max-width: 820px) {
        flex-direction: column;
        height: 640px;
        max-height: 100%;
    }
`;

const ImageBox = styled.div`
    flex: 1;
    max-width: 450px;
    position: relative;
    z-index: 1;

    // Used for making the whole ImageBox clickable while still maintaining nested links
    position: relative;
    a {
        position: relative;
        z-index: 1;
    }
    
    @media screen and (max-width: 820px) {
        background-color: rgba(255, 255, 255, .1);
        max-height: 310px;
        max-width: unset;
    }
`;

const ImageWrapper = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media screen and (min-width: 821px) {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 450px;
            height: 450px;
            border-top-right-radius: 100%;
            background-color: rgba(255, 255, 255, .1);
            z-index: -1;
        }
    }
`;

const Image = styled.img`
    max-width: 257px;
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;

    @media screen and (max-width: 820px) {
        width: auto;
        height: 100%;
        max-height: 270px;
        margin-left: unset;
    }
`;

const Box = styled.div`
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
`;

const Content = styled.div`
    position: relative;
    overflow: hidden;
    padding: ${({$extraPaddingBottom}) => $extraPaddingBottom ? '30px 50px 60px' : '30px 50px'};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // Used for making the whole Content clickable while still maintaining nested links
    position: relative;
    a {
        position: relative;
        z-index: 1;
    }
    
    @media screen and (max-width: 1024px) {
        padding: 20px 20px 30px;    
    }
    
    @media screen and (min-width: 821px) {
        &:after {
            content: '';
            position: absolute;
            top: 300px;
            right: -50px;
            width: 400px;
            height: 400px;
            border-top-left-radius: 100%;
            background-color: rgba(255, 255, 255, .1);
        }
    }
`;

const InnerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    z-index: 1;
    
    ${Body} { margin: 12px 0 0; }

    @media screen and (max-width: 1024px) {
        ${Headline1} { font-size: 40px; }
    }

    @media screen and (max-width: 820px) {
        ${Body} { margin: 6px 0 0; }
        margin-bottom: 0;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 30px;
    
    @media screen and (max-width: 820px) {
        margin-top: 20px;
    }
`;

export const BookSlide = ({superTitle, title, subTitle, description, buttonText, url, imageUrl, variant, onAgenda = false}) => {
    const {width} = useWindowDimensions();

    return (
        <StyledBookSlide $variant={variant}>
             <ImageBox to={url}>
                 <LayeredCustomLink to={url}>
                    <ImageWrapper>
                        <Image src={imageUrl} alt={title} />
                    </ImageWrapper>
                 </LayeredCustomLink>
            </ImageBox>
            <Box>
                <Content $extraPaddingBottom={!Boolean(buttonText)}>
                    <InnerContent>
                        {superTitle && <Subtitle2 $truncated $clamp={1} $variant={variant}>{superTitle}</Subtitle2>}
                        <LayeredCustomLink to={url}><Headline1 as='h2' $truncated $clamp={onAgenda ? 3 : 2} $variant={variant}>{title}</Headline1></LayeredCustomLink>
                        {subTitle && <Subtitle3 $truncated $clamp={onAgenda ? 3 : (width < 820 ? 1 : 2)} $variant={variant}>{subTitle}</Subtitle3>}
                        {description && <Body $truncated $clamp={width < 820 ? 2 : 3} $variant={variant}>{description}</Body>}
                    </InnerContent>
                    {buttonText &&
                        <ButtonWrapper>
                            <OptionalButton to={url} truncate={true} $variant={variant === 'secondary' ? 'tertiary' : 'secondary'}>{buttonText}</OptionalButton>
                        </ButtonWrapper>
                    }
                </Content>
            </Box>
        </StyledBookSlide>
    );
}