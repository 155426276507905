import {Header} from '../../components/Header/Header';
import {NavBar} from '../../components/NavBar/NavBar';
import styled from 'styled-components';

const StyledMenu = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

export const Menu = () => {

    return (
        <StyledMenu>
            <Header />
            <NavBar />
        </StyledMenu>
    )
}