import React, {useRef} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {Headline2} from "../../components/Headlines/Headlines";
import {IconButton} from "../../components/Buttons/Buttons";
import {ReactComponent as ChevronLeft} from "../../assets/icons/chevron-left.svg";
import {ReactComponent as ChevronRight} from "../../assets/icons/chevron-right.svg";
import {Spacing} from '../../components/Spacing/Spacing';
import {AgendaItem} from '../../components/AgendaItem/AgendaItem';

const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const ControlButtons = styled.div`
    display: flex;
    gap: 10px;

    @media screen and (max-width: 768px) {
        padding-right: 20px;
    }
    
    ${({$amount}) => $amount === 3 && `
        display: none;
        
        @media screen and (max-width: 1024px) {
            display: flex;
        }
    `};

    ${({$amount}) => $amount === 2 && `
        display: none;
        
        @media screen and (max-width: 768px) {
            display: flex;
        }
    `};

    ${({$amount}) => $amount === 1 && ` display: none; `};
`;

const StyledIconButton = styled(IconButton)`
  &:disabled {
      opacity: 0.2;

      &:hover {
          background-color: transparent;
          color: var(--color-primary);
          path { fill: var(--color-primary); }
      }
  }
`;

export const AgendaBlock = ({data}) => {
    const swiperRef = useRef(null);
    const prevBtnRef = useRef(null);
    const nextBtnRef = useRef(null);

    if(!data?.agenda || data?.agenda?.length === 0) { return null; }

    return (
        <Spacing>
            <Container>
                <TopBox>
                    <Headline2>{data?.title}</Headline2>
                    <ControlButtons $amount={data?.agenda?.length}>
                        <StyledIconButton $variant="outline" as="button" ref={prevBtnRef}><ChevronLeft/></StyledIconButton>
                        <StyledIconButton $variant="outline" as="button" ref={nextBtnRef}><ChevronRight/></StyledIconButton>
                    </ControlButtons>
                </TopBox>
                <Swiper
                    ref={swiperRef}
                    breakpoints={{
                        481: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        769: {
                            slidesPerView: data?.agenda?.length >= 2 ? 2 : 1,
                            spaceBetween: 10,
                        },
                        1025: {
                            slidesPerView: data?.agenda?.length >= 3 ? 3 : data?.agenda?.length === 2 ? 2 : 1,
                            spaceBetween: 30
                        },
                    }}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevBtnRef.current;
                        swiper.params.navigation.nextEl = nextBtnRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    modules={[Navigation]}
                >
                    {data?.agenda?.map(item => (
                        <SwiperSlide key={item.id}>
                            <AgendaItem
                                type={item.type}
                                image={item.image}
                                date={`${item.day} ${item.shortMonth}`}
                                title={item.title}
                                shop={item.shop}
                                startTime={item.startTime}
                                endTime={item.endTime}
                                description={item.description}
                                linkType="relative"
                                url={item.url}
                                linkText={item.linkText}
                                direction={data?.agenda?.length === 1 ? 'horizontal' : 'vertical'}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </Spacing>
    );
}