import React, {useEffect} from 'react';
import {ReactComponent as ChevronIcon} from '../../../assets/icons/chevron-left.svg';
import {AddressForm} from '../../../components/AddressForm/AddressForm';
import styled from 'styled-components';
import {Button} from '../../../components/Buttons/Buttons';
import {useLocation, useSubmit} from 'react-router-dom';
import FormField from '../../../components/Fields/FormField';
import {useForm} from 'react-hook-form';
import {useAddressFromPostalCode} from '../../../hooks/useAddressFromPostalCode';
import {destroyAddress, updateAddress} from '../../../api/addresses';
import {toast} from 'react-toastify';
import {Toaster} from '../../../components/Toaster/Toaster';

const StyledDetails = styled.section``;

const BackButton = styled(Button)`
    max-width: 116px;
`;


export async function newAddressAction({request}) {
    let formData = await request.formData();

    switch(request.method) {
        // POST (CreateAddress) is in detailsLayoutAction to have useActionData as callback and set new address as selected

        case "PUT": {
            const addressObj = {
                "id": parseInt(formData.get("id")),
                "default": true,
            }

            try {
                const updateAddressData = await updateAddress(request, addressObj);
                toast(<Toaster type='success' title='Gelukt!' message='Het adres is succesvol als standaard ingesteld.' $onLight/>);
                return ({addressData: updateAddressData});
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }
        case "DELETE": {
            const addressObj = {
                "id": parseInt(formData.get("id")),
            }

            try {
                const destroyAddressData = await destroyAddress(request, addressObj);
                toast(<Toaster type='success' title='Gelukt!' message='Het adres is succesvol verwijderd.' $onLight/>);
                return ({addressData: destroyAddressData});
            } catch(err) {
                return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
            }
        }
        default: {
            throw new Response("Method not allowed", { status: 405 })

        }
    }
}

export default function NewAddress() {
    const { state } = useLocation();

    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm({
        defaultValues: {
            type: state?.type ?? 1,
            salutation: "De heer",
            intent: "createAddress",
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    const submit = useSubmit();

    const onSubmit = (data) => {
        submit(data, { method: 'post', action: '/bestellen/jouw-gegevens' })
    };

    const {displayAddressFromPostalCode, handleAddress} = useAddressFromPostalCode(getValues, setValue);

    return (
        <StyledDetails>
            <BackButton to=".." relative="path" $icon $variant="outline"><ChevronIcon /> Terug</BackButton>
            <AddressForm onSubmit={handleSubmit(onSubmit)} handleAddress={handleAddress} displayAddressFromPostalCode={displayAddressFromPostalCode} breakpoint={1024} register={register} errors={errors} intent="createAddress" />
        </StyledDetails>
    );
}