import styled from "styled-components";
import {Select} from "../Input/Input";

const StyledSelectField = styled.div``

export default function SelectField({...props}) {
    return (
        <StyledSelectField>
            <Select $small={props.hugged} $hugged={props.hugged} disabled={props.disabled}
                {...props?.register(props.name, {
                    onBlur: props?.onBlur,
                    onChange: props?.onChange,
                    required: props?.required && `${props?.title} is verplicht`,
                })}
            >
                {props.options.map((option) => (
                    <option key={option.id} value={option.value} disabled={option.disabled}>{option.title}</option>
                ))}
            </Select>
        </StyledSelectField>
    )
}