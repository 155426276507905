import React, {useEffect, useRef, useState} from 'react';
import {Seo} from '../../components/Seo/Seo';
import {createCustomer} from '../../api/customer';
import {toast} from 'react-toastify';
import {Toaster} from '../../components/Toaster/Toaster';
import {Await, defer, redirect, useActionData, useLoaderData, useLocation, useNavigate, useNavigation, useRouteLoaderData, useSubmit} from 'react-router-dom';
import styled from 'styled-components';
import {Body} from '../../components/Texts/Texts';
import {Container} from '../../components/Container/Container';
import {Headline1, Headline3, Headline4} from '../../components/Headlines/Headlines';
import {Spinner} from '../../components/Spinner/Spinner';
import {ReactComponent as EnvelopeIcon} from '../../assets/icons/envelope.svg';
import {ReactComponent as TruckIcon} from '../../assets/icons/truck.svg';
import {ReactComponent as CardIcon} from '../../assets/icons/card.svg';
import {useForm} from 'react-hook-form';
import FormField from '../../components/Fields/FormField';
import {FormRow, FormRowHidden} from '../../components/Fields/FormRows';
import {Button} from '../../components/Buttons/Buttons';
import {rootLoaderPromise} from '../../layouts/Root';
import {getAddresses} from '../../api/addresses';
import {savePayment} from '../../api/payment';

const StyledHeaderSuccess = styled.section`
    overflow: hidden;
    position: relative;
    
    @media screen and (max-width: 1024px) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 120px;
    }
`;

const Outer = styled.div`
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
    
    @media screen and (max-width: 1024px) {
        flex-basis: 100%;
    }
`;

const Inner = styled.div`
    background-color: var(--color-tertiary);
    padding: 60px 0;
    position: relative;
    
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: ${props => props.$authenticated ? '-10px' : '200px'};
        width: 100vw;
        height: 2px;
        background-color: var(--color-primary);
    }
    
    @media screen and (max-width: 1024px) {
        &:after {
            right: -10px;
        }
    }
`;

const Subtitle = styled(Body)`
    font-size: 40px;
    max-width: 740px;
    margin: 0 420px 0 0; // Card width + 30px = 420px
    
    @media screen and (max-width: 1024px) {
        margin: 0;
    }
    
    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
    
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
`;

const RelativeContainer = styled(Container)`
    position: relative;
    
    @media screen and (max-width: 1024px) {
        flex-basis: 40%;
        order: 3;
    }

    @media screen and (max-width: 768px) {
        flex-basis: 100%;
    }
`;

const Card = styled.section`
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
    padding: 30px;
    max-width: 390px;
    position: absolute;
    right: 40px;
    top: 100px;
    z-index: 1;
    
    @media screen and (max-width: 1024px) {
        position: static;
    }
    
    @media screen and (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 30px;
        padding: 20px;
    }
`;

const Info = styled.div`
    margin-top: 30px;
    
    @media screen and (max-width: 1024px) {
        flex-basis: 60%;
    }
    
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
        flex-basis: 100%;
    }
`;

const ExpectationTitle = styled(Headline3)`
    margin-bottom: 60px;
    
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const InfoItems = styled.div`
    display: flex;
    gap: 30px;
    max-width: 740px;
    margin: 0 420px 120px 0; // Card width + 30px = 420px
    
    @media screen and (max-width: 1024px) {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }

    @media screen and (max-width: 475px) {
        flex-direction: column;
    }
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Icon = styled.div`
    background-color: var(--color-primary-10);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    
    svg {
        width: 35px;
        height: 35px;
        
        path { fill: var(--color-primary); }
    }
    
    @media screen and (max-width: 768px) {
        width: 78px;
        height: 78px;
        
        svg {
            width: 55px;
            height: 55px;
        }
    }
`;

const SpinnerWrapper = styled.div`
    padding: 24px 0;
    text-align: center;
`;

export async function orderSuccessAction({request}) {
    let formData = await request.formData();
    const customerObj = {
        "email": formData.get("email"),
        "password": formData.get("password"),
        "firstName": formData.get("firstName"),
        "prefix": formData.get("prefix"),
        "lastName": formData.get("lastName")
    }

    try {
        await createCustomer(request, customerObj);
        toast(<Toaster type="success" title="Gelukt!" message="Je hebt je succesvol geregistreerd!"/>)
        return redirect('/account/mijn-bestellingen');
    } catch(err) {
        switch (err.status) {
            case 400: {
                return err;
            }
            case 409: {
                return err;
            }
            default: {
                toast(<Toaster type="danger" title="Mislukt!" message="Het is niet gelukt om te registreren."/>)
                return null;
            }
        }
    }
}

export async function orderSuccessLoader({request}) {
    const url = new URL(request.url);

    const {checkTokenData} = await rootLoaderPromise;

    // If not authenticated, it should fetch the addresses
    let getAddressesData;
    if(!Boolean(checkTokenData?.customer)) {
        getAddressesData = await getAddresses(request);
    }

    let savePaymentData;
    if(url?.searchParams?.get('transactionid')) {
        try {
            savePaymentData = await savePayment(request, url?.searchParams?.get('transactionid'))
        } catch(err) {
            toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        }
    }

    return defer({paymentData: savePaymentData, ...(getAddressesData && getAddressesData)});
}

export default function OrderSuccess() {
    const data = useLoaderData();
    const { checkTokenData } = useRouteLoaderData("root");
    const {search} = useLocation();
    let hasTransactionId = !!search;
    const [authenticated, setAuthenticated] = useState(!!checkTokenData?.customer?.firstName)

    useEffect(() => {
        setAuthenticated(!!checkTokenData?.customer?.firstName)
    }, [checkTokenData])

    return (
        <>
            <Seo metaTitle="Bestelling geplaatst" />
            <StyledHeaderSuccess>
                <RelativeContainer>
                    {!authenticated &&
                        <Card>
                            <Headline3>Maak een account aan</Headline3>
                            <Body>Heb je zonder account besteld? Wanneer je nu een account aanmaakt kun je de status van je bestelling zien en in de toekomst makkelijker bestellen.</Body>
                            <React.Suspense fallback={<SpinnerWrapper><Spinner /></SpinnerWrapper>}>
                                <Await resolve={data?.addressesData}>
                                    {(addresses) => (
                                        <HeaderSuccessRegisterForm address={addresses?.invoiceAddresses[0]} checkTokenData={checkTokenData}/>
                                    )}
                                </Await>
                            </React.Suspense>

                        </Card>
                    }
                </RelativeContainer>

                <Outer>
                    <Inner $authenticated={authenticated}>
                        <Container>
                            <Headline1>Gelukt!</Headline1>
                            <Subtitle>Bedankt voor je bestelling. We gaan voor je aan de slag.</Subtitle>
                        </Container>
                    </Inner>
                </Outer>

                <Info>
                    <Container>
                        <ExpectationTitle>Wat kun je van ons verwachten?</ExpectationTitle>
                        <InfoItems>
                            <InfoItem>
                                <Icon>
                                    <EnvelopeIcon />
                                </Icon>
                                <Headline4>Bestelbevestiging</Headline4>
                                <Body>
                                    We sturen een bevestiging van je bestelling naar {checkTokenData?.customer?.email
                                    ? checkTokenData?.customer?.email
                                    : checkTokenData?.cart?.email ? checkTokenData?.cart?.email
                                        : "je e-mailadres"}.
                                </Body>
                            </InfoItem>
                            <InfoItem>
                                <Icon>
                                    {hasTransactionId ? <TruckIcon /> : <CardIcon />}
                                </Icon>
                                <Headline4>{hasTransactionId ? 'Verzendbevestiging' : 'Betalen in de winkel'}</Headline4>
                                <Body>{hasTransactionId ? 'Indien je hebt gekozen om de bestelling te laten bezorgen ontvang je nog een e-mail met track & trace code wanneer je bestelling is meegegeven aan de bezorger.' : 'We laten weten wanneer je bestelling in de winkel is zodat je deze kunt ophalen.'}</Body>
                            </InfoItem>
                        </InfoItems>
                    </Container>
                </Info>
            </StyledHeaderSuccess>
        </>
    );
}

function HeaderSuccessRegisterForm({address, checkTokenData}) {
    const { register, formState: { errors }, handleSubmit, clearErrors, setError, setFocus, watch } = useForm({
        defaultValues: {
            email: checkTokenData?.customer?.email
                ? checkTokenData?.customer?.email
                : checkTokenData?.cart?.email ? checkTokenData?.cart?.email
                    : "",
            firstName: address?.firstName ?? "",
            prefix: address?.prefix ?? "",
            lastName: address?.lastName ?? "",
        }
    });
    FormField.defaultProps = {errors: errors, register: register};

    // Use action data for setting errors with the response
    const actionData = useActionData();
    const navigation = useNavigation();
    let prevState = useRef('idle');

    useEffect(() => {
        if(prevState.current === 'loading' && navigation.state === 'idle') {
            if(actionData) {
                let err = JSON.parse(actionData);

                // Errorcode 11 = already exists
                if(err?.errorcode === 11) {
                    setError('email', { type: 'required', message: 'Dit e-mailadres wordt al gebruikt'});
                    setFocus('email')
                }
                if(err?.error) {
                    setError(err?.error, { type: 'required', message: 'Dit veld is verplicht'});
                }
            }
        }

        prevState.current = navigation.state;
    }, [errors, navigation.state, setFocus, actionData, setError]);

    const navigate = useNavigate();
    const submit = useSubmit();
    const onSubmit = async (data) => {
        clearErrors();

        if(!data?.firstName && !data?.lastName) {
            const emailObj = {
                "registerEmail": data?.email,
            }

            navigate('/registreren', { state: emailObj });
        } else {
            submit(data, { method: 'post', action: '/bestelling-gelukt'})
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormRow $spacing={(address?.firstName && address?.lastName) ? 'small' : 'large'} $ignore>
                <FormField type="email" title="E-mailadres" placeholder="E-mailadres" name="email" autoComplete="username" required={true}/>
            </FormRow>
            {(address?.firstName && address?.lastName) &&
                <>
                    <FormRow $spacing="small" $ignore>
                        <FormField type="password" name="password" autoComplete="new-password" title="Wachtwoord" placeholder="Wachtwoord" watch={watch} required={true} />
                    </FormRow>
                    <FormRow $spacing="large" $ignore>
                        <FormField type="password" name="password-repeat" autoComplete="new-password" title="Herhaal wachtwoord" placeholder="Herhaal wachtwoord" watch={watch} required={true} />
                    </FormRow>
                </>
            }

            {/* Hidden fields so it's included in FormData */}
            <FormRowHidden>
                <FormField type="text" name="firstName"  />
                <FormField type="text" name="prefix"  />
                <FormField type="text" name="lastName"  />
            </FormRowHidden>
            <Button as="button" $variant="secondary">Aanmaken</Button>
        </form>
    );
}