import styled from 'styled-components';
import {Headline3} from '../../Headlines/Headlines';
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import { Transition } from 'react-transition-group';
import {useEffect, useRef} from 'react';
import {IconButton} from '../../Buttons/Buttons';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const StyledSideDrawer = styled.section`
    position: fixed;
    top: 0;
    right: -500px;
    background-color: var(--color-white);
    min-height: 100vh;
    height: 100%;

    @supports (min-height: -webkit-fill-available) {
        min-height: -webkit-fill-available;
    }

    width: 100%;
    max-width: 500px;
    z-index: 100;
    transition: right .2s ease;
    
    &.entering { right: 0; }
    &.entered { right: 0; }
    &.exiting { right: -500px; }
    &.exited { right: -500px; }
`;

const SideDrawerInner = styled.div`
    overflow-y: auto;
    padding: 40px 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 768px) {
        padding: 18px 20px;
    }
`;

const SideDrawerHeader = styled.header`
    border-bottom: 1px solid var(--color-primary-10);
    padding-bottom: 20px;
    margin-bottom: ${props => props.$noMargin ? '0' : '20px'};
    display: flex;
    align-items: center;
    gap: 30px;
    
    ${Headline3} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const Underlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--color-black);
    height: 100%;
    width: 100%;
    z-index: 99;
    opacity: 0;
    transition: opacity .2s ease;

    &.entering { opacity: .6; }
    &.entered { opacity: .6; }
    &.exiting { opacity: 0; }
    &.exited { opacity: 0; }
`;

const Flex = styled.div`
    display: flex;
    flex-grow: 1;
    gap: 10px;
    justify-content: flex-end;
    
    ${IconButton} { 
        flex-shrink: 0;
    }
`;

export const SideDrawer = ({title, header, children, isOpen, close, ...props}) => {
    const sideDrawerNodeRef = useRef(null);
    const underlayNodeRef = useRef(null);

    // Disable scroll when isOpen and width is smaller than 768px
    const touchActionStyle = useRef("");
    const overflowStyle = useRef("");
    const {width} = useWindowDimensions();

    useEffect(() => {
        if(isOpen && width <= 768) {
            touchActionStyle.current = document.body.style.touchAction;
            document.body.style.touchAction = "none";
            overflowStyle.current = document.body.style.overflow;
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.touchAction = touchActionStyle.current;
            document.body.style.overflow = overflowStyle.current;
        }
    }, [width, isOpen])

    return (
        <>
            <Transition nodeRef={sideDrawerNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <StyledSideDrawer ref={sideDrawerNodeRef} className={state}>
                        <SideDrawerInner>
                            <SideDrawerHeader {...props}>
                                <Headline3>{title}</Headline3>
                                <Flex>
                                    {header}
                                    <IconButton as="button" onClick={close} $small={width <= 768}><CloseIcon /></IconButton>
                                </Flex>
                            </SideDrawerHeader>

                            {children}

                        </SideDrawerInner>
                    </StyledSideDrawer>
                )}
            </Transition>

            <Transition nodeRef={underlayNodeRef} in={isOpen} timeout={200} unmountOnExit>
                {state => (
                    <Underlay ref={underlayNodeRef} className={state} onClick={close} />
                )}
            </Transition>
        </>
    );
}