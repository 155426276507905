import styled from "styled-components";
import {Body, bodyTextStyles} from "../Texts/Texts";
import BasicField from "./BasicField";
import RadioField from "./RadioField";
import SelectField from "./SelectField";
import CheckboxField from "./CheckboxField";
import PasswordField from "./PasswordField";

const StyledLabel = styled.label`
    ${bodyTextStyles};
    font-weight: 700;
    font-size: 14px;
`;

export const Error = styled(Body)`
    margin: 0;
    color: var(--color-secondary);
`;

export default function FormField({...props}) {
    const fieldElement = ((fieldType) => {
        switch (fieldType) {
            case "text":
                return <BasicField
                    {...props}
                />
            case "int":
                return <BasicField
                    {...props}
                    pattern={/^[0-9]+$/}
                    message={"Alleen cijfers zijn toegestaan"}
                />
            case "email":
                return <BasicField
                    {...props}
                    pattern={/\S+@\S+\.\S+/}
                    message={"Voer alsjeblieft een geldig e-mailadres in"}
                />
            case "tel":
                return <BasicField
                    {...props}
                    pattern={/^[0-9]+$/}
                    message={"Voer alsjeblieft een geldig telefoonnummer in"}
                />
            case "date":
                return <BasicField
                    {...props}
                />
            case "radio":
                return <RadioField
                    {...props}
                />
            case "select":
                return <SelectField
                    {...props}
                />
            case "checkbox":
                return <CheckboxField
                    {...props}
                />
            case "password":
                return <PasswordField
                    {...props}
                />
            default:
                return null;
        }
    })(props?.type);

    const errors = props.errors[props.name];

    return (
        <>
            {(props?.type !== "checkbox" && props?.title) &&
                <StyledLabel htmlFor={props?.name}>
                    {props?.title}
                    {props?.required && " *"}
                </StyledLabel>
            }

            {fieldElement}

            {errors &&
                <Error $bold $small>{errors.message}</Error>
            }
        </>
    )
}