import styled from 'styled-components';
import {Body} from '../Texts/Texts';
import {ReactComponent as Chevron} from '../../assets/icons/chevron-down.svg';
import {ReactComponent as ChevronRight} from '../../assets/icons/breadcrumb.svg';
import {NavLink} from 'react-router-dom';
import {useRef} from 'react';
import {Headline5} from '../Headlines/Headlines';

const StyledMobileMenuSubLink = styled.section`
    user-select: none;
    margin: 15px 0;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

const Title = styled(Body).attrs({
    as: NavLink
})`
    text-decoration: underline;
    margin: 0;
    color: ${props => props.$open ? 'var(--color-secondary)' : 'var(--color-primary)'};
`;

const StyledChevron = styled(Chevron)`
    ${props => props.$open && `transform: rotate(180deg)`};
    
    path { fill: ${props => props.$open ? 'var(--color-secondary)' : 'var(--color-primary)'}; }
`;

const Content = styled.article`
    padding: 10px 0 10px 8px;
    
    ${Headline5} {
        margin: 16px 0;
    }
`;

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    &:not(:last-of-type) {
        margin-bottom: 30px;
    }
`;

const Item = styled.li``;

const StyledChevronRight = styled(ChevronRight)`
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: 4px;
    flex-shrink: 0;
    
    path { fill: var(--color-primary); }
`;

const StyledLink = styled(Body).attrs({
    as: NavLink
})`
    text-decoration: none;
    display: flex;
    
    &:not(:last-of-type) {
        margin-right: 22px;
    }
    
    &:hover {
        text-decoration: underline;
    }
`;


export const MobileMenuSubLink = ({data, toggleMenu, isActive, setActiveId, onShow}) => {

    const ref = useRef();

    const toggleExpand = (e) => {
        // If clicked on the link itself, it shouldn't open the expandable section
        if(e.target === ref.current) {
            setActiveId(null);
        }
    }

    if (data.columns.length === 0) {
        return (
            <StyledMobileMenuSubLink>
                <Header>
                    <Title to={data.url} onClick={toggleMenu}>{data.title}</Title>
                </Header>
            </StyledMobileMenuSubLink>
        )
    }

    return (
        <StyledMobileMenuSubLink>
            <Header onClick={(e) => { onShow(); toggleExpand(e) }}>
                <Title to={data.url} ref={ref} onClick={toggleMenu} $open={isActive}>{data.title}</Title>
                <StyledChevron $open={isActive} />
            </Header>
            {isActive &&
                <Content>
                    <List>
                        {data.columns.map(item => {
                            return (
                                item.items.map(subItem => (
                                    <Item key={subItem.id}>
                                        <StyledLink to={subItem.url} onClick={toggleMenu}>
                                            <StyledChevronRight/>{subItem.title}
                                        </StyledLink>
                                    </Item>
                                    )
                                ))}
                            )
                        }
                    </List>
                </Content>
            }
        </StyledMobileMenuSubLink>
    );
}