import {useEffect, useRef} from 'react';
import {Headline3} from '../../../components/Headlines/Headlines';
import styled from 'styled-components';
import {Body} from '../../../components/Texts/Texts';
import {Link, useActionData, useNavigation, useOutletContext, useRouteLoaderData, useSubmit} from 'react-router-dom';
import {FormRowHidden} from '../../../components/Fields/FormRows';
import FormField, {Error} from '../../../components/Fields/FormField';
import {useForm} from 'react-hook-form';

const StyledDetails = styled.section``;

const StyledSection = styled.section`
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    padding: 30px 25px;
    margin-top: 20px;
`;

const StyledForm = styled.form`
    display: none;
`;

const FlexAddress = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    gap: 10px;
    
    ${Body} { margin: 0; }
    
    &:not(:last-child) {
        border-bottom: 1px solid var(--color-primary-10);
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const AddressTitle = styled(Body)`
    flex: 1;
`;

const ShowAddress = styled(Body)`
    flex-basis: 100%;
`;

const ChangeAddress = styled(Body)`
    text-align: right;
    text-decoration: underline;
    align-self: flex-start;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    
    @media screen and (max-width: 425px) {
        order: 3;
        text-align: left;
        padding-top: 10px;
    }
`;

export default function RegisteredUser() {
    const {cartData} = useRouteLoaderData("orderLayout");
    const [deliveryAddress, invoiceAddress, setIsDeliveryDrawerOpen, setIsInvoiceDrawerOpen] = useOutletContext();
    const { register, formState: { errors }, setError, handleSubmit, setFocus, clearErrors, setValue } = useForm({
        defaultValues: {
            deliveryAddress: deliveryAddress?.id ?? "",
            invoiceAddress: invoiceAddress?.id ?? "",
            deliveryType: cartData?.deliveryType,
            shop: cartData?.shop ?? "",
            intent: "registeredUser",
        }
    });
    FormField.defaultProps = {errors: errors, register: register}

    useEffect(() => {
        setValue('deliveryAddress', deliveryAddress?.id);
        setValue('invoiceAddress', invoiceAddress?.id);
        // eslint-disable-next-line
    }, [deliveryAddress, invoiceAddress])

    // Use action data for setting errors with the response
    const actionData = useActionData();
    const navigation = useNavigation();
    let prevState = useRef('idle');

    useEffect(() => {
        if(prevState.current === 'loading' && navigation.state === 'idle') {
            if(actionData) {
                let err = JSON.parse(actionData);

                if(err?.error) {
                    setError(err?.error, { message: `Stel een ${err?.error === 'invoiceAddress' ? 'factuuradres' : 'bezorgadres'} in om de bestelling te plaatsen`});
                }
            }
        }

        prevState.current = navigation.state;
    }, [errors, navigation.state, setFocus, actionData, setError])

    const submit = useSubmit();
    const onSubmit = (data) => {
        clearErrors();

        submit(data, { method: 'put', action: '/bestellen/jouw-gegevens' })
    };

    const openDeliveryDrawer = () => { setIsDeliveryDrawerOpen(true); }
    const openInvoiceDrawer = () => { setIsInvoiceDrawerOpen(true); }

    return (
        <StyledDetails>
            <Headline3>Jouw gegevens</Headline3>
            <StyledSection>
                {(cartData?.deliveryType !== 1 && cartData?.deliveryType !== 2) &&
                    <FlexAddress>
                        <AddressTitle $bold>Je bestelling wordt bezorgd bij:</AddressTitle>
                        {deliveryAddress ?
                            <>
                                <ChangeAddress as="button" onClick={openDeliveryDrawer}>Wijzig adres</ChangeAddress>
                                <ShowAddress>
                                    {deliveryAddress?.firstName} {deliveryAddress?.prefix} {deliveryAddress?.lastName}<br/>
                                    {deliveryAddress?.addressLine1} {deliveryAddress?.number}{deliveryAddress?.postbus}<br/>
                                    {deliveryAddress?.postcode}, {deliveryAddress?.city}
                                </ShowAddress>
                            </>
                            :
                            <>
                                <ChangeAddress as={Link} to="/bestellen/jouw-gegevens/adres-toevoegen" state={{ type: 1 }}>Nieuw bezorgadres</ChangeAddress>
                                <ShowAddress>Je hebt nog geen bezorgadres ingesteld.</ShowAddress>
                                {errors?.deliveryAddress && <Error $bold $small>{errors?.deliveryAddress?.message}</Error>}
                            </>
                        }
                    </FlexAddress>
                }
                <FlexAddress>
                    <AddressTitle $bold>Factuuradres</AddressTitle>
                    {invoiceAddress ?
                        <>
                            <ChangeAddress as="button" onClick={openInvoiceDrawer}>Wijzig adres</ChangeAddress>
                            <ShowAddress>
                                {invoiceAddress?.firstName} {invoiceAddress?.prefix} {invoiceAddress?.lastName}<br/>
                                {invoiceAddress?.addressLine1} {invoiceAddress?.number}{invoiceAddress?.postbus}<br/>
                                {invoiceAddress?.postcode}, {invoiceAddress?.city}
                            </ShowAddress>
                        </>
                        :
                        <>
                            <ChangeAddress as={Link} to="/bestellen/jouw-gegevens/adres-toevoegen" state={{ type: 2 }}>Nieuw factuuradres</ChangeAddress>
                            <ShowAddress>Je hebt nog geen factuuradres ingesteld.</ShowAddress>
                            {errors?.invoiceAddress && <Error $bold $small>{errors?.invoiceAddress?.message}</Error>}
                        </>
                    }
                </FlexAddress>
            </StyledSection>
            <StyledForm id="detailsForm" onSubmit={handleSubmit(onSubmit)}>
                {/* Hidden fields so it's included in FormData */}
                <FormRowHidden>
                    <FormField type="int" name="deliveryAddress" />
                    <FormField type="int" name="invoiceAddress" />
                    <FormField type="int" name="deliveryType" />
                    <FormField type="text" name="intent" />
                </FormRowHidden>
            </StyledForm>
        </StyledDetails>
    );
}