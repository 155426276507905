import styled from 'styled-components';
import {Headline1} from '../Headlines/Headlines';
import {OptionalButton} from '../Buttons/Buttons';
import {Subtitle2, Subtitle3} from '../Subtitles/Subtitles';

const StyledImageSlide = styled.article`
    background-color: ${props => props.$variant === 'secondary' ? 'var(--color-secondary)' : props.$variant === 'tertiary' ? 'var(--color-tertiary)' : 'var(--color-primary)'};
    display: flex;
    height: 548px;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 640px;
        max-height: 100%;
    }
`;

const Box = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const ImageBox = styled.div`
    flex: 1;
    
    @media screen and (max-width: 768px) {
        max-height: 310px;
    }
`;

const Content = styled.div`
    margin-top: auto;
    padding: 0 70px 100px 70px;
    width: 100%;
    //max-width: 400px;
    
    ${Subtitle2}, ${Headline1}, ${Subtitle3} { margin-bottom: 16px; }
    
    @media screen and (max-width: 768px) {
        padding: 0 20px 40px;
        margin: auto 0 0;
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
`;

const ButtonWrapper = styled.div`
    margin-top: 30px;
    
    @media screen and (max-width: 768px) {
        margin-top: 50px;
    }
`;

export const AgendaImageSlide = ({superTitle, title, subTitle, buttonText, url, imageUrl, variant, linkType, onAgenda = false}) => {
    return (
        <StyledImageSlide $variant={variant}>
            <ImageBox>
                <Image src={imageUrl} alt={title} />
            </ImageBox>
            <Box>
                <Content>
                    {superTitle && <Subtitle2 $truncated $clamp={1} $white>{superTitle}</Subtitle2>}
                    <Headline1 $truncated={!onAgenda} $clamp="2" $white>{title}</Headline1>
                    <Subtitle3 $truncated={!onAgenda} $clamp={1} $white>{subTitle}</Subtitle3>
                    <ButtonWrapper>
                        <OptionalButton to={url} linkType={linkType} truncate={true} $variant={variant === 'secondary' ? 'tertiary' : 'secondary'}>{buttonText}</OptionalButton>
                    </ButtonWrapper>
                </Content>
            </Box>
        </StyledImageSlide>
    );
}