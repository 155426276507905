import {Spacing} from '../../components/Spacing/Spacing';
import {Breadcrumbs} from '../Breadcrumbs/Breadcrumbs';
import {Container} from '../../components/Container/Container';
import {Headline1} from '../../components/Headlines/Headlines';
import styled from 'styled-components';
import {CustomLink} from '../../components/CustomLink/CustomLink';
import {AspectRatio} from "../../components/AspectRatio/AspectRatio";

const StyledImageBannerHeader = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ContentContainer = styled(Container)`
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 1024px) {
        padding-top: 30px;
    }

    @media screen and (max-width: 768px) {
        gap: 10px;
    }
`;

const ImageContainer = styled(Container)`
    padding-top: 20px;
    width: 100%;

    @media screen and (max-width: 768px) {
        padding-top: 0;
    }
`;

const DesktopAspectRatio = styled(AspectRatio)`
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const ResponsiveAspectRatio = styled(AspectRatio)`
    @media screen and (min-width: 769px) {
        display: none;
    }
`;

export const ImageBannerHeader = ({data, breadcrumbs}) => {
    if(!data?.imageBannerHeader) { return null; }

    return (
        <Spacing>
            <StyledImageBannerHeader>
                {(Boolean(data?.imageBannerHeader?.breadcrumbs) || Boolean(data?.imageBannerHeader?.title)) &&
                    <ContentContainer>
                        {Boolean(data?.imageBannerHeader?.breadcrumbs) && <Breadcrumbs data={breadcrumbs} />}
                        {Boolean(data?.imageBannerHeader?.title) && <Headline1>{data.imageBannerHeader.title}</Headline1>}
                    </ContentContainer>
                }
                {Boolean(data?.imageBannerHeader?.imageURL) &&
                    <ImageContainer>
                        <CustomLink to={data?.imageBannerHeader?.url} linkType={data?.imageBannerHeader?.linkType}>
                            {Boolean(data?.imageBannerHeader?.responsiveImageURL)
                                ? <>
                                    <DesktopAspectRatio
                                        src={data?.imageBannerHeader?.imageURL}
                                        alt=""
                                        ratioWidth={6}
                                        ratioHeight={1}
                                    />
                                    <ResponsiveAspectRatio
                                        src={data?.imageBannerHeader?.responsiveImageURL}
                                        alt=""
                                        ratioWidth={4}
                                        ratioHeight={3}
                                    />
                                </>
                                : <AspectRatio
                                    src={data?.imageBannerHeader?.imageURL}
                                    alt=""
                                    ratioWidth={6}
                                    ratioHeight={1}
                                />
                            }
                        </CustomLink>
                    </ImageContainer>
                }
            </StyledImageBannerHeader>
        </Spacing>
    );
}