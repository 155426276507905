import styled from 'styled-components';
import {Body, buttonTextStyles} from '../Texts/Texts';
import {NavLink} from 'react-router-dom';
import {ReactComponent as ChevronRight} from '../../assets/icons/breadcrumb.svg';

const StyledFlyOutMenu = styled.div`
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 99;
    padding: 25px 45px 45px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 2px;
    border: 1px solid var(--color-primary-10);

    max-width: 926px;
    width: max-content;
    height: auto;
    max-height: calc(100vh - 150px);
    overflow-y: auto;

    display: grid;
    grid-template-columns: repeat(${props => props.$columns && props.$columns}, 1fr);
    gap: 40px;
`;

const Heading = styled.p`
    ${buttonTextStyles}
`;

const StyledLink = styled(Body).attrs({
    as: NavLink
})`
    text-decoration: none;
    display: flex;
    
    &:not(:last-of-type) {
        margin-right: 22px;
    }
    
    &:hover, &.active {
        text-decoration: underline;
    }
`;

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const Item = styled.li``;

const Box = styled.div`
    &:first-child {
        grid-row: 1/3;
    }
`;

const StyledChevronRight = styled(ChevronRight)`
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
    flex-shrink: 0;
    margin-top: 4px;
    
    path { fill: var(--color-primary); }
`;

export const FlyOutMenu = ({data, clickAction, ...props}) => {

    if(!data) return null

    return (
        <StyledFlyOutMenu $columns={data.length <= 3 ? data.length : 3} {...props}>
            {data.map((item, index) => (
                <Box key={index}>
                    <Heading>{item.title}</Heading>
                    <List>
                        {item.items.map(subItem => (
                            <Item key={subItem.id}><StyledLink to={subItem.url} onClick={() => clickAction(false)}><StyledChevronRight />{subItem.title}</StyledLink></Item>
                        ))}
                    </List>
                </Box>
            ))}
        </StyledFlyOutMenu>
    );
}