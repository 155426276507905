import {Navigate, Outlet, useLocation, useRouteLoaderData} from 'react-router-dom';

export function RequireAuth() {
    let location = useLocation();
    const { checkTokenData } = useRouteLoaderData("root");

    // User is authenticated if customer block exists in checkTokenData
    if (checkTokenData?.customer) {
        return <Outlet />
    }

    return <Navigate to="/login" state={{ from: location }} replace />;
}