import {Headline2, Headline3} from "../../components/Headlines/Headlines";
import {ReactComponent as ChevronLeft} from "../../assets/icons/chevron-left.svg";
import {ReactComponent as ChevronRight} from "../../assets/icons/chevron-right.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {Body} from "../../components/Texts/Texts";
import {IconButton, OptionalButton} from "../../components/Buttons/Buttons";
import React, {useRef} from "react";
import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {Spacing} from '../../components/Spacing/Spacing';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {LayeredCustomLink} from '../../components/CustomLink/CustomLink';

const StyledEmployeeSlider = styled(Container)``;

const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const ControlButtons = styled.div`
    display: flex;
    gap: 10px;

    @media screen and (max-width: 768px) {
        padding-right: 20px;
    }

    ${({$amount}) => $amount === 3 && `
        display: none;
        
        @media screen and (max-width: 1024px) {
            display: flex;
        }
    `};

    ${({$amount}) => $amount === 2 && `
        display: none;
        
        @media screen and (max-width: 768px) {
            display: flex;
        }
    `};

    ${({$amount}) => $amount === 1 && ` display: none; `};
`;

const StyledIconButton = styled(IconButton)`
    &:disabled {
        opacity: 0.2;

        &:hover {
            background-color: transparent;
            color: var(--color-primary);
            path { fill: var(--color-primary); }
        }
    }
`;

const EmployeeSliderItem = styled.div`
    // Used for making the whole EmployeeSliderItem clickable while still maintaining nested links
    position: relative;
    a {
        position: relative;
        z-index: 1;
    }  
`;

const ImageBox = styled.div`
    display: flex;
    width: 100%;
    height: 230px;
    max-height: 100%;
    background-color: var(--color-primary);
`;

const StyledImage = styled.img`
    border-radius: 50%;
    width: 195px;
    height: 195px;
    margin: 65px auto auto auto;
    object-fit: cover;
`;

const SlideContent = styled.div`
    padding: 60px 28px 0;
    border-left: 2px solid  var(--color-primary-10);
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    ${Body} { height: 173px; }
`;

export const EmployeeSlider = ({data}) => {
    const swiperRef = useRef(null);
    const prevBtnRef = useRef(null);
    const nextBtnRef = useRef(null);
    const {width} = useWindowDimensions();

    if(data.slides.length === 0 ) { return null; }

    return (
        <Spacing>
            <StyledEmployeeSlider>
                <TopBox>
                    <Headline2>{data.title}</Headline2>
                    <ControlButtons $amount={data?.slides?.length}>
                        <StyledIconButton $variant="outline" as="button" ref={prevBtnRef}><ChevronLeft/></StyledIconButton>
                        <StyledIconButton $variant="outline" as="button" ref={nextBtnRef}><ChevronRight/></StyledIconButton>
                    </ControlButtons>
                </TopBox>
                <Swiper
                    ref={swiperRef}
                    threshold={40}
                    allowTouchMove={width < 768}
                    breakpoints={{
                        481: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        769: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        1025: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                    }}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevBtnRef.current;
                        swiper.params.navigation.nextEl = nextBtnRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    modules={[Navigation]}
                >
                    {data.slides.map(employee => (
                        <SwiperSlide key={employee.id}>
                            <EmployeeSliderItem>
                                <ImageBox>
                                    <StyledImage src={employee.imageURL}/>
                                </ImageBox>
                                <SlideContent>
                                    <LayeredCustomLink to={employee.url} linkType={employee.linkType}><Headline3 $truncated $clamp={2}>{employee.title}</Headline3></LayeredCustomLink>
                                    <Body $truncated $clamp={7}>{employee.description}</Body>
                                    <OptionalButton to={employee.url} linkType={employee.linkType} truncate={true} $variant="outline" $small>{employee.linkText}</OptionalButton>
                                </SlideContent>
                            </EmployeeSliderItem>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </StyledEmployeeSlider>
        </Spacing>
    )
}