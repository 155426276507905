import {useForm} from 'react-hook-form';
import FormField from '../../Fields/FormField';
import {Link, useFetcher, useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {Headline5} from '../../Headlines/Headlines';
import styled from 'styled-components';
import {Body, bodyTextStyles} from '../../Texts/Texts';
import {Button} from '../../Buttons/Buttons';
import {Toaster} from '../../Toaster/Toaster';
import {toast} from "react-toastify";

const ForgotPassword = styled(Body)`
    color: var(--color-secondary);
    margin: 20px 0 0;
    display: block;
`;

const StyledForm = styled.form`
    padding-bottom: 30px;
    &:first-of-type {
        margin-top: 10px;
    }
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-primary-10);
        margin-bottom: 30px;
    }
`;

const Row = styled.div`
    margin-top: ${props => props.$large ? '20px' : '10px'};
`;

const FormButton = styled(Button)`
    margin-top: 20px;
`;

const Announcement = styled(Body)`
    padding: 0 0 0 15px;
    border-left: 5px solid var(--color-danger);
`;

const AnnouncementLink = styled(Link)`
    ${bodyTextStyles};
`;

export function GuestLogInForm({close, newLocation}) {
    const { state } = useLocation();
    const { register, watch, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            email: state?.email,
        }
    });

    const watchEmail = watch('email', null);

    FormField.defaultProps = { errors: errors, register: register }

    const fetcher = useFetcher();
    const onSubmit = async (data) => {
        fetcher.submit(data, { method: 'POST', action: '/login'})
    }

    const navigate = useNavigate();
    useEffect(() => {
        if(fetcher.state === "idle" && fetcher.data?.logInData) {
            navigate(newLocation ?? '/account/mijn-gegevens');
            close();
            toast(<Toaster type='success' title='Gelukt!' message='Je bent succesvol ingelogd.' $onLight/>);
        }
        // eslint-disable-next-line
    }, [fetcher.state, fetcher.data])

    // Handle errorcodes for auth codes 11 & 12
    const [fetchedError, setFetchedError] = useState(false);
    useEffect(() => {
        if(fetcher.data && !fetcher.data?.logInData) {
            let parsed = JSON.parse(fetcher.data);
            if(parsed.errorcode === 11) setFetchedError(11);
            if(parsed.errorcode === 12) setFetchedError(12);
        }
    }, [fetcher.data]);

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Headline5>Ik ben al klant</Headline5>
            {fetchedError === 11 ? (
                <Announcement>De combinatie van e-mailadres en wachtwoord is niet geldig. Ben je je <AnnouncementLink to="/wachtwoord-vergeten" state={{"email": watchEmail}} onClick={close}>wachtwoord vergeten</AnnouncementLink>?</Announcement>
            ) : fetchedError === 12 ? (
                <Announcement>Dit e-mailadres bestaat niet, <AnnouncementLink to="/registreren" state={{"registerEmail": watchEmail}} onClick={close}>maak een account aan</AnnouncementLink>.</Announcement>
            ) : null}
            <Row $large>
                <FormField type="email" title="E-mailadres" placeholder="E-mailadres" name="email" autoComplete="username" required={true}/>
            </Row>
            <Row>
                <FormField type="password" title="Wachtwoord" placeholder="Wachtwoord" name="password" autoComplete="current-password" required={true}/>
            </Row>
            <ForgotPassword as={Link} to="/wachtwoord-vergeten" state={{"email": watchEmail}} onClick={close} $small>Wachtwoord vergeten?</ForgotPassword>
            <FormButton as="button" type="submit" disabled={fetcher.state !== 'idle'}>Inloggen</FormButton>
        </StyledForm>
    );
}