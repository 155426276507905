import {Container} from '../../components/Container/Container';
import styled from 'styled-components';
import {Subtitle1} from '../../components/Subtitles/Subtitles';
import {Headline2, Headline5} from '../../components/Headlines/Headlines';
import {Body} from '../../components/Texts/Texts';
import {Button, ButtonTruncate} from '../../components/Buttons/Buttons';
import {Spacing} from '../../components/Spacing/Spacing';
import React from 'react';
import {LampMask} from '../../components/Masks/LampMask/LampMask';
import {LabelMask} from '../../components/Masks/LabelMask/LabelMask';
import {BookmarkMask} from '../../components/Masks/BookmarkMask/BookmarkMask';

const StyledBanner = styled.section`
    overflow: hidden;
`;

const Outer = styled.div`
    padding: 20px;
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const Inner = styled.div`
    background: var(--color-${props => props.$variant});
    padding: 40px 0;
`;

const StyledContainer = styled(Container)`
    max-width: 1160px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: ${props => props.$position === 'left' ? 'row' : 'row-reverse'};
    align-items: center;
    justify-content: space-between;
    gap: 40px;
`;

const ContentBox = styled.div`
    flex-basis: 500px;
    margin-bottom: 60px;
    
    ${Subtitle1} { margin-bottom: 5px; }
    ${Headline2} { margin-bottom: 5px; }
    ${Headline5} { margin-bottom: 16px; }
    ${Body} { margin: 0; }
    ${Button} { margin-top: 50px; }
    
    @media screen and (max-width: 768px) {
        flex-basis: 100%;
        margin-bottom: 0;
    }
`;

export const Banner = ({data}) => {
    if(!data.banner) { return null }

    return (
        <Spacing>
            <StyledBanner>
                <Outer>
                    <Inner $variant={data?.banner?.color}>
                        <StyledContainer>
                            <Flex $position={data?.banner?.alignment}>
                                {data?.banner?.shapeType === 'lamp' ?
                                    <LampMask variant={data?.banner?.color} imageURL={data?.banner?.imageURL} position={data?.banner?.alignment} />
                                : data?.banner?.shapeType === 'label' ?
                                    <LabelMask variant={data?.banner?.color} imageURL={data?.banner?.imageURL} position={data?.banner?.alignment} />
                                :
                                    <BookmarkMask variant={data?.banner?.color} imageURL={data?.banner?.imageURL} position={data?.banner?.alignment} />
                                }
                                <ContentBox>
                                    <Subtitle1 $variant={data?.banner?.color}>{data?.banner?.supertitle}</Subtitle1>
                                    <Headline2 $variant={data?.banner?.color}>{data?.banner?.title}</Headline2>
                                    <Headline5 $variant={data?.banner?.color}>{data?.banner?.subtitle}</Headline5>
                                    <Body $variant={data?.banner?.color}>{data?.banner?.description}</Body>
                                    {(data?.banner?.linkType === 'absolute' && data?.banner?.url)
                                        ? <Button as="a" href={data?.banner?.url} target="_blank" $variant={data?.banner?.color === 'secondary' ? 'tertiary' : 'secondary'}><ButtonTruncate>{data?.banner?.linkText}</ButtonTruncate></Button>
                                        : <Button to={data?.banner?.url || "/"} $variant={data?.banner?.color === 'secondary' ? 'tertiary' : 'secondary'}><ButtonTruncate>{data?.banner?.linkText}</ButtonTruncate></Button>
                                    }
                                </ContentBox>
                            </Flex>
                        </StyledContainer>
                    </Inner>
                </Outer>
            </StyledBanner>
        </Spacing>
    );
}