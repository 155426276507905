import {useForm} from 'react-hook-form';
import FormField from '../../Fields/FormField';
import {Headline5} from '../../Headlines/Headlines';
import styled from 'styled-components';
import {Body} from '../../Texts/Texts';
import {Button} from '../../Buttons/Buttons';
import {useNavigate} from 'react-router-dom';

const Description = styled(Body)`
    margin: 0 0 20px;
`;

const StyledForm = styled.form`
    padding-bottom: 30px;
    &:first-of-type {
        margin-top: 10px;
    }
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-primary-10);
        margin-bottom: 30px;
    }
`;

const Row = styled.div`
    margin-top: ${props => props.$large ? '20px' : '10px'};
`;

const FormButton = styled(Button)`
    margin-top: 20px;
`;

export function GuestRegisterForm({close}) {
    const { register, formState: { errors }, handleSubmit } = useForm();

    FormField.defaultProps = { errors: errors, register: register }

    const navigate = useNavigate();
    const onSubmit = (data) => {
        close();
        navigate('/registreren', { state: data });
    }

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Headline5>Nieuw bij De Kler</Headline5>
            <Description>Maak binnen een paar minuten een account aan.</Description>
            <Row $large>
                <FormField type="email" title="E-mailadres" placeholder="E-mailadres" name="registerEmail" required={true}/>
            </Row>
            <FormButton as="button" type="submit">Maak een account aan</FormButton>
        </StyledForm>
    );
}