import styled from 'styled-components';
import {Button} from '../../Buttons/Buttons';
import {Body} from '../../Texts/Texts';
import {ReactComponent as Marker} from '../../../assets/icons/marker.svg';

export const Addresses = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    
    @media screen and (max-width: 425px) {
        padding: 0;
    }
`;

export const Address = styled.div`
    display: flex;
    gap: 20px;
    cursor: pointer;
    padding: 30px 10px;
    
    &.active, &:hover {
        background-color: var(--color-light);
    }
    
    &:not(:last-child) {
        border-bottom: 1px solid var(--color-primary-10);
    }
`;

export const SideDrawerButtonsWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-top: auto;
    padding-top: 30px;
    
    ${Button} {
        max-width: unset;
    }
    
    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

export const AddressBox = styled.div`
    flex-grow: 1;

    ${Body} { margin: 0; }
`;

export const DefaultAddress = styled(Body)`
    color: var(--color-tertiary);
    padding-top: 5px;
`;

export const StyledMarker = styled(Marker)`
    width: 38px;
    height: 38px;
    flex-shrink: 0;
    path { fill: var(--color-primary); }
`;