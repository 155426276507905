import {useEffect, useRef} from "react";
import {NavLink, Outlet} from "react-router-dom";
import styled from "styled-components";
import {Container} from "../components/Container/Container";
import {Headline2} from "../components/Headlines/Headlines";
import {Body} from "../components/Texts/Texts";

const Wrapper = styled.div`
    margin: 56px 0 56px;

    ${Headline2}{
        margin-bottom: 35px;
    }
`;

const TabContainer = styled.div`
    display: flex;
    margin-bottom: 37px;
    overflow-x: auto;
    
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }
`;

const Tab = styled.button`
    flex: 1;
    min-width: 190px;
    margin: 0;
    cursor: pointer;
    background: none;
    outline: 0;
    border: 0;
    box-shadow: inset 0 -2px 0 var(--color-primary-10);

    @media screen and (max-width: 768px) {
        flex-basis: 160px;
    }
`;

const StyledNavLink = styled(NavLink)`
    &.active {
        ${Tab} { box-shadow: inset 0 -5px 0 var(--color-primary); }
        ${Body} { font-weight: 700; }
    }
`;

export default function Account() {
    const activeTabRef = useRef(null);

    const tabs = [
        {
            title: "Mijn bestellingen",
            key: "mijn-bestellingen",
        },
        {
            title: "Mijn gegevens",
            key: "mijn-gegevens",
        },
        {
            title: "Mijn adressen",
            key: "mijn-adressen"
        },
        {
            title: "Instellingen",
            key: "instellingen"
        },
    ];

    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    return (
        <Container>
            <Wrapper>
                <Headline2>Mijn account</Headline2>
                <TabContainer>
                    {tabs.map((tab) => (
                        <StyledNavLink ref={(el) => {
                            if (el?.classList?.contains('active')) {
                                activeTabRef.current = el;
                            }
                        }} key={tab.key} to={`/account/${tab.key}`}>
                            <Tab>
                                <Body $small>{tab.title}</Body>
                            </Tab>
                        </StyledNavLink>
                    ))}
                </TabContainer>
                <Outlet />
            </Wrapper>
        </Container>
    )
}