import React, {useEffect} from 'react';
import {Container} from '../../components/Container/Container';
import {Headline2, Headline3} from '../../components/Headlines/Headlines';
import styled from 'styled-components';
import {useForm} from 'react-hook-form';
import FormField from '../../components/Fields/FormField';
import {Button} from '../../components/Buttons/Buttons';
import {FormRow, FormRowItem} from '../../components/Fields/FormRows';
import {useSubmit, useLocation, useActionData, Link} from 'react-router-dom';
import {Body, bodyTextStyles} from "../../components/Texts/Texts";
import {Seo} from '../../components/Seo/Seo';
import {startForgotPassword} from '../../api/forgot-password';
import {toast} from 'react-toastify';
import {Toaster} from '../../components/Toaster/Toaster';

const StyledRegister = styled.div`
    margin: 56px 0 175px;
`;

const RegisterContainer = styled.section`
    max-width: 1020px;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary-10);
    padding: 40px 60px;
    margin-top: 56px;

    @media screen and (max-width: 1024px) {
        padding: 30px 40px;
    }
    
    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
        padding: 30px 25px;
    }
`;

const StyledForm = styled.form`
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--color-primary-10);
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
`;

const FormHeading = styled(Headline3)`
    margin-bottom: 30px;
`;

const StyledFormRow = styled(FormRow)`
    margin-bottom: 30px;
`;

const Announcement = styled(Body)`
    padding: 0 0 0 15px;
    border-left: 5px solid var(--color-danger);
`;

const AnnouncementLink = styled(Link)`
    ${bodyTextStyles};
`;

export async function forgotPasswordAction({request}) {
    let formData = await request.formData();

    const emailObj = {
        "email": formData.get("email"),
    }

    try {
        const startForgotPasswordData = await startForgotPassword(request, emailObj);
        toast(<Toaster type='success' title='Gelukt!' message='Email is verzonden.' $onLight/>);
        return ({forgotPasswordData: startForgotPasswordData});
    } catch(err) {
        toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        return ({createNewAccount: true})
    }
}

export default function ForgotPassword() {
    const { state } = useLocation();

    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    const { register, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            email: state?.email
        }
    });

    FormField.defaultProps = {errors: errors, register: register}

    const submit = useSubmit();

    const onSubmit = (data) => {
        submit(data, { method: "post", action: "/wachtwoord-vergeten" });
    }

    const data = useActionData();

    return (
        <StyledRegister>
            <Seo metaTitle="Wachtwoord vergeten" />

            <Container>
                <Headline2>Wachtwoord vergeten</Headline2>
                <RegisterContainer>
                    <StyledForm onSubmit={handleSubmit(onSubmit)}>
                        <FormHeading>Herstel je wachtwoord.</FormHeading>

                        {data?.forgotPasswordData ? <Body>Er is een e-mail verstuurd waarmee je het wachtwoord voor je account kunt herstellen.</Body> :
                            <>
                                {data?.createNewAccount && <Announcement>Uw e-mail wordt niet herkend. <AnnouncementLink to="/registreren">Maak een account aan</AnnouncementLink>.</Announcement>}
                                <StyledFormRow $spacing="extraLarge" $breakpoint="768px">
                                    <FormRowItem>
                                        <FormField type="email" title="E-mailadres" placeholder="E-mailadres" name="email" autoComplete="username" required={true}/>
                                    </FormRowItem>
                                </StyledFormRow>
                                <Button as="button" $variant="secondary">Versturen</Button>
                            </>
                        }
                    </StyledForm>
                </RegisterContainer>
            </Container>
        </StyledRegister>
    );
}