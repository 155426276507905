import {Subtitle2} from '../Subtitles/Subtitles';
// import {IconButton} from '../Buttons/Buttons';
// import {ReactComponent as ShoppingCart} from '../../assets/icons/shopping-cart.svg';
// import {ReactComponent as Favorite} from '../../assets/icons/favorite-outline.svg';
import styled from 'styled-components';
import {Headline5} from '../Headlines/Headlines';
import {Link} from 'react-router-dom';
import React, {useState} from 'react';
// import {useSideDrawerContext} from '../../contexts/SideDrawerContext';
// import {useProductsInCartContext} from '../../contexts/ProductsInCartContext';
import {ProductFlag} from '../ProductFlag/ProductFlag';
import placeholder from '../../assets/images/placeholder.jpg';
import {sendEcommerceEvent} from '../../events/dataLayer';

const StyledProductItem = styled.div`
    display: ${({$onProductOverview}) => $onProductOverview ? 'block' : 'flex'};
    flex-direction: ${props => props.$ranking ? 'row' : 'column'};
    align-items: ${props => props.$ranking ? 'flex-end' : 'baseline'};
    justify-content: ${props => props.$ranking ? 'unset' : 'flex-end'};
    gap: 20px;
    height: ${({$onProductOverview}) => $onProductOverview ? 'unset' : '100%'};
    ${({$onProductOverview}) => $onProductOverview && 'margin-top: auto;'};
    position: relative;
    color: inherit;
    text-decoration: none;
    opacity: ${props => props.$load ? 1 : 0};
    transition: .2s ease opacity;
`;

const ImageWrapper = styled.div`
    flex-shrink: 0;
    display: flex;
    ${({$onProductOverview}) => $onProductOverview && 'margin-bottom: 20px;'};
`;

const Image = styled.img`
    width: ${props => !props.$onProductRows && (props.$ranking ? '127px' : '180px')};
    max-width: 100%;
    height: 100%;
    max-height: ${props => !props.$onProductRows && '300px'};
    display: block;
    object-fit: contain;
    object-position: left bottom;
    margin-top: auto;
    
    @media screen and (max-width: 768px) {
        width: ${props => props.$ranking ? '127px' : '153px'};
    }
`;

const Content = styled.div`
    ${({$ranking}) => $ranking && `margin-bottom: 20px;`} // NOTE: temporarily extra spacing while buttons are hidden for tips block
`;

const ContentInfo = styled.div`
    color: inherit;
    text-decoration: none;
`;

const AuthorTitle = styled(Subtitle2)`
    min-height: 18px; // If there are no authors, keep the space
`;

const BookTitle = styled(Headline5)`
    height: ${props => props.$ranking ? '69px' : '46px'};
    margin-bottom: 4px;

    @media screen and (max-width: 768px) {
        height: ${props => props.$ranking ? '62px' : '42px'};
    }
`;

const Price = styled(Headline5)`
    color: var(--color-secondary);
    margin-bottom: 4px;
`;

// const Buttons = styled.div`
//     display: flex;
//     gap: 10px;
//     margin: 10px 0;
// `;

export const ProductItem = ({data, isbn, eanf, title, author, price, imageUrl, url, ranking, flag, location, alsoSecondHand, targetList}) => {
    // const {isbnsWithGiftwrapInCart} = useProductsInCartContext();
    const [load, setLoad] = useState(false); // Smooth loading (when Image is loaded)
    const [imageError, setImageError] = useState(false);
    // const {openDrawer, closeDrawer} = useSideDrawerContext();

    // const fetcher = useFetcher();
    // let addingToCart = (fetcher.formMethod === "post" && fetcher.formAction === '/winkelmandje' && fetcher.state !== 'idle');
    // let addingToWishlist = (fetcher.formMethod === "post" && fetcher.formAction === '/verlanglijstje' && fetcher.state !== 'idle');
    //
    // const handleWishlistClick = async (isbn) => {
    //     const formData = new FormData();
    //     formData.append("isbn", isbn);
    //     fetcher.submit(formData, { method: 'POST', action: '/verlanglijstje'})\
    //     sendEcommerceEvent('add_to_wishlist', [data]);
    // }
    //
    // const handleCartClick = async (isbn) => {
    //     const formData = new FormData();
    //     formData.append("isbn", isbn);
    //     if(isbnsWithGiftwrapInCart?.includes(isbn)) formData.append("giftwrap", 128); // Add giftwrap if it is already in cart with giftwrap
    //     if(flag === "secondHand") formData.append("eanf", eanf); // Add eanf if it is a secondHand product
    //     fetcher.submit(formData, { test: closeDrawer('Cart'), method: 'POST', action: '/winkelmandje'})
    //     sendEcommerceEvent('add_to_cart', [data]);
    // }

    // useEffect(() => {
    //     if(fetcher.data) {
    //         openDrawer(fetcher.formAction === '/verlanglijstje' ? 'Wishlist' : 'Cart')
    //     }
    //     // eslint-disable-next-line
    // }, [fetcher.data])

    const handleProductItemClick = () => {
        if(data) {
            sendEcommerceEvent('select_item', [data], targetList ? {
                item: {
                    item_list_name: targetList.name,
                    item_list_id: targetList.id,
                }
            } : {});
        }
    }

    return (
        <StyledProductItem $ranking={!!ranking} $load={load} $onProductOverview={location === "product-overview"}>
            <ImageWrapper as={url ? Link : "div"} to={`/${url}`} onClick={handleProductItemClick} $ranking={!!ranking} $onProductOverview={location === "product-overview"}>
                <Image src={imageError ? placeholder : imageUrl} alt={title} $ranking={!!ranking} $onProductRows={location === "product-rows"} onLoad={() => setLoad(true)} onError={() => setImageError(true)} />
            </ImageWrapper>
            <Content $ranking={!!ranking}>
                <ContentInfo as={url ? Link : "div"} to={`/${url}`} onClick={handleProductItemClick}>
                    <AuthorTitle $truncated $clamp={1}>{author}</AuthorTitle>
                    <BookTitle $truncated $clamp={ranking ? 3 : 2} $ranking={!!ranking}>{title}</BookTitle>
                    <Price>{price ?? "Fout"}</Price>
                </ContentInfo>
                {/*<Buttons>*/}
                {/*    {flag === "secondHand"*/}
                {/*        ? <IconButton as={(addingToWishlist || addingToCart) ? "button" : Link} to={`/${url}`} $variant="secondary"><ShoppingCart /></IconButton>*/}
                {/*        : <IconButton as="button" onClick={() => handleCartClick(isbn)} disabled={addingToWishlist || addingToCart} $variant="secondary"><ShoppingCart /></IconButton>*/}
                {/*    }*/}
                {/*    <IconButton as="button" onClick={() => handleWishlistClick(isbn)} disabled={addingToWishlist || addingToCart} $variant="outline"><Favorite /></IconButton>*/}
                {/*</Buttons>*/}
            </Content>
            <ProductFlag ranking={ranking} />
            <ProductFlag flag={flag} alsoSecondHand={alsoSecondHand} />
        </StyledProductItem>
    );
}