import {createContext, useContext, useState} from "react";

export const SideDrawerContext = createContext(null);

export default function SideDrawerProvider({children}) {
    const [state, setState] = useState({
        isAccountOpen: false,
        isGuestOpen: false,
        isWishlistOpen: false,
        isCartOpen: false,
    });

    const openDrawer = (drawer) => {
        setState({
            ...state,
            [`is${drawer}Open`]: true,
        });
    };

    const closeDrawer = (drawer) => {
        setState({
            ...state,
            [`is${drawer}Open`]: false,
        });
    };

    return (
        <SideDrawerContext.Provider value={{
            state,
            openDrawer,
            closeDrawer,
        }}>
            {children}
        </SideDrawerContext.Provider>
    )
}

export const useSideDrawerContext = () => useContext(SideDrawerContext);