import styled, {css} from 'styled-components';
import {bodyTextStyles} from '../Texts/Texts';
import ChevronDown from '../../assets/icons/chevron-down-primary.svg';

export const inputStyles = css`
    ${bodyTextStyles};
    padding: ${props => props.$hugged ? '5px 12px' : '12px'};
    width: 100%;
    border: 1px solid ${props => props?.errors ? 'var(--color-secondary)' : 'var(--color-primary-10)'};
    outline: none;
    border-radius: 0;
    max-height: 50px;

    &::placeholder {
        ${bodyTextStyles};
        ${props => props.$lightPlaceholder && 'color: var(--color-primary-60);'}
        font-style: italic;
    }

    &:focus {
        border: 1px solid var(--color-primary);
    }
`;

export const Input = styled.input`
    ${inputStyles};
`;

export const Select = styled.select`
    ${inputStyles};
    appearance: none;
    background-color: var(--color-white);
    background-image: url(${ChevronDown});
    background-repeat: no-repeat, repeat;
    background-position: right 14px center, 0 0;
    ${props => props.$hugged && `
        background-size: 16px;
        max-width: 426px;
        min-height: unset;
    `}
`;
