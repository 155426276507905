import styled from 'styled-components';
import {Container} from '../../components/Container/Container';
import {Headline2} from '../../components/Headlines/Headlines';
import {Spacing} from '../../components/Spacing/Spacing';
import {FAQAccordion} from '../../components/FAQAccordion/FAQAccordion';

const StyledFAQ = styled.section`
    ${Headline2} { margin-bottom: 30px; }
`;

export const FAQ = ({data}) => {
    if(!data?.faq || !data?.faq?.faqItems ) { return null; }

    return (
        <Spacing>
            <StyledFAQ>
                <Container>
                    {data?.title && <Headline2>{data?.title}</Headline2>}

                    {data?.faq?.faqItems.map(item => (
                        <FAQAccordion key={item.id} data={item} />
                    ))}
                </Container>
            </StyledFAQ>
        </Spacing>
    );
}