import {Outlet} from 'react-router-dom';
import {Menu} from '../sections/Menu/Menu';
import {MobileMenu} from '../sections/MobileMenu/MobileMenu';
import {Footer} from '../sections/Footer/Footer';
import styled from 'styled-components';
import CookieBanner from '../components/CookieBanner/CookieBanner';

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
`;

const Content = styled.main`
    margin-top: 119px; // Fixed menu's height
    min-height: calc(100vh - 119px); // To make sure the footer is always outside the screen

    @media screen and (max-width: 1024px) {
        margin-top: 135px;
        min-height: calc(100vh - 135px);
    }
`;

export async function navigationLoader({request}) {
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/navigation`, { signal: request.signal })

    // Handle if response is not ok, probably bad request so not found
    if (!res.ok) { throw new Response("Network response was not OK", { status: 400 }) }

    return res
}

// It should not revalidate to prevent extra fetching of navigation bar
export function shouldRevalidateNavigation() {
    return false;
}

export default function Layout() {
    return (
        <StyledLayout>
            <Menu/>
            <MobileMenu />

            <Content>
                <Outlet />
            </Content>

            <Footer/>
            <CookieBanner />
        </StyledLayout>
    )
}