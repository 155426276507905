import styled from "styled-components";
import {Container} from "../../components/Container/Container";
import {Headline3, Headline4} from "../../components/Headlines/Headlines";
import {Body, bodyTextStyles} from "../../components/Texts/Texts";
import {DirectToLink} from "../../components/DirectToLink/DirectToLink";
import {Button} from "../../components/Buttons/Buttons";
import {useEffect, useRef, useState} from "react";
import {useFetchData} from '../../hooks/useFetchData';
import {Spinner} from '../../components/Spinner/Spinner';
import {Review} from '../Review/Review';
import {ProductSlider} from '../ProductSlider/ProductSlider';
import {Spacing} from '../../components/Spacing/Spacing';

const StyledProductInfo = styled.article`
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    ${Container} { padding: 0 20px; }
`;

const ContentBox = styled.div`
    display: flex;
    
    @media screen and (max-width: 1280px) {
        justify-content: center;
    }

    @media screen and (max-width: 1024px) {
        margin-top: 38px;
    }
`;

const Filler = styled.div`
    flex-basis: 354px;
    flex-shrink: 0;

    @media screen and (max-width: 1280px) {
        flex-basis: unset;
        flex-shrink: unset;
    }
    
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;

const DetailBox = styled.div`
    max-width: 754px;
`;

const Text = styled.article`
    position: relative;
    margin: 16px 0;
    ${bodyTextStyles};
    max-height: ${props => props.$expanded ? 'unset' : '125px'};
    overflow: hidden;
    
    // To make sure there is no margin-top from html elements inside
    & > * {
        margin-top: 0;
    }
    
    a { ${bodyTextStyles}; }
    
    &.collapsed {
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(180deg, rgba(250,255,255,0) 0%, rgba(255,255,255,1) 100%);
            height: 100%;
            max-height: 80px;
        }
    }
`;

const ReadMoreBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    position: relative;

    ${Button} {
        background-color: var(--color-white);

        &:hover {
            background-color: var(--color-primary);
        }
    }
`;

const BookShelf = styled.hr`
    border: 1px solid var(--color-primary);
    position: absolute;
    z-index: -1;
    margin: 0;
    width: 100%;
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
`;

const SpinnerWrapper = styled(Spacing)`
    text-align: center;
`;

export const ProductInfo = ({data}) => {
    const [expanded, setExpanded] = useState(false);
    const [truncated, setTruncated] = useState(false);
    const textRef = useRef(null);

    useEffect(() => {
        // Set truncated if textRef is initialized and bigger than 5 lines (5 lines = height: 125px)
        setTruncated(() => textRef?.current?.offsetHeight > 125 || textRef?.current?.scrollHeight > 125)
    }, [textRef]);

    const shouldFetchRelatedProducts = Boolean(data?.product?.isbn) && Boolean(data?.product?.nurCode);
    const [blockData, blockError] = useFetchData(shouldFetchRelatedProducts ? `product-detail-related?nurCode=${data?.product?.nurCode}&isbn=${data?.product?.isbn}` : null);

    return (
        <>
            <Spacing>
                <StyledProductInfo>
                    <Container>
                        <ContentBox>
                            <Filler/>
                            <DetailBox>
                                {data?.product?.text &&
                                    <>
                                        <Headline3>Over dit boek</Headline3>
                                        {/* We can't use $truncated and $clamp props because there could be <p> tags (not 1 element to truncate) */}
                                        <Text ref={textRef} dangerouslySetInnerHTML={{__html: data?.product?.text}} className={truncated && (expanded ? 'expanded' : 'collapsed')} $expanded={expanded} />
                                        {/*<Text ref={textRef} dangerouslySetInnerHTML={{__html: "Test"}} className={expanded ? 'expanded' : 'collapsed'} $expanded={expanded} />*/}

                                        {truncated &&
                                            <ReadMoreBox>
                                                <Button onClick={() => setExpanded(expanded => !expanded)} as={'button'} $variant='outline' $small>{expanded ? "Minder weergeven" : "Lees meer"}</Button>
                                                <BookShelf/>
                                            </ReadMoreBox>
                                        }
                                    </>
                                }

                                <Headline4>Reserveren</Headline4>
                                <Body>{data?.product?.reserveText}</Body>

                                <Headline4>Bezorgen</Headline4>
                                <Body>{data?.product?.deliveryText}</Body>

                                <Links>
                                    {data?.product?.authorLink && <DirectToLink to={`/${data?.product?.authorLink?.url}`} $variant="outline">{data?.product?.authorLink?.label}</DirectToLink>}
                                    {data?.product?.nurLink && <DirectToLink to={`/${data?.product?.nurLink?.url}`} $variant="outline">{data?.product?.nurLink?.label}</DirectToLink>}
                                </Links>
                            </DetailBox>
                        </ContentBox>
                    </Container>
                </StyledProductInfo>
            </Spacing>

            {shouldFetchRelatedProducts && (
                blockData ? (
                    <>
                        <Review data={blockData?.review ? blockData : null} />
                        <ProductSlider data={blockData?.related ? blockData?.related : null} />
                    </>
                ) : blockError ? null : (
                    <SpinnerWrapper><Spinner /></SpinnerWrapper>
                ))
            }
        </>
    )
}