import styled from 'styled-components';
import Lamp from '../../../assets/masks/lamp.svg';
import {ReactComponent as LampOuter} from '../../../assets/masks/lamp-outer.svg';

const StyledBookMask = styled.div`
    position: relative;
    display: inline-block;
    align-self: flex-end;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        ${props => props.$position === 'left' && `left: 100%;`}
        ${props => props.$position === 'right' && `right: 100%;`}
        height: 2px;
        width: 100vw;
        background-color: ${props => props.$variant === 'primary' ? 'var(--color-primary-10)' : 'var(--color-primary)'};
    }
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 13px;
`;

const Image = styled.img`
    display: inline-block;
    width: 350px;
    max-width: 100%;
    height: 425px;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
    
    @supports(-webkit-mask-image: url(${Lamp})) or (mask-image: url(${Lamp})) {
        mask-image: url(${Lamp});
        -webkit-mask-image: url(${Lamp});
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
    }
`;

const StyledLampOuter = styled(LampOuter)`
    width: 100%;
    max-width: 379px;
    height: auto;
    max-height: 546px;
    vertical-align: bottom;
    ${props => props.$position === 'right' && `
        transform: scaleX(-1);
    `}

    path { 
        stroke: ${props => props.$variant === 'primary' ? 'var(--color-primary-10)' : 'var(--color-primary)'};
        vector-effect: non-scaling-stroke;
    }
`;

export const LampMask = ({variant, position, imageURL}) => {
    return (
        <StyledBookMask $variant={variant} $position={position}>
            {imageURL &&
                <ImageWrapper>
                    <Image src={imageURL} alt="" />
                </ImageWrapper>
            }

            <StyledLampOuter $variant={variant} $position={position} />
        </StyledBookMask>
    );
}