import {Await, defer, useLoaderData} from 'react-router-dom';
import {Seo} from '../../components/Seo/Seo';
import React from 'react';
import {AgendaHeaderSlider} from '../../sections/AgendaHeaderSlider/AgendaHeaderSlider';
import styled from 'styled-components';
import {Headline5} from '../../components/Headlines/Headlines';
import {Container} from '../../components/Container/Container';
import {AgendaItem} from '../../components/AgendaItem/AgendaItem';
import {Newsletter} from '../../sections/Newsletter/Newsletter';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledHeadline5 = styled(Headline5)`
    text-align: center;
    margin: 120px 0; 
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    row-gap: 100px;
    margin: 80px 0 160px;

    > * { grid-column: span 2; }

    ${({$remainder}) => $remainder === 1 && `
        > *:last-child { grid-column: span 6; }
    `};

    ${({$remainder}) => $remainder === 2 && `
        > *:nth-last-child(-n+2) { grid-column: span 3; }
    `};
    
    @media screen and (max-width: 1024px) {
        margin: 80px 0 100px;
    }

    @media screen and (max-width: 768px) {
        row-gap: 60px;

        > * { grid-column: span 3; }

        ${({$length}) => $length === 1 && `
            > *:last-child { grid-column: span 6; }
        `};
    }

    @media screen and (max-width: 475px) {
        row-gap: 40px;

        > * { grid-column: span 6; }

        ${({$remainder}) => $remainder === 1 && `
            > *:last-child { grid-column: span 6; }
        `};
    }
`;

export async function agendaLoader({request}) {
    const res = fetch(`${process.env.REACT_APP_API_BASE_URL}/agenda`, {
        signal: request.signal
    }).then((res) => {
        // Handle if response is not ok, bad request so probably not found
        if (!res.ok) { throw new Response("Network response was not OK, page is probably Not Found", { status: 404 }); }

        return res.json();
    });

    return defer({content: res})
}

export default function Agenda() {
    const data  = useLoaderData();
    const {width} = useWindowDimensions();

    const newsletterData = {
        "type": "newsletter",
        "title": null,
        "newsletter": {
            "id": "2faef791-af5f-425a-bfb0-99a188d8367a",
            "title": "Blijf op de hoogte",
            "description": "Ontdek als een van de eerste onze aankomende evenementen door je in te schrijven voor de nieuwsbrief.",
            "placeholderText": "Vul hier je e-mailadres in",
            "linkText": "Inschrijven",
            "thanksTitle": "Gelukt!",
            "thanksText": "Je hebt je succesvol ingeschreven voor onze nieuwsbrief. ",
            "color": "secondary"
        }
    }

    return (
        <>
            <Seo metaTitle="Agenda" />

            <React.Suspense>
                <Await resolve={data.content}>
                    {(content) => (
                        <>
                            <AgendaHeaderSlider data={content.featuredItems} />

                            <Container>
                                {content.allItems?.length ? (
                                    <Grid $length={content.allItems.length} $remainder={width >= 768 ? content.allItems.length % 3 : width >= 475 ? content.allItems.length % 2 : content.allItems.length % 1}>
                                        {content.allItems.map((item, index, {length}) => (
                                            <AgendaItem
                                                key={item.id}
                                                type={item.type}
                                                image={item.image}
                                                date={`${item.day} ${item.shortMonth}`}
                                                title={item.title}
                                                shop={item.shop}
                                                startTime={item.startTime}
                                                endTime={item.endTime}
                                                description={item.description}
                                                linkType="relative"
                                                url={item.url}
                                                linkText={item.linkText}
                                                direction={((length % 3 === 1) && (length - 1 === index)) ? 'horizontal' : 'vertical'}
                                            />
                                        ))}
                                    </Grid>
                                ) : (
                                    <StyledHeadline5>Er zijn geen aankomende evenementen</StyledHeadline5>
                                )}
                            </Container>

                            {!content.allItems?.length && <Newsletter data={newsletterData} /> }
                        </>
                    )}
                </Await>
            </React.Suspense>
        </>
    )
}