import styled from 'styled-components';
import Cookies from 'js-cookie';
import {useState} from 'react';
import {Link, useLoaderData, useSearchParams} from 'react-router-dom';
import {Body} from '../Texts/Texts';
import {Button} from '../Buttons/Buttons';
import {Headline5} from '../Headlines/Headlines';

const StyledCookieBanner = styled.div`
    position: fixed;
    left: 30px;
    bottom: 30px;
    width: 100%;
    max-width: 500px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    padding: 20px;
    border: 1px solid var(--color-primary-10);
    z-index: 98;
    
    ${Body} { margin: 0; }
    
    @media screen and (max-width: 1024px) {
        left: 20px;
        bottom: 20px;
    }

    @media screen and (max-width: 540px) {
        left: 0;
        bottom: 0;
        box-shadow: var(--box-shadow-up);
        max-width: unset;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
`;

const CookieBanner = () => {
    const { subFooterNav }  = useLoaderData();
    const [acceptedCookies, setAcceptedCookies] = useState(Boolean(Cookies.get('dekler_cookies')));
    const [searchParams,] = useSearchParams();

    const handleAcceptation = () => {
        Cookies.set('dekler_cookies', true, {expires: 365, secure: true, sameSite: 'none'});
        setAcceptedCookies(true);
    }

    if(acceptedCookies || searchParams.get("CMSPreview") === "1") return null;

    return (
        <StyledCookieBanner>
            <Headline5>Cookies</Headline5>
            <Body>Wij maken gebruik van functionele cookies om ervoor te zorgen dat de website werkt zoals verwacht. Met analytische cookies krijgen we inzicht in hoe u onze website gebruikt. <Body as={Link} to={subFooterNav?.find(item => item?.identify === "cookie-policy")?.url ?? "/"}>Lees meer</Body>.</Body>
            <ButtonWrapper>
                <Button as="button" onClick={handleAcceptation} $primary $small>Begrepen</Button>
            </ButtonWrapper>
        </StyledCookieBanner>
    );
}

export default CookieBanner;