import {Headline1} from '../../../components/Headlines/Headlines';
import {isRouteErrorResponse, useRouteError} from 'react-router-dom';
import {Toaster} from '../../../components/Toaster/Toaster';

export default function ErrorBoundary() {

    const error = useRouteError();

    //Check if response is thrown inside a loader
    if(isRouteErrorResponse(error)) {
        if(error.status === 400) {
            return <Headline1>Bad request</Headline1>
        }

        if(error.status === 404) {
            return <Headline1>De pagina kon niet gevonden worden</Headline1>
        }
    }

    return <Toaster type="danger" title="Mislukt!" message="Er is iets fout gegaan, probeer het opnieuw." $onLight />
}