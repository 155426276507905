import React from 'react';
import styled from 'styled-components';
import {Headline4} from '../Headlines/Headlines';
import {Body} from '../Texts/Texts';
import {ReactComponent as Pennant} from '../../assets/pennants/pennant.svg';
import {ReactComponent as PennantEbook} from '../../assets/pennants/pennant-ebook.svg';
import {ReactComponent as PennantEbookMobile} from '../../assets/pennants/pennant-ebook-mobile.svg';
import {ReactComponent as PennantSecondHand} from '../../assets/pennants/pennant-second-hand.svg';
import {ReactComponent as PennantSecondHandMobile} from '../../assets/pennants/pennant-second-hand-mobile.svg';
import {ReactComponent as Ebook} from '../../assets/icons/ebook.svg';
import {ReactComponent as SecondHand} from '../../assets/icons/second-hand.svg';

const Ranking = styled.div`
    position: absolute;
    top: -13px;
    left: 13px;
`;

const Desktop = styled.div`
    ${({$location}) => ($location === "CartProduct" || $location === "WishlistProduct") && `
        @media screen and (max-width: 768px) {
            display: none;    
        }
    `}
`;

const Mobile = styled.div`
    display: none;

    ${({$location}) => ($location === "CartProduct" || $location === "WishlistProduct") && `
        @media screen and (max-width: 768px) {
            display: block;
        }
    `}
`;

const Type = styled.div`
    position: absolute;
    top: 10px;
    left: -15px;
    pointer-events: none;
`;

const PennantWrapper = styled.div`
    position: relative;

    svg {
        display: block;
    }
`;

const RankingNumber = styled(Headline4).attrs({
    $white: true
})`
    width: max-content;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
`;

const TypeText = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    gap: 2px;
    
    ${Body} { 
        margin: 0; 
        line-height: 1;
    }
`;

const StyledEbook = styled(Ebook)`
    vertical-align: text-bottom;
    path { fill: var(--color-white); }
`;

const StyledSecondHand = styled(SecondHand)`
    vertical-align: text-bottom;
`;

export const ProductFlag = ({ranking, flag, location, alsoSecondHand}) => {

    if(Boolean(ranking)) {
        return (
            <Ranking>
                <PennantWrapper>
                    <Pennant />
                    <RankingNumber>{ranking}</RankingNumber>
                </PennantWrapper>
            </Ranking>
        );
    }

    if(flag === "ebook" || flag === "secondHand") {
        return (
            <>
                <Desktop $location={location}>
                    <Type>
                        <PennantWrapper>
                            {flag === "ebook" ? <PennantEbook /> : <PennantSecondHand />}
                            <TypeText>{flag === "ebook" ? <><StyledEbook /> <Body $white $bold>Ebook</Body></> : <><StyledSecondHand /> <Body $white $bold>{alsoSecondHand ? "Ook tweedehands" : "Tweedehands"}</Body></>}</TypeText>
                        </PennantWrapper>
                    </Type>
                </Desktop>

                <Mobile $location={location}>
                    <Type>
                        <PennantWrapper>
                            {flag === "ebook" ? <PennantEbookMobile /> : <PennantSecondHandMobile />}
                            <TypeText>{flag === "ebook" ? <StyledEbook /> : <StyledSecondHand />}</TypeText>
                        </PennantWrapper>
                    </Type>
                </Mobile>
            </>
        )
    }

    return null
}