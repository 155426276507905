import Cookies from "js-cookie";

export const getOrders = async (request) => {
    const getOrdersRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/orders`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getOrdersRes.ok) {
        throw new Response("Response is not OK", {status: getOrdersRes.status});
    }

    const getOrdersData = await getOrdersRes.json();

    return getOrdersData;
}

export const getOrder = async (request, id) => {
    const getOrderRes = await fetch(`${process.env.REACT_APP_API_BASE_URL}/orders/${id}`, {
        signal: request.signal,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'token': Cookies.get('titelive_webshop'),
        }
    });

    if (!getOrderRes.ok) {
        throw new Response("Response is not OK", {status: getOrderRes.status});
    }

    const getOrderData = await getOrderRes.json();

    return getOrderData;
}