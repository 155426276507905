import {Headline2, Headline3} from "../../components/Headlines/Headlines";
import {Container} from "../../components/Container/Container";
import {Subtitle2} from "../../components/Subtitles/Subtitles";
import styled from "styled-components";
import {Body} from "../../components/Texts/Texts";
import {Button, ButtonTruncate} from "../../components/Buttons/Buttons";
import {Spacing} from '../../components/Spacing/Spacing';
import {Link} from 'react-router-dom';

const StyledReview = styled.div`
    background: var(--color-${props => props.$variant});
    padding: 53px 90px 53px;
    
    @media screen and (max-width: 820px) {
        padding: 20px 30px 20px;
    }
`

const LeftBox = styled.div`
    flex-basis: 257px;
    align-self: ${props => props.$end ? 'flex-end' : 'unset'};

    @media screen and (max-width: 820px) {
        align-self: unset;
        flex-basis: unset;
        width: 100%;
    }
`

const RightBox = styled.div`
    flex-basis: 600px;
    margin-bottom: 30px;
    flex-shrink: 3;
    
    @media screen and (max-width: 820px) {
        flex-basis: unset;
    }
`

const TopBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 60px;
    
    @media screen and (max-width: 820px) {
        flex-direction: column;
        gap: 38px;
    }
`

const Author = styled.div`
    display: flex;
    align-items: center;
`;

const Avatar = styled.img`
    width: 60px;
    max-width: 100%;
    height: 60px;
    max-height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 26px;
`;

const BottomBox = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 60px;

    @media screen and (max-width: 820px) {
        flex-direction: column;
        gap: 38px;
    }
`

const BookImageWrapper = styled.div`
    @media screen and (max-width: 820px) {
        padding-left: 16px;
        border-bottom: 2px solid ${props => props.$variant === 'primary' ? 'var(--color-primary-10)' : props.$variant === 'secondary' ? 'var(--color-secondary-60)' : 'var(--color-primary)'};
    }
`;

const BookImage = styled.img`
    max-width: 257px; 
    width:100%;
    height: auto;
    object-fit: cover;
    display: block;
    
    @media screen and (max-width: 820px) {
        width: auto;
        max-height: 206px;
    }
`;

const BookShelf = styled.hr`
    border: 1px solid ${props => props.$variant === 'primary' ? 'var(--color-primary-10)' : props.$variant === 'secondary' ? 'var(--color-secondary-60)' : 'var(--color-primary)'};
    width: 100%;
    position: absolute;
    margin: 0;
    bottom: 0;
    left: -50%;
    
    @media screen and (max-width: 820px) {
        display: none;
    }
`;

const Info = styled.div``;

const AuthorName = styled(Subtitle2)`
    ${props => props.$variant === 'tertiary' &&
        `color: var(--color-secondary)`
    }
`;

export const Review = ({data}) => {
    if(!data?.review) { return null; }

    return (
        <Spacing>
            <Container>
                <StyledReview $variant={data?.review?.color}>
                    <TopBox>
                        <LeftBox>
                            <Headline2 $variant={data?.review?.color}>{data?.review?.blockTitle}</Headline2>
                        </LeftBox>
                        <RightBox>
                            <Author>
                                {data?.review?.imageURL && <Avatar src={data?.review?.imageURL} alt={data?.review?.imageTitle}/>}
                                <Info $variant={data?.review?.color}>
                                    <AuthorName $variant={data?.review?.color}>{data?.review?.imageTitle}</AuthorName>
                                    <Subtitle2 $variant={data?.review?.color}>{data?.review?.imageSubtitle}</Subtitle2>
                                </Info>
                            </Author>
                        </RightBox>
                    </TopBox>
                    <BottomBox>
                        <LeftBox $end>
                            <Link to={`/${data?.review?.url}`}>
                                <BookImageWrapper $variant={data?.review?.color}>
                                    <BookImage src={data?.review?.productImage} alt={data.review?.title} />
                                </BookImageWrapper>
                            </Link>
                        </LeftBox>
                        <RightBox>
                            <Headline3 $variant={data?.review?.color}>{data?.review?.title}</Headline3>
                            <Body $variant={data?.review?.color} dangerouslySetInnerHTML={{__html: data?.review?.description}} />
                            {data?.review?.showButton === 1 &&
                                <Button $variant={data?.review?.color === 'primary' || data?.review?.color === 'tertiary' ? 'secondary' : 'tertiary'} to={`/${data?.review?.url}`}><ButtonTruncate>{data?.review?.linkText}</ButtonTruncate></Button>
                            }
                        </RightBox>
                        <BookShelf $variant={data?.review?.color}/>
                    </BottomBox>
                </StyledReview>
            </Container>
        </Spacing>
    )
}