import {ReactComponent as EmptyShelf} from '../../assets/images/empty-shelf.svg';
import {Headline5} from '../Headlines/Headlines';
import {Body} from '../Texts/Texts';
import React from 'react';
import styled from 'styled-components';

const EmptyBooks = styled.article`
    padding: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    ${Body} { margin: 8px 0; }
`;

const EmptyShelfBox = styled.div`
    margin-bottom: 20px;
    width: 100%;
    
    svg { width: 100% }
`;

export const EmptyState = ({heading, description}) => {

    return (
        <EmptyBooks>
            <EmptyShelfBox>
                <EmptyShelf />
            </EmptyShelfBox>
            <Headline5>{heading}</Headline5>
            <Body>{description}</Body>
        </EmptyBooks>
    )
}