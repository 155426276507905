import {Navigate, Outlet, useLocation, useRouteLoaderData} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Toaster} from '../../components/Toaster/Toaster';
import {useEffect} from 'react';

export function OrderFlowProtector() {
    // const { cartData } = useRouteLoaderData("root");
    const { cartData } = useRouteLoaderData("orderLayout");
    const { pathname } = useLocation();

    const allowedInOrderFlow = cartData?.products?.length !== 0;
    const allowedInDetailsRoute = allowedInOrderFlow && (cartData?.deliveryType === 2 || cartData?.deliveryType === 4 || (cartData?.deliveryType === 1 && cartData?.shop));
    const allowedInPaymentRoute = allowedInDetailsRoute && cartData?.invoiceAddress;

    useEffect(() => {
        if (!allowedInOrderFlow) {
            toast(<Toaster type='warning' title='Let op' message='Voeg eerst artikelen toe aan je winkelmandje om te kunnen bestellen.' $onLight/>);
        }

        if(allowedInOrderFlow && pathname.startsWith('/bestellen/jouw-gegevens') && !allowedInDetailsRoute) {
            toast(<Toaster type='warning' title='Let op' message='Selecteer eerst een leveringsoptie.' $onLight/>);
        }

        if (allowedInOrderFlow && pathname.startsWith('/bestellen/betaling')) {
            if(!allowedInDetailsRoute) toast(<Toaster type='warning' title='Let op' message='Selecteer eerst een leveringsoptie.' $onLight/>);
            if(allowedInDetailsRoute && !allowedInPaymentRoute) toast(<Toaster type='warning' title='Let op' message='Vul eerst jouw adresgegevens in.' $onLight/>);
        }
    }, [allowedInOrderFlow, allowedInDetailsRoute, allowedInPaymentRoute, pathname])

    // Protect all orderflow urls if a cart exits
    if (!allowedInOrderFlow) return <Navigate to="/winkelmandje" replace />;

    // When navigating to /bestellen/jouw-gegevens, check if you are allowed
    if (allowedInOrderFlow && pathname.startsWith('/bestellen/jouw-gegevens') && !allowedInDetailsRoute) return <Navigate to="/bestellen/levering" replace />;

    // When navigating to /bestellen/betaling, check if you are allowed
    if (allowedInOrderFlow && pathname.startsWith('/bestellen/betaling')) {
        if(!allowedInDetailsRoute) return <Navigate to="/bestellen/levering" replace />;
        if(allowedInDetailsRoute && !allowedInPaymentRoute) return <Navigate to="/bestellen/jouw-gegevens" replace />;
    }

    return <Outlet />
}