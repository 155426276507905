import {logOut} from '../../api/authentication';
import Cookies from 'js-cookie';
import {toast} from 'react-toastify';
import {Toaster} from '../../components/Toaster/Toaster';
import React from 'react';
import {getTokens} from '../../api/token';

export async function logoutAction({request}) {

    try {
        const logOutData = await logOut(request);

        Cookies.remove('titelive_webshop');
        Cookies.remove('titelive_webshoprefresh');

        try {
            const getTokensData = await getTokens();

            return ({logOutData: logOutData, tokensData: getTokensData});
        } catch (err) {
            toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
        }

        return ({logOutData: logOutData});
    } catch(err) {
        return toast(<Toaster type='danger' title='Mislukt!' message='Er is iets fout gegaan, probeer het opnieuw.' $onLight/>);
    }
}

export default function Logout() {

    return (
        <></>
    )
}