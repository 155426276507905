// item_list_name and item_list_id constants
export const PRODUCT_OVERVIEW_LIST = {
    name: "Boeken overzicht",
    id: "products_overview"
}

export const CART_LIST = {
    name: "Winkelmandje",
    id: "cart"
}

export const WISHLIST_LIST = {
    name: "Verlanglijstje",
    id: "wishlist"
}