import React, {useEffect} from "react";
import styled from "styled-components";
import {Await, defer, Link, useLoaderData} from "react-router-dom";
import {Container} from "../../../components/Container/Container";
import {Headline3, Headline5} from "../../../components/Headlines/Headlines";
import {Subtitle2} from "../../../components/Subtitles/Subtitles";
import {Body, bodyTextStyles} from "../../../components/Texts/Texts";
import {Button, OptionalButton} from "../../../components/Buttons/Buttons";
import {ReactComponent as ChevronLeft} from "../../../assets/icons/chevron-left.svg";
import {Seo} from '../../../components/Seo/Seo';
import {rootLoaderPromise} from '../../../layouts/Root';
import {getOrder} from '../../../api/orders';

const Wrapper = styled.div``

const BackButton = styled(Button)`
    max-width: 116px;
    margin: 40px 0 40px;

    @media screen and (max-width: 768px) {
        margin: 16px 0 40px;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-primary-10);
    
    @media screen and (max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 40px;
    }
`

const HeaderLeft = styled.div`
    ${Headline3}{
        margin-bottom: 40px;

        @media screen and (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
    ${Body}{
        margin: 0;
    }
`
const HeaderRight = styled.div`
    ${Body}{
        margin: 0;
    }
`

const Details = styled.div`
    margin: 50px 0 50px;

    @media screen and (max-width: 768px) {
        margin: 30px 0 30px;
        gap: 10px;
    }
`

const Products = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    
    @media screen and (max-width: 768px) {
        gap: 10px;
        margin-bottom: 10px;
    }
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    padding: 8px 34px 8px;
    background-color: var(--color-white);
    box-shadow: 0 4px 4px 0 #9696960D;
    
    @media screen and (max-width: 1024px) {
        gap: 40px;
    }
    
    @media screen and (max-width: 768px) {
        align-items: unset;
        padding: 35px 21px 35px;
    }
`

const ImageBox = styled.div`
    max-width: 88.5px;
    width: 100%;
    height: 129px;
    display: flex;
    align-items: center;
`

const ImageWrapper = styled(Link)`
    width: 100%;
`

const Image = styled.img`
    max-width: 81px;
    width: 100%;
    object-fit: contain;
    object-position: bottom;
    display: block;
`

const BookShelf = styled.hr`
    border: 1px solid var(--color-primary);
    margin: 0;
    width: 100%;
`;

const Info = styled.div`
    flex: auto;
    display: flex;
    justify-content: space-between;
    gap: 3px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`

const Specs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    
    ${Body} {
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        gap: 3px;
    }
`

const Title = styled(Link)`
    text-decoration: none;
    color: var(--color-primary);

    &:hover {
        text-decoration: underline;
    }
    
    ${Headline5} {
        @media screen and (max-width: 768px) {
            ${bodyTextStyles};
        }   
    }
`

const PriceEbook = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    ${Button} { width: 160px; }
    
    @media screen and (max-width: 768px) {
        flex-direction: row;
        align-items: unset;
    }

    @media screen and (max-width: 475px) {
        flex-direction: column;
        gap: 20px;

        ${Button} { align-self: flex-end; }
    }
`;

const BottomBox = styled.div`
    display: flex;
    //justify-content: space-between;
    align-items: center;
    gap: 45px;
    padding: 22px 34px 22px;
    background-color: var(--color-white);

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 42px 24px 42px;
    }
`

const Addresses = styled.div`
    display: flex;
    flex-basis: 500px;
    gap: 45px;
    
    ${Body}{
        margin: 0;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 30px;
        width: 100%;
        flex-basis: unset;
    }
`

const AddressBox = styled.div`
    flex-grow: 1
`

const Costs = styled.div`
    flex-basis: 355px;
    margin-left: auto;
    align-self: flex-end;
    
    @media screen and (max-width: 768px) {
        margin-left: 0;
        width: 100%;
        flex-basis: 100%;
    }
`

const CostTable = styled.div`
    border-bottom: 1px solid var(--color-primary-10);

    ${Body}{
        margin: 0 0 10px;
    }
    
    margin-bottom: 20px;
    padding-bottom: 10px;
`

const CostItem = styled.div`
    display: flex;
    justify-content: space-between;
`

const CostOverview = styled.div`
    display: flex;
    justify-content: space-between;
`

export async function orderDetailLoader({request, params}) {
    await rootLoaderPromise;

    let getOrderData;
    try {
        getOrderData = await getOrder(request, params?.id);
    } catch (err) {
        throw new Response("Page is not found", { status: 404 });
    }

    return defer({orderData: getOrderData});
}

export default function OrderDetail() {
    const {orderData} = useLoaderData();

    useEffect(()  => {
        document.body.style.backgroundColor = '#FAFAFA';
        return () => {
            document.body.style.removeProperty('background-color');
        };
    });

    return (
        <>
            <Seo metaTitle="Mijn bestellingen" />
            <React.Suspense>
                <Await resolve={orderData}>
                    {(order) => (
                        <Container>
                            <Wrapper>
                                <BackButton to=".." relative="path" $variant="outline" $icon><ChevronLeft/>Terug</BackButton>

                                <Header>
                                    <HeaderLeft>
                                        <Headline3>Details bestelling</Headline3>

                                        <Body>
                                            <strong>Besteld op: </strong>{order?.date}<br/>
                                            <strong>Bestelnummer: </strong>{order?.id}
                                        </Body>
                                    </HeaderLeft>

                                    <HeaderRight>
                                        <Body $bold>Factuur volgt per mail</Body>
                                    </HeaderRight>
                                </Header>

                                <Details>
                                    <Products>
                                        {order?.products?.map((product, index) => (
                                            <Row key={index}>
                                                <ImageBox>
                                                    <ImageWrapper to={`/${product?.url}`}>
                                                        <Image src={product?.image} alt={product?.title}/>
                                                        <BookShelf/>
                                                    </ImageWrapper>
                                                </ImageBox>

                                                <Info>
                                                    <Specs>
                                                        <Subtitle2>{product?.author}</Subtitle2>
                                                        <Title to={`/${product?.url}`}>
                                                            <Headline5 $bold>{product?.quantity > 1 && `${product?.quantity}x`} {product?.title}</Headline5>
                                                        </Title>
                                                        <Body>{product?.secondhand ? "Tweedehands" : product?.productForm}</Body>
                                                        <Body>ISBN: {product?.isbn}</Body>
                                                    </Specs>

                                                    <PriceEbook>
                                                        <Headline5>{product?.totalPrice ?? "Fout"}</Headline5>
                                                        <OptionalButton to={product?.eBookLink} linkType="external" $variant="outline" $small>Ebook downloaden</OptionalButton>
                                                    </PriceEbook>
                                                </Info>
                                            </Row>
                                        ))}
                                    </Products>

                                    <BottomBox>
                                        <Addresses>
                                            <AddressBox>
                                                <Body>
                                                    <strong>Factuuradres</strong><br/>
                                                    {order?.invoiceAddress?.firstName} {order?.invoiceAddress?.lastName}<br/>
                                                    {order?.invoiceAddress?.addressLine1} {order?.invoiceAddress?.number} {order?.invoiceAddress?.postbus}<br/>
                                                    {order?.invoiceAddress?.postcode}, {order?.invoiceAddress?.city}<br/>
                                                    {order?.invoiceAddress?.country}
                                                </Body>
                                            </AddressBox>

                                            {order?.deliveryType === 4 &&
                                                <AddressBox>
                                                    <Body>
                                                        <strong>Bezorgadres</strong><br/>
                                                        {order?.deliveryAddress?.firstName} {order?.deliveryAddress?.lastName}<br/>
                                                        {order?.deliveryAddress?.addressLine1} {order?.deliveryAddress?.number} {order?.deliveryAddress?.postbus}<br/>
                                                        {order?.deliveryAddress?.postcode}, {order?.deliveryAddress?.city}<br/>
                                                        {order?.deliveryAddress?.country}
                                                    </Body>
                                                </AddressBox>
                                            }

                                            {order?.deliveryType === 1 &&
                                                <AddressBox>
                                                    <Body>
                                                        <strong>Afhaaladres</strong><br/>
                                                        {order?.deliveryAddress?.addressLine1}<br/>
                                                        {order?.deliveryAddress?.addressLine2} {order?.deliveryAddress?.number} {order?.deliveryAddress?.postbus}<br/>
                                                        {order?.deliveryAddress?.postcode}, {order?.deliveryAddress?.city}<br/>
                                                        {order?.deliveryAddress?.country}
                                                    </Body>
                                                </AddressBox>
                                            }
                                        </Addresses>

                                        <Costs>
                                            <CostTable>
                                                <CostItem>
                                                    <Body>Totaal artikelen</Body>
                                                    <Body $bold>{order?.price ?? "Fout"}</Body>
                                                </CostItem>

                                                {order?.deliveryType !== 1 &&
                                                    <CostItem>
                                                        <Body>Verzendkosten</Body>
                                                        <Body $bold>{order?.shipping ?? "Fout"}</Body>
                                                    </CostItem>
                                                }
                                            </CostTable>

                                            <CostOverview>
                                                <Headline5>Totaal</Headline5>
                                                <Headline5>{order?.totalPrice ?? "Fout"}</Headline5>
                                            </CostOverview>
                                        </Costs>
                                    </BottomBox>
                                </Details>
                            </Wrapper>
                        </Container>
                    )}
                </Await>
            </React.Suspense>
        </>
    )
}