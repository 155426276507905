import styled from 'styled-components';
import {bodyTextStyles} from '../Texts/Texts';
import {headline3Styles} from '../Headlines/Headlines';

export const HTMLText = styled.div`
    ${bodyTextStyles};
    
    p:first-of-type { margin-top: 0; }
    
    h3 {
        ${headline3Styles};
        margin: 0;
    }

    ul, ol {
        display: flex;
        flex-direction: column;
    }

    li { margin:0; }
    strong { font-weight: 700; }
    em { font-style: italic; }

    a {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;