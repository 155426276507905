import {useSideDrawerContext} from '../contexts/SideDrawerContext';
import {createPortal} from 'react-dom';
import {AccountSideDrawer} from '../components/SideDrawers/AccountSideDrawer/AccountSideDrawer';
import {WishlistSideDrawer} from '../components/SideDrawers/WishlistSideDrawer/WishlistSideDrawer';
import {CartSideDrawer} from '../components/SideDrawers/CartSideDrawer/CartSideDrawer';
import {defer, Outlet, useLoaderData} from 'react-router-dom';
import {GuestSideDrawer} from '../components/SideDrawers/AccountSideDrawer/GuestSideDrawer';
import {rootLoaderPromise} from './Root';
import {getCart} from '../api/cart';
import {useEffect} from 'react';
import {useProductsInCartContext} from '../contexts/ProductsInCartContext';

export async function sideDrawerLoader({request}) {
    await rootLoaderPromise;

    const url = new URL(request.url);

    if (url?.pathname.startsWith('/winkelmandje')) {
        return {"shoppingCart": true};
    }

    let getCartData;
    try {
        getCartData = getCart(request);
    } catch (err) {
        // Catching err to prevent going in errorElement
    }

    return defer({cartData: getCartData});
}

// It should not revalidate to prevent fetching cart after each action
export function shouldRevalidateSideDrawer() {
    return false;
}

export default function SideDrawerLayout() {
    const {state, closeDrawer} = useSideDrawerContext();
    const {cartData} = useLoaderData();
    const {addInitialCart} = useProductsInCartContext();

    // Initially set the isbnsWithGiftwrapInCart
    useEffect(() => {
        if(cartData) {
            addInitialCart(cartData);
        }
        // eslint-disable-next-line
    }, [cartData])

    return (
        <>
            {createPortal(
                <>
                    <AccountSideDrawer isOpen={state.isAccountOpen} close={() => closeDrawer('Account')} />
                    <GuestSideDrawer isOpen={state.isGuestOpen} close={() => closeDrawer('Guest')} />
                    <WishlistSideDrawer isOpen={state.isWishlistOpen} close={() => closeDrawer('Wishlist')} />
                    <CartSideDrawer isOpen={state.isCartOpen} close={() => closeDrawer('Cart')} />
                </>,
                document.body
            )}

            <Outlet />
        </>
    )
}